import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import Spinner from '../../../App/components/Spinner/Spinner';

import styles from './OnEnter.css';

const OnEnter = ({ children, func }) => {
  const params = useParams();
  const location = useLocation();
  const [query] = useSearchParams();
  location.query = Object.fromEntries(query.entries());

  const store = useStore();
  const navigate = useNavigate();
  const [done, setDone] = useState();
  const replace = useCallback(url => navigate(url, { replace: true }), [navigate]);
  const callback = useCallback(() => {
    setDone(true);
  }, [setDone]);
  useEffect(() => {
    if (store) {
      func(store, { params, location }, replace, callback);
    }
  }, [store, func, replace, callback]);

  return done ? <>{children}</> : <div className={styles.root}><Spinner /></div>;
};

OnEnter.propTypes = {
  children: PropTypes.node.isRequired,
  func: PropTypes.func.isRequired,
};

export default OnEnter;
