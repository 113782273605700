import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { enabledLanguages } from '../../../../../Intl/setup';
import { setLocale } from '../../../../AppActions';
import { getI18n } from '../../../../AppReducer';
// Import Style
import styles from './Footer.css';

function Footer(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const isHomePage = ['/en', '/es', '/', ''].indexOf(location.pathname) >= 0;

  const switchLanguage = (event, newLang) => {
    event.preventDefault();
    props.setLocale(newLang);
    if (isHomePage) {
      navigate(`/${newLang}`);
    }
  };

  const languageNodes = enabledLanguages.map(lang => (
    <li key={lang} className={lang === props.locale ? styles.selected : ''}>
      <a href={`${isHomePage ? '/' : '#'}${lang}`} onClick={event => switchLanguage(event, lang)}>{lang}</a>
    </li>
  ));
  return (
    <div className={styles.footer}>
      <Grid className={styles['main-grid']} fluid>
        <Row className={styles.row}>
          <Col xs={11} sm={11} md={4}>
            <h3><FormattedMessage id="Footer.Col1" defaultMessage="Integrations and doubts" /></h3>
            <ul className={styles.footerLinks}>
              <li><a href="/apidocs">API</a></li>
              <li>
                <Link to="/help">
                  <FormattedMessage id="Footer.Help" defaultMessage="Help" />
                </Link>
              </li>
              <li>
                <a href="mailto:hello@promoter.ninja">
                  <FormattedMessage id="Footer.Contact" defaultMessage="Contact Us" />
                </a>
              </li>
            </ul>
          </Col>
          <Col xs={11} sm={11} md={4}>
            <h3><FormattedMessage id="Footer.Col2" defaultMessage="Legal" /></h3>
            <ul className={styles.footerLinks}>
              <li>
                <Link to="/legal/privacy"><FormattedMessage id="Footer.Privacy" defaultMessage="Privacy Policy" /></Link>
              </li>
              <li><Link to="/legal/terms"><FormattedMessage id="Footer.Terms" defaultMessage="Terms of Use" /></Link></li>
              <li>
                <Link to="/legal/cookies"><FormattedMessage id="Footer.Cookies" defaultMessage="Cookie Policy" /></Link>
              </li>
            </ul>
          </Col>
          {props.locale === 'es'
            ? (
              <Col xs={11} sm={11} md={4}>
                <h3><FormattedMessage id="Footer.Col3" defaultMessage="Attributes" /></h3>
                <ul className={styles.footerLinks}>
                  <li><a href="calculadora-net-promoter-score.html">Calculadora Net Promoter Score</a></li>
                  <li><a href="net-promoter-score.html">Net Promoter Score - NPS</a></li>
                  <li><a href="encuesta-nps-gratis.html">Encuesta NPS gratis</a></li>
                  <li><a href="encuesta-satisfaccion-clientes.html">Encuesta de Satisfacción de Clientes</a></li>
                </ul>
              </Col>
            ) : null
          }
        </Row>
        <Row className={styles.languageSwitcher}>
          <Col xs={12} sm={12}>
            <FormattedMessage
              id="switchLanguage"
              defaultMessage="Switch language"
            />
            <ul>
              {languageNodes}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <p>
              &copy;
              {new Date().getFullYear()}
              {' '}
              &middot; Promoter Ninja
            </p>
            <FormattedMessage
              id="Footer.NPS"
              defaultMessage="Net Promoter, NPS are registered service marks, and Net Promoter Score and
          Net Promoter System are service marks, of Bain & Company, Inc., Satmetrix Systems, Inc. and Fred Reichheld."
            >
              {msg => <p>{msg}</p>}
            </FormattedMessage>
          </Col>
        </Row>
      </Grid>
    </div>
  );
}

// Retrieve data from store as props
const mapStateToProps = (state) => ({
  locale: getI18n(state).locale,
});

const mapDispatchToProps = dispatch => ({
  setLocale(newLang) {
    dispatch(setLocale(newLang));
  },
});

Footer.propTypes = {
  locale: PropTypes.string.isRequired,
  setLocale: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
