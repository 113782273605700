import {
  createApi,
} from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithReauth from 'client/util/baseQueryWithReauth';

export const helpSlice = createApi({
  reducerPath: 'helpReducer',
  baseQuery: baseQueryWithReauth,
  // eslint-disable-next-line consistent-return
  tagTypes: ['category', 'content'],
  endpoints: builder => ({
    listCategories: builder.query({
      query: locale => `/public/category/?l=${locale}`,
      providesTags: (categories) => {
        return [
          ...(categories || []).map(({ _id: id }) => ({
            type: 'category',
            id,
          })),
          {
            type: 'category',
            id: 'LIST',
          },
        ];
      },
    }),
    listContents: builder.query({
      query: ({ category, locale }) => `/public/category/${category}/?l=${locale}`,
      providesTags: (contents) => {
        return [
          ...(contents || []).map(({ _id: id }) => ({
            type: 'content',
            id,
          })),
          {
            type: 'content',
            id: 'LIST',
          },
        ];
      },
    }),
    readContent: builder.query({
      query: ({ category, locale, article }) => `/public/category/${category}/${article}/?l=${locale}`,
      providesTags: (content) => {
        return [...(content ? [{
          type: 'content',
          id: content._id,
        }] : []),
        {
          type: 'content',
          id: 'LIST',
        },
        ];
      },
    }),
  }),
});

export const {
  useListCategoriesQuery,
  useListContentsQuery,
  useReadContentQuery,
  reducer: helpReducer,
} = helpSlice;
