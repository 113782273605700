import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import {
  defineMessages, FormattedMessage, injectIntl, useIntl,
} from 'react-intl';
import Formsy from 'formsy-react';
import FormsyText from 'components/FormsyText/FormsyText';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import { useNavigate } from 'react-router-dom';
import { setError } from '../../../App/AppActions';
import { sendPasswordReminder } from '../../AuthActions';
import { getI18n } from '../../../../AppReducer';
import { loginMessages } from './components/LoginWidget/LoginWidget';
import LayoutFocused from '../../../App/components/LayoutFocused/LayoutFocused';

import styles from './Login.css';

const messages = defineMessages({
  title: {
    id: 'ForgotPassword.Title',
    defaultMessage: 'Password reminder',
  },
  send: {
    id: 'ForgotPassword.Send',
    defaultMessage: 'Send',
  },
});

const ForgotPasswordPage = (props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  let emailInput;
  const handleSubmit = async () => {
    try {
      await props.sendPasswordReminder(emailInput.getValue(), props.locale);
      navigate('/forgot-password-sent');
    } catch (e) {
      props.setError(formatMessage('ForgotPassword.UnexpectedError'));
    }
  };
  return (
    <LayoutFocused title={formatMessage(messages.title)}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <Formsy.Form
              className={styles.form}
              onValidSubmit={handleSubmit}
            >
              <FormsyText
                id="email"
                name="email"
                validations="isEmail"
                ref={(el) => {
                  emailInput = el;
                }}
                validationError={formatMessage(loginMessages.invalidEmail)}
                floatingLabelText={formatMessage(loginMessages.email)}
                required
                autoFocus
                fullWidth
              />
              <p style={{ zIndex: 2, position: 'relative' }}>
                <FormattedMessage
                  id="ForgotPassword.Description"
                  defaultMessage={'Enter the e-mail address you used when you signed up for this site. '
                  + 'We will then send you an e-mail that you can use to generate a new password.'}
                />
              </p>
              <div>
                <RaisedButton
                  type="submit"
                  primary
                  label={formatMessage(messages.send)}
                  className={styles.submit}
                />
              </div>
            </Formsy.Form>
          </Col>
        </Row>
      </Grid>
    </LayoutFocused>
  );
};

ForgotPasswordPage.propTypes = {
  sendPasswordReminder: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  locale: getI18n(state).locale,
});

const mapDispatchToProps = dispatch => ({
  sendPasswordReminder(email, locale) {
    dispatch(sendPasswordReminder(email, locale));
  },
  setError(msg) {
    dispatch(setError(msg));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotPasswordPage));
