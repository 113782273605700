import { Box } from '@mui/material';
import { getI18n } from 'client/AppReducer';
import App from 'client/modules/App/App';
import { helpSlice, useListCategoriesQuery, useReadContentQuery } from 'client/modules/Help/helpSlice';
import Layout from 'components/Layout/LayoutContainer';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import styles from './Help.css';

const HelpLayout = ({ store }) => {
  const { category, article } = useParams();
  const { locale } = useIntl();
  const { data: categories } = useListCategoriesQuery(locale);
  const { data: articleObj } = useReadContentQuery({ article, category, locale }, { skip: !article });
  const categoryObj = categories?.find(c => c.slug === category);
  return (
    <Layout backContent={(
      category ? (
        <Box className={styles.leftNav}>
          <ul className={styles.leftNav}>
            <li>
              <Link to="/help">
                <FormattedMessage
                  id="help.home"
                  defaultMessage="Help Center"
                />
              </Link>
            </li>
            <li>
              {article ? (
                <Link to={`/help/${category}`}>
                  {categoryObj.name}
                </Link>
              ) : (
                categoryObj.name
              )}
            </li>
            {article && articleObj ? (
              <li>
                {articleObj.title}
              </li>
            ) : null}
          </ul>
        </Box>
      ) : null
    )}
    >
      <Box className={styles.main}>
        <App store={store} />
      </Box>
    </Layout>
  );
};

HelpLayout.propTypes = {
  store: PropTypes.object.isRequired,
};

HelpLayout.loader = ({ dispatch, getState }) => ({ params: { category, article } }) => {
  const { locale } = getI18n(getState());
  dispatch(helpSlice.endpoints.listCategories.initiate(locale));
  if (category) {
    dispatch(helpSlice.endpoints.listContents.initiate({ category, locale }));
  }
  if (article) {
    dispatch(helpSlice.endpoints.readContent.initiate({ article, category, locale }));
  }
  dispatch(helpSlice.endpoints.readContent.initiate(locale));
  return Promise.all(dispatch(helpSlice.util.getRunningQueriesThunk()));
};

export default HelpLayout;
