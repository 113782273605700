import PropTypes from 'prop-types';
import React from 'react';
import styles from './ChatBubble.css';

const ChatBubble = props => (
  <div className={styles[props.from]}>
    {props.children}
  </div>
);

ChatBubble.defaultProps = {
  children: null,
  from: 'them',
};

ChatBubble.propTypes = {
  children: PropTypes.any,
  from: PropTypes.oneOf(['them', 'me']),
};

export default ChatBubble;
