import { Add } from '@mui/icons-material';
import {
  Box, Button, Tooltip, Typography,
} from '@mui/material';
import AudienceCard from 'client/modules/Surveys/pages/CustomizeSurvey/AudienceCard';
import QuestionLogicCard from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionLogicCard';
import { getId, getSurvey } from 'client/modules/Surveys/SurveyReducer';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import InfoIcon from '@mui/icons-material/Info';
import { useSelector } from 'react-redux';

const styles = {
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    my: 2,
  },
  button: {
    borderRadius: 2,
    border: '1px dashed #e0e0d9',
    width: '100%',
    p: 4,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
};

const EditQuestionAudience = () => {
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'audiences',
  });
  const handleAdd = useCallback(() => {
    append({ conditions: [{ operator: 'in', value: [], field: '' }] });
  }, []);
  return (
    <Box>
      <Box sx={styles.title}>
        <Typography component="h3" sx={{ fontWeight: 'bold' }}>
          <FormattedMessage id="EditQuestionAudience.Title" defaultMessage="Who will see this question?" />
        </Typography>
        <Tooltip title={(
          <FormattedMessage
            id="EditQuestionAudience.Description"
            defaultMessage="Select the audiences that will see this question"
          />
        )}
        >
          <InfoIcon color="disabled" fontSize="small" />
        </Tooltip>
      </Box>
      <Box sx={styles.list}>
        {fields.map((field, index) => {
          return (
            <AudienceCard
              key={field.id}
              index={index}
              remove={() => remove(index)}
            />
          );
        })}
        <Button sx={styles.button} startIcon={<Add />} onClick={handleAdd}>
          <FormattedMessage
            id="EditQuestionAudience.AddNewAudience"
            defaultMessage="Add new audience"
          />
        </Button>
      </Box>
    </Box>
  );
};

export default EditQuestionAudience;
