import { Add, RemoveCircleOutline } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import {
  Box,
  Button,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import FieldWithUnit from 'client/modules/Surveys/pages/CustomizeSurvey/FieldWithUnit';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  triggers: {
    id: 'TargetingTriggers.Triggers',
    defaultMessage: 'Triggers',
  },
  triggersDescription: {
    id: 'TargetingTriggers.TriggersDescription',
    defaultMessage: 'Decide when to show the survey based on user behavior.',
  },
  pageRules: {
    id: 'TargetingTriggers.PageRules',
    defaultMessage: 'Page rules',
  },
  pageRulesDescription: {
    id: 'TargetingTriggers.PageRulesDescription',
    defaultMessage: 'Decide which pages to show the survey on.',
  },
  allPages: {
    id: 'TargetingTriggers.AllPages',
    defaultMessage: 'All pages',
  },
  certainPages: {
    id: 'TargetingTriggers.CertainPages',
    defaultMessage: 'Only certain pages',
  },
  showOn: {
    id: 'TargetingTriggers.ShowOn',
    defaultMessage: 'Show the survey on the following pages',
  },
  dontShowOn: {
    id: 'TargetingTriggers.DontShowOn',
    defaultMessage: "Don't show the survey on the following pages",
  },
  addNewPages: {
    id: 'TargetingTriggers.AddNewPages',
    defaultMessage: 'Add new pages',
  },
  instantly: {
    id: 'TargetingTriggers.Instantly',
    defaultMessage: 'Instantly when a user lands on the page',
  },
  afterDelay: {
    id: 'TargetingTriggers.AfterDelay',
    defaultMessage: 'After page load with delay',
  },
  exitIntent: {
    id: 'TargetingTriggers.ExitIntent',
    defaultMessage: 'When a user is about to exit',
  },
  scrolled: {
    id: 'TargetingTriggers.Scrolled',
    defaultMessage: 'When a user has scrolled the page',
  },
  triggersEvent: {
    id: 'TargetingTriggers.TriggersEvent',
    defaultMessage: 'When a user triggers an event',
  },
  ofThePage: {
    id: 'TargetingTriggers.OfThePage',
    defaultMessage: 'of the page',
  },
  dontShowIfAnswered: {
    id: 'TargetingTriggers.DontShowIfAnswered',
    defaultMessage:
      "Don't show this survey if a visitor has answered or closed other surveys in a current visit",
  },
  closeOnUrlChange: {
    id: 'TargetingTriggers.CloseOnUrlChange',
    defaultMessage: 'Close this survey when URL changes in a Single Page Application',
  },
  sampling: {
    id: 'TargetingTriggers.Sampling',
    defaultMessage: 'Sampling',
  },
  samplingDescription: {
    id: 'TargetingTriggers.SamplingDescription',
    defaultMessage: 'Control the percentage of visitors who see your survey.',
  },
  adaptiveSampling: {
    id: 'TargetingTriggers.AdaptiveSampling',
    defaultMessage: 'Use adaptive sampling',
  },
  adaptiveSamplingTooltip: {
    id: 'TargetingSampling.AdaptiveSamplingTooltip',
    defaultMessage: `
      <p>We automatically take care of sampling to optimize the rate of feedback.</p>
      <p>This is based on a number of factors, including your current plan and site traffic.</p>
      <p>As we learn more about the traffic to your site, the sampling rate will update in real time, 
      ensuring you receive a steady stream of feedback throughout the entire month.</p>
      <p>This makes it easier to track changes over time, and also helps to ensure the feedback is unbiased.</p>
    `,
  },
  sendEmailAfterWebSurvey: {
    id: 'TargetingTriggers.SendEmailAfterWebSurvey',
    defaultMessage: 'Send email if web survey is not answered',
  },
  emailSendDelay: {
    id: 'TargetingTriggers.EmailSendDelay',
    defaultMessage: 'Send email after',
  },
  delaySeconds: {
    id: 'TargetingTriggers.DelaySeconds',
    defaultMessage: 'Delay (seconds)',
  },
  dismissThrottle: {
    id: 'TargetingScheduling.DismissThrottle',
    defaultMessage: 'If not answered, show again after',
  },
  dontShowOnCertainPages: {
    id: 'TargetingTriggers.DontShowOnCertainPages',
    defaultMessage: "Don't show on certain pages",
  },
  exactUrlMatch: {
    id: 'TargetingTriggers.ExactUrlMatch',
    defaultMessage: 'Exact URL match',
  },
  contains: {
    id: 'TargetingTriggers.Contains',
    defaultMessage: 'Contains',
  },
  regex: {
    id: 'TargetingTriggers.Regex',
    defaultMessage: 'Regex',
  },
});

const RuleFields = ({ name }) => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const { formatMessage } = useIntl();

  return (
    <Box>
      {fields.map((ruleField, index) => (
        <Box key={ruleField.id} display="flex" alignItems="center" mb={2}>
          <Controller
            name={`${name}.${index}.type`}
            control={control}
            defaultValue="exact"
            render={({ field }) => (
              <FormControl sx={{ minWidth: 220, mr: 2 }}>
                <Select {...field} size="small">
                  <MenuItem value="exact">{formatMessage(messages.exactUrlMatch)}</MenuItem>
                  <MenuItem value="contains">{formatMessage(messages.contains)}</MenuItem>
                  <MenuItem value="regex">{formatMessage(messages.regex)}</MenuItem>
                </Select>
              </FormControl>
            )}
          />
          <Controller
            name={`${name}.${index}.value`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                size="small"
                fullWidth
                placeholder="e.g. www.website.com/page.html"
                sx={{ mr: 2 }}
              />
            )}
          />
          <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button startIcon={<Add />} onClick={() => append({ type: 'exact', value: '' })}>
        {formatMessage(messages.addNewPages)}
      </Button>
    </Box>
  );
};

RuleFields.propTypes = {
  name: PropTypes.string.isRequired,
};

const TargetingWeb = () => {
  const { control, watch } = useFormContext();
  const { formatMessage } = useIntl();
  const triggerOn = watch('triggers.triggerOn');
  const sendEmailAfterWebSurvey = watch('sendEmailAfterWebSurvey');

  return (
    <CardContent
      sx={{
        borderWidth: 0,
        borderColor: 'muted.lighter',
        borderStyle: 'solid',
        borderTopWidth: 1,
      }}
    >
      <Typography variant="h6">{formatMessage(messages.triggers)}</Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 3 }}>
        {formatMessage(messages.triggersDescription)}
      </Typography>

      <Box mb={4}>
        <Controller
          name="triggers.triggerOn"
          control={control}
          defaultValue="instantly"
          render={({ field: triggerOnField }) => (
            <RadioGroup {...triggerOnField}>
              <FormControlLabel
                value="instantly"
                control={<Radio />}
                label={formatMessage(messages.instantly)}
              />
              <FormControlLabel
                value="delay"
                control={<Radio />}
                label={formatMessage(messages.afterDelay)}
              />
              {triggerOn === 'delay' && (
                <Box ml={4} mt={1} mb={2}>
                  <Controller
                    name="timeDelayOnPage"
                    control={control}
                    defaultValue={5}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="number"
                        size="small"
                        label={formatMessage(messages.delaySeconds)}
                        InputProps={{ inputProps: { min: 0 } }}
                      />
                    )}
                  />
                </Box>
              )}
              <FormControlLabel
                value="exit"
                control={<Radio />}
                label={formatMessage(messages.exitIntent)}
              />
              <FormControlLabel
                value="scroll"
                control={<Radio />}
                label={formatMessage(messages.scrolled)}
              />
              {triggerOn === 'scroll' && (
                <Box display="flex" alignItems="center" ml={4}>
                  <Controller
                    name="triggers.scrollPercentage"
                    control={control}
                    defaultValue={0}
                    render={({ field }) => (
                      <Select {...field} size="small" sx={{ width: 100, mr: 2 }}>
                        {[0, 25, 50, 75, 100].map(value => (
                          <MenuItem key={value} value={value}>
                            {value}%
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                  <Typography>{formatMessage(messages.ofThePage)}</Typography>
                </Box>
              )}
            </RadioGroup>
          )}
        />
      </Box>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6">{formatMessage(messages.pageRules)}</Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 3 }}>
        {formatMessage(messages.pageRulesDescription)}
      </Typography>

      <Box mb={4}>
        <Controller
          name="triggers.showOn"
          control={control}
          defaultValue="all"
          render={({ field }) => (
            <RadioGroup {...field}>
              <FormControlLabel
                value="all"
                control={<Radio />}
                label={formatMessage(messages.allPages)}
              />
              <FormControlLabel
                value="certain"
                control={<Radio />}
                label={formatMessage(messages.certainPages)}
              />
            </RadioGroup>
          )}
        />
      </Box>

      <Controller
        name="triggers.showOn"
        control={control}
        render={({ field }) =>
          field.value === 'certain' && (
            <>
              <Box mb={4}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  {formatMessage(messages.showOn)}
                </Typography>
                <RuleFields name="triggers.inclusionRules" />
              </Box>

              <Box mb={4}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  {formatMessage(messages.dontShowOn)}
                </Typography>
                <RuleFields name="triggers.exclusionRules" />
              </Box>
            </>
          )
        }
      />

      <Box mb={4}>
        <Controller
          name="triggers.closeOnUrlChange"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label={formatMessage(messages.closeOnUrlChange)}
            />
          )}
        />
      </Box>

      <Divider sx={{ my: 4 }} />

      <Box mb={4}>
        <Typography variant="h6">{formatMessage(messages.sampling)}</Typography>
        <Typography variant="body2" color="textSecondary" sx={{ mt: 1, mb: 3 }}>
          {formatMessage(messages.samplingDescription)}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Controller
            name="adaptiveSampling"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                control={<Switch checked={value} onChange={onChange} />}
                label={formatMessage(messages.adaptiveSampling)}
              />
            )}
          />
          <Tooltip
            title={formatMessage(messages.adaptiveSamplingTooltip, { p: msg => <p>{msg}</p> })}
          >
            <InfoIcon color="disabled" fontSize="small" />
          </Tooltip>
        </Box>

        <Controller
          name="adaptiveSampling"
          control={control}
          render={({ field: adaptiveSamplingField }) =>
            !adaptiveSamplingField.value && (
              <Box>
                <Controller
                  name="sampling"
                  control={control}
                  defaultValue={100}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      size="small"
                      label={formatMessage(messages.sampling)}
                      InputProps={{
                        endAdornment: '%',
                        inputProps: { min: 0, max: 100 },
                      }}
                    />
                  )}
                />
              </Box>
            )
          }
        />
      </Box>

      <Box mb={4} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <FieldWithUnit name="dismissThrottle" messages={messages} />
      </Box>

      <Box mb={4}>
        <Controller
          name="sendEmailAfterWebSurvey"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} />}
              label={formatMessage(messages.sendEmailAfterWebSurvey)}
            />
          )}
        />

        {sendEmailAfterWebSurvey && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <FieldWithUnit name="emailSendDelay" messages={messages} />
          </Box>
        )}
      </Box>
    </CardContent>
  );
};

export default TargetingWeb;
