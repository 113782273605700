import { integrationsPropertiesChange } from 'client/modules/Settings/SettingsActions';
import querystring from 'querystring';
import { createActions } from 'redux-actions';

import callApi from '../../util/apiCaller';

export const {
  getCb, addCb, removeCb, resetState,
} = createActions(
  {},
  'GET_CB',
  'ADD_CB',
  'REMOVE_CB',
  'RESET_STATE',
);

export const getIntegration = (token, type, fields) => dispatch => (
  callApi(dispatch, `integrations/${type}?${querystring.stringify(fields)}`, token)
    .then((res) => {
      dispatch(getCb(res));
      return res;
    })
);

export const removeIntegration = (token, type) => dispatch => callApi(dispatch, `integrations/${type}`, token, 'delete')
  .then(() => dispatch(removeCb()));

export const addIntegration = (token, code, state, type) => (dispatch) => {
  dispatch(resetState());
  callApi(dispatch, `integrations/${type}`, token, 'post', { code, state })
    .then((res) => {
      dispatch(addCb({
        type: 'success', message: null, integrationType: type, state, integrationProps: res,
      }));
      dispatch(integrationsPropertiesChange({ type, props: res }));
    })
    .catch((err) => {
      dispatch(addCb({ type: 'error', message: err.response.message }));
    });
};
