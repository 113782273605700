import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React from 'react';

const DEBOUNCE_WAIT = 100;

class AutomaticWidth extends React.Component {

  constructor() {
    super();
    this.state = {
      listener: null,
      width: 0,
    };
  }

  componentDidMount() {
    const { debounceWait } = this.props;

    const boundListener = debounce(
      this._resizeHandler.bind(this),
      debounceWait || DEBOUNCE_WAIT,
      true
    );
    boundListener();
    window.addEventListener('resize', boundListener);
    this.setState({
      listener: boundListener,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.state.listener);
  }

  _resizeHandler() {
    const dom = this.autowidthWrapper;
    if (dom) {
      const { clientWidth } = dom;
      if (clientWidth !== this.state.width && clientWidth > 0) {
        this.setState({
          width: clientWidth,
        });
      }
    }
  }


  render() {
    const { width } = this.state;
    const props = { ...this.props };
    delete props.debounceWait;
    return (
      <div
        ref={(c) => {
          this.autowidthWrapper = c;
        }}
        {...props}
      >
        {React.Children.map(this.props.children, c =>
          React.cloneElement(c, { width }))}
      </div>
    );
  }
}

AutomaticWidth.defaultProps = {
  debounceWait: DEBOUNCE_WAIT,
};

AutomaticWidth.propTypes = {
  debounceWait: PropTypes.number,
  children: PropTypes.object.isRequired,
};

export default AutomaticWidth;
