import { getToken } from 'client/modules/Auth/AuthReducer';
import { createAction } from 'redux-actions';

import callApi from '../../util/apiCaller';
import { setError, setOk } from '../App/AppActions';

export const sendGridApiKeyInputChange = createAction('SENGRID_API_KEY_INPUT_CHANGE');
export const sendGridCustomDomainInputChange = createAction('SENGRID_CUSTOM_DOMAIN_INPUT_CHANGE');
export const setSendGridApiKeyCb = createAction('SET_SENDGRID_API_KEY_CB');
export const resetApiKeyCb = createAction('RESET_API_KEY_CB');
export const fetchSettingsCb = createAction('FETCH_SETTINGS_CB');
export const integrationsPropertiesChange = createAction('INTEGRATION_PROPERTIES_CHANGE');
export const setIntegrationPropertiesCb = createAction('SET_INTEGRATION_PROPERTIES_CB');
export const useCustomMailServiceChange = createAction('USE_CUSTOM_MAIL_SERVICE_CHANGE');
export const customMailServiceTypeChange = createAction('CUSTOM_MAIL_SERVICE_TYPE_CHANGE');
export const customMailServiceDomainChange = createAction('CUSTOM_MAIL_SERVICE_DOMAIN_CHANGE');
export const customMailServiceFromEmailChange = createAction('CUSTOM_MAIL_SERVICE_FROM_EMAIL_CHANGE');
export const customMailServiceApikeyChange = createAction('CUSTOM_MAIL_SERVICE_APIKEY_CHANGE');
export const minSurveyIntervalChange = createAction('MIN_SURVEY_INTERVAL_CHANGE');

export function fetchSettings(token) {
  return (dispatch) => {
    return callApi(dispatch, 'users/current/settings', token).then((res) => {
      dispatch(fetchSettingsCb(res));
    });
  };
}

export function resetApiKey(token) {
  return (dispatch) => {
    return callApi(dispatch, 'users/current/settings/reset-api-key', token, 'PUT').then((res) => {
      dispatch(resetApiKeyCb(res));
    });
  };
}

export function setSettings(settings, getErrorMessage) {
  return (dispatch, getState) => {
    const state = getState();
    const token = getToken(state);
    callApi(dispatch, 'users/current/settings', token, 'PUT', settings).then((res) => {
      dispatch(fetchSettingsCb(res));
      if (getErrorMessage) {
        const msg = getErrorMessage({ message: 'OK' });
        dispatch(setOk(msg));
      }
    }).catch((err) => {
      if (getErrorMessage) {
        const msg = getErrorMessage(err);
        dispatch(setError(msg));
      } else {
        throw err;
      }
    });
  };
}

export function setIntegrationProperties(token, integration, properties) {
  return async (dispatch) => {
    const res = callApi(dispatch, `integrations/${integration}`, token, 'PUT', properties);
    dispatch(setIntegrationPropertiesCb(res));
    return res;
  };
}
