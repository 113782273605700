import PropTypes from 'prop-types';
import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';

const LinkButton = ({ sx, ...props }) => (
  <ButtonBase
    variant="text"
    sx={{
      color: 'primary.main',
      textDecoration: 'underline',
      ...sx,
    }}
    {...props}
  />
);

LinkButton.propTypes = {
  sx: PropTypes.object,
};

LinkButton.defaultProps = {
  sx: {},
};

export default LinkButton;
