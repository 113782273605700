import { handleActions } from 'redux-actions';

const initialState = {
  hostedPage: null,
  session: null,
};

export const SettingsReducer = handleActions({
  GET_CHECKOUT_PAGE_CB: (state, action) => ({
    ...state,
    hostedPage: action.payload,
  }),
  ADD_SUBSCRIPTION_CB: (state, action) => ({
    ...state,
    subscription: action.payload,
  }),
  CHECK_SUBSCRIPTION_CB: (state, action) => ({
    ...state,
    subscription: action.payload,
  }),
}, initialState);

export const getHostedPage = state => state.subscriptions.hostedPage;
export const getSession = state => state.subscriptions.session;
export const getSubscription = state => state.subscriptions.subscription;

export default SettingsReducer;
