import { FormControlLabel, Radio } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const FormsyRadio = ({ label, value, ...rest }) => <FormControlLabel value={value} control={<Radio />} label={label} {...rest} />;

FormsyRadio.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
};

FormsyRadio.defaultValues = {
  value: null,
};

export default FormsyRadio;
