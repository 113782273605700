import { Box } from '@mui/material';
import AnalyzeEvolutionTooltip from 'client/modules/Analyze/components/AnalyzeEvolutionTooltip';
import { useAnalyzeEvolution } from 'client/modules/Analyze/AnalyzeEvolutionContext';
import LineTooltip from 'client/modules/Surveys/components/LineTooltip/LineTooltip';
import styles from 'client/modules/Surveys/pages/DashboardPage/DashboardPage.css';
import { useAnalyzeEvolutionQuery } from 'client/modules/Surveys/surveyAnalyzeApiSlice';
import useDebounce from 'client/util/useDebounce';
import d3 from 'd3';
import isEmpty from 'lodash/isEmpty';
import { Loading } from 'ra-ui-materialui';
import React, { useMemo } from 'react';

const AnalyzeEvolutionBody = () => {
  const {
    groupBy, filters, dateRange, survey, searches, isLoadingSearches, field
  } = useAnalyzeEvolution();
  const query = useDebounce(useMemo(() => ({
    survey: survey.id, dateRange, groupBy, filters,
  }), [survey, dateRange, groupBy, filters]), 500);
  const { data: analysis, isLoading } = useAnalyzeEvolutionQuery(query, { skip: !query.survey || isEmpty(query.filters) });

  const data = analysis?.map(({ key, ...series }) => ({
    key,
    ...(Object.entries(series).reduce((acc, [s, value]) => ({
      ...acc,
      [s]: {
        ...value,
        total: value.positive + value.neutral + value.negative,
      },
    }), {})),
  })).filter(d => Object.values(d).every(v => v !== null));
  const chartSeries = filters.map((filter) => {
    const idx = searches?.findIndex(s => s._id === filter);
    return ({
      field: filter,
      symbol: 'none',
      symbolSize: 0,
      hideLabels: true,
      strokeWidth: filter === 'all' ? 3 : 2,
      strokeDasharray: idx > 10 ? [5, 5] : null,
      color: filter === 'all' ? '#474645' : d3.scale.category10()
        .range()[idx],
    });
  });
  return (
    <Box>
      {isLoading || isLoadingSearches ? <Loading /> : (
        <LineTooltip
          chartPeriod={groupBy}
          surveyType={survey.surveyType}
          svgClassName={styles.chart}
          data={data || []}
          height={430}
          width={1280}
          margins={{
            left: 50,
            right: 0,
            top: 10,
            bottom: 30,
          }}
          chartSeries={chartSeries}
          x={d => (d ? new Date(d.key) : null)}
          y={d => d?.[field]}
          point={d => d}
          xScale="time"
          tension={0.3}
          showTooltipLine
        >
          <AnalyzeEvolutionTooltip chartPeriod={groupBy} chartSeries={chartSeries} field={field} />
        </LineTooltip>
      )}
    </Box>
  );
};

export default AnalyzeEvolutionBody;
