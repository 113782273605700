/* eslint-disable no-param-reassign */
import d3 from 'd3';

export default function series(props, horizontal) {
  const {
    data,
    chartSeries,
    x,
    y,
    categoricalColors,
  } = props;

  const cc = categoricalColors || d3.scale.category10();

  const chartSeriesData = chartSeries.map((f, i) => {
    // set a color if not set
    f.color = f.color || cc(i);

    // set name if not set
    f.name = f.name || f.field;

    // mapping throught data set x, y data
    const mapping = data.map((d) => {
      if (!d._style) d._style = {};

      if (!horizontal) {
        return {
          x: x(d),
          y: y(d[f.field]),
          color: f.color,
          name: f.name,
          field: f.field,
          _style: d._style,
        };
      }
      return {
        y: y(d),
        x: x(d[f.field]),
        color: f.color,
        name: f.name,
        field: f.field,
        _style: d._style,
      };
    });

    return Object.assign(f, { data: mapping });
  });

  return chartSeriesData;
}
