import { handleActions } from 'redux-actions';

// Import Actions

// Initial State
const initialState = {
  question: null,
  error: null,
  responseEnabled: true,
};

export const getQuestion = state => state.pairwise.question;
export const getError = state => state.pairwise.error;
export const getResponseEnabled = state => state.pairwise.responseEnabled;

export const PairwiseReducer = handleActions({
  GET_PAIRWISE_QUESTION_CB: (state, action) => ({
    ...state,
    question: action.payload,
  }),
  SET_PAIRWISE_ERROR: (state, action) => ({
    ...state,
    error: action.payload,
  }),
  SET_RESPONSE_ENABLED: (state, action) => ({
    ...state,
    responseEnabled: action.payload,
  }),
}, initialState);

export default PairwiseReducer;
