import styles from 'client/modules/Surveys/pages/DashboardPage/DashboardPage.css';
import groupsByType from 'client/modules/Surveys/pages/DashboardPage/groupsByType';
import { getChartPeriod } from 'client/modules/Surveys/SurveyReducer';
import formatDate from 'client/util/formatDate';
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  tooltipGroupCount: {
    id: 'Dashboard.Tooltip.GroupCount',
    defaultMessage: '{tpc}% {group} ({total} total)',
  },
  stars: {
    id: 'Dashboard.Tooltip.Stars',
    defaultMessage: '{num, number}-{num, plural, one {star} other {stars}}',
  },
  sent: {
    id: 'Dashboard.Tooltip.Sent',
    defaultMessage: 'not opened',
  },
  opened: {
    id: 'Dashboard.Tooltip.Opened',
    defaultMessage: 'opened',
  },
  responded: {
    id: 'Dashboard.Tooltip.Responded',
    defaultMessage: 'responded',
  },
  commented: {
    id: 'Dashboard.Tooltip.Commented',
    defaultMessage: 'commented',
  },
  promoters: {
    id: 'Dashboard.Tooltip.Promoters',
    defaultMessage: 'promoters',
  },
  passives: {
    id: 'Dashboard.Tooltip.Passives',
    defaultMessage: 'passives',
  },
  detractors: {
    id: 'Dashboard.Tooltip.Detractors',
    defaultMessage: 'detractors',
  },
  agree: {
    id: 'Dashboard.Tooltip.Agree',
    defaultMessage: 'agree',
  },
  neutral: {
    id: 'Dashboard.Tooltip.Neutral',
    defaultMessage: 'neutral',
  },
  disagree: {
    id: 'Dashboard.Tooltip.Disagree',
    defaultMessage: 'disagree',
  },
  satisfied: {
    id: 'Dashboard.Tooltip.Satisfied',
    defaultMessage: 'satisfied',
  },
  dissatisfied: {
    id: 'Dashboard.Tooltip.Dissatisfied',
    defaultMessage: 'dissatisfied',
  },
});

const ContentTooltip = (props) => {
  const colors = {
    promoters: styles.tooltipGroupPercentFive,
    agree: styles.tooltipGroupPercentFive,
    satisfied: styles.tooltipGroupPercentFive,
    '5-stars': styles.tooltipGroupPercentFive,
    '4-stars': styles.tooltipGroupPercentFour,
    passives: styles.tooltipGroupPercentThree,
    neutral: styles.tooltipGroupPercentThree,
    '3-stars': styles.tooltipGroupPercentThree,
    '2-stars': styles.tooltipGroupPercentTwo,
    detractors: styles.tooltipGroupPercentOne,
    disagree: styles.tooltipGroupPercentOne,
    dissatisfied: styles.tooltipGroupPercentOne,
    '1-stars': styles.tooltipGroupPercentOne,
  };
  const { contentTooltip, surveyType } = props;
  const { locale } = useIntl();
  const chartPeriod = useSelector(getChartPeriod);
  const { formatMessage, formatNumber } = useIntl();
  const fmt = ((key, values) => formatMessage(messages[key], values));
  const groups = groupsByType[surveyType];
  const p = contentTooltip.point;
  let { opened } = p;
  const {
    sent, commented, nps, key_as_string: keyAsString, average,
  } = p;
  const responded = groups.reduce((t, g) => t + p[g], 0);
  const total = sent + opened + responded;
  opened += responded;
  const tpcs = groups.map(g => (
    (responded === 0 ? 0 : Math.round((p[g] / responded) * 100))
  ));
  const {
    sent: sentTpc, opened: openedTpc, responded: respondedTpc, commented: commentedTpc,
  } = mapValues({
    sent, opened, responded, commented,
  }, o => (total === 0 ? 0 : Math.round((o / total) * 100)));
  const style = {
    style: 'decimal',
    maximumFractionDigits: 2,
  };
  const max = surveyType === 'nps' ? 10 : 5;
  const avg = formatNumber(average, style);
  const score = formatNumber(nps, style);
  const avgString = `(${avg}/${max})`;
  // Remove offset from the key_as_string
  const date = new Date(keyAsString.slice(0, -6));
  return (
    <div className={styles.tooltip}>
      <div className={styles.tooltipPercent}>
        {surveyType === 'star_five' ? avg
          : (
            <span>
              {score}
              <small>{avgString}</small>
            </span>
          )
        }
      </div>
      <div className={styles.tooltipDate}>{formatDate(date, locale, chartPeriod !== 'month')}</div>
      <div className={styles.tooltipCount}>
        <FormattedMessage
          id="Dashboard.Tooltip.Count"
          defaultMessage="{respondedTotal} responses ({total} sent)"
          values={{
            respondedTotal: formatNumber(responded),
            total: formatNumber(total),
          }}
        />
      </div>
      {groups.map((group, idx) => (
        <div className={`${styles.tooltipGroupPercent} ${colors[group]}`}>
          {formatMessage(messages.tooltipGroupCount, {
            tpc: tpcs[idx],
            group: surveyType === 'star_five' ? fmt('stars', { num: group.split('-')[0] }) : fmt(group),
            total: formatNumber(p[group]),
          })}
        </div>
      ))}
      <hr className={styles.tooltipGroupPercentSep} />
      <div className={`${styles.tooltipGroupPercent} ${styles.tooltipGroupPercentSent}`}>
        {formatMessage(messages.tooltipGroupCount, { tpc: sentTpc, group: fmt('sent'), total: formatNumber(sent) })}
      </div>
      <div className={`${styles.tooltipGroupPercent} ${styles.tooltipGroupPercentOpened}`}>
        {formatMessage(messages.tooltipGroupCount, { tpc: openedTpc, group: fmt('opened'), total: formatNumber(opened) })}
      </div>
      <div className={`${styles.tooltipGroupPercent} ${styles.tooltipGroupPercentResponded}`}>
        {formatMessage(messages.tooltipGroupCount, { tpc: respondedTpc, group: fmt('responded'), total: formatNumber(responded) })}
      </div>
      <div className={`${styles.tooltipGroupPercent} ${styles.tooltipGroupPercentCommented}`}>
        {formatMessage(messages.tooltipGroupCount, { tpc: commentedTpc, group: fmt('commented'), total: formatNumber(commented) })}
      </div>
    </div>
  );
};

ContentTooltip.defaultProps = {
  contentTooltip: null,
};

ContentTooltip.propTypes = {
  surveyType: PropTypes.oneOf(['nps', 'ces', 'csat', 'star_five']).isRequired,
  contentTooltip: PropTypes.shape({
    point: PropTypes.shape({
      promoters: PropTypes.number.isRequired,
      passives: PropTypes.number.isRequired,
      detractors: PropTypes.number.isRequired,
      nps: PropTypes.number.isRequired,
      key_as_string: PropTypes.string.isRequired,
      opened: PropTypes.number.isRequired,
      sent: PropTypes.number.isRequired,
      commented: PropTypes.number.isRequired,
      average: PropTypes.number.isRequired,
    }).isRequired,
  }),
};

export default ContentTooltip;
