import React from 'react';
import Icon from 'react-icon-base';

const Nps = props => (
  <Icon viewBox="0 0 97 36" {...props}>
    <g>
      <path
        d="M10.2,4.4,23.6,24.7V4.2a4.5,4.5,0,0,1,.9-3,3.1,3.1,0,0,1,4.6,0,4.5,4.5,0,0,1,.9,3v27c0,3.1-1.2,4.6-3.7,4.6a4.3,4.3,0,0,1-1.7-.3,4.2,4.2,0,0,1-1.4-.9,8,8,0,0,1-1.3-1.3,9.1,9.1,0,0,0-1.1-1.6L7.8,11.6V31.8a4.5,4.5,0,0,1-.9,3,3.5,3.5,0,0,1-2.4,1,3,3,0,0,1-2.4-1.1,4,4,0,0,1-.8-2.9V5.3a9.6,9.6,0,0,1,.3-2.7A3.5,3.5,0,0,1,3.1.9,4.4,4.4,0,0,1,5.3.2,3.5,3.5,0,0,1,6.9.5a2.7,2.7,0,0,1,1.2.9,4.9,4.9,0,0,1,1,1.3A9.9,9.9,0,0,1,10.2,4.4Z"
      />
      <path
        d="M51,21.8H44.6v9.7a4.4,4.4,0,0,1-1,3.2,3.4,3.4,0,0,1-2.5,1.1,3.3,3.3,0,0,1-2.5-1.1,4.3,4.3,0,0,1-1-3.1V5.1a4.5,4.5,0,0,1,1.1-3.3A4.5,4.5,0,0,1,42,.8h9a24.4,24.4,0,0,1,6.1.6,9.1,9.1,0,0,1,3.6,2A8.6,8.6,0,0,1,63,6.7a10.5,10.5,0,0,1,.8,4.4c0,3.5-1.1,6.2-3.2,8S55.2,21.8,51,21.8ZM49.3,6H44.6V16.5h4.7a12.4,12.4,0,0,0,4.1-.5,4.5,4.5,0,0,0,2.5-1.7,4.7,4.7,0,0,0,.9-3,5.1,5.1,0,0,0-1.4-3.7C54.4,6.6,52.4,6,49.3,6Z"
      />
      <path
        d="M95.7,24.9a10.1,10.1,0,0,1-1.6,5.6,10.9,10.9,0,0,1-4.7,3.9,17.9,17.9,0,0,1-7.3,1.4,16.3,16.3,0,0,1-8.4-2,11.9,11.9,0,0,1-3.8-3.6,8.6,8.6,0,0,1-1.5-4.5,2.9,2.9,0,0,1,.9-2.2,3.2,3.2,0,0,1,2.3-.9,2.4,2.4,0,0,1,1.8.7,5.4,5.4,0,0,1,1.3,2.1,16.7,16.7,0,0,0,1.4,2.7,5.5,5.5,0,0,0,2.2,1.8,7.4,7.4,0,0,0,3.6.8,7.6,7.6,0,0,0,5-1.5,4.2,4.2,0,0,0,2-3.6,4.1,4.1,0,0,0-1.1-2.8,7.2,7.2,0,0,0-2.7-1.6A32.4,32.4,0,0,0,80.7,20a34.8,34.8,0,0,1-6.2-2,9.5,9.5,0,0,1-3.9-3.2,8,8,0,0,1-1.5-5,8.9,8.9,0,0,1,1.5-5,10.4,10.4,0,0,1,4.5-3.4A18.5,18.5,0,0,1,82,.2a18.2,18.2,0,0,1,5.5.8,10.7,10.7,0,0,1,3.8,2.1,8.7,8.7,0,0,1,2.2,2.7,6.1,6.1,0,0,1,.7,2.8,3.2,3.2,0,0,1-.8,2.3,3,3,0,0,1-2.2,1,2.4,2.4,0,0,1-1.8-.6,6.9,6.9,0,0,1-1.4-2,9,9,0,0,0-2.2-3c-.9-.8-2.3-1.1-4.3-1.1a7.1,7.1,0,0,0-4.3,1.2,3.5,3.5,0,0,0-1.7,2.8,2.8,2.8,0,0,0,.6,1.8,5.3,5.3,0,0,0,1.6,1.3l1.9.8L83,14l5.2,1.5a16.6,16.6,0,0,1,4,2,8.3,8.3,0,0,1,2.6,3A9.6,9.6,0,0,1,95.7,24.9Z"
      />
    </g>
  </Icon>
);

export default Nps;
