import Box from '@mui/material/Box';
import { getToken } from 'client/modules/Auth/AuthReducer';
import AnalyzeEvolutionBody from 'client/modules/Analyze/components/AnalyzeEvolutionBody';
import AnalyzeEvolutionControls from 'client/modules/Analyze/components/AnalyzeEvolutionControls';
import AnalyzeTitle from 'client/modules/Analyze/components/AnalyzeTitle';
import { AnalyzeEvolutionProvider } from 'client/modules/Analyze/AnalyzeEvolutionContext';
import LeftNav from 'client/modules/Surveys/pages/DashboardPage/LeftNav';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import { fetchSurveys } from 'client/modules/Surveys/SurveyActions';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import Layout from 'components/Layout/Layout';
import React from 'react';
import { useIntl } from 'react-intl';
import { useLoaderData } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';

const styles = {
  root: {
    display: 'flex',
    maxWidth: 1280,
    width: '100%',
    alignSelf: 'center',
    mb: 4,
    flexDirection: 'column',
  },
};
const AnalyzePage = () => {
  const { id: surveyId } = useParams();
  const navigate = useNavigate();
  const surveys = useLoaderData();
  const survey = surveys?.find(s => s.id === surveyId);

  const { formatMessage } = useIntl();

  return (
    <Layout secondaryNav={<SecondaryNavTabs survey={surveyId} />}>
      <AnalyzeTitle />
      <Box sx={styles.root}>
        <AnalyzeEvolutionProvider survey={survey}>
          <AnalyzeEvolutionControls />
          <AnalyzeEvolutionBody />
        </AnalyzeEvolutionProvider>
      </Box>
    </Layout>
  );
};

AnalyzePage.loader = ({ getState, dispatch }) => () => {
  const state = getState();
  const token = getToken(state);
  const surveys = getSurveys(state);
  if (surveys?.length > 0) {
    return surveys;
  }
  return dispatch(fetchSurveys(token));
};

export default AnalyzePage;
