import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getToken } from 'client/modules/Auth/AuthReducer';
import Config from '../../../server/config';

const prepareHeaders = (headers, { getState }) => {
  // By default, if we have a token in the store, let's use that for authenticated requests
  const token = getToken(getState());
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }
  return headers;
};

export const peopleApiSlice = createApi({
  reducerPath: 'peopleReducer',
  baseQuery: fetchBaseQuery({ baseUrl: Config.apiUrl, prepareHeaders }),
  tagTypes: ['Person'],
  endpoints: builder => ({
    getPeople: builder.query({
      query: ({
        survey, skip, limit, sort, filter,
      }) => `/surveys/${survey}/people?skip=${skip}&limit=${limit}&sort=${sort || ''}&filter=${filter || ''}`,
      providesTags: ['Person'],
    }),
    getPerson: builder.query({
      query: ({
        survey,
        personId,
      }) => `/surveys/${survey}/people/${personId}`,
    }),
    addNewPerson: builder.mutation({
      query: (survey, initialPost) => ({
        url: `/surveys/${survey}/people`,
        method: 'POST',
        body: initialPost,
      }),
      invalidatesTags: ['Person'],
    }),
    editPerson: builder.mutation({
      query: (survey, person) => ({
        url: `/surveys/${survey}/people/${person.id}`,
        method: 'PATCH',
        body: person,
      }),
    }),
    getActivities: builder.query({
      query: ({
        personId, survey, skip, limit, sort, filter,
      }) => `/surveys/${survey}/people/${personId}/activity?skip=${skip}&limit=${limit}&sort=${sort || ''}&filter=${filter || ''}`,
    }),
  }),
});

export const {
  useGetActivitiesQuery,
  useGetPeopleQuery,
  useGetPersonQuery,
  useAddNewPersonMutation,
  useEditPersonMutation,
  reducer: peopleReducer,
} = peopleApiSlice;
