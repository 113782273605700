import React from 'react';
import PropTypes from 'prop-types';

import styles from './ResponsiveImage.css';

class ResponsiveImage extends React.Component {

  componentDidMount() {
    if (this.img && this.img.complete) {
      this.img.className = `${styles.img} ${styles.initiallyComplete}`;
    }
  }

  render() {
    const { placeholder, sizes, srcSet: origSrcSet, title, alt } = this.props;
    const srcSetSizesSorted = Object.keys(origSrcSet).map(k => parseInt(k)).sort();
    const getPath = size => origSrcSet[size];
    const srcSet = srcSetSizesSorted.map(size => `${getPath(size)} ${size}w`);
    const src = getPath(srcSetSizesSorted[0]);
    const onLoad = () => {
      if (this.img.className === styles.img) {
        this.img.className = `${styles.img} ${styles.complete}`;
      }
    };
    const imgAttrs = { src, srcSet, sizes, title, onLoad };
    return (
      <div className={styles.container} style={{ backgroundImage: `url(${placeholder})` }}>
        <img
          className={styles.img}
          alt={alt}
          ref={(img) => {
            this.img = img;
          }}
          {...imgAttrs}
        />
      </div>
    );
  }
}

ResponsiveImage.defaultProps = {
  title: null,
  sizes: undefined,
};

ResponsiveImage.propTypes = {
  srcSet: PropTypes.object.isRequired,
  placeholder: PropTypes.string.isRequired,
  sizes: PropTypes.string,
  alt: PropTypes.string.isRequired,
  title: PropTypes.string,
};

export default ResponsiveImage;
