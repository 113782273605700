import { Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DefaultCopyField from 'client/util/CopyField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ApiKeyCreatedDialog = ({
  isOpen, close, apiKey,
}) => {
  if (!isOpen) {
    return null;
  }
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '500px' } }}
      open={isOpen}
      onClose={close}
      aria-labelledby="apikeycreateddialog-title"
      aria-describedby="apikeycreateddialog-description"
    >
      <DialogTitle id="apikeycreateddialog-title">
        <FormattedMessage
          id="ApiKeysSettings.KeyCreated.YourNewAPIKey"
          defaultMessage="Your new API key"
        />
      </DialogTitle>
      <DialogContent sx={{
        backgroundColor: 'background.body', borderColor: 'divider', borderStyle: 'solid', borderWidth: '1px 0',
      }}
      >
        <Typography sx={{ mt: 2, mb: 1, fontWeight: 'bold' }}>
          <FormattedMessage
            id="ApiKeysSettings.KeyCreated.KeepYourAPIKeyPrivate"
            defaultMessage="Keep your API key private"
          />
        </Typography>
        <Typography>
          <FormattedMessage
            id="ApiKeysSettings.KeyCreated.StoreThisKey"
            defaultMessage={'Guarda y almacena esta clave en un lugar seguro, como un administrador de contraseñas o '
              + 'una herramienta de gestión de claves secretas porque no volverás a verla.'}
          />
        </Typography>
        <DefaultCopyField fullWidth value={apiKey?.token} sx={{ backgroundColor: 'background.paper' }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="inherit">
          <FormattedMessage id="ApiKeysSettings.KeyCreated.Done" defaultMessage="Done" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApiKeyCreatedDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  apiKey: PropTypes.object.isRequired,
};

export default ApiKeyCreatedDialog;
