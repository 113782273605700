import { refresh as refreshAction } from 'client/modules/Auth/AuthActions';
import { getToken } from 'client/modules/Auth/AuthReducer';
import { ExistsQuery } from 'client/modules/Surveys/components/searchkit/ExistsFieldFilter/ExistsFieldFilterAccessor';
import { searchResults as searchResultsAction } from 'client/modules/Surveys/SurveyActions';
import { getShouldReloadQuery } from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import useCreateHistory from 'client/util/useCreateHistory';
import HttpStatusCodes from 'http-status-codes';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AxiosESTransport, BoolMust, SearchkitManager } from 'searchkit';

const useSearchkit = (survey) => {
  if (!survey) {
    return null;
  }
  const surveyId = survey.hasResponses ? survey.id : 'demo';
  const token = useSelector(getToken);
  const refresh = useActions(refreshAction);

  const host = useMemo(() => `/api/surveys/${surveyId}/responses`, [surveyId]);

  const createHistory = useCreateHistory();
  const searchResults = useActions(searchResultsAction);
  const shouldReload = useSelector(getShouldReloadQuery);

  let reloadingTimer;

  const searchkit = useMemo(() => new SearchkitManager(host, {
    searchUrlPath: '/search',
    timeout: 10000,
    createHistory,
  }), [host]);

  useEffect(() => {
    searchkit.emitter.addListener(() => {
      if (searchkit.error?.response?.status === HttpStatusCodes.UNAUTHORIZED) {
        searchkit.error = null;
        refresh(token).then((user) => {
          searchkit.transport.axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;
          searchkit.reloadSearch();
        });
      }
    });
  }, [searchkit]);


  useEffect(() => {
    // Reset the searchkit manager when the token changes
    if (token) {
      if (searchkit.transport) {
        searchkit.transport.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      }
    }
  }, [token]);

  useEffect(() => {
    if (searchkit.transport.host !== host) {
      searchkit.transport = new AxiosESTransport(host, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        ...transportOptions,
      });
      searchkit.reloadSearch();
    }
  }, [host, token]);

  useEffect(() => {
    const defaultQueryAccessor = searchkit.addDefaultQuery((query) => {
      return query.addQuery(BoolMust([
        ExistsQuery('score'),
      ]));
    });
    searchkit.setQueryProcessor((plainQueryObject) => {
      const demo = !survey || !survey.hasResponses;
      return {
        query: plainQueryObject,
        demo,
      };
    });
    const removeListener = searchkit.addResultsListener((results) => {
      searchResults(results.hits.hits, results.hits.total);
    });
    return () => {
      removeListener();
      searchkit.removeAccessor(defaultQueryAccessor);
    };
  }, [searchkit]);

  useEffect(() => {
    if (shouldReload) {
      if (!reloadingTimer) {
        reloadingTimer = setTimeout(() => {
          searchkit.reloadSearch();
          setTimeout(() => {
            reloadingTimer = null;
          }, 1000);
        }, 1000);
      }
    }
  }, [shouldReload]);


  return searchkit;
};

export default useSearchkit;
