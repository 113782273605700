import { useFindFoldersQuery } from 'client/modules/Surveys/folderApiSlice';
import React, { useMemo } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box, FormControl, FormControlLabel, InputLabel,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { ObjectID } from 'bson';
import FlatButton from 'components/FlatButton/FlatButton';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';

import styles from '../IntegrationsListPage.css';

const messages = defineMessages({
  syncResponses: {
    id: 'IntegrationsSettings.HubspotSyncResponses',
    defaultMessage: 'Sync responses to contacts',
  },
  hubspotPropertyPrefix: {
    id: 'IntegrationsSettings.HubspotPropertyPrefix',
    defaultMessage: 'Property prefix',
  },
  hubspotPropertyShowSurvey: {
    id: 'IntegrationsSettings.HubspotPropertyShowSurvey',
    defaultMessage: 'Hubspot Property ID',
  },
  addSyncSurvey: {
    id: 'IntegrationsSettings.HubspotAddSyncSurvey',
    defaultMessage: 'Add survey to sync',
  },
  surveysRequired: {
    id: 'IntegrationsSettings.HubspotSurveysRequired',
    defaultMessage: 'Please select some surveys.',
  },
  survey: {
    id: 'IntegrationsSettings.HubspotSurvey',
    defaultMessage: 'Survey',
  },
  properties: {
    id: 'IntegrationsSettings.HubspotProperties',
    defaultMessage: 'Properties',
  },
  all: {
    id: 'IntegrationsSettings.All',
    defaultMessage: 'All',
  },
  active: {
    id: 'activeSurveys',
    defaultMessage: 'Active surveys',
  },
});

const IntegrationConfigHubspot = () => {
  const { formatMessage } = useIntl();
  const {
    integration,
    onIntegrationsPropertiesChange,
    surveys,
  } = useOutletContext();
  const { data: folders, isSuccess } = useFindFoldersQuery({});
  const foldersMap = useMemo(
    () => (isSuccess ? folders.reduce((acc, curr) => ({ ...acc, [curr._id]: `${curr.name} / ` }), {}) : {}),
    [folders],
  );
  const surveyOptions = useMemo(
    () => surveys
      .filter(s => !s.archived)
      .map(s => ({
        value: s.id,
        folder: foldersMap[s.folder] || '',
        name: (s.name || s.brand),
      }))
      .sort((a, b) => a.folder.localeCompare(b.folder) || a.name.localeCompare(b.name)),
    [foldersMap, surveys],
  );
  const {
    portalId,
    syncResponses = false,
    syncSurveys = [],
  } = integration || {};
  const errors = {};
  const onToggle = name => (event) => {
    onIntegrationsPropertiesChange(event, 'hubspot', { [name]: event.target.checked });
    if (event.target.checked && !syncSurveys?.length) {
      onIntegrationsPropertiesChange(null, 'hubspot', {
        syncSurveys: [{
          _id: new ObjectID().toString(),
          survey: surveys[0]?.id,
          propertyPrefix: 'promoterninja',
        }],
      });
    }
  };
  const setSyncSurveyProps = (index, props) => {
    onIntegrationsPropertiesChange(null, 'hubspot', { syncSurveys: syncSurveys.map((r, idx) => (index === idx ? { ...r, ...props } : r)) });
  };
  const addSyncSurvey = () => {
    const newSyncSurveys = [...syncSurveys, { _id: new ObjectID().toString() }];
    onIntegrationsPropertiesChange(null, 'hubspot', { syncSurveys: newSyncSurveys });
  };

  const removeRule = index => () => {
    const newSyncSurveys = syncSurveys.filter((r, idx) => idx !== index);
    onIntegrationsPropertiesChange(null, 'hubspot', { syncSurveys: newSyncSurveys });
  };
  const handleChangeSurveys = index => (event, elem) => {
    let newSurvey = event.target.value;
    const { id } = elem.props;
    if (id === 'all-surveys') {
      newSurvey = null;
    }
    setSyncSurveyProps(index, { survey: newSurvey });
  };

  return (
    <Box sx={{
      gap: 6, display: 'flex', flexDirection: 'column', mb: 4,
    }}
    >
      <span>
        <FormattedMessage
          id="IntegrationsSettings.HubID"
          defaultMessage="Hub ID: {portalId}"
          values={{
            portalId,
          }}
        />
      </span>
      <Box>
        <FormControlLabel
          classes={{ root: styles.formControlLabel, label: styles.label }}
          control={(
            <Switch
              color="primary"
              checked={syncResponses}
              onChange={onToggle('syncResponses')}
            />
          )}
          label={formatMessage(messages.syncResponses)}
          labelPlacement="end"
        />
        {!syncResponses || (
          <>
            {syncSurveys?.map((syncSurvey, index) => {
              return (
                <div key={syncSurvey._id} className={styles.rule}>
                  <FormControl variant="standard" className={styles.ruleInput}>
                    <InputLabel htmlFor={`select-survey-${syncSurvey._id}`}>{formatMessage(messages.survey)}</InputLabel>
                    <Select
                      variant="standard"
                      className={styles.input}
                      id={`select-survey-${syncSurvey._id}`}
                      errorText={errors[`${index}-surveys`]}
                      hintText={formatMessage(messages.survey)}
                      value={syncSurvey.survey || ''}
                      onChange={handleChangeSurveys(index)}
                      autoWidth
                      required
                    >
                      {surveyOptions.map(s => (
                        (
                          <MenuItem
                            insetChildren
                            key={s.name}
                            checked={!syncSurvey.surveys || (syncSurvey.survey === s.value)}
                            value={s.value}
                          >
                            {s.folder + s.name}
                          </MenuItem>
                        )
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    required
                    id={`propertyPrefix-${syncSurvey._id}`}
                    value={syncSurvey.propertyPrefix}
                    onChange={e => setSyncSurveyProps(index, { propertyPrefix: e.target.value })}
                    variant="standard"
                    className={styles.input}
                    inputStyle={{ paddingLeft: '1.25em' }}
                    label={formatMessage(messages.hubspotPropertyPrefix)}
                    fullWidth
                    autoWidth
                  />
                  {(() => {
                    if (syncSurvey.surveys && syncSurvey.surveys.length === 0) {
                      errors[`${index}-surveys`] = formatMessage(messages.surveysRequired);
                    }
                    return null;
                  })()}
                  <IconButton sx={{ alignSelf: 'flex-start', top: 25 }} onClick={removeRule(index)}>
                    <Close />
                  </IconButton>
                </div>
              );
            })}
            <br />
            <FlatButton
              primary
              label={formatMessage(messages.addSyncSurvey)}
              onClick={addSyncSurvey}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default IntegrationConfigHubspot;
