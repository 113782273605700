import React from 'react';
import Icon from 'react-icon-base';

const Link = props => (
  <Icon viewBox="0 0 12 12" {...props}>
    <path strokeWidth={0} d="M9.156 5.475 7.978 6.652a.194.194 0 1 1-.275-.275L8.881 5.2a1.472 1.472 0 0 0-1.04-2.51 1.46 1.46 0 0 0-1.04.431L5.623 4.297A1.473 1.473 0 0 0 6.109 6.7c.1.04.148.154.107.254-.083.206-.313.083-.253.107a1.86 1.86 0 0 1-.616-3.038l1.178-1.177c.351-.352.819-.546 1.315-.546s.964.193 1.315.545c.726.725.726 1.905.001 2.63m-3.119-.536a.196.196 0 0 0-.254.107c-.04.1.008.213.107.254a1.471 1.471 0 0 1 .486 2.403L5.199 8.88a1.462 1.462 0 0 1-1.039.431 1.472 1.472 0 0 1-1.04-2.51l1.178-1.178a.194.194 0 1 0-.275-.275L2.844 6.525a1.86 1.86 0 0 0 2.631 2.631l1.178-1.177a1.861 1.861 0 0 0-.616-3.04" />
  </Icon>
);

export default Link;
