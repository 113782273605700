import { Box, Button, Card, ListItemButton, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import CardTitle from '@mui/material/CardHeader';
import List from '@mui/material/List';
import Grid from '@mui/material/Unstable_Grid2';
import styles from 'client/modules/Settings/pages/SettingsPage/SettingsPage.css';
import { editSurvey as editSurveyAction } from 'client/modules/Surveys/SurveyActions';
import { getSurvey } from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation, useMatch, useParams } from 'react-router-dom';

const messages = defineMessages({
  scheduling: {
    id: 'Targeting.Scheduling',
    defaultMessage: 'Scheduling',
  },
  web: {
    id: 'Targeting.Web',
    defaultMessage: 'Web surveys',
  },
  audiences: {
    id: 'Targeting.Audiences',
    defaultMessage: 'Audiences',
  },
});

const Targeting = () => {
  const [saving, setSaving] = React.useState(false);
  const editSurvey = useActions(editSurveyAction);
  const location = useLocation();
  const params = useParams();
  const { id: surveyId } = params;
  const survey = useSelector(state => getSurvey(state, surveyId));
  const { formatMessage } = useIntl();
  const form = useForm({
    defaultValues: survey.targeting || {
      firstSurvey: {
        value: 3,
        unit: 'months',
      },
      nextSurveys: [],
      resurveyThrottle: { value: 6, unit: 'months' },
      dismissThrottle: { value: 30, unit: 'days' },
      timeDelayOnPage: 5,
      sampling: 100,
      adaptiveSampling: true,
      sendEmailAfterWebSurvey: false,
      emailSendDelay: { value: 1, unit: 'week' },
    },
  });

  useEffect(() => {
    form.reset();
  }, [location]);
  const onSubmit = async targeting => {
    try {
      setSaving(true);
      await editSurvey(surveyId, { targeting });
    } finally {
      setSaving(false);
    }
  };
  const {
    params: { tab },
  } = useMatch('/surveys/:survey/edit/targeting/:tab') || { params: { tab: 'scheduling' } };
  const pages = ['scheduling', 'web', 'audiences'];
  return (
    <Grid
      spacing={2}
      container
      sx={{
        width: '100%',
        maxWidth: 1280,
        alignSelf: 'center',
        minHeight: 'calc(100vh - 250px)',
      }}
    >
      <Grid item xs={12} sm={4} md={3}>
        <Box component="nav" sx={{ my: 3 }}>
          <List>
            {pages.map(key => (
              <ListItemButton component={NavLink} to={key} className={styles.listItem} key={key}>
                {formatMessage(messages[key])}
              </ListItemButton>
            ))}
          </List>
        </Box>
      </Grid>
      <Grid xs={12} sm={8} md={9}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <Card sx={{ my: 3 }}>
              <CardTitle
                title={
                  <Typography variant="h6" sx={{ mb: 0 }}>
                    {formatMessage(messages[tab])}
                  </Typography>
                }
                disableTypography
              />
              <Outlet />
              <CardActions sx={{ justifyContent: 'flex-end' }}>
                <Button type="submit" variant="contained" color="primary">
                  {saving ? (
                    <FormattedMessage id="Targeting.Saving" defaultMessage="Saving..." />
                  ) : (
                    <FormattedMessage id="Targeting.Save" defaultMessage="Save" />
                  )}
                </Button>
              </CardActions>
            </Card>
          </form>
        </FormProvider>
      </Grid>
    </Grid>
  );
};

export default Targeting;
