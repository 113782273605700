import useCreateEvents from 'client/util/useCreateEvents';
import { useMemo } from 'react';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import useDidUpdateEffect from '../../server/util/useDidUpdateEffect';

const useCreateHistory = () => {
  const listeners = useCreateEvents();
  const navigate = useNavigate();
  const location = useLocation();
  const action = useNavigationType();

  useDidUpdateEffect(() => {
    listeners.call(location, action);
  }, [location, action]);

  return useMemo(() => () => {
    return ({
      push(path) {
        navigate(path);
      },
      replace(path) {
        navigate(path, { replace: true });
      },
      listen(func) {
        return listeners.push(func);
      },
    });
  }, [listeners, navigate]);
};

export default useCreateHistory;
