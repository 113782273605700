import {
  BoolShould, RangeQuery, StatefulAccessor, ValueState,
} from 'searchkit';
import moment from 'moment';

class RangeQueryAccessor extends StatefulAccessor {
  constructor(key, field, options = {}) {
    super(key);
    this.field = field;
    this.state = new ValueState();
    this.options = options;
  }

  fromQueryObject(ob) {
    let q = ob;
    if (ob && ob[this.key] && ob[this.key].length) {
      q = {
        ...ob,
        key: ob[this.key].map(o => (o === '' ? null : o)),
      };
    }
    super.fromQueryObject(q);
    if (this.options.onQueryStateChange) {
      this.options.onQueryStateChange(this.state.value);
    }
  }

  keepOnlyQueryState() {
    this.setQueryString(this.getQueryString(), true);
  }

  clear() {
    this.state = this.state.setValue([]);
  }

  setValues(min, max, withReset = false) {
    if (withReset) {
      this.searchkit.resetState();
    }
    this.state = this.state.setValue([min, max]);
  }

  getValues() {
    return this.state.getValue();
  }

  buildSharedQuery(q) {
    let query = q;
    const value = this.state.getValue();
    if (value?.length > 0) {
      const [gte, lte] = value;
      const queryBuilder = this.options.queryBuilder || RangeQuery;
      const simpleQuery = queryBuilder(
        this.field,
        { gte, lte, ...this.options.queryOptions },
      );
      query = query.addQuery(BoolShould([simpleQuery]));
      const dateFormatter = this.options.dateFormatter || (d => moment(d).format('YYYY-MM-DD'));
      query = query.addSelectedFilter({
        name: this.options.title,
        value: `${gte ? dateFormatter(gte) : ''} - ${lte ? dateFormatter(lte) : ''}`,
        id: this.key,
        remove: () => {
          this.state = this.state.clear();
          return this.state;
        },
      });
      return query;
    }
    return query;
  }
}

export default RangeQueryAccessor;
