import { connect } from 'react-redux';
import withParams from '../../../../util/withParams';
import { getAllowSMS, getToken } from '../../../Auth/AuthReducer';
import { customizeSurveyChangeTab, fetchSurveys, loadInputValues } from '../../SurveyActions';
import { getCustomizeSurveyTab, getSurveys } from '../../SurveyReducer';
import CustomizeSurvey from './CustomizeSurvey';

const mapStateToProps = (state, ownProps) => {
  return {
    id: ownProps.params && ownProps.params.id,
    tab: getCustomizeSurveyTab(state),
    allowSMS: getAllowSMS(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeTab: (event, tab) => {
      dispatch(customizeSurveyChangeTab(tab));
    },
  };
};

export default withParams(connect(mapStateToProps, mapDispatchToProps)(CustomizeSurvey));

export const loadSurvey = (store, nextState, replace, callback) => {
  const loadValues = () => {
    if (nextState.params && nextState.params.id) {
      store.dispatch(loadInputValues(nextState.params.id));
    }
  };

  const token = getToken(store.getState());
  if (token) {
    if (!getSurveys(store.getState())) {
      store.dispatch(fetchSurveys(token))
        .then(() => {
          loadValues(nextState);
          callback();
        }).catch((err) => {
          callback();
          throw err;
        });
    } else {
      loadValues(nextState);
      callback();
    }
  } else {
    callback();
  }
};
