import { ListItemButton } from '@mui/material';
import List from '@mui/material/List';
import { fetchSurveys } from 'client/modules/Surveys/SurveyActions';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, useIntl } from 'react-intl';
import {
  NavLink, Outlet, useLocation, useParams,
} from 'react-router-dom';
import Sticky from 'react-stickynode';
import Layout from '../../../App/components/Layout/LayoutContainer';
import { getToken } from '../../../Auth/AuthReducer';
import { fetchSettings } from '../../SettingsActions';
import { isSettingsLoaded } from '../../SettingsReducer';
import styles from './SettingsPage.css';

const messages = defineMessages({
  developerSettings: {
    id: 'Settings.DeveloperSettings',
    defaultMessage: 'Developer settings',
  },
  mailSettings: {
    id: 'Settings.MailSettings',
    defaultMessage: 'Mail settings',
  },
  throttlingSettings: {
    id: 'Settings.SurveyThrottling',
    defaultMessage: 'Survey throttling',
  },
  integrationsSettings: {
    id: 'Settings.Integrations',
    defaultMessage: 'Integrations',
  },
  accountSettings: {
    id: 'Settings.AccountSettings',
    defaultMessage: 'Account settings',
  },
  back: {
    id: 'Settings.Back',
    defaultMessage: 'Back',
  },
});
const SettingsPage = () => {
  const { page } = useParams();
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const levels = pathname.split('/').filter(Boolean).length;
  const pages = [
    'developer',
    'mail',
    'integrations',
    'account',
  ];
  return (
    <Layout returnLinkText={levels > 2 ? formatMessage(messages.back) : null} returnLinkTo="back">
      <Grid className={styles['main-grid']} fluid>
        <Row className={styles['main-content']}>
          <Col xs={12} sm={5} md={3} lg={3} id="settingsCol">
            <Sticky innerZ={1} bottomBoundary="#settingsCol" enabled>
              <List>
                {pages.map(key => (
                  <ListItemButton
                    component={NavLink}
                    to={`/settings/${key}`}
                    selected={key === page}
                    className={styles.listItem}
                    key={key}
                  >
                    {formatMessage(messages[`${key}Settings`])}
                  </ListItemButton>
                ))}
              </List>
            </Sticky>
          </Col>
          <Col xs={12} sm={7} md={9} lg={9}>
            <Outlet />
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

SettingsPage.loader = store => () => {
  const state = store.getState();
  const surveys = getSurveys(state);
  const token = getToken(state);
  const promises = [];
  if (!isSettingsLoaded(state)) {
    promises.push(store.dispatch(fetchSettings(token)));
  }
  if (!surveys) {
    promises.push(store.dispatch(fetchSurveys(token)));
  }
  return Promise.all(promises);
};

export default SettingsPage;
