import React, { useEffect, useRef, useState } from 'react';

import Nav from './Nav';

import styles from './Nav.css';

const NavContainer = (props) => {
  const nav = useRef();
  const [className, setClassName] = useState(styles.normal);

  useEffect(() => {
    const handleScroll = () => {
      if (nav.current) {
        const value = +(window.scrollY || window.pageYOffset);
        if (value > 80 && className !== styles.inverse) {
          setClassName(styles.inverse);
        } else if (value < 80 && className !== styles.normal) {
          setClassName(styles.normal);
        }
      }
    };
    // Fixed Header
    window.addEventListener('scroll', handleScroll);
    return () => { window.removeEventListener('scroll', handleScroll); };
  }, [className]);

  return (
    <div ref={nav} className={className}>
      <Nav {...props} />
    </div>
  );
};

export default NavContainer;
