import { defineMessages } from 'react-intl';

const messages = defineMessages({
  Unknown: {
    id: 'Errors.Unknown',
    defaultMessage: 'An unknown error occurred.',
  },
  'Survey is disabled': {
    id: 'Errors.Survey is disabled',
    defaultMessage: 'This survey is disabled, please enable it to be able to send it.',
  },
});
const getErrorMessageKey = ({ message }) => {
  return messages[message] || messages.Unknown;
};
export default getErrorMessageKey;
