import React from 'react';

import Testimonials from './Testimonials';

class TestimonialsContainer extends React.Component {
  componentDidMount() {
    window.onload = () => {
      if (document.createEvent) { // W3C
        const ev = document.createEvent('Event');
        ev.initEvent('resize', true, true);
        window.dispatchEvent(ev);
      } else if (document.fireEvent) { // IE
        document.fireEvent('onresize');
      }
    };
  }

  render() {
    return <Testimonials {...this.props} />;
  }
}

export default TestimonialsContainer;
