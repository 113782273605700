import { useChargebee } from 'client/modules/App/ChargebeeContext';
import { getToken } from 'client/modules/Auth/AuthReducer';
import callApi from 'client/util/apiCaller';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../../App/components/Layout/LayoutContainer';
import PricingPlan from '../../../../App/components/PricingPlan/PricingPlan';
import Spinner from '../../../../App/components/Spinner/Spinner';

import styles from './PricingPage.css';

const Pricing = (props) => {
  const navigate = useNavigate();
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const cbInstance = useChargebee();
  const handleSelect = async (plan) => {
    if (!token) {
      return;
    }
    const response = await callApi(
      dispatch,
      'users/checkout-pages',
      token, 'POST',
      { planId: plan.id, locale: props.locale },
    );
    const { url, id: hostedPageId } = response.hosted_page;
    cbInstance.openCheckout({
      hostedPage: async () => ({
        url,
        embed: true,
        type: 'checkout_new',
      }),

      /*
       * This will be triggered when checkout is complete.
       */
      success() {
        cbInstance.closeAll();
        navigate(`/subscriptions/ok?state=succeeded&id=${hostedPageId}`);
      },
    });
  };

  return (
    <Layout>
      <Grid className={styles.mainGrid} fluid>
        <Row>
          <Col xs={12}>
            <h2><FormattedMessage id="Pricing.Select" defaultMessage="Select plan" /></h2>
            <p>
              <FormattedMessage
                id="Pricing.ContactForCustomPlan"
                defaultMessage="If you don’t see a plan that meets your needs, please {contact}."
                values={{
                  contact: <a href="mailto:hello@promoter.ninja">
                    <FormattedMessage id="Pricing.ContactUs" defaultMessage="contact us" />
                  </a>,
                }}
              />
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={styles.pricingTable}>
              {!props.plans || props.plans.length === 0 ? <Spinner /> : null}
              {props.plans.map(plan => (
                <PricingPlan
                  key={plan.id}
                  name={plan.name}
                  price={plan.price / 100}
                  currency={plan.currency_code}
                  people={plan.cf_people}
                  popular={plan.cf_featured}
                  freeMonths={plan.cf_free_months || 0}
                  period={plan.period}
                  unit={plan.period_unit}
                  handleSelect={handleSelect.bind(this, plan)}
                />
              ))}
            </div>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

Pricing.propTypes = {
  plans: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    currency_code: PropTypes.string.isRequired,
    cf_order: PropTypes.number.isRequired,
    cf_people: PropTypes.number.isRequired,
    cf_featured: PropTypes.bool.isRequired,
  })).isRequired,
  getCheckoutPage: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
};

export default Pricing;
