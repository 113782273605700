import Typography from '@mui/material/Typography';
import { useFindBySurveyQuery } from 'client/modules/Surveys/savedSearchApiSlice';
import NotFound from 'components/NotFound/NotFound';
import Spinner from 'components/Spinner/Spinner';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'react-router-dom';

const styles = {
  inner: {
    fontSize: 'inherit',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 0.5,
    flexWrap: 'nowrap',
  },
  title: {
    fontSize: 'inherit',
    margin: 0,
    color: 'muted.light',
    display: 'flex',
    alignItems: 'baseline',
  },
};

const SavedSearch = ({ sx }) => {
  const { savedSearchId, id: surveyId } = useParams();
  const location = useLocation();
  const { data: savedSearches, isLoading, isSuccess } = useFindBySurveyQuery(surveyId);
  const savedSearch = savedSearches?.find(s => s._id === savedSearchId);
  const savedSearchTouched = location.search && savedSearch && savedSearch.query !== location.search;

  if (savedSearchId && !savedSearch && isSuccess) {
    return <NotFound />;
  }
  if (savedSearchId && isLoading) {
    return <Spinner />;
  }

  if (!savedSearch) {
    return null;
  }

  return (
    <Typography sx={{ ...styles.inner, ...sx }}>
      {savedSearch.name}
      {savedSearchTouched && (
        <Typography sx={styles.title}>
          <FormattedMessage
            id="Dashboard.SavedSearchTouched"
            defaultMessage="- Edited"
          />
        </Typography>
      )}
    </Typography>
  );
};

export default SavedSearch;
