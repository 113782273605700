import {
  Box, Button, FormControlLabel, ToggleButton, ToggleButtonGroup, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useCreateApiKeyMutation, useUpdateApiKeyMutation } from 'client/modules/Surveys/apiKeysSlice';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const objects = [
  'ApiKeys',
  'Folders',
  'Links',
  'People',
  'Responses',
  'SavedSearches',
  'Surveys',
  'Webhooks',
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    margin: 0,
    border: 0,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    '&:not(:first-of-type)': {
      marginLeft: 0,
    },
    '&:last-of-type': {
      borderBottom: 0,
    },
  },
}));

const messages = defineMessages({
  name: {
    id: 'ApiKeysSettings.ApiKeys.Name',
    defaultMessage: 'Name',
  },
  restricted: {
    id: 'ApiKeysSettings.ApiKeys.Restricted',
    defaultMessage: 'Restricted',
  },
  fullAccess: {
    id: 'ApiKeysSettings.ApiKeys.FullAccess',
    defaultMessage: 'Full access',
  },
  restrictedDescription: {
    id: 'ApiKeysSettings.ApiKeys.RestrictedDescription',
    defaultMessage: 'This API key will only have access to the objects and permissions you select below.',
  },
  fullAccessDescription: {
    id: 'ApiKeysSettings.ApiKeys.FullAccessDescription',
    defaultMessage: 'This API key will have full access to all objects and permissions.',
  },
  ApiKeys: {
    id: 'ApiKeysSettings.ApiKeys.object.ApiKeys',
    defaultMessage: 'API keys',
  },
  Folders: {
    id: 'ApiKeysSettings.ApiKeys.object.Folders',
    defaultMessage: 'Folders',
  },
  Links: {
    id: 'ApiKeysSettings.ApiKeys.object.Links',
    defaultMessage: 'Links',
  },
  People: {
    id: 'ApiKeysSettings.ApiKeys.object.People',
    defaultMessage: 'People',
  },
  Responses: {
    id: 'ApiKeysSettings.ApiKeys.object.Responses',
    defaultMessage: 'Responses',
  },
  SavedSearches: {
    id: 'ApiKeysSettings.ApiKeys.object.SavedSearches',
    defaultMessage: 'Saved searches',
  },
  Surveys: {

    id: 'ApiKeysSettings.ApiKeys.object.Surveys',
    defaultMessage: 'Surveys',
  },
  Webhooks: {
    id: 'ApiKeysSettings.ApiKeys.object.Webhooks',
    defaultMessage: 'Webhooks',
  },
  none: {
    id: 'ApiKeysSettings.ApiKeys.permission.none',
    defaultMessage: 'None',
  },
  read: {
    id: 'ApiKeysSettings.ApiKeys.permission.read',
    defaultMessage: 'Read',
  },
  write: {
    id: 'ApiKeysSettings.ApiKeys.permission.write',
    defaultMessage: 'Write',
  },
  delete: {
    id: 'ApiKeysSettings.ApiKeys.permission.delete',
    defaultMessage: 'Delete',
  },
});
const ApiKeyDialog = ({
  isOpen, close, apiKey, onCreated,
}) => {
  const [create] = useCreateApiKeyMutation();
  const [update] = useUpdateApiKeyMutation();
  const form = useForm({});
  useEffect(() => {
    if (isOpen) {
      form.reset({
        name: apiKey?.name || '',
        fullAccess: apiKey?.fullAccess || true,
        permissions: apiKey?.permissions || objects.reduce((acc, object) => ({ ...acc, [object]: 'none' }), {}),
      });
    }
  }, [apiKey, form, isOpen]);
  const { formatMessage } = useIntl();
  const onSubmit = async (values) => {
    if (apiKey?._id) {
      await update({ _id: apiKey._id, ...values });
    } else {
      const { data } = await create(values);
      onCreated({ apiKey: data });
    }
    close();
  };
  const fullAccess = form.watch('fullAccess');
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '500px' } }}
      component="form"
      onSubmit={form.handleSubmit(onSubmit)}
      open={isOpen}
      onClose={close}
      aria-labelledby="apikeydialog-title"
      aria-describedby="apikeydialog-description"
    >

      <DialogTitle id="apikeydialog-title">
        {apiKey?._id ? (
          <FormattedMessage
            id="ApiKeysSettings.ApiKeys.EditApiKey"
            defaultMessage="Edit API key"
          />
        ) : (
          <FormattedMessage
            id="ApiKeysSettings.ApiKeys.CreateApiKey"
            defaultMessage="Create API key"
          />
        )}
      </DialogTitle>
      <DialogContent sx={{
        backgroundColor: 'background.body', borderColor: 'divider', borderStyle: 'solid', borderWidth: '1px 0',
      }}
      >
        <TextField
          {...form.register('name')}
          label={formatMessage(messages.name)}
          fullWidth
          required
          autoFocus
        />
        <RadioGroup
          {...form.register('fullAccess')}
          aria-label="fullAccess"
          defaultValue
          sx={{
            display: 'flex', flexDirection: 'column', justifyContent: 'space-between', mt: 2,
          }}
        >
          {['fullAccess', 'restricted'].map(permission => (
            <FormControlLabel
              key={permission}
              value={permission === 'fullAccess'}
              control={<Radio />}
              disableTypography
              label={(
                <Box sx={{ my: 1 }}>
                  <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                    {formatMessage(messages[permission])}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {formatMessage(messages[`${permission}Description`])}
                  </Typography>
                </Box>
              )}
              sx={{
                backgroundColor: 'background.paper',
                display: 'flex',
                alignItems: 'flex-start',
                gap: 1,
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: 1,
                mx: 0,
                my: 1,
              }}
            />
          ))}
        </RadioGroup>
        {!fullAccess && (
          <Paper
            variant="outlined"
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              flexDirection: 'column',
              backgroundColor: 'background.body',
              justifyContent: 'space-between',
              alignItems: 'center',
              '& > *': {
                px: 1,
                display: 'flex',
                alignItems: 'center',
                height: '3em',
                borderBottom: '1px solid',
                borderColor: 'divider',
              },
            }}
          >
            {objects.map(object => (
              <Controller
                render={
                  ({ field }) => (
                    <>
                      <Typography sx={{ whiteSpace: 'nowrap' }}>
                        {formatMessage(messages[object])}
                      </Typography>
                      <Box>
                        <Paper variant="outlined">
                          <StyledToggleButtonGroup
                            {...field}
                            exclusive
                            size="small"
                            color="primary"
                            onChange={(event, value) => field.onChange(value)}
                          >
                            {['none', 'read', 'write', 'delete'].map(permission => (
                              <ToggleButton
                                disableRipple
                                key={`${object}.${permission}`}
                                value={permission}
                                aria-label={formatMessage(messages[permission])}
                                sx={{ width: '25%' }}
                              >
                                {formatMessage(messages[permission])}
                              </ToggleButton>
                            ))}
                          </StyledToggleButtonGroup>
                        </Paper>
                      </Box>
                    </>
                  )}
                key={object}
                name={`permissions.${object}`}
                control={form.control}
              />
            ))}
          </Paper>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="inherit">
          <FormattedMessage id="ApiKeysSettings.ApiKeys.cancel" defaultMessage="Cancel" />
        </Button>
        <Button type="submit" color="primary">
          {apiKey?._id ? (
            <FormattedMessage
              id="ApiKeysSettings.ApiKeys.Save"
              defaultMessage="Save"
            />
          ) : (
            <FormattedMessage
              id="ApiKeysSettings.ApiKeys.Create"
              defaultMessage="Create"
            />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ApiKeyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  apiKey: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired,
};

export default ApiKeyDialog;
