import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Message from '../../../App/components/Message/Message';

import styles from './NoResponses.css';
import surveyShape from './surveyShape';

const NoResponses = (props) => {
  return (
    <Message className={styles.noResponses} sticky={false}>
      <h2>
        <FormattedMessage
          id="NoResponses.demoData"
          defaultMessage="Demo data"
        />
      </h2>
      <p>
        {props.survey.sent
          ? (
            <div>
              <p>
                <FormattedMessage
                  id="NoResponses.receiveFirstResponse"
                  defaultMessage="Once we receive your first response, this page will update automatically and we’ll send you an email."
                />
              </p>
              <p>
                <FormattedMessage
                  id="NoResponses.orSendMoreSurveys"
                  defaultMessage="You may also {survey} to increase your chances of having responses."
                  values={{
                    survey: (
                      <Link to={props.importURL}>
                        <FormattedMessage
                          id="NoResponses.surveyMorePeople"
                          defaultMessage="survey more people"
                        />
                      </Link>
                    ),
                  }}
                />
              </p>
            </div>
          )
          : (
            <FormattedMessage
              id="NoResponses.pleaseSurveyToStart"
              defaultMessage="Please {survey} to start collecting your own data."
              values={{
                survey: (
                  <Link to={props.importURL}>
                    <FormattedMessage
                      id="NoResponses.surveySomePeople"
                      defaultMessage="survey some people"
                    />
                  </Link>
                ),
              }}
            />
          )
        }
      </p>
    </Message>
  );
};

NoResponses.propTypes = {
  survey: PropTypes.shape(surveyShape).isRequired,
  importURL: PropTypes.string.isRequired,
};

export default connect()(injectIntl(NoResponses));
