import { defineMessages } from 'react-intl';

const surveyTypeMessages = defineMessages({
  nps: {
    id: 'ChooseType.nps',
    defaultMessage: 'Net Promoter Score',
  },
  ces: {
    id: 'ChooseType.ces',
    defaultMessage: 'Customer Effort Score',
  },
  csat: {
    id: 'ChooseType.csat',
    defaultMessage: 'Customer Satisfaction',
  },
  star_five: {
    id: 'ChooseType.star_five',
    defaultMessage: '5-star rating',
  },
  custom: {
    id: 'ChooseType.custom',
    defaultMessage: 'Custom',
  },
  choose: {
    id: 'ChooseType.ChooseAndCustomize',
    defaultMessage: 'Choose and customize',
  },
});

export default surveyTypeMessages;
