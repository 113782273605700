import { connect } from 'react-redux';

import { getHostedPage } from '../../SubscriptionReducer';

import CheckoutPage from './CheckoutPage';

export const ensurePlan = (store, nextState, replace, callback) => {
  const hostedPage = getHostedPage(store.getState());
  if (!hostedPage) {
    replace('/pricing');
  }
  callback();
};

const mapStateToProps = (state) => {
  return {
    hostedPage: getHostedPage(state),
  };
};

const CheckoutPageContainer = connect(
  mapStateToProps,
)(CheckoutPage);

export default CheckoutPageContainer;
