import PropTypes from 'prop-types';
import React from 'react';

const Accordion = ({ onOpenedChange, openPosition, children }) => {
  const nodes = children.map((node, index) => {
    return React.cloneElement(node, {
      onExpandChange: (newExpandedState) => {
        onOpenedChange(newExpandedState ? index : null);
      },
      expanded: openPosition === index,
    }, node.props.children);
  });

  return <div style={{ width: '100%' }}>{nodes}</div>;
};

Accordion.defaultProps = {
  openPosition: 0,
};

Accordion.propTypes = {
  openPosition: PropTypes.number,
  onOpenedChange: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
};

export default Accordion;
