import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import SVGInline from 'react-svg-inline';
import email from '../../../Auth/pages/Login/images/email.svg';

import styles from './Login.css';
import { Link } from 'react-router-dom';
import LayoutFocused from '../../../App/components/LayoutFocused/LayoutFocused';

const messages = defineMessages({
  title: {
    id: 'ForgotPassword.Title',
    defaultMessage: 'Password reminder',
  },
});

const ForgotPasswordSentPage = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <LayoutFocused title={formatMessage(messages.title)}>
      <div className={styles.email}>
        <SVGInline svg={email} />
      </div>
      <h1>
        <FormattedMessage
          id="ForgotPasswordSent.CheckYourEmail"
          defaultMessage="Check your email"
        />
      </h1>
      <p className={styles.justify}>
        <FormattedMessage
          id="ForgotPasswordSent.YouWillGetAnEmail"
          defaultMessage="Once we find your account you will receive a message with further instructions. If you don't see it in 15 minutes, please check your spam folder."
        />
      </p>
      <p>
        <FormattedMessage
          id="ForgotPasswordSent.GoBackTo"
          defaultMessage="Go back to {loginLink}"
          values={{
            loginLink: <Link to="/login">
              <FormattedMessage id="ForgotPasswordSent.LoginLink" defaultMessage="promoter.ninja/login" />
            </Link>,
          }}
        />
      </p>
    </LayoutFocused>
  );
};

ForgotPasswordSentPage.propTypes = {
  intl: PropTypes.any.isRequired,
};

export const checkToken = () => {

};

export default injectIntl(ForgotPasswordSentPage);
