import { handleActions } from 'redux-actions';

const initialState = {
  message: null,
  type: null,
  done: false,
  integrationProps: {},
};

const IntegrationsReducer = handleActions({
  ADD_CB: (state, action) => ({
    ...state,
    message: action.payload.message,
    type: action.payload.type,
    integrationProps: action.payload.integrationProps,
    done: true,
  }),
  RESET_STATE: () => initialState,
}, initialState);


export const getMessage = state => state.integrations.message;
export const getType = state => state.integrations.type;
export const getDone = state => state.integrations.done;
export const getIntegrationProps = state => state.integrations.integrationProps;

export default IntegrationsReducer;
