const applyStyle = styleFile => (selectors, styleIn = {}) => {
  const style = { ...styleIn };
  // Our 'className' will be appended here
  let className = '';

  // For each 'selector' provided (eg '.pull-xs-right'), look up
  // the styles in the stylesheet. 'inline-style-loader', described
  // more below, is how we query the stylesheet.
  selectors.forEach((slctr) => {
    const rules = styleFile[slctr];
    // If CSS rules exist for this selector, add them to
    // 'className' and 'style'.
    if (rules) {
      const styles = rules.split(';');
      styles.forEach((stl) => {
        const [attribute, value] = stl.split(':');
        const attr = attribute.replace(/-([a-z])/g, g => g[1].toUpperCase());
        // add key/value to the 'style' object
        style[attr.trim()] = style[attr.trim()] || value.trim();
      });
    }
    // Append selector to className
    className += `${className === '' ? '' : ' '}${slctr.substr(1)}`;
  });

  // Return an object that can be spread directly onto a component.
  return {
    style,
    className,
  };
};

export default applyStyle;
