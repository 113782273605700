import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import Sticky from 'react-stickynode';

import styles from './Message.css';

const Message = (props) => {
  const typeStyle = {
    ok: styles.ok,
    info: styles.info,
    error: styles.error,
  }[props.type]; // I need above references for uglifier not to remove styles.
  const stickyRef = React.useRef(null);
  useEffect(() => {
    if ((props.msg || props.children?.length) && stickyRef.current) {
      requestAnimationFrame(() => {
        stickyRef.current?.update();
      });
    }
  }, [props.msg, props.children]);
  return (
    <Sticky innerZ={100} enabled={props.sticky} ref={stickyRef}>
      <div className={`${typeStyle} ${props.msg || props.children ? '' : styles.closed} ${props.className || ''}`}>
        <div id="__msg" className={styles.container} style={props.maxWidth ? { maxWidth: props.maxWidth } : null}>
          <div className={styles.msg}>{props.msg || props.children}</div>
          {props.onClose
            ? (
              <IconButton
                style={{ fontSize: '24px', color: 'inherit' }}
                name="close"
                className={styles.close}
                onClick={() => props.onClose()}
                size="large"
              >
                <MdClose />
              </IconButton>
            ) : null
          }
        </div>
      </div>
    </Sticky>
  );
};

Message.defaultProps = {
  type: 'ok',
  className: null,
  msg: null,
  maxWidth: null,
  onClose: null,
  children: null,
  sticky: true,
};

Message.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(['ok', 'info', 'error']),
  msg: PropTypes.node,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  children: PropTypes.any,
  sticky: PropTypes.bool,
};

export default Message;
