import { Close } from '@mui/icons-material';
import Neutral from '@mui/icons-material/Face';
import Dissatisfied from '@mui/icons-material/SentimentDissatisfied';
import Satisfied from '@mui/icons-material/SentimentSatisfied';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Input,
  InputAdornment,
  InputLabel,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ObjectID } from 'bson';
import FlatButton from 'components/FlatButton/FlatButton';
import React from 'react';
import { FaHashtag, FaLock } from 'react-icons/fa';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';
import cartesian from '../../../../../util/cartesian';
import styles from '../IntegrationsListPage.css';

const messages = defineMessages({
  slackChannel: {
    id: 'IntegrationsSettings.SlackChannel',
    defaultMessage: 'Channel Name',
  },
  isPublic: {
    id: 'IntegrationsSettings.SlackChannelIsPublic',
    defaultMessage: 'Public Channel',
  },
  channelRequired: {
    id: 'IntegrationsSettings.SlackChannelRequired',
    defaultMessage: 'Channel name is required.',
  },
  surveysRequired: {
    id: 'IntegrationsSettings.SlackSurveysRequired',
    defaultMessage: 'Please select some surveys.',
  },
  channelFormat: {
    id: 'IntegrationsSettings.SlackChannelFormat',
    defaultMessage: 'Channel names can only contain lowercase letters, numbers, hyphens, and underscores, and must be 80 characters or less.',
  },
  surveys: {
    id: 'IntegrationsSettings.SlackSurveys',
    defaultMessage: 'Surveys',
  },
  condition: {
    id: 'IntegrationsSettings.SlackCondition',
    defaultMessage: 'Condition',
  },
  addRule: {
    id: 'IntegrationsSettings.SlackAddRule',
    defaultMessage: '+ Add a new rule',
  },
  conditionAllAll: {
    id: 'IntegrationsSettings.ConditionAllAll',
    defaultMessage: 'All responses',
  },
  conditionCommentedAll: {
    id: 'IntegrationsSettings.ConditionCommentedAll',
    defaultMessage: 'Only responses with comments',
  },
  conditionAllPromoters: {
    id: 'IntegrationsSettings.ConditionAllPromoters',
    defaultMessage: 'All "Satisfied"',
  },
  conditionCommentedPromoters: {
    id: 'IntegrationsSettings.ConditionCommentedPromoters',
    defaultMessage: 'Only "Satisfied" with comments',
  },
  conditionAllPassives: {
    id: 'IntegrationsSettings.ConditionAllPassives',
    defaultMessage: 'All "Neutral"',
  },
  conditionCommentedPassives: {
    id: 'IntegrationsSettings.ConditionCommentedPassives',
    defaultMessage: 'Only "Neutral" with comments',
  },
  conditionAllDetractors: {
    id: 'IntegrationsSettings.ConditionAllDetractors',
    defaultMessage: 'All "Dissatisfied"',
  },
  conditionCommentedDetractors: {
    id: 'IntegrationsSettings.ConditionCommentedDetractors',
    defaultMessage: 'Only "Dissatisfied" with comments',
  },
  all: {
    id: 'IntegrationsSettings.All',
    defaultMessage: 'All',
  },
});


const DEFAULT_RULES = [{
  _id: new ObjectID().toString(), surveys: null, condition: 'group:all,commented:true', channel: '#promoter-ninja',
}];

const IntegrationConfigSlack = () => {
  const {
    onIntegrationsPropertiesChange, integration, surveys,
  } = useOutletContext();
  const { formatMessage } = useIntl();
  const { rules = DEFAULT_RULES } = integration;

  const setRuleProps = (index, props) => {
    onIntegrationsPropertiesChange(null, 'slack', { rules: rules.map((r, idx) => (index === idx ? { ...r, ...props } : r)) });
  };

  const setChannelName = index => (event) => {
    const channelName = event.target.value;
    const isPublic = rules[index].channel.startsWith('#');
    const channel = isPublic ? `#${channelName}` : channelName;
    setRuleProps(index, { channel });
  };

  const setPublic = index => (event, isPublic) => {
    const channelName = rules[index].channel.replace(/^#/, '');
    const channel = isPublic ? `#${channelName}` : channelName;
    setRuleProps(index, { channel, isPublic });
  };

  const handleChangeSurveys = index => (event, elem) => {
    let newSurveys = event.target.value;
    const { id } = elem.props;
    if (id === 'all-surveys') {
      newSurveys = !rules[index].surveys ? [] : null;
    }
    setRuleProps(index, { surveys: newSurveys });
  };

  const handleChangeCondition = index => (event) => {
    setRuleProps(index, { condition: event.target.value });
  };

  const addRule = () => {
    const newRules = [...rules, { ...DEFAULT_RULES[0], _id: new ObjectID().toString(), channel: '' }];
    onIntegrationsPropertiesChange(null, 'slack', { rules: newRules });
  };

  const removeRule = index => () => {
    const newRules = rules.filter((r, idx) => idx !== index);
    onIntegrationsPropertiesChange(null, 'slack', { rules: newRules });
  };

  const errors = {};
  const icons = {
    Promoters: <Satisfied style={{ color: '#4fc44f' }} />,
    Passives: <Neutral style={{ color: '#f7c64b' }} />,
    Detractors: <Dissatisfied style={{ color: '#f66242' }} />,
  };
  return (
    <div>
      <span>
        <FormattedMessage
          id="IntegrationsSettings.SlackTeam"
          defaultMessage="Team name: {teamName}"
          values={{
            teamName: integration.teamName,
          }}
        />
      </span>
      <div>
        {rules.map((rule, index) => {
          const surveysValue = rule.surveys === null ? [...surveys.map(s => s.id), 'all'] : rule.surveys;
          return (
            <div key={rule._id} className={styles.rule}>
              <div className={styles.ruleInput}>
                <div className={styles.inputIcon}>
                  {(() => {
                    if (!rule.channel || rule.channel === '#') {
                      errors[`${index}-channel`] = formatMessage(messages.channelRequired);
                    } else if (!rule.channel.match(/^#?[a-zA-Z0-9_-]{1,80}$/)) {
                      errors[`${index}-channel`] = formatMessage(messages.channelFormat);
                    }
                    return null;
                  })()}
                  <TextField
                    required
                    pattern="^#?[a-zA-Z0-9_-]{1,80}$"
                    variant="standard"
                    className={styles.input}
                    fullWidth
                    style={errors[`${index}-channel`] ? { marginBottom: 20 } : {}}
                    name="slackChannel"
                    value={rule.channel.replace(/^#/, '')}
                    inputStyle={{ paddingLeft: '1.25em' }}
                    onChange={setChannelName(index)}
                    label={formatMessage(messages.slackChannel)}
                    errorText={errors[`${index}-channel`]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {rule.channel.startsWith('#')
                            ? <FaHashtag />
                            : <FaLock />}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={rule.channel.startsWith('#')}
                      onChange={setPublic(index)}
                    />
                  )}
                  label={formatMessage(messages.isPublic)}
                />
              </div>
              <FormControl variant="standard" className={styles.ruleInput}>
                <InputLabel htmlFor={`select-group-${rule._id}`} shrink>{formatMessage(messages.condition)}</InputLabel>
                <Select
                  variant="standard"
                  className={styles.input}
                  input={<Input id={`select-group-${rule._id}`} />}
                  fullWidth
                  hintText={formatMessage(messages.condition)}
                  value={rule.condition}
                  onChange={handleChangeCondition(index)}
                  autoWidth
                >
                  {cartesian(['All', 'Promoters', 'Passives', 'Detractors'], [false, true])
                    .map(([group, commented]) => (
                      (
                        <MenuItem value={`group:${group.toLowerCase()},commented:${commented}`}>
                          <ListItemIcon className={styles.item}>{icons[group]}</ListItemIcon>
                          {formatMessage(messages[`condition${commented ? 'Commented' : 'All'}${group}`])}
                        </MenuItem>
                      )
                    ))}
                </Select>
              </FormControl>
              {(() => {
                if (rule.surveys && rule.surveys.length === 0) {
                  errors[`${index}-surveys`] = formatMessage(messages.surveysRequired);
                }
                return null;
              })()}
              <FormControl variant="standard" className={styles.ruleInput}>
                <InputLabel htmlFor={`select-survey-${rule._id}`}>{formatMessage(messages.surveys)}</InputLabel>
                <Select
                  variant="standard"
                  className={styles.input}
                  id={`select-survey-${rule._id}`}
                  errorText={errors[`${index}-surveys`]}
                  multiple
                  hintText={formatMessage(messages.surveys)}
                  value={surveysValue}
                  onChange={handleChangeSurveys(index)}
                  autoWidth
                  renderValue={values => (values.length > surveys.length ? formatMessage(messages.all) : values.map(v => (surveys.find(s => s.id === v) || {}).name)
                    .filter(v => v)
                    .join(', '))}
                >
                  <MenuItem
                    insetChildren
                    checked={!rule.surveys}
                    value="all"
                    id="all-surveys"
                    className={styles.all}
                  >
                    <Checkbox checked={!rule.surveys} />
                    {formatMessage(messages.all)}
                  </MenuItem>
                  {surveys.map(s => (
                    (
                      <MenuItem
                        insetChildren
                        key={s.name}
                        checked={!rule.surveys || (rule.surveys.includes(s.id))}
                        value={s.id}
                      >
                        <Checkbox checked={!rule.surveys || (rule.surveys.includes(s.id))} />
                        <ListItemText primary={s.name} />
                      </MenuItem>
                    )
                  ))}
                </Select>
              </FormControl>
              <IconButton sx={{ alignSelf: 'flex-start', top: 25 }} onClick={removeRule(index)}>
                <Close />
              </IconButton>
            </div>
          );
        })}
        <FlatButton
          primary
          label={formatMessage(messages.addRule)}
          onClick={addRule}
        />
      </div>
    </div>
  );
};

export default IntegrationConfigSlack;
