import { MenuList } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import { fetchContent } from 'client/modules/Content/ContentActions';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { useLoaderData } from 'react-router';
import { Link } from 'react-router-dom';
import Layout from '../../../App/components/Layout/LayoutContainer';

import styles from './LegalPage.css';

const LegalPage = () => {
  const { value } = useLoaderData();
  return (
    <Layout>
      <Grid className={styles['main-grid']}>
        <Row>
          <Col xs={12} sm={5} md={4} lg={4} style={{ position: 'relative' }}>
            <Paper sx={{ position: 'sticky', top: 100, mb: 4 }}>
              <MenuList>
                <MenuItem component={Link} to="/legal/terms">Terms of Service</MenuItem>
                <MenuItem component={Link} to="/legal/privacy">Privacy Policy</MenuItem>
                <MenuItem component={Link} to="/legal/dpa">Data Processing Agreement</MenuItem>
                <MenuItem component={Link} to="/legal/cookies">Cookie Policy</MenuItem>
              </MenuList>
            </Paper>
          </Col>
          <Col xs={12} sm={7} md={8} lg={8}>
            <div dangerouslySetInnerHTML={{ __html: value }} />
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

LegalPage.loader = ({ dispatch }) => ({ params: { key } }) => {
  return dispatch(fetchContent(key));
};

export default LegalPage;
