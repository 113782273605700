import { ExtendableError } from './ExtendableBuiltin';

const UNPROCESSABLE_ENTITY = 422;

class AppError extends ExtendableError {
  constructor({
    message, errors = {}, status = UNPROCESSABLE_ENTITY, details, cause,
  }) {
    super(message);
    this.message = message;
    this.errors = errors;
    this.status = status;
    this.name = 'AppError';
    this.details = details;
    this.cause = cause;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

export default AppError;
