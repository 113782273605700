import PropTypes from 'prop-types';
import React from 'react';
import { FaCheck } from 'react-icons/fa';
import Spinner from '../../../App/components/Spinner/Spinner';
import style from './AddPairwiseResponsePage.css';

const KEYCODE_A = 65;

class PairwiseResponsePage extends React.Component {
  constructor(props) {
    super(props);
    this.keyPress = this.keyPress.bind(this);
    this.responses = [];
    this.reverse = false;
    this.touch = true;
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyPress, false);
    this.responses.forEach(e => e.classList.remove(style.selected));
    this.touch = 'ontouchstart' in document.documentElement;
  }

  componentWillReceiveProps() {
    this.reverse = false;
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress, false);
  }

  toggleSelected(elem) {
    if (!this.props.responseEnabled) {
      return;
    }
    const { pairwiseSurveyId, question, locale, user, properties, addPairwiseAnswer } = this.props;
    if (elem) {
      this.responses.forEach(e => e.classList.remove(style.selected));
      elem.classList.add(style.selected);
      addPairwiseAnswer(pairwiseSurveyId, question.combination.id, locale, user, elem.id, properties, this.reverse);
    }
  }

  keyPress(event) {
    const idx = event.keyCode - KEYCODE_A;
    if (idx >= 0 && idx < this.responses.length) {
      this.toggleSelected(this.responses[event.keyCode - KEYCODE_A]);
    }
  }

  render() {
    const noTouchClass = this.touch ? null : style.noTouch;
    if (!this.props.question) return <Spinner />;
    let options = [this.props.question.combination.first, this.props.question.combination.second];
    if (this.reverse) {
      options = options.reverse();
    }
    return (
      <div className={[style.pageContainer, noTouchClass].join(' ')}>
        <div className={style.page}>
          <div className={style.question}>
            <span>{this.props.question.question}</span>
          </div>
          <div className={style.content}>
            <div className={style.control}>
              <ul className={style.columns}>
                {options.map((option, idx) => (
                  <li
                    key={option.id}
                    id={option.id}
                    role="button"
                    className={style.container}
                    onClick={e => this.toggleSelected(e.currentTarget)}
                    ref={(e) => {
                      this.responses[idx] = (e);
                    }}
                  >
                    <div className={style.letter}><span>{String.fromCharCode(KEYCODE_A + idx)}</span></div>
                    <span className={style.label}>{option.text}</span>
                    <span className={style.tick}><FaCheck /></span>
                    <div className={style.aux}>
                      <div className={style.bg} />
                      <div className={style.bd} />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className={style.footer}>
          {this.props.question.footer}
        </div>
      </div>
    );
  }
}

PairwiseResponsePage.defaultProps = {
  question: null,
  properties: null,
};

PairwiseResponsePage.propTypes = {
  question: PropTypes.shape({
    question: PropTypes.string,
    footer: PropTypes.string,
    combination: PropTypes.shape({
      id: PropTypes.string,
      first: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
      }),
      second: PropTypes.shape({
        id: PropTypes.string,
        text: PropTypes.string,
      }),
    }),
  }),
  pairwiseSurveyId: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
  addPairwiseAnswer: PropTypes.func.isRequired,
  properties: PropTypes.object,
  responseEnabled: PropTypes.bool.isRequired,
};

export default PairwiseResponsePage;
