import { getToken } from 'client/modules/Auth/AuthReducer';

const prepareHeaders = (headers, { getState }) => {
  // By default, if we have a token in the store, let's use that for authenticated requests
  const token = getToken(getState());
  if (token) {
    headers.set('authorization', `Bearer ${token}`);
  }
  return headers;
};

export default prepareHeaders;
