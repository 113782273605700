import classNames from 'classnames/bind';
import homeMessages from 'client/modules/Public/pages/HomePage/homeMessages';
import PropTypes from 'prop-types';
import React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { FaInfoCircle } from 'react-icons/fa';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import bsStyle from '../../../../../App/components/Bootstrap/Bootstrap.css';
import MailStars from './images/mail-5-star.png';
import MailStars2x from './images/mail-5-star@2x.png';
import MailCes from './images/mail-ces.png';
import MailCes2x from './images/mail-ces@2x.png';
import MailCsat from './images/mail-csat.png';
import MailCsat2x from './images/mail-csat@2x.png';

import MailNps from './images/mail-nps.png';
import MailNps2x from './images/mail-nps@2x.png';

import style from './Product.css';

const bs = classNames.bind(bsStyle);

const messages = defineMessages({
  productScreenshotAlt: {
    id: 'Product.ScreenshotAlt',
    defaultMessage: 'Survey email mobile screenshot',
  },
  genericTitle: {
    id: 'Product.generic.Title',
    defaultMessage: 'Ask any customer satisfaction question',
  },
  npsTitle: {
    id: 'Product.nps.Title',
    defaultMessage: 'NPS, a question to know them all',
  },
  cesTitle: {
    id: 'Product.ces.Title',
    defaultMessage: 'CES, best metric to predict engagement',
  },
  csatTitle: {
    id: 'Product.csat.Title',
    defaultMessage: 'CSAT, see how satisfied your customers are',
  },
  'five-starTitle': {
    id: 'Product.five-star.Title',
    defaultMessage: 'Quick and familiar 5-star rankings',
  },
  genericDescription: {
    id: 'Product.generic.Description',
    defaultMessage: 'Ask the right question at the right moment to get the most actionable feedback. Choose among the main customer satisfaction questions.',
  },
  npsDescription: {
    id: 'Product.nps.Description',
    defaultMessage: 'According to Harvard Business Review, the Net Promoter Score© is "The one number you need to grow".{lineBreak}'
      + 'NPS is the simplest and most efficient way to measure customer loyalty and grow your business.',
  },
  cesDescription: {
    id: 'Product.ces.Description',
    defaultMessage: 'Customer Effort Score is the best way to know how easy it is for your customers to interact with your product or customer support.',
  },
  csatDescription: {
    id: 'Product.csat.Description',
    defaultMessage: 'The most versatile question, can be used to directly ask customers how satisfied are they with specific parts of your products or services.',
  },
  'five-starDescription': {
    id: 'Product.five-star.Description',
    defaultMessage: 'Gather feedback about any part of the customer experience with the classic rating from one to five stars.',
  },
});

const Product = (props) => {
  const { intl: { formatMessage }, handleSignup, surveyType } = props;
  const mails = {
    generic: MailNps,
    nps: MailNps,
    ces: MailCes,
    csat: MailCsat,
    'five-star': MailStars,
  };
  const mails2x = {
    generic: MailNps2x,
    nps: MailNps2x,
    ces: MailCes2x,
    csat: MailCsat2x,
    'five-star': MailStars2x,
  };
  return (
    <section className={style.bg}>
      <div className={style.intro3}>
        <Grid className={bs('container')} fluid>
          <Row>
            <Col sm={5} xs={12}>
              <h3 className={bs('mbr-title-font')}>
                {formatMessage(messages[`${surveyType}Title`])}
              </h3>
              <p>
                <FormattedMessage
                  id={messages[`${surveyType}Description`].id}
                  values={{
                    lineBreak: <br />,
                  }}
                />
              </p>
              {surveyType === 'generic'
                ? (
                  <dl className={style.products}>
                    <dt>
                      {formatMessage(homeMessages.npsShort)}
                    </dt>
                    <dd>
                      {formatMessage(homeMessages.nps)}
                    </dd>
                    <dt>
                      {formatMessage(homeMessages.csatShort)}
                    </dt>
                    <dd>
                      {formatMessage(homeMessages.csat)}
                    </dd>
                    <dt>
                      {formatMessage(homeMessages.cesShort)}
                    </dt>
                    <dd>
                      {formatMessage(homeMessages.ces)}
                    </dd>
                    <dt>
                      {formatMessage(homeMessages['five-starShort'])}
                    </dt>
                    <dd>
                      {formatMessage(homeMessages['five-star'])}
                    </dd>
                  </dl>
                )
                : null}
              <h4>
                <FormattedMessage
                  id="Product.SendSurveys"
                  defaultMessage="Send {surveyType} surveys now"
                  values={{
                    surveyType: surveyType === 'generic' ? '' : formatMessage(homeMessages[`${surveyType}Short`]),
                  }}
                />
              </h4>
              <ul>
                <li><FormattedMessage id="Product.Benefit1" defaultMessage="500 surveys for free" /></li>
                <li>
                  <FormattedMessage id="Product.Benefit2" defaultMessage="40 languages" />
                  <a
                    href="https://promoter-ninja.freshdesk.com/support/solutions/articles/44001376568-how-to-send-the-nps-survey-in-different-languages"
                    target="_blank"
                    style={{ marginLeft: '.5em' }}
                    rel="noreferrer"
                  >
                    <FaInfoCircle />
                  </a>
                </li>
                <li><FormattedMessage id="Product.Benefit3" defaultMessage="Mobile friendly e-mail" /></li>
              </ul>
              <div className={style.btn}>
                <button
                  onClick={handleSignup}
                  type="button"
                  className={bs('btn', 'mbr-title-font', 'btn-lg', 'btn-primary')}
                >
                  <FormattedMessage id="Product.CallToAction" defaultMessage="Try now" />
                </button>
              </div>
            </Col>
            <Col className={style.right} sm={7} xs={12}>
              <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
                <img
                  src={mails[surveyType]}
                  srcSet={`${mails[surveyType]} 400w, ${mails2x[surveyType]} 800w`}
                  alt={formatMessage(messages.productScreenshotAlt)}
                  className={bs('mbr-figure__img', 'img-responsive', 'center-block')}
                />
              </figure>
            </Col>
          </Row>
        </Grid>
      </div>
    </section>
  );
};

Product.defaultProps = {
  surveyType: 'generic',
};

Product.propTypes = {
  handleSignup: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
  surveyType: PropTypes.string,
};

export default injectIntl(Product);
