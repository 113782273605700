import { useChargebee } from 'client/modules/App/ChargebeeContext';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { useNavigate } from 'react-router-dom';
import Layout from '../../../App/components/Layout/LayoutContainer';

const CheckoutPage = (props) => {
  const navigate = useNavigate();

  const insertCheckoutForm = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (!props.hostedPage) {
      return;
    }
    const { url, id: hostedPageId } = props.hostedPage;

    const cbInstance = useChargebee();

    cbInstance.openCheckout({
      hostedPage: async () => ({
        url,
        embed: true,
        type: 'checkout_new',
      }),

      /*
       * This will be triggered when checkout is complete.
       */
      success() {
        cbInstance.closeAll();
        navigate(`/subscriptions/ok?state=succeeded&id=${hostedPageId}`);
      },
    });
  };



  return (
    <Layout>
      <Grid style={{ width: '100%', padding: '20px 0', background: '#fafaf9' }} fluid>
        <Row>
          <Col xs={12} sm={12}>
            <div id="checkout-info" />
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

CheckoutPage.defaultProps = {
  hostedPage: null,
};

CheckoutPage.propTypes = {
  hostedPage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    siteName: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

export default CheckoutPage;
