import useActions from 'client/util/useActions';
import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { localizationData } from '../../../../../Intl/setup';

import { unsubscribe as unsubscribeAction, unsubscribeFromLink as unsubscribeFromLinkAction } from '../../PeopleActions';
import { getDone, getPerson } from '../../PeopleReducer';

import styles from './UnsubscribePage.css';

const UnsubscribePage = () => {
  const [unsubscribe, unsubscribeFromLink] = useActions([unsubscribeAction, unsubscribeFromLinkAction]);
  const { token, link, email } = useParams();
  const person = useSelector(getPerson);
  const {
    brand, topColor, logoUrl, language,
  } = person;
  const done = useSelector(getDone);
  useEffect(() => {
    if (token && !done) {
      unsubscribe(token);
    } else if (email && !done) {
      unsubscribeFromLink(link, email);
    }
  }, [token, done]);

  if (!done) {
    return null;
  }
  const emailMessages = localizationData[language].messages;
  const { unsubscribed } = emailMessages;
  const successfullyUnsubscribed = emailMessages.successfully_unsubscribed;
  const powered = emailMessages['nps.powered'].replace('{promoter_ninja}', '<a target="_blank" rel="noopener noreferrer" href="https://www.promoter.ninja/">Promoter Ninja</a>');

  const dir = ['ar', 'he'].indexOf(language) > -1 ? 'rtl' : 'ltr';

  return (
    <div className={styles.main}>
      <div className={styles['survey-response']}>
        <div className={styles['survey-response-header']} style={{ borderColor: topColor, color: topColor }}>
          <div className={styles.l11} dir={dir}>
            {logoUrl
              ? <img alt={brand} className={styles.l12} src={logoUrl} />
              : <div className={styles.l11} dir={dir}><span className="val-display-name">{brand}</span></div>
            }
          </div>
        </div>
        <div className={styles['survey-response-body']}>
          <div className={styles['survey-response-body-inner']}>
            <div className={styles['survey-response-text-confirmation']} dir={dir}>
              {unsubscribed}
            </div>
            <div className={styles['survey-response-thank-you']}>
              <div className={styles['survey-response-thank-you-message']}>
                <p>
                  {successfullyUnsubscribed}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div dir={dir} className={styles['survey-response-footer']} dangerouslySetInnerHTML={{ __html: powered }} />
      </div>
    </div>
  );
};

UnsubscribePage.loader = ({ dispatch }) => ({ params: { token } }) => dispatch(unsubscribeAction(token));
export default UnsubscribePage;
