import React from 'react';
import AsyncComponent from '../../../AsyncComponent/AsyncComponent';

const CodeSnippet = React.lazy(() => import(/* webpackChunkName: "codeSnippet" */ './CodeSnippet'));

export default props => (
  <AsyncComponent>
    <CodeSnippet {...props} />
  </AsyncComponent>
);
