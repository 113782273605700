const generateToken = (length = 32) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let token = '';

  const array = new Uint32Array(chars.length);
  window.crypto.getRandomValues(array);

  for (let i = 0; i < length; i += 1) {
    token += chars[array[i] % chars.length];
  }

  return token;
};

export default generateToken;
