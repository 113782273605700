/* eslint-disable react/require-default-props, react/destructuring-assignment */
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { MdClose } from 'react-icons/md';
import { block } from 'searchkit';
import styles from './RefinementListFilterDivider.css';

const FilterContainer = ({
  title, mod, className, disabled, children, intentRef, outline, onClose,
}) => {
  const containerBlock = block(mod)
    .state({ disabled });

  return (
    <div className={containerBlock.mix(className)}>
      <div className={`${containerBlock.el('header')} ${styles.title}`} ref={intentRef}>
        <span>{title}</span>
        {outline && <button type="button" onClick={onClose} className={styles.closeButton}><MdClose /></button>}
      </div>
      <div className={containerBlock.el('content')}>
        {children}
      </div>
    </div>
  );
};


FilterContainer.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  mod: PropTypes.string,
  className: PropTypes.string,
  outline: PropTypes.bool,
  children: PropTypes.any,
  intentRef: PropTypes.any,
  onClose: PropTypes.func.isRequired,
};

FilterContainer.defaultProps = {
  disabled: false,
  outline: false,
  mod: 'sk-panel',
  intentRef: null,
};

export default FilterContainer;
