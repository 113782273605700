import React from 'react';
import Icon from 'react-icon-base';

const Ces = props => (
  <Icon viewBox="0 0 97 36" {...props}>
    <g>
      <path
        d="M31.6,24.3a8.4,8.4,0,0,1-.8,3.6,12.1,12.1,0,0,1-2.5,3.7,11.9,11.9,0,0,1-4.5,3,14.9,14.9,0,0,1-6.2,1.2,19.6,19.6,0,0,1-5-.6,10.9,10.9,0,0,1-4-1.6,12.7,12.7,0,0,1-3.4-2.8,22.1,22.1,0,0,1-2.3-3.6,21.6,21.6,0,0,1-1.4-4.3A24.6,24.6,0,0,1,1,18a21.7,21.7,0,0,1,1.2-7.4A16.1,16.1,0,0,1,5.7,5a14.1,14.1,0,0,1,5.2-3.5A15.7,15.7,0,0,1,17.4.2a16.1,16.1,0,0,1,7.4,1.7,13.8,13.8,0,0,1,5,4.1,7.7,7.7,0,0,1,1.7,4.6,3.7,3.7,0,0,1-.8,2.2,2.9,2.9,0,0,1-2.1.9,2.7,2.7,0,0,1-2-.7,8.3,8.3,0,0,1-1.4-2.1A11.7,11.7,0,0,0,22,7a8.5,8.5,0,0,0-4.6-1.2A8.4,8.4,0,0,0,10.6,9C8.9,11.2,8,14.3,8,18.3a15.6,15.6,0,0,0,1.2,6.6,8.1,8.1,0,0,0,3.2,4,8.2,8.2,0,0,0,4.8,1.3,8.5,8.5,0,0,0,5.1-1.5,8.9,8.9,0,0,0,3.1-4.3,7.5,7.5,0,0,1,1.1-2.2,2.3,2.3,0,0,1,2.1-.9,3.1,3.1,0,0,1,3,3Z"
      />
      <path
        d="M60.5,6.2H44.8v8.4H59.3a4.3,4.3,0,0,1,2.4.7,2.5,2.5,0,0,1,.7,1.9,2.5,2.5,0,0,1-.7,1.9,3.3,3.3,0,0,1-2.4.8H44.8v9.7H61.1a3.3,3.3,0,0,1,2.4.8,2.7,2.7,0,0,1,0,4,3.3,3.3,0,0,1-2.4.8h-19a4.5,4.5,0,0,1-3.2-1,4.5,4.5,0,0,1-1-3.3V5.1a6.2,6.2,0,0,1,.4-2.5,2.5,2.5,0,0,1,1.4-1.3A6,6,0,0,1,42.1.8H60.5a3.6,3.6,0,0,1,2.5.8,2.6,2.6,0,0,1,.8,1.9A2.4,2.4,0,0,1,63,5.4,3.6,3.6,0,0,1,60.5,6.2Z"
      />
      <path
        d="M96,24.9a10.1,10.1,0,0,1-1.6,5.6,11.5,11.5,0,0,1-4.7,3.9,18.3,18.3,0,0,1-7.3,1.4,16.3,16.3,0,0,1-8.4-2,10.1,10.1,0,0,1-3.8-3.6,8.6,8.6,0,0,1-1.5-4.5,3.1,3.1,0,0,1,3.1-3.1,2.5,2.5,0,0,1,1.9.7A6.9,6.9,0,0,1,75,25.4a11.3,11.3,0,0,0,1.4,2.7,4.6,4.6,0,0,0,2.1,1.8,9,9,0,0,0,8.7-.7,4.1,4.1,0,0,0,1.9-3.6,4,4,0,0,0-1-2.8,7.2,7.2,0,0,0-2.7-1.6L81,20a30.2,30.2,0,0,1-6.2-2,9.8,9.8,0,0,1-4-3.2,8,8,0,0,1-1.4-5,8.9,8.9,0,0,1,1.5-5,9.3,9.3,0,0,1,4.5-3.4A17.8,17.8,0,0,1,82.3.2a17.1,17.1,0,0,1,5.4.8,10.1,10.1,0,0,1,6.1,4.8,6.1,6.1,0,0,1,.7,2.8,3.3,3.3,0,0,1-.9,2.3,3,3,0,0,1-2.2,1,2.4,2.4,0,0,1-1.8-.6,9.9,9.9,0,0,1-1.3-2,9.1,9.1,0,0,0-2.3-3c-.9-.8-2.3-1.1-4.2-1.1a7.1,7.1,0,0,0-4.3,1.2,3.5,3.5,0,0,0-1.7,2.8,2.8,2.8,0,0,0,.6,1.8,4.9,4.9,0,0,0,1.5,1.3l2,.8,3.3.9,5.3,1.5a16.6,16.6,0,0,1,4,2,8.3,8.3,0,0,1,2.6,3A9.6,9.6,0,0,1,96,24.9Z"
      />
    </g>
  </Icon>
);

export default Ces;
