import { handleActions } from 'redux-actions';

const initialState = {
  errorMessage: null,
  done: false,
  person: {},
};

const PeopleReducer = handleActions({
  UNSUBSCRIBE_SUCCESS: (state, action) => ({
    ...state,
    errorMessage: null,
    done: true,
    person: action.payload.person,
  }),
  UNSUBSCRIBE_FAILURE: (state, action) => ({
    ...state,
    errorMessage: action.payload,
    done: true,
  }),
}, initialState);

export const getErrorMessage = state => state.people.errorMessage;
export const getDone = state => state.people.done;
export const getPerson = state => state.people.person;

export default PeopleReducer;
