import withLocation from 'client/util/withLocation';
import SurveyDisabled from 'components/SurveyDisabled/SurveyDisabled';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl, IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Navigate } from 'react-router';
import { localizationData } from '../../../../../Intl/setup';
import withParams from '../../../../util/withParams';
import NotFound from '../../../App/components/NotFound/NotFound';
import SurveyEmail from '../../../Emails/Survey/SurveyEmail';
import { getRand } from '../../../Surveys/SurveyReducer';

import { fetchPublicResponse } from '../../ResponseActions';
import {
  getBrand,
  getButtonColor,
  getClient,
  getCsatFacesNum,
  getIntro,
  getLanguage,
  getLogoUrl,
  getNextQuestion,
  getPerson,
  getQuestion,
  getSurveyCsatType,
  getSurveyType,
  getTokenValid,
  getTopColor,
  isDisabled,
} from '../../ResponseReducer';

const AddScorePage = props => {
  const {
    intl,
    language,
    tokenValid,
    brand,
    topColor,
    buttonColor,
    question,
    intro,
    logoUrl,
    rand,
    responseId,
    client,
    person,
    surveyType,
    surveyCsatType,
    csatFacesNum,
    disabled,
    nextQuestion,
  } = props;

  const lang = language || intl.locale;

  if (surveyType === 'custom') {
    return <Navigate replace to={`/response/${responseId}/q/${nextQuestion}`} />;
  }

  return (
    <IntlProvider locale={lang} messages={localizationData[lang].messages} key={lang}>
      {disabled && <SurveyDisabled />}
      {!tokenValid && <NotFound />}
      {tokenValid && !disabled && (
        <SurveyEmail
          intl={intl}
          brand={brand}
          topColor={topColor}
          buttonColor={buttonColor}
          question={question}
          intro={intro}
          language={lang}
          logoUrl={logoUrl ? `${logoUrl}?r=${rand}` : null}
          medium="link"
          responseId={responseId}
          creatorId={client}
          personToken={person}
          surveyType={surveyType}
          surveyCsatType={surveyCsatType}
          csatFacesNum={csatFacesNum}
        />
      )}
    </IntlProvider>
  );
};

// Specifies the default values for props:
AddScorePage.defaultProps = {
  intro: null,
  logoUrl: null,
  nextQuestion: undefined,
};

AddScorePage.propTypes = {
  responseId: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  person: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  topColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  tokenValid: PropTypes.bool.isRequired,
  logoUrl: PropTypes.string,
  question: PropTypes.string.isRequired,
  intro: PropTypes.string,
  rand: PropTypes.string.isRequired,
  intl: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyCsatType: PropTypes.string.isRequired,
  csatFacesNum: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  nextQuestion: PropTypes.number,
};

function mapStateToProps(state, ownProps) {
  return {
    responseId: ownProps.params.response,
    client: getClient(state),
    person: getPerson(state),
    brand: getBrand(state),
    topColor: getTopColor(state),
    buttonColor: getButtonColor(state),
    language: getLanguage(state),
    tokenValid: !!getTokenValid(state),
    logoUrl: getLogoUrl(state),
    question: getQuestion(state),
    intro: getIntro(state),
    rand: `${getRand(state)}`,
    surveyType: getSurveyType(state),
    surveyCsatType: getSurveyCsatType(state),
    csatFacesNum: getCsatFacesNum(state),
    disabled: isDisabled(state),
    nextQuestion: getNextQuestion(state),
  };
}

export default injectIntl(withParams(connect(mapStateToProps)(withLocation(AddScorePage))));

export const addScorePageLoader = store => props =>
  store.dispatch(fetchPublicResponse(props.params.response));
