/* eslint-disable react/require-default-props */
import classNames from 'classnames/bind';
import { HOC } from 'formsy-react';
import PropTypes from 'prop-types';
import React from 'react';
import bsStyle from '../../../../../../App/components/Bootstrap/Bootstrap.css';

import style from './HomeInput.css';

const bs = classNames.bind(bsStyle);

const HomeInput = ({
  getValue, setValue, defaultValue, updateImmediately, validations, validationError, validationErrors,
  value, validationColor, requiredError, isRequired, isPristine, isValid, isFormSubmitted, getErrorMessage,
  setValidations, resetValue, hasValue, getErrorMessages, isFormDisabled, showRequired, showError, isValidValue,
  ...rest
}) => {
  const handleChange = (event) => {
    setValue(event.currentTarget.value);
  };

  const isRequiredError = isRequired() && !isPristine() && !isValid() && isFormSubmitted() && requiredError;
  const errorText = getErrorMessage() || isRequiredError;

  return (
    <div className={bs('form-group')}>
      <input
        className={bs('form-control')}
        value={getValue()}
        onChange={handleChange}
        {...rest}
      />
      <span className={errorText ? style.error : null}>{errorText}</span>
    </div>
  );
};

HomeInput.defaultProps = ({
  placeholder: '',
});

HomeInput.propTypes = ({
  placeholder: PropTypes.string,
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  getErrorMessage: PropTypes.func.isRequired,
  defaultValue: PropTypes.any,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  requiredError: PropTypes.string,
  underlineFocusStyle: PropTypes.object,
  underlineStyle: PropTypes.object,
  updateImmediately: PropTypes.bool,
  validationColor: PropTypes.string,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.any,
  isRequired: PropTypes.func.isRequired,
  isPristine: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  isFormSubmitted: PropTypes.func.isRequired,
  setValidations: PropTypes.func.isRequired,
  resetValue: PropTypes.func.isRequired,
  hasValue: PropTypes.func.isRequired,
  getErrorMessages: PropTypes.func.isRequired,
  isFormDisabled: PropTypes.func.isRequired,
  showRequired: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  isValidValue: PropTypes.func.isRequired,
});

export default HOC(HomeInput);
