import { Button } from '@mui/material';
import withParams from 'client/util/withParams';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setError } from '../../../App/AppActions';
import { getError } from '../../../App/AppReducer';
import FormsyPassword from '../../../App/components/FormsyPassword/FormsyPassword';
import LayoutFocused from '../../../App/components/LayoutFocused/LayoutFocused';
import { passwordReminderChange, passwordReminderCheck } from '../../AuthActions';
import { getChangingPasswordFor } from '../../AuthReducer';
import { loginMessages } from './components/LoginWidget/LoginWidget';

import styles from './Login.css';

const messages = defineMessages({
  title: {
    id: 'ForgotPasswordChange.Title',
    defaultMessage: 'Set your new password',
  },
  send: {
    id: 'ForgotPasswordChange.Send',
    defaultMessage: 'Send',
  },
});

const ForgotPasswordChangePage = (props) => {
  const password = useRef();
  const navigate = useNavigate();
  const [changed, setChanged] = useState(false);
  const { formatMessage } = useIntl();
  useEffect(() => {
    if (!changed) {
      props.dispatchPasswordReminderCheck();
    }
  }, []);

  const {
    dispatchPasswordReminderChange, email, error,
  } = props;
  const handleSubmit = () => {
    setChanged(true);
    dispatchPasswordReminderChange(password.current.getValue(), navigate);
  };
  return (
    <LayoutFocused title={formatMessage(messages.title)}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            {error ? (
              <FormattedMessage
                id="ForgotPasswordChange.SendAgain"
                defaultMessage="Remember that the link we generated is only valid during 24h, please try {link}."
                values={{
                  link: (
                    <Link to="/forgot-password">
                      <FormattedMessage id="ForgotPasswordChange.SendAgainLink" defaultMessage="sending it again" />
                    </Link>
                  ),
                }}
              />
            ) : (
              <Formsy.Form
                onValidSubmit={handleSubmit}
                className={styles.form}
              >
                <p>
                  <FormattedMessage
                    id="ForgotPasswordChange.ChooseYourNewPassword"
                    defaultMessage="Pick a new password for {email}"
                    values={{
                      email: <input
                        type="text"
                        name="email"
                        value={email}
                        className={styles.changePassEmail}
                        disabled
                      />,
                    }}
                  />
                </p>
                <FormsyPassword
                  id="password"
                  name="password"
                  type="password"
                  ref={password}
                  floatingLabelText={formatMessage(loginMessages.password)}
                  required
                  fullWidth
                  autoFocus
                />
                <Button
                  className={styles.submit}
                  variant="contained"
                  type="submit"
                  color="primary"
                >
                  {formatMessage(messages.send)}
                </Button>
              </Formsy.Form>
            )
            }
          </Col>
        </Row>
      </Grid>
    </LayoutFocused>
  );
};

ForgotPasswordChangePage.defaultProps = {
  error: null,
  email: null,
};

ForgotPasswordChangePage.propTypes = {
  error: PropTypes.any,
  email: PropTypes.string,
  dispatchPasswordReminderChange: PropTypes.func.isRequired,
  dispatchPasswordReminderCheck: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    email: getChangingPasswordFor(state),
  };
};

const mapDispatchToProps = (dispatch, state) => {
  return {
    setError: msg => dispatch(setError(msg)),
    dispatchPasswordReminderChange: (password, navigate) => dispatch(passwordReminderChange(state.params.id, state.params.token, password, navigate)),
    dispatchPasswordReminderCheck: () => dispatch(passwordReminderCheck(state.params.id, state.params.token)),
  };
};

export default withParams(connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordChangePage));
