import { getI18n } from 'client/AppReducer';
import { loginError, loginUser } from 'client/modules/Auth/AuthActions';
import { getErrorMessage } from 'client/modules/Auth/AuthReducer';
import withNavigate from 'client/util/withNavigate';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import LoginWidget from './LoginWidget';

const LoginWidgetContainer = (props) => {
  const googleSigninId = 'google-signin';
  const location = useLocation();

  useEffect(() => {
    props.onCloseError();
  }, [location]);

  return <LoginWidget googleSigninId={googleSigninId} {...props} />;
};

LoginWidgetContainer.defaultProps = {
  isSignup: false,
};

LoginWidgetContainer.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  onCloseError: PropTypes.func.isRequired,
  lang: PropTypes.string.isRequired,
  isSignup: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    errorMessage: getErrorMessage(state),
    lang: getI18n(state).locale,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onLoginClick(creds, isSignup) {
    dispatch(loginUser(creds, isSignup, ownProps.navigate));
  },
  onCloseError() {
    dispatch(loginError(null));
  },
  onLoginError(msg) {
    dispatch(loginError(msg));
  },
});

export default withNavigate(connect(mapStateToProps, mapDispatchToProps)(LoginWidgetContainer));
