import { FormControl, FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, useIntl } from 'react-intl';
import styles from './IntegrationPreRequiredFields.css';

const messages = defineMessages({
  zendeskDomain: {
    id: 'IntegrationsPage.ZendeskDomain',
    defaultMessage: 'Zendesk domain',
  },
  salesforceUseSandbox: {
    id: 'IntegrationsPage.SalesforceUseSandbox',
    defaultMessage: 'Use sandbox environment',
  },
});

const IntegratonPreRequiredFields = (props) => {
  const {
    name, onChange, preRequiredFields: { subDomain, useSandbox },
  } = props;
  const { formatMessage } = useIntl();
  return {
    zendesk: (
      <div className={styles.inline}>
        <TextField
          required
          variant="standard"
          name="zendeskSubDomain"
          value={subDomain}
          onChange={event => onChange({ subDomain: event.target.value })}
          label={formatMessage(messages.zendeskDomain)}
          style={{ width: '157px' }}
        />
        <span>.zendesk.com</span>
      </div>
    ),
    salesforce: (
      <div className={styles.inline}>
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              checked={useSandbox}
              onChange={event => onChange({ useSandbox: event.target.checked })}
            />
          )}
          label={formatMessage(messages.salesforceUseSandbox)}
        />
      </div>
    ),
  }[name] || null;
};


IntegratonPreRequiredFields.propTypes = {
  name: PropTypes.string.isRequired,
  preRequiredFields: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default IntegratonPreRequiredFields;
