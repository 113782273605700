import { connect } from 'react-redux';
import * as surveyActions from '../../SurveyActions';
import { getExportResults, getSurvey } from '../../SurveyReducer';

const mapStateToProps = (state, ownProps) => {
  const id = ownProps.id || ownProps.params.id;
  return {
    exportResults: getExportResults(state),
    survey: getSurvey(state, id),
    total: state.surveys.total,
    currentExport: state.surveys.currentExport,
  };
};

const mapDispatchToProps = dispatch => ({
  actions: {
    exportResultsList: surveyId => dispatch(surveyActions.exportResultsList(surveyId)),
    listen: surveyId => dispatch(surveyActions.listen(surveyId)),
    stopListening: surveyId => dispatch(surveyActions.stopListening(surveyId)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps);
