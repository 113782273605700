import { Extension, Layers, Palette, RocketLaunch, Share } from '@mui/icons-material';

const icons = {
  Layers,
  Share,
  Extension,
  Palette,
  RocketLaunch,
};

export default icons;
