import React from 'react';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import { Box, FormControl, InputLabel, Select, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ConditionalLogic from 'client/modules/Surveys/pages/CustomizeSurvey/ConditionalLogic';
import FreeResponseTypes from 'client/modules/Surveys/pages/CustomizeSurvey/FreeResponseTypes';
import ListOptions from 'client/modules/Surveys/pages/CustomizeSurvey/ListOptions';
import QuestionIcon from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionIcon';
import {
  contactForm,
  freeResponse,
  scale,
  selectMany,
  selectOne,
  statement,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import LinkButton from 'components/LinkButton';
import MentionsTextField from 'components/MentionsTextField';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  introPlaceholder: {
    id: 'EditQuestion.introPlaceholder',
    defaultMessage: 'Add an introduction to the question. Recall information with @',
  },
  descriptionPlaceholder: {
    id: 'EditQuestion.descriptionPlaceholder',
    defaultMessage: 'Add a description to the question. Recall information with @',
  },
  // The following keys are used by the server, I add them here for manage:translations not to delete them.
  commentPlaceholder: {
    id: 'commentPlaceholder',
    defaultMessage: 'Type your answer here...',
  },
  requiredAnswer: {
    id: 'requiredAnswer',
    defaultMessage: 'Required answer',
  },
  selectManyExact: {
    id: 'selectManyExact',
    defaultMessage: 'Select exactly {num} options',
  },
  selectManyRangeBetween: {
    id: 'selectManyRangeBetween',
    defaultMessage: 'Select between {min} and {max} options',
  },
  selectManyRangeDown: {
    id: 'selectManyRangeDown',
    defaultMessage: 'Select up to {num} options',
  },
  selectManyRangeUp: {
    id: 'selectManyRangeUp',
    defaultMessage: 'Select {num} or more options',
  },
  selectManyUnlimited: {
    id: 'selectManyUnlimited',
    defaultMessage: 'Select as many as you like',
  },
});

const EditQuestionContent = ({ additionalQuestions, questionIndex }) => {
  const { formatMessage } = useIntl();
  const { control, watch, setValue } = useFormContext();
  const options = watch('optionsAttributes');
  const setOptions = optionsIn => {
    let newOptions = optionsIn;
    if (typeof newOptions === 'function') {
      newOptions = newOptions(options);
    }
    setValue('optionsAttributes', newOptions);
  };
  const type = watch('type');
  const isNew = !watch('_id');
  const data =
    additionalQuestions
      ?.map((q, i) => ({
        id: q._id,
        display: `${i + 1}. ${q.text.slice(0, 15)}...`,
      }))
      .filter((q, i) => i < questionIndex) || [];
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography component="h3" sx={{ fontWeight: 'bold' }}>
        {isNew ? (
          <FormattedMessage id="EditQuestion.AddQuestion" defaultMessage="Add a question" />
        ) : (
          <FormattedMessage id="EditQuestion.EditQuestion" defaultMessage="Edit question" />
        )}
      </Typography>

      <Controller
        name="intro"
        control={control}
        render={({ field: { onChange, value }, fieldState }) => {
          return typeof value !== 'undefined' && value !== null ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  mb: 1,
                  flexGrow: 1,
                }}
              >
                <MentionsTextField
                  autoFocus
                  value={value}
                  onChange={onChange}
                  sx={{ backgroundColor: '#fff' }}
                  fullWidth
                  label={<FormattedMessage id="EditQuestion.intro" defaultMessage="Intro" />}
                  placeholder={formatMessage(messages.introPlaceholder)}
                  multiline
                  data={data}
                />
                {fieldState.error && (
                  <Typography color="error">{fieldState.error.message}</Typography>
                )}
              </Box>
              <IconButton onClick={() => setValue('intro', null)}>
                <RemoveCircleOutline />
              </IconButton>
            </Box>
          ) : (
            <LinkButton onClick={() => setValue('intro', '')} sx={{ alignSelf: 'flex-end' }}>
              <FormattedMessage id="EditQuestion.AddIntro" defaultMessage="Add intro" />
            </LinkButton>
          );
        }}
      />

      <Controller
        name="text"
        rules={{
          required: (
            <FormattedMessage
              id="AdditionalQuestions.Errors.NoText"
              defaultMessage="The the question can't be blank"
            />
          ),
        }}
        control={control}
        render={({ field: { onChange, value }, fieldState }) => (
          <Box sx={{ mb: 1 }}>
            <MentionsTextField
              autoFocus
              value={value}
              onChange={onChange}
              sx={{ backgroundColor: '#fff' }}
              fullWidth
              label={<FormattedMessage id="EditQuestion.question" defaultMessage="Question" />}
              multiline
              data={data}
            />
            {fieldState.error && <Typography color="error">{fieldState.error.message}</Typography>}
          </Box>
        )}
      />

      <Controller
        name="description"
        control={control}
        render={({ field: { onChange, value }, fieldState }) => {
          return typeof value !== 'undefined' && value !== null ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 2,
                alignItems: 'center',
                flexGrow: 1,
              }}
            >
              <Box
                sx={{
                  mb: 1,
                  flexGrow: 1,
                }}
              >
                <MentionsTextField
                  autoFocus
                  value={value}
                  onChange={onChange}
                  sx={{ backgroundColor: '#fff' }}
                  fullWidth
                  label={
                    <FormattedMessage id="EditQuestion.description" defaultMessage="Description" />
                  }
                  placeholder={formatMessage(messages.descriptionPlaceholder)}
                  multiline
                  data={data}
                />
                {fieldState.error && (
                  <Typography color="error">{fieldState.error.message}</Typography>
                )}
              </Box>
              <IconButton onClick={() => setValue('description', null)}>
                <RemoveCircleOutline />
              </IconButton>
            </Box>
          ) : (
            <LinkButton onClick={() => setValue('description', '')} sx={{ alignSelf: 'flex-end' }}>
              <FormattedMessage id="EditQuestion.AddDescription" defaultMessage="Add description" />
            </LinkButton>
          );
        }}
      />
      {type === statement && (
        <Controller
          name="buttonLabel"
          control={control}
          render={({ field: { onChange, value } }) => (
            <TextField
              value={value}
              onChange={onChange}
              sx={{
                backgroundColor: '#fff',
                flexGrow: 1,
                minWidth: 0,
                width: '40%',
              }}
              label={
                <FormattedMessage id="EditQuestion.buttonLabel" defaultMessage="Button label" />
              }
            />
          )}
        />
      )}
      {[selectOne, selectMany].includes(type) && (
        <ListOptions options={options} setOptions={setOptions} />
      )}
      <ConditionalLogic />
    </Box>
  );
};

EditQuestionContent.propTypes = {
  additionalQuestions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default EditQuestionContent;
