import SearchIcon from '@mui/icons-material/Search';
import { Box, Grid, Typography } from '@mui/material';
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import NPS from 'client/modules/Surveys/components/searchkit/NPS/NPS';
import ProgressBarItem from 'client/modules/Surveys/components/searchkit/Progress/ProgressBarItem';
import groupsByType from 'client/modules/Surveys/pages/DashboardPage/groupsByType';
import LeftNav from 'client/modules/Surveys/pages/DashboardPage/LeftNav';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import { useFindBySurveyQuery } from 'client/modules/Surveys/savedSearchApiSlice';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import removeAccents from 'client/util/removeAccents';
import Layout from 'components/Layout/Layout';
import Spinner from 'components/Spinner/Spinner';
import { isEmpty } from 'lodash';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Link, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';

const messages = defineMessages({
  title: {
    id: 'SavedSearches.title',
    defaultMessage: 'Saved searches ({count})',
  },
  subtitle: {
    id: 'SavedSearches.subtitle',
    defaultMessage: 'Saved searches allow you to categorize and track responses. Save them from a search or filters on the responses tab.',
  },
  responses: {
    id: 'SavedSearches.responses',
    defaultMessage: '{count, plural, =0 {no responses} one {# response} other {# responses}}',
  },
  create: {
    id: 'SavedSearches.create',
    defaultMessage: 'Create saved search,',
  },
  stars: {
    id: 'Dashboard.Tooltip.Stars',
    defaultMessage: '{num, number}-{num, plural, one {star} other {stars}}',
  },
  promoters: {
    id: 'Dashboard.Tooltip.Promoters',
    defaultMessage: 'promoters',
  },
  passives: {
    id: 'Dashboard.Tooltip.Passives',
    defaultMessage: 'passives',
  },
  detractors: {
    id: 'Dashboard.Tooltip.Detractors',
    defaultMessage: 'detractors',
  },
  agree: {
    id: 'Dashboard.Tooltip.Agree',
    defaultMessage: 'agree',
  },
  neutral: {
    id: 'Dashboard.Tooltip.Neutral',
    defaultMessage: 'neutral',
  },
  disagree: {
    id: 'Dashboard.Tooltip.Disagree',
    defaultMessage: 'disagree',
  },
  satisfied: {
    id: 'Dashboard.Tooltip.Satisfied',
    defaultMessage: 'satisfied',
  },
  dissatisfied: {
    id: 'Dashboard.Tooltip.Dissatisfied',
    defaultMessage: 'dissatisfied',
  },
  search: {
    id: 'Search',
    defaultMessage: 'Search...',
  },
});

const SearchControl = (props) => {
  const { focused } = useFormControl() || {};
  return (
    <OutlinedInput
      startAdornment={<SearchIcon sx={{ color: 'muted.main' }} />}
      placeholder="Search"
      variant="outlined"
      sx={{
        width: focused ? '100%' : 280,
        backgroundColor: 'white',
        transition: 'width 200ms ease',
        '& input': { p: 1 },
      }}
      {...props}
    />
  );
};


/**
 * Display a list of saved searches
 */
const SearchesList = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const surveys = useSelector(getSurveys);
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') || 'active';
  const quickFilterValues = searchParams.get('q')?.split(' ').filter(v => v) || [];
  const filter = { status };
  if (!isEmpty(quickFilterValues)) {
    filter.query = quickFilterValues.join(' ');
  }

  const { id: surveyId } = useParams();
  const survey = surveys.find(s => s.id === surveyId);
  const surveyType = survey?.surveyType;
  const isNPS = surveyType === 'nps';
  const typeString = surveyType.toUpperCase() + (isNPS ? '®' : '');
  const { formatMessage, locale } = useIntl();
  const { data, isFetching } = useFindBySurveyQuery(surveyId);
  const filteredData = data?.filter(d => quickFilterValues
    .reduce(
      (ret, str) => ret
          && removeAccents(d.name)
            .toLowerCase()
            .includes(str),
      true,
    )) || [];
  const onFilterChange = (e) => {
    navigate({ search: `q=${removeAccents(e.target.value).toLowerCase().split(/\s+/).join('+')}` });
  };

  return (
    <Layout secondaryNav={<SecondaryNavTabs survey={surveyId} />}>
      <Box sx={{
        display: 'flex', maxWidth: 1280, width: '100%', alignSelf: 'center', mt: 3, mb: 2,
      }}
      >
        <Box>
          <Typography variant="h1">{formatMessage(messages.title, { count: filteredData?.length || 0 })}</Typography>
          <Typography>{formatMessage(messages.subtitle)}</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', maxWidth: 1280, width: '100%', alignSelf: 'center', mb: 4, gap: 2,
      }}
      >
        {/** <Box sx={{ flexGrow: 1, flexBasis: '25%' }} /> */}
        <Box sx={{
          flexGrow: 4, flexBasis: '75%', minHeight: '65vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <Box sx={{ mb: 2, display: 'flex' }}>
            <FormControl sx={{ flex: 1, display: 'flex', alignItems: 'flex-end' }}>
              <SearchControl onChange={onFilterChange} />
            </FormControl>
          </Box>
          <Box>
            {isFetching && <Spinner />}
            {!isFetching && (filteredData.map(search => (
              <Grid
                container
                spacing={2}
                key={search.id}
                sx={{
                  paddingTop: 2,
                  paddingBottom: 2,
                  borderBottom: '1px solid',
                  borderColor: 'grey.300',
                  '&:first-child': {
                    marginTop: 2,
                    paddingTop: 0,
                    borderTop: '1px solid',
                    borderColor: 'grey.300',
                  },
                }}
              >
                <Grid item xs={12} md={6} lg={4}>
                  <Box>
                    <Link to={`/surveys/${search.survey}/saved-searches/${search._id}`}><Typography sx={{ textWrap: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{search.name}</Typography></Link>
                    <Typography color="muted.main">
                      {formatMessage(messages.responses, { count: search.count || 0 })}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={8} key={search.id} sx={{ display: 'flex', gap: 1 }}>
                  <NPS
                    intl={intl}
                    surveyType={surveyType}
                    showToggle={false}
                    items={Object.entries(search.groups30d).map(([key, doc_count]) => ({ key, doc_count }))}
                    docCount={search.count30d}
                    filtered
                  />
                  <Box sx={{ flexGrow: 1, borderRadius: 1, overflow: 'hidden' }}>
                    {!!survey && search.count30d > 0 && groupsByType[surveyType].map((group) => {
                      const groupName = surveyType === 'star_five' ? 'stars' : group;
                      const [num] = surveyType === 'star_five' ? group.split('-') : [0];
                      if (!search.groups30d[group]) {
                        return null;
                      }
                      return (
                        <ProgressBarItem itemKey={group} label={formatMessage(messages[groupName], { num })} count={search.groups30d[group] || 0} listDocCount={search.count30d} />
                      );
                    })}
                  </Box>
                </Grid>
              </Grid>
            )))}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default SearchesList;
