import { connect } from 'react-redux';
import withParams from '../../../../util/withParams';
import { getToken } from '../../../Auth/AuthReducer';
import { setEmailPreferences } from '../../EmailPreferencesActions';
import { getEmail, getGroup } from '../../EmailPreferencesReducer';

import UserUnsubscribePage from './UserUnsubscribePage';

export const userUnsubscribe = (store, nextState, replace, callback) => {
  const state = store.getState();
  store.dispatch(setEmailPreferences(getToken(state), nextState.params.payload, nextState.params.expiry, nextState.params.signature, null, true, () => null)).then(() => {
    callback();
  }).catch((err) => {
    callback();
    throw err;
  });
};

const mapStateToProps = (state, ownProps) => {
  return {
    email: getEmail(state),
    group: getGroup(state),
    preferencesPath: `/email-preferences/${ownProps.params.payload}/${ownProps.params.expiry}/${ownProps.params.signature}`,
  };
};

const UserUnsubscribePageContainer = withParams(connect(mapStateToProps)(UserUnsubscribePage));

export default UserUnsubscribePageContainer;
