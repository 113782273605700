import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Navigate, Outlet, useLocation, useNavigate,
} from 'react-router-dom';
import { ChargebeeProvider } from 'client/modules/App/ChargebeeContext';
import { saveNextPath as saveNextPathAction, updateToken as updateTokenAction } from './modules/Auth/AuthActions';
import { getProfile, isLoggedIn, shouldRefreshAuth as shouldRefreshAuthReducer } from './modules/Auth/AuthReducer';

const Authenticated = ({
  loggedIn, user, shouldRefreshAuth, updateToken, saveNextPath,
}) => {
  const nextPath = useLocation().pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn) {
      if (shouldRefreshAuth) {
        updateToken();
      } else {
        saveNextPath(nextPath);
      }
    } else if (!user.email_verified && nextPath !== '/verify-email') {
      navigate('/verify-email-resend');
    }
  }, []);


  if (loggedIn) {
    return <ChargebeeProvider><Outlet /></ChargebeeProvider>;
  }
  return <Navigate to="/login" />;
};

Authenticated.defaultProps = {
  user: {},
};

Authenticated.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  shouldRefreshAuth: PropTypes.bool.isRequired,
  user: PropTypes.object,
  saveNextPath: PropTypes.func.isRequired,
  updateToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return ({
    loggedIn: isLoggedIn(state),
    shouldRefreshAuth: shouldRefreshAuthReducer(state),
    user: getProfile(state),
  });
};

const mapDispatchToProps = dispatch => ({
  saveNextPath(nextPath) {
    dispatch(saveNextPathAction(nextPath));
  },
  updateToken() {
    dispatch(updateTokenAction());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Authenticated);
