import { handleActions } from 'redux-actions';

const initialState = {
  plans: [],
  period: '1m',
};

export const SettingsReducer = handleActions({
  FETCH_PLANS_CB: (state, action) => ({
    ...state,
    plans: action.payload.plans.sort((a, b) => a.cf_order - b.cf_order),
    period: action.payload.period,
  }),
  ADD_SUBSCRIPTION_CB: (state, action) => ({
    ...state,
    checkoutUrl: action.payload,
  }),
}, initialState);

export const getPlans = state => state.pricing.plans;
export const getCheckoutUrl = state => state.pricing.checkoutUrl;
export const getPeriod = state => state.pricing.period;

export default SettingsReducer;
