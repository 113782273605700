/* eslint-disable react/destructuring-assignment */
import D3Shape from 'd3-shape';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import CommonProps from '../CommonProps';
import series from '../utils/Series';

import styles from './LineDot.css';

const LineDot = (props) => {
  const {
    xScaleSet,
    yScaleSet,
    tension,
    lineClassName,
    bars,
    data,
    dotClassName,
  } = props;
  const { locale } = useIntl();
  const renderOneBarSeries = useCallback((b, idx) => (
    <rect
      x={xScaleSet(b.x) - 5}
      y={b.y}
      height={b.height}
      width={10}
      style={{ fill: '#54546b', opacity: 1 - idx * 0.2 }}
    />
  ), [xScaleSet]);

  const renderOneBar = useCallback(bar => bar.map(renderOneBarSeries), [renderOneBarSeries]);

  const hasResponses = useCallback((v) => {
    return !Object.prototype.hasOwnProperty.call(v, 'responded') || ((v.responded + v.commented) > 0);
  }, []);

  const renderDot = useCallback(({ symbol, hideLabels, color }) => (d) => {
    return (!hideLabels && (
      <g>
        {symbol === 'circle' && (
          <circle
            stroke={color}
            className={`${dotClassName} ${styles.dot} ${hasResponses(data[d.i]) ? '' : styles.dotEmpty}`}
            cx={xScaleSet(d.x)}
            cy={yScaleSet(d.y)}
            key={`dot-${d.x}`}
          />
        )}
        <text
          fill={color}
          dx={xScaleSet(d.x)}
          dy={yScaleSet(d.y) - 12}
          className={styles.label}
        >
          {Intl.NumberFormat(locale, { maximumFractionDigits: 2 }).format(d.y)}
        </text>
      </g>
    ));
  }, [xScaleSet, yScaleSet, hasResponses, data]);

  const _setAxes = useCallback((lines) => {
    const c = context => D3Shape.curveCardinal(context, tension);

    const line = D3Shape.line()
      .x((d) => {
        return xScaleSet(d.x);
      })
      .y((d) => {
        return yScaleSet(d.y);
      })
      .curve(c);

    return line(lines);
  }, [xScaleSet, yScaleSet]);


  const dataset = series(props);

  const renderOneSeries = (line) => {
    const lines = line.data.filter((v, i) => hasResponses(data[i]));
    const dots = line.data.map((v, i) => ({ ...v, i })).filter(v => data[v.i].total > 0);
    return (
      <g>
        {bars ? bars.map(renderOneBar) : null}
        <path
          stroke={line.color}
          strokeWidth={typeof line.strokeWidth === 'number' ? line.strokeWidth : 2}
          strokeDasharray={line.strokeDasharray}
          strokeLinecap={line.strokeLinecap || 'round'}
          className={lineClassName}
          d={_setAxes(lines)}
          style={line.style}
          key={Math.random()}
        />
        {dots.map(renderDot(line))}
      </g>
    );
  };
  return (
    <g className={styles.lineGroup} key={props.chartPeriod}>
      {dataset.map(renderOneSeries)}
    </g>
  );
};


LineDot.propTypes = {
  lineClassName: PropTypes.string,
  dotClassName: PropTypes.string,
  xScaleSet: PropTypes.func.isRequired,
  yScaleSet: PropTypes.func.isRequired,
  bars: PropTypes.any,
  data: PropTypes.array.isRequired,
  chartPeriod: PropTypes.string.isRequired,
  tension: PropTypes.number,
};

LineDot.defaultProps = {
  interpolate: null,
  lineClassName: 'react-d3-basic__line',
  dotClassName: 'react-d3-basic__dot',
  ...CommonProps,
  bars: null,
  tension: 0.85,
};

export default LineDot;
