import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { FormattedMessage, injectIntl } from 'react-intl';

import PricingPlan from '../../../../../App/components/PricingPlan/PricingPlan';

import bsStyle from '../../../../../App/components/Bootstrap/Bootstrap.css';
import style from './Pricing.css';

const bs = classNames.bind(bsStyle);

const Pricing = (props) => {
  return (
    <section className={`${style.pricingTable} ${bs('mbr-section--bg-adapted', 'mbr-section--relative')}`}>
      <div className={style.pricing}>

        <div className={bs('container')}>
          <div className={`${style.sectionHeadLite} ${bs('text-center', 'col-md-8', 'col-md-offset-2', 'space80')}`}>
            <h1 className={bs('mbr-title-font')}>
              <FormattedMessage id="Pricing.ChooseYourPlan" defaultMessage="Choose your plan" />
            </h1>
            <p>
              <FormattedMessage id="Pricing.PricingDescription" defaultMessage="The cheapest and simplest pricing in the market. All features included." />
            </p>
          </div>
          <div className={bs('row')}>
            <div className={bs('col-md-12')}>
              <PricingPlan name="Survey 1K" people={1000} price={29} handleSelect={props.handleSignup} />
              <PricingPlan name="Survey 10K" people={10000} price={99} popular handleSelect={props.handleSignup} />
              <PricingPlan name="Survey 100K" people={100000} price={299} handleSelect={props.handleSignup} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Pricing.propTypes = {
  handleSignup: PropTypes.func.isRequired,
};

export default injectIntl(Pricing);
