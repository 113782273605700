import { Close } from '@mui/icons-material';
import Neutral from '@mui/icons-material/Face';
import Dissatisfied from '@mui/icons-material/SentimentDissatisfied';
import Satisfied from '@mui/icons-material/SentimentSatisfied';
import { FormControl, Input, InputLabel, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { ObjectID } from 'bson';
import FlatButton from 'components/FlatButton/FlatButton';
import { defineMessages, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';
import React from 'react';
import cartesian from '../../../../../util/cartesian';
import styles from '../IntegrationsListPage.css';

const messages = defineMessages({
  gsheetUrl: {
    id: 'IntegrationsSettings.GSheetUrl',
    defaultMessage: 'Sheet URL',
  },
  gsheetUrlRequired: {
    id: 'IntegrationsSettings.GSheetUrlRequired',
    defaultMessage: 'Please enter a sheet URL.',
  },
  surveysRequired: {
    id: 'IntegrationsSettings.GSheetSurveysRequired',
    defaultMessage: 'Please select some surveys.',
  },
  gsheetUrlFormat: {
    id: 'IntegrationsSettings.GSheetUrlFormat',
    defaultMessage: 'It must be a valid URL.',
  },
  surveys: {
    id: 'IntegrationsSettings.GSheetSurvey',
    defaultMessage: 'Survey',
  },
  condition: {
    id: 'IntegrationsSettings.GSheetCondition',
    defaultMessage: 'Condition',
  },
  addRule: {
    id: 'IntegrationsSettings.GSheetAddRule',
    defaultMessage: '+ Add a new rule',
  },
  conditionAllAll: {
    id: 'IntegrationsSettings.ConditionAllAll',
    defaultMessage: 'All responses',
  },
  conditionCommentedAll: {
    id: 'IntegrationsSettings.ConditionCommentedAll',
    defaultMessage: 'Only responses with comments',
  },
  conditionAllPromoters: {
    id: 'IntegrationsSettings.ConditionAllPromoters',
    defaultMessage: 'All "Satisfied"',
  },
  conditionCommentedPromoters: {
    id: 'IntegrationsSettings.ConditionCommentedPromoters',
    defaultMessage: 'Only "Satisfied" with comments',
  },
  conditionAllPassives: {
    id: 'IntegrationsSettings.ConditionAllPassives',
    defaultMessage: 'All "Neutral"',
  },
  conditionCommentedPassives: {
    id: 'IntegrationsSettings.ConditionCommentedPassives',
    defaultMessage: 'Only "Neutral" with comments',
  },
  conditionAllDetractors: {
    id: 'IntegrationsSettings.ConditionAllDetractors',
    defaultMessage: 'All "Dissatisfied"',
  },
  conditionCommentedDetractors: {
    id: 'IntegrationsSettings.ConditionCommentedDetractors',
    defaultMessage: 'Only "Dissatisfied" with comments',
  },
  all: {
    id: 'IntegrationsSettings.All',
    defaultMessage: 'All',
  },
});

const DEFAULT_RULES = [
  {
    _id: new ObjectID().toString(),
    survey: null,
    condition: 'group:all,commented:false',
    gsheetUrl: '',
  },
];

const IntegrationConfigGSheet = () => {
  const { onIntegrationsPropertiesChange, integration, surveys } = useOutletContext();
  const { formatMessage } = useIntl();
  const { rules = DEFAULT_RULES } = integration;

  const setRuleProps = (index, props) => {
    onIntegrationsPropertiesChange(null, 'gsheets', {
      rules: rules.map((r, idx) => (index === idx ? { ...r, ...props } : r)),
    });
  };

  const setGsheetUrl = index => event => {
    const gsheetUrl = event.target.value;
    setRuleProps(index, { gsheetUrl });
  };

  const handleChangeSurvey = index => event => {
    const survey = event.target.value;
    const sheetName = surveys.find(s => s.id === survey)?.name || 'Survey Responses';
    setRuleProps(index, { survey, sheetName });
  };

  const handleChangeCondition = index => event => {
    setRuleProps(index, { condition: event.target.value });
  };

  const addRule = () => {
    const newRules = [
      ...rules,
      { ...DEFAULT_RULES[0], _id: new ObjectID().toString(), gsheetUrl: '' },
    ];
    onIntegrationsPropertiesChange(null, 'gsheets', { rules: newRules });
  };

  const removeRule = index => () => {
    const newRules = rules.filter((r, idx) => idx !== index);
    onIntegrationsPropertiesChange(null, 'gsheets', { rules: newRules });
  };

  const errors = {};
  const icons = {
    Promoters: <Satisfied style={{ color: '#4fc44f' }} />,
    Passives: <Neutral style={{ color: '#f7c64b' }} />,
    Detractors: <Dissatisfied style={{ color: '#f66242' }} />,
  };
  return (
    <div>
      <div>
        {rules.map((rule, index) => {
          return (
            <div key={rule._id} className={styles.rule}>
              <div className={styles.ruleInput}>
                <div className={styles.inputIcon}>
                  {(() => {
                    if (!rule.gsheetUrl) {
                      errors[`${index}-gsheetUrl`] = formatMessage(messages.gsheetUrlRequired);
                    } else if (
                      !rule.gsheetUrl.match(/^https:\/\/docs.google.com\/spreadsheets\//)
                    ) {
                      errors[`${index}-gsheetUrl`] = formatMessage(messages.gsheetUrlFormat);
                    }
                    return null;
                  })()}
                  <TextField
                    required
                    pattern="^https://docs.google.com/spreadsheets/[a-zA-Z0-9-_#=%]*$"
                    variant="standard"
                    className={styles.input}
                    fullWidth
                    style={errors[`${index}-gsheetUrl`] ? { marginBottom: 20 } : {}}
                    name="gsheetUrl"
                    value={rule.gsheetUrl}
                    inputStyle={{ paddingLeft: '1.25em' }}
                    onChange={setGsheetUrl(index)}
                    label={formatMessage(messages.gsheetUrl)}
                    errorText={errors[`${index}-gsheetUrl`]}
                  />
                </div>
              </div>
              <FormControl variant="standard" className={styles.ruleInput}>
                <InputLabel htmlFor={`select-group-${rule._id}`} shrink>
                  {formatMessage(messages.condition)}
                </InputLabel>
                <Select
                  variant="standard"
                  className={styles.input}
                  input={<Input id={`select-group-${rule._id}`} />}
                  fullWidth
                  hintText={formatMessage(messages.condition)}
                  value={rule.condition}
                  onChange={handleChangeCondition(index)}
                  autoWidth
                >
                  {cartesian(['All', 'Promoters', 'Passives', 'Detractors'], [false, true]).map(
                    ([group, commented]) => (
                      <MenuItem
                        key={`group:${group.toLowerCase()},commented:${commented}`}
                        value={`group:${group.toLowerCase()},commented:${commented}`}
                      >
                        <ListItemIcon className={styles.item}>{icons[group]}</ListItemIcon>
                        {formatMessage(
                          messages[`condition${commented ? 'Commented' : 'All'}${group}`],
                        )}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              {(() => {
                if (rule.surveys && rule.surveys.length === 0) {
                  errors[`${index}-surveys`] = formatMessage(messages.surveysRequired);
                }
                return null;
              })()}
              <FormControl variant="standard" className={styles.ruleInput}>
                <InputLabel htmlFor={`select-survey-${rule._id}`}>
                  {formatMessage(messages.surveys)}
                </InputLabel>
                <Select
                  variant="standard"
                  className={styles.input}
                  id={`select-survey-${rule._id}`}
                  errorText={errors[`${index}-surveys`]}
                  hintText={formatMessage(messages.surveys)}
                  value={rule.survey}
                  onChange={handleChangeSurvey(index)}
                  autoWidth
                  renderValue={v => (surveys.find(s => s.id === v) || {}).name}
                >
                  {surveys.map(s => (
                    <MenuItem
                      insetChildren
                      key={s.name}
                      checked={!rule.surveys || rule.surveys.includes(s.id)}
                      value={s.id}
                    >
                      <ListItemText primary={s.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton sx={{ alignSelf: 'flex-start', top: 25 }} onClick={removeRule(index)}>
                <Close />
              </IconButton>
            </div>
          );
        })}
        <FlatButton primary label={formatMessage(messages.addRule)} onClick={addRule} />
      </div>
    </div>
  );
};

export default IntegrationConfigGSheet;
