import { Close } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import getErrorMessageKey from 'client/util/getErrorMessageKey';
import useActions from 'client/util/useActions';
import FlatButton from 'components/FlatButton/FlatButton';
import FormsyText from 'components/FormsyText/FormsyText';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import Formsy from 'formsy-react';
import GoogleLibPhoneNumber from 'google-libphonenumber';
import React, { useEffect, useRef, useState } from 'react';
import DropZone from 'react-dropzone';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Config from '../../../../../server/config';
import AppError from '../../../../../server/util/AppError';

import { setError } from '../../../App/AppActions';
import FileUpload from '../../../App/components/Icons/FileUpload';
import FileUploadOk from '../../../App/components/Icons/FileUploadOk';

import Layout from '../../../App/components/Layout/LayoutContainer';
import { getToken } from '../../../Auth/AuthReducer';
import TrialExpired from '../../components/TrialExpired/TrialExpired';

import {
  clearErrorMessage as clearErrorMessageAction,
  setPeopleInput as setPeopleInputAction,
  setSurveySubmitEnabled as setSurveySubmitEnabledAction,
  setUploadFile as setUploadFileAction,
  startUpload as startUploadAction,
} from '../../SurveyActions';
import {
  canSubmit, getErrorMessage, getErrors, getId, getPeopleInput, getSubmitButtonMessage, getUploadFile, surveyMessages,
} from '../../SurveyReducer';

import emailsCSV from './emails.csv';

import styles from './ImportPeople.css';

const phoneUtil = GoogleLibPhoneNumber.PhoneNumberUtil.getInstance();
const { PhoneNumberType } = GoogleLibPhoneNumber;

const messages = defineMessages({
  'Import.Next': {
    id: 'Import.Next',
    defaultMessage: 'Next: Review',
  },
  invalidEmail: {
    id: 'Import.Errors.InvalidEmail',
    defaultMessage: 'Line {num} format is invalid, please enter a valid email (or a name and an email separated by a comma).',
  },
  invalidPhone: {
    id: 'Import.Errors.InvalidPhone',
    defaultMessage: 'Line {num} format is invalid, please enter a valid phone number with the international prefix (or a name and an international phone number separated by a comma).',
  },
  segment: {
    id: 'Import.Segment',
    defaultMessage: 'Segment people using properties',
  },
  close: {
    id: 'Import.ModalClose',
    defaultMessage: 'Close',
  },
  learnMore: {
    id: 'Import.LearnMore',
    defaultMessage: 'Learn more',
  },
  placeholderEmail: {
    id: 'Surveys.Import.People.PlaceholderEmail',
    defaultMessage: 'Janine Melnitz, janine.melnitz@example.com',
  },
  placeholderSMS: {
    id: 'Surveys.Import.People.PlaceholderSMS',
    defaultMessage: 'Janine Melnitz, +1(212)555-2368',
  },
  placeholderLink: {
    id: 'Surveys.Import.People.PlaceholderLink',
    defaultMessage: 'Janine Melnitz, janine.melnitz@example.com',
  },
});

export const getErrMessage = (formatMessage, err) => {
  const key = err ? err.message : null;
  const defaultErr = getErrorMessageKey(err) ? (
    formatMessage(getErrorMessageKey(err))
  ) : (
    <FormattedMessage
      id="Import.Errors.Unknown"
      defaultMessage="An unknown error occurred while uploading file."
    />
  );
  switch (key) {
    case 'Number of messages in trial period exceeded':
      return (
        <FormattedMessage
          id="Import.Errors.TrialCuotaExceeded"
          defaultMessage="You have exceeded the number of surveys for the trial period. {choosePlan}."
          values={{
            choosePlan: (
              <Link to="/pricing">
                <FormattedMessage id="Import.Errors.ChoosePlan" defaultMessage="Choose a plan" />
              </Link>
            ),
          }}
        />
      );
    case 'Number of monthly messages in your plan exceeded':
      return (
        <FormattedMessage
          id="Import.Errors.PlanQuotaExceeded"
          defaultMessage="You have exceeded the number of surveys in your plan. {improvePlan}."
          values={{
            improvePlan: (
              <Link to="/pricing">
                <FormattedMessage id="Import.Errors.ImprovePlan" defaultMessage="Improve your plan" />
              </Link>
            ),
          }}
        />
      );
    case 'Trial expired':
      return <TrialExpired />;
    case 'File too large':
      return (
        <FormattedMessage
          id="Import.Errors.FileTooLarge"
          defaultMessage="The maximum file size is {limit}Kb"
          values={{
            limit: Config.maxFileSize,
          }}
        />
      );
    case 'No headers found':
      return (
        <FormattedMessage
          id="Import.Errors.HeadersRequired"
          defaultMessage="The first row of the file has to contain the column names."
        />
      );
    case 'No email column found':
      return (
        <FormattedMessage
          id="Import.Errors.NoEmailColumnFound"
          defaultMessage='One of the columns of the file has to be named "email".'
        />
      );
    case 'No phone number column found':
      return (
        <FormattedMessage
          id="Import.Errors.NoPhoneNumberColumnFound"
          defaultMessage='One of the columns of the file has to be named "phone number".'
        />
      );
    case 'Delimiters with spaces':
      return (
        <FormattedMessage
          id="Import.Errors.SpacesAfterDelimiter"
          defaultMessage="There should be no spaces after the delimiters in the file."
        />
      );
    case 'Parsing Error':
      if (err.errors && err.errors.line) {
        let r = /Line (\d+): Unexpected Error: column header mismatch expected: (\d+) columns got: (\d+)/;
        let found = r.exec(err.errors.line);
        if (found) {
          return (
            <FormattedMessage
              id="Import.Errors.HeaderMismatch"
              defaultMessage="Column header mismatch at line {line}. Expected: {expected} columns got: {found}."
              values={{
                line: found[1],
                expected: found[2],
                found: found[3],
              }}
            />
          );
        }
        r = /Line (\d+):.*Validator failed for path .email. with value/;
        found = r.exec(err.errors.line);
        if (found) {
          return (formatMessage(messages.invalidEmail, { num: found[1] }));
        }
        r = /Line (\d+):.* is not a valid phone number/;
        found = r.exec(err.errors.line);
        if (found) {
          return (formatMessage(messages.invalidPhone, { num: found[1] }));
        }
        r = /Line (\d+): (.+)/;
        found = r.exec(err.errors.line);
        if (found) {
          return (
            <FormattedMessage
              id="Import.Errors.ParsingError"
              defaultMessage="Error processing line {line}. Details: {message}"
              values={{
                line: found[1],
                message: found[2],
              }}
            />
          );
        }
      }
      return defaultErr;
    default:
      return defaultErr;
  }
};

const ImportPeople = () => {
  const navigate = useNavigate();
  const peopleInputRef = useRef();
  const errorMessage = useSelector(getErrorMessage);
  const errors = useSelector(getErrors);
  const submitEnabled = useSelector(canSubmit);
  const submitButtonMessage = useSelector(getSubmitButtonMessage);
  const uploadFile = useSelector(getUploadFile);
  const token = useSelector(getToken);
  const surveyId = useSelector(getId);
  const peopleInput = useSelector(getPeopleInput);
  const { id: importId } = useParams();
  let { channel } = useParams();

  if (!['sms', 'email'].includes(channel)) {
    channel = null;
  }

  const [clearErrorMessage, setPeopleInput, setSurveySubmitEnabled, setUploadFile, startUpload] = useActions([clearErrorMessageAction, setPeopleInputAction, setSurveySubmitEnabledAction, setUploadFileAction, startUploadAction]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [percentLoaded, setPercentLoaded] = useState(null);

  const { formatMessage } = useIntl();

  useEffect(() => {
    if (errorMessage) {
      setError(getErrMessage(formatMessage, { message: errorMessage, errors }));
    }
    return () => {
      clearErrorMessage();
    };
  }, [errorMessage, setError, clearErrorMessage, errors]);

  if (!channel) {
    throw new AppError('Unknown channel');
  }

  const fileTypeNotSupportedMessage = (ext = '') => (
    <FormattedMessage
      id="FiletypeNotSupported"
      defaultMessage="Filetype {ext} not supported. Use CSV instead."
      values={{ ext }}
    />
  );

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const arr = rejectedFiles[0].name.split('.');
      let ext = '';
      if (arr.length > 1) {
        ext = arr.pop().toUpperCase();
      }
      setError(fileTypeNotSupportedMessage(ext));
    } else if (acceptedFiles.length > 0) {
      setUploadFile(acceptedFiles[0]);
    }
  };

  const onProgress = (newPercentLoaded) => {
    setPercentLoaded(newPercentLoaded);
  };

  const handleSubmit = () => {
    setSurveySubmitEnabled(false, formatMessage(surveyMessages['Survey.Submitting']));
    startUpload(importId, channel, token, uploadFile, peopleInput, err => getErrMessage(formatMessage, err), onProgress, navigate);
  };

  const
    handleDragging = (event) => {
      event.target.classList.add(styles.dragging);
    };

  const
    handleNotDragging = (event) => {
      event.target.classList.remove(styles.dragging);
    };

  const
    handleDropIgnore = () => {
    };

  const
    handleRemoveFile = (event) => {
      event.stopPropagation();
      setUploadFile(null);
    };

  let
    returnLinkTo = null;
  let
    returnLinkText = null;

  if (surveyId) {
    returnLinkTo = `/surveys/${surveyId}/import`;
    returnLinkText = formatMessage(surveyMessages.chooseADifferentChannel);
  }

  const isExisty = value => value !== null && value !== undefined;
  const isEmpty = value => value === '';
  const matchRegexp = (value, regexp) => !isExisty(value) || isEmpty(value) || regexp.test(value);
  // eslint-disable-next-line no-useless-escape
  const isEmail = value => matchRegexp(value, /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i);

  Formsy.addValidationRule('onePersonPerLine', (values, value) => {
    if (!isExisty(value)) {
      return true;
    }
    const invalidIdx = value.split('\n').findIndex((line) => {
      const [name, contact] = line.split(',');
      if (channel === 'email') {
        return !isEmail((contact || name).trim());
      }
      try {
        const numTxt = (contact || name).trim();
        if (numTxt.length === 0) {
          return false;
        }
        const number = phoneUtil.parseAndKeepRawInput(numTxt);
        return !phoneUtil.isValidNumber(number) || ![PhoneNumberType.MOBILE, PhoneNumberType.FIXED_LINE_OR_MOBILE].includes(phoneUtil.getNumberType(number));
      } catch (e) {
        return true;
      }
    });
    return invalidIdx >= 0 ? formatMessage(channel === 'email' ? messages.invalidEmail : messages.invalidPhone, { num: invalidIdx + 1 }) : true;
  });

  const handleOpen = () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const actions = [
    <FlatButton
      label={formatMessage(messages.close)}
      primary
      onClick={handleClose}
    />,
  ];
  const channelMessages = {
    email: <FormattedMessage id="Surveys.Import.Heading.ChannelEmail" defaultMessage="email" />,
    sms: <FormattedMessage id="Surveys.Import.Heading.ChannelSMS" defaultMessage="SMS" />,
    link: <FormattedMessage id="Surveys.Import.Heading.ChannelLink" defaultMessage="link" />,
  };
  const channelMsgs = channelMessages[channel];

  const placeHolderMessages = {
    email: messages.placeholderEmail,
    sms: messages.placeholderSMS,
    link: messages.placeholderLink,
  };
  const placeholder = formatMessage(placeHolderMessages[channel]);
  const value = peopleInputRef.current && peopleInputRef.current.getValue && peopleInputRef.current.getValue();
  return (
    <div onDragEnter={handleDragging} onDragLeave={handleNotDragging} onDrop={handleDropIgnore}>
      <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText} secondaryNav={<SecondaryNavTabs survey={surveyId} />}>
        <Grid className={styles.mainGrid} fluid>
          <Row>
            <Col xs={12}>
              <h2>
                <FormattedMessage
                  id="Surveys.Import.Heading"
                  defaultMessage="Send {channel} survey"
                  values={{ channel: channelMsgs }}
                />
              </h2>
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={7} md={8} lg={8}>
              <Paper className={`${styles.col} ${styles.right}`}>
                <Formsy.Form
                  onValid={() => {
                    if (isExisty(value) && !isEmpty(value)) {
                      const people = value.split('\n').map((line) => {
                        let [name, contact] = line.split(',').map(v => v.trim());
                        if (!contact) {
                          [name, contact] = [contact, name];
                        }
                        return { name, [channel === 'email' ? 'email' : 'phoneNumber']: contact };
                      });
                      setPeopleInput(people);
                    } else {
                      setPeopleInput([]);
                    }
                  }}
                  onInvalid={() => {
                    setPeopleInput(null);
                  }}
                >
                  <p style={{ padding: '20px 16px' }}>
                    <FormattedMessage
                      id="Surveys.Import.TypeOrPaste"
                      defaultMessage="Type or paste one person per line below (name is optional)"
                    />
                  </p>
                  <FormsyText
                    ref={peopleInputRef}
                    inputProps={{ style: { padding: '0 16px' } }}
                    id="people"
                    name="people"
                    multiline
                    fullWidth
                    rows={3}
                    placeholder={placeholder}
                    autoFocus
                    validations="onePersonPerLine"
                  />
                </Formsy.Form>
                <div className={`${styles.dropContainer} ${peopleInput === null ? styles.invalid : ''}`}>
                  <div className={styles.dropHere}>
                    <div className={styles.dropInner}>
                      <p>
                        <FormattedMessage id="Surveys.Import.DropFileHere" defaultMessage="Drop your file here" />
                      </p>
                    </div>
                  </div>
                  <DropZone
                    multiple={false}
                    accept=".csv"
                    onDrop={onDrop}
                    className={styles.dropzone}
                    activeClassName={styles.active}
                    rejectClassName={styles.rejected}
                  >
                    <div
                      className={styles.dropInner}
                      style={uploadFile ? { display: 'none' } : null}
                    >
                      <p className={styles.dropInnerP}>
                        <FileUpload className={styles.icon} />
                        <span className={styles.dropMessage}>
                          <FormattedMessage
                            id="Surveys.Import.TryDropping"
                            defaultMessage="Or drag and drop a CSV file here."
                          />
                          <br />
                          <button type="button" className={styles.linkButton}>
                            <FormattedMessage
                              id="Surveys.Import.Browse"
                              defaultMessage="Browse files in your computer"
                            />
                          </button>
                        </span>
                      </p>
                    </div>
                    <div className={styles.dropInnerActive}>
                      <p>
                        <FormattedMessage id="Surveys.Import.DropFileHere" defaultMessage="Drop your file here" />
                      </p>
                    </div>
                    <div className={styles.dropInnerRejected}>
                      <p>
                        {fileTypeNotSupportedMessage()}
                      </p>
                    </div>
                    <div
                      className={styles.dropInnerDropped}
                      style={!(uploadFile) ? { display: 'none' } : null}
                    >
                      <p className={styles.dropInnerP}>
                        <FileUploadOk className={styles.icon} />
                        <span>
                          <FormattedMessage
                            id="Surveys.Import.DroppedFile"
                            defaultMessage="{file} has been added"
                            values={{ file: <b>{uploadFile ? uploadFile.name : ''}</b> }}
                          />
                        </span>
                        <button
                          type="button"
                          className={styles.linkButton}
                          style={{ marginLeft: '5em' }}
                          onClick={handleRemoveFile}
                        >
                          <FormattedMessage
                            id="Surveys.Import.RemoveFile"
                            defaultMessage="Remove"
                          />
                        </button>
                      </p>
                    </div>
                  </DropZone>
                </div>
              </Paper>
              <Formsy.Form onValidSubmit={handleSubmit}>
                <RaisedButton
                  type="submit"
                  style={{ margin: '1em 0 2em 0' }}
                  label={submitButtonMessage || formatMessage(messages['Import.Next'])}
                  disabled={!submitEnabled || percentLoaded !== null}
                  icon={percentLoaded !== null && (
                    <CircularProgress style={{ marginBottom: 2 }} size={25} mode="determinate" value={percentLoaded} />
                  )}
                  primary
                />
              </Formsy.Form>
            </Col>
            <Col xs={12} sm={5} md={4} lg={4}>
              <Helmet title={formatMessage(surveyMessages['Survey.Import.Title'])} />
              <div className={`${styles.col} ${styles.left}`}>
                <div className={styles.okText}>
                  <h4>{formatMessage(messages.segment)}</h4>
                  <p>
                    <FormattedMessage
                      id="Import.YouCanUseProperties"
                      defaultMessage={
                        'You can use properties to later be able to filter responses from different '
                          + 'segments of people in your dashboard.'
                      }
                    />
                  </p>
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <Link component="button" sx={{ p: 0, textTransform: 'none' }} tabIndex={0} onClick={handleOpen}>
                    {formatMessage(messages.learnMore)}
                  </Link>
                  <Dialog
                    title={formatMessage(messages.segment)}
                    actions={actions}
                    modal={false}
                    open={dialogOpen}
                    onClose={handleClose}
                  >
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'muted.main',
                      }}
                    >
                      <Close />
                    </IconButton>
                    <DialogContent>
                      <p>
                        <FormattedMessage
                          id="Import.UploadACSV"
                          defaultMessage="Upload CSV file with the desired segmentation properties."
                        />
                      </p>
                      <div>
                        <FormattedMessage
                          id="Review.FileFormat"
                          defaultMessage="The CSV file must"
                        />
                        <ul>
                          <li>
                            <FormattedMessage
                              id="Review.FileFormat.ColumnNames"
                              defaultMessage="contain a first row with the column names."
                            />
                          </li>
                          <li>
                            <FormattedMessage
                              id="Review.FileFormat.EmailColumn"
                              defaultMessage={'have one column named "email".'}
                            />
                          </li>
                        </ul>
                      </div>
                      <p>
                        <FormattedMessage
                          id="Review.FileFormat.NameColumn"
                          defaultMessage={'You can add a "name" column that will display in your dashboard.'}
                        />
                      </p>
                      <p>
                        <FormattedMessage
                          id="Review.AddProperties"
                          defaultMessage="Add more columns to filter feedback from different segments of people instantly from your dashboard."
                        />
                      </p>
                      <p>
                        <a href={emailsCSV}>
                          <FormattedMessage
                            id="Review.DownloadExampleCSV"
                            defaultMessage="Download an example CSV file"
                          />
                        </a>
                      </p>
                    </DialogContent>
                  </Dialog>
                  <h4>
                    <FormattedMessage
                      id="Import.UseOurAPI"
                      defaultMessage="Integrate with your Apps"
                      values={{
                        api: <a href="/apidocs">API</a>,
                      }}
                    />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="Import.YouCanAlsoUseOurAPI"
                      defaultMessage="You can also use our {api} to import people periodically or after a purchase or similar event."
                      values={{
                        api: <a href="/apidocs">API</a>,
                      }}
                    />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </Layout>
    </div>
  );
};

export default ImportPeople;
