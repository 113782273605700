import { Alert, FormControlLabel, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { CopyField } from 'client/util/CopyField';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';

const messages = defineMessages({
  urlFormatError: {
    id: 'IntegrationsSettings.Webhooks.URLFormatError',
    defaultMessage: 'The URL must be valid and start with https://',
  },
  isPublic: {
    id: 'IntegrationsSettings.SlackChannelIsPublic',
    defaultMessage: 'Public Channel',
  },
  channelRequired: {
    id: 'IntegrationsSettings.SlackChannelRequired',
    defaultMessage: 'Channel name is required.',
  },
  channelFormat: {
    id: 'IntegrationsSettings.SlackChannelFormat',
    defaultMessage: 'Channel names can only contain lowercase letters, numbers, hyphens, and underscores, and must be 80 characters or less.',
  },
});


const IntegrationConfigWebhooks = () => {
  const { integration, onIntegrationsPropertiesChange } = useOutletContext();
  const { formatMessage } = useIntl();
  return (
    <>
      <TextField
        variant="standard"
        name="webhooksUrl"
        onInvalid={e => e.target.setCustomValidity(formatMessage(messages.urlFormatError))}
        onInput={e => e.target.setCustomValidity('')}
        value={integration?.url}
        type="url"
        pattern="https://.+"
        onChange={event => onIntegrationsPropertiesChange(event, 'webhooks', { url: event.target.value })}
        label="URL"
        style={{ width: '100%' }}
        required
      />
      <FormControlLabel
        sx={{ mt: 4 }}
        control={(
          <Switch
            checked={integration?.signRequests}
            onChange={event => onIntegrationsPropertiesChange(event, 'webhooks', { signRequests: event.target.checked })}
            name="signRequests"
            color="primary"
          />
        )}
        label={(
          <FormattedMessage
            id="IntegrationsSettings.Webhooks.SignRequests"
            defaultMessage="Sign Requests"
          />
        )}
      />
      {integration?.signRequests && !integration.secret ? (
        <Typography variant="body2" color="muted.main">
          <FormattedMessage
            id="IntegrationsSettings.Webhooks.SignRequestsWarning"
            defaultMessage="The secret was shown when you enabled when you enabled webhooks. If you lost it, you can remove the integration and add it again to get a new secret."
          />
        </Typography>
      ) : null}
      {integration?.signRequests && integration.secret ? (
        <>
          <CopyField
            variant="standard"
            name="secret"
            value={integration?.secret}
            onChange={event => onIntegrationsPropertiesChange(event, 'webhooks', { secret: event.target.value })}
            label="Secret"
            style={{ width: '100%' }}
            required
          />
          <Alert
            variant="filled"
            severity="warning"
            sx={{ mt: 2 }}
          >
            <FormattedMessage
              id="IntegrationsSettings.Webhooks.SecretWarning"
              defaultMessage="The secret is used to sign requests sent to your server. It is only shown once, so make sure to copy it now."
            />
          </Alert>
        </>
      ) : null}
    </>
  );
};
export default IntegrationConfigWebhooks;
