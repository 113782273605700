import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import QuestionLogicCard from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionLogicCard';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const styles = {
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    my: 2,
  },
  button: {
    borderRadius: 2,
    border: '1px dashed #e0e0d9',
    width: '100%',
    p: 4,
  },
};

const EditQuestionLogic = ({ questions, questionIndex }) => {
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'logics',
  });
  const handleAdd = useCallback(() => {
    append({
      operator: '',
      value: [],
      action: '',
    });
  }, []);
  return (
    <Box>
      <Typography component="h3" sx={{ fontWeight: 'bold' }}>
        <FormattedMessage id="EditQuestionLogic.AddLogic" defaultMessage="Add logic" />
      </Typography>
      <Box sx={styles.list}>
        {fields.map((field, index) => {
          return (
            <QuestionLogicCard
              key={field.id}
              questions={questions}
              questionIndex={questionIndex}
              index={index}
              remove={() => remove(index)}
            />
          );
        })}
        <Button sx={styles.button} startIcon={<Add />} onClick={handleAdd}>
          <FormattedMessage
            id="EditQuestionLogic.AddNewLogic"
            defaultMessage="Add new logic"
          />
        </Button>
      </Box>
    </Box>
  );
};

EditQuestionLogic.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  questionIndex: PropTypes.number.isRequired,
};
export default EditQuestionLogic;
