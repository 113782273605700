import { useFormContext } from 'react-hook-form';

const useRegisterSelect = () => {
  const { register, watch } = useFormContext();
  return (name, options) => {
    const defaultValue = watch(name);
    const { onChange, ...rest } = register(name, options);
    return ({
      onChange: ({ value }) => onChange({
        target: {
          value,
          name,
        },
        type: 'change',
      }),
      defaultValue,
      ...rest,
    });
  };
};
export default useRegisterSelect;
