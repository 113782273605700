import Copy from '@mui/icons-material/ContentCopy';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import copyText, { shouldRejectCopy } from 'client/util/copyText';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  copy: {
    id: 'CopyField.copy',
    defaultMessage: 'Copy',
  },
  copied: {
    id: 'CopyField.copied',
    defaultMessage: 'Copied!',
  },
});

const IconButtonWithTooltip = styled(IconButton)({
  root: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
    },
  },
});
const AlignedInputAdornment = styled(InputAdornment)({
  margin: '0 auto', // fix for vertically unaligned icon
});

export function CopyField({
  onCopyError, onCopySuccess, copyTooltip = 'Copy', value, ...rest
}) {
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    const err = shouldRejectCopy();
    setDisabled(!!err);

    if (err) {
      if (typeof onCopyError === 'function') onCopyError(err);
    }
  }, []);

  const handleCopy = async () => {
    try {
      copyText(value);
      onCopySuccess(value);
    } catch (err) {
      if (typeof onCopyError === 'function') onCopyError(err);
    }
  };

  return (
    <TextField
      type="text"
      value={value}
      InputProps={{
        endAdornment: (
          <AlignedInputAdornment position="end">
            {disabled ? (
              <IconButtonWithTooltip disabled={disabled} onClick={handleCopy}>
                <Copy />
              </IconButtonWithTooltip>
            ) : (
              <Tooltip title={copyTooltip}>
                <IconButtonWithTooltip role="button" disabled={disabled} onClick={handleCopy}>
                  <Copy />
                </IconButtonWithTooltip>
              </Tooltip>
            )}
          </AlignedInputAdornment>
        ),
      }}
      {...rest}
    />
  );
}
CopyField.propTypes = {
  onCopyError: PropTypes.func,
  onCopySuccess: PropTypes.func,
  copyTooltip: PropTypes.string,
  value: PropTypes.string.isRequired,
};
CopyField.defaultProps = {
  onCopyError: null,
  onCopySuccess: null,
  copyTooltip: 'Copy',
};
const DefaultCopyField = (props) => {
  const { formatMessage } = useIntl();
  const [tooltip, setTooltip] = useState('Copy');
  const setCopied = () => setTooltip(formatMessage(messages.copied));
  const setCopyError = () => setTooltip(formatMessage(messages.copy));
  return <CopyField copyTooltip={tooltip} onCopySuccess={setCopied} onCopyError={setCopyError} {...props} />;
};

export default DefaultCopyField;
