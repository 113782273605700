import React from 'react';
import Icon from 'react-icon-base';

const FileUploadOk = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path
        d="M35.8,8.5c0.4,0.4,0.7,1,1,1.7c0.3,0.7,0.4,1.4,0.5,1.9v25.8c0,0.5-0.2,1-0.6,1.5c-0.4,0.5-0.9,0.7-1.6,0.6h-30c-0.6,0-1.1-0.2-1.5-0.6C3.2,39,3,38.5,3,37.9V2.1c0-0.5,0.2-1,0.6-1.5S4.5-0.1,5.1,0h20c0.6,0,1.3,0.1,2,0.4s1.3,0.6,1.7,1.1L35.8,8.5z M25.9,3v8.4h8.4c-0.2-0.4-0.4-0.7-0.5-0.9l-7-7C26.6,3.4,26.3,3.2,25.9,3z M34.4,37.1V14.3h-9.3c-0.6,0-1.1-0.2-1.5-0.6c-0.4-0.4-0.6-0.9-0.6-1.6V2.9H5.9v34.2H34.4z M31.1,19.3L18,32.4c-0.2,0.3-0.6,0.4-0.9,0.4s-0.7-0.1-0.9-0.4l-6.9-6.9c-0.3-0.2-0.4-0.6-0.4-0.9c0-0.4,0.1-0.7,0.4-0.9l1.7-1.8c0.3-0.2,0.6-0.4,0.9-0.4c0.3,0,0.7,0.1,0.9,0.4l4.2,4.3l10.5-10.5c0.2-0.2,0.6-0.4,0.9-0.4s0.7,0.1,0.9,0.4l1.8,1.8c0.2,0.2,0.4,0.6,0.4,0.9C31.5,18.8,31.4,19.1,31.1,19.3z"
      />
    </g>
  </Icon>
);

export default FileUploadOk;
