import { Button } from '@mui/material';
import { getRemainingTrialDays } from 'client/modules/Auth/AuthReducer';
import styles from 'client/modules/Surveys/pages/DashboardPage/DashboardPage.css';
import { surveyMessages } from 'client/modules/Surveys/SurveyReducer';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import PropTypes from 'prop-types';
import React from 'react';
import { MdPersonAdd } from 'react-icons/md';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

const messages = defineMessages({
  dashboard: {
    id: 'Dashboard.Dashboard',
    defaultMessage: 'Dashboard',
    description: 'Text in the button to go to the dashboard.',
  },
  people: {
    id: 'Dashboard.People',
    defaultMessage: 'People',
    description: 'Text in the button to go to the list of imported people.',
  },
  searches: {
    id: 'Dashboard.Searches',
    defaultMessage: 'Searches',
    description: 'Text in the button to go to the list of saved searches.',
  },
  analyze: {
    id: 'Dashboard.Analyze',
    defaultMessage: 'Analyze',
    description: 'Text in the button to go to the analyze section.',
  },
  personalizeSurvey: {
    id: 'Survey.Personalize',
    defaultMessage: 'Personalize survey',
    description: 'Text in the button to edit a survey.',
  },
});
const SecondaryNavTabs = ({ survey: surveyId }) => {
  const intl = useIntl();
  const remaining = useSelector(getRemainingTrialDays);
  const trialFinished = remaining !== null && remaining <= 0;
  const navigate = useNavigate();
  const handleImport = (event) => {
    event.preventDefault();
    navigate(`/surveys/${surveyId}/import`);
  };
  const { formatMessage } = useIntl();
  return (
    <div className={styles.secondaryNav}>
      <div>
        <Link to={`/surveys/${surveyId}`} className={styles.headerLink}>
          {formatMessage(messages.dashboard)}
        </Link>
        <Link to={`/surveys/${surveyId}/people/?status=active`} className={styles.headerLink}>
          {formatMessage(messages.people)}
        </Link>
        <Link to={`/surveys/${surveyId}/searches`} className={styles.headerLink}>
          {formatMessage(messages.searches)}
        </Link>
        <Link to={`/surveys/${surveyId}/analyze`} className={styles.headerLink}>
          {formatMessage(messages.analyze)}
        </Link>
      </div>
      <div>
        <Link to={`/surveys/${surveyId}/edit`} className={styles.headerLink}>
          {formatMessage(messages.personalizeSurvey)}
        </Link>
        <Button
          variant="contained"
          className={styles.surveyPeopleButton}
          startIcon={<MdPersonAdd />}
          disabled={trialFinished}
          onClick={handleImport}
          primary
        >
          {intl.formatMessage(surveyMessages['Survey.SurveyPeople'])}
        </Button>
      </div>
    </div>
  );
};

SecondaryNavTabs.propTypes = {
  survey: PropTypes.string.isRequired,
};

export default SecondaryNavTabs;
