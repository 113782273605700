const isTouch = () => {
  let ret = false;
  if (navigator !== null && typeof navigator === 'object') {
    if ('maxTouchPoints' in navigator) {
      ret = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      if (navigator !== null) {
        ret = navigator.msMaxTouchPoints > 0;
      }
    } else {
      const mQ = window.matchMedia && matchMedia('(pointer:coarse)');
      if (mQ && mQ.media === '(pointer:coarse)') {
        ret = !!mQ.matches;
      } else if ('orientation' in window) {
        ret = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        ret = (
          /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA)
          || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
        );
      }
    }
  }
  return ret;
};
export default isTouch;
