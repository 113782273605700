import {
  Box, Button, Checkbox, FormControl, FormControlLabel, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { getAllTags } from 'client/modules/Response/ResponseReducer';
import PropTypes from 'prop-types';
import { ChipField } from 'ra-ui-materialui';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  aboveTagName: {
    id: 'ManageTags.aboveTagName',
    defaultMessage: 'the tag name above',
  },
  renameTagTo: {
    id: 'ManageTags.renameTagTo',
    defaultMessage: 'Rename tag to',
  },
  newTags: {
    id: 'ManageTags.newTags',
    defaultMessage: 'New tags to add',
  },
});

const ReplaceTagDialog = ({
  isOpen, close, onConfirm, tag, op,
}) => {
  const { formatMessage } = useIntl();
  const [newTag, setNewTag] = React.useState((op === 'rename' && tag?.name) || '');
  const tags = useSelector(getAllTags);
  const handleSubmit = (event) => {
    event.preventDefault();
    onConfirm(newTag);
  };
  useEffect(() => {
    if (isOpen) {
      setNewTag((op === 'rename' && tag?.name) || '');
    }
  }, [isOpen, op, tag?.name]);
  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '500px' } }}
      component="form"
      onSubmit={handleSubmit}
      open={isOpen}
      onClose={close}
      aria-labelledby="replace-alert-dialog-title"
      aria-describedby="replace-alert-dialog-description"
    >

      <DialogTitle id="replace-alert-dialog-title">
        {op === 'rename' && (
          <FormattedMessage
            id="ManageTags.renameTag"
            defaultMessage="Rename tag"
          />
        )}
        {op === 'merge' && (
          <FormattedMessage
            id="ManageTags.mergeTag"
            defaultMessage="Merge tag"
          />
        )}
        {op === 'add' && (
          <FormattedMessage
            id="ManageTags.addTags"
            defaultMessage="Add tags"
          />
        )}
      </DialogTitle>
      <DialogContent>
        {op === 'add' && (
          <Box>
            <Autocomplete
              freeSolo
              autoSelect
              multiple
              renderInput={params => <TextField required={newTag?.length === 0} {...params} autoFocus label={formatMessage(messages.newTags)} />}
              options={[]}
              value={newTag}
              onChange={(event, value) => setNewTag(value)}
            />
          </Box>
        )}
        {op === 'rename' && (
          <Box>
            <TextField
              required
              autoFocus
              label={formatMessage(messages.renameTagTo)}
              type="text"
              fullWidth
              value={newTag}
              onChange={event => setNewTag(event.target.value)}
            />
            <Typography sx={{ minHeight: 48, mt: 2 }}>
              <FormattedMessage
                id="ManageTags.renameTagWarning"
                defaultMessage="This will rename all instances of the tag {name} to {newName}."
                values={{ name: <strong>{tag.name}</strong>, newName: <strong>{(tag?.name !== newTag && newTag) || formatMessage(messages.aboveTagName)}</strong> }}
              />
            </Typography>
          </Box>
        )}
        {op === 'merge' && (
          <Box>
            <FormControl fullWidth>
              <Select
                required
                value={newTag}
                onChange={event => setNewTag(event.target.value)}
                inputProps={{
                  name: 'newTag',
                  id: 'newTag',
                }}
              >
                {tags.filter(t => t.name !== tag.name).map(t => (
                  <MenuItem key={t._id} value={t.name}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography sx={{ minHeight: 48, mt: 2 }}>
              <FormattedMessage
                id="ManageTags.mergeTagWarning"
                defaultMessage="This will merge all instances of the tag {name} into the tag {newName}."
                values={{ name: <strong>{tag.name}</strong>, newName: <strong>{newTag || formatMessage(messages.aboveTagName)}</strong> }}
              />
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="inherit">
          <FormattedMessage id="ManageTags.cancel" defaultMessage="Cancel" />
        </Button>
        <Button type="submit" color="primary" disabled={tag?.name === newTag}>
          {op === 'rename' && (
            <FormattedMessage id="ManageTags.rename" defaultMessage="Rename" />
          )}
          {op === 'merge' && (
            <FormattedMessage id="ManageTags.merge" defaultMessage="Merge" />
          )}
          {op === 'add' && (
            <FormattedMessage id="ManageTags.add" defaultMessage="Add" />
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReplaceTagDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
  op: PropTypes.string.isRequired,
};

export default ReplaceTagDialog;
