import HelpArticle from 'client/modules/Help/HelpArticle';
import HelpCategory from 'client/modules/Help/HelpCategory';
import HelpHome from 'client/modules/Help/HelpHome';
import HelpLayout from 'client/modules/Help/HelpLayout';
import AdditionalQuestionPage from 'client/modules/Response/pages/AddResponsePages/AdditionalQuestionPage';
import AddResponseLinkPage from 'client/modules/Response/pages/AddResponsePages/AddReponseLinkPage';
import React from 'react';
import { Navigate, Route } from 'react-router';
import ErrorPage from './modules/App/components/ErrorPage/ErrorPage';
import ForgotPasswordChangePage from './modules/Auth/pages/Login/ForgotPasswordChangePage';
import ForgotPasswordPage from './modules/Auth/pages/Login/ForgotPasswordPage';
import ForgotPasswordSent from './modules/Auth/pages/Login/ForgotPasswordSent';
import LoginPage from './modules/Auth/pages/Login/LoginPage';
import SignupPage, { showError } from './modules/Auth/pages/Login/SignupPage';
import VerifyEmailPage from './modules/Auth/pages/Login/VerifyEmailPage';
import EmailPreferencesPage from './modules/EmailPreferences/pages/EmailPreferencesPage/EmailPreferencesPage';
import UserUnsubscribePage, { userUnsubscribe } from './modules/EmailPreferences/pages/UserUnsubscribePage/UserUnsubscribePageContainer';
import AddPairwiseResponsePage from './modules/Pairwise/pages/AddPairwiseResponsePage/AddPairwiseResponsePageContainer';
import UnsubscribePage from './modules/People/pages/Unsubscribe/UnsubscribePage';
import LegalPage from './modules/Public/pages/Legal/LegalPage';
import AddResponsePage, { addResponsePageLoader } from './modules/Response/pages/AddResponsePages/AddResponsePage';
import AddScorePage, { addScorePageLoader } from './modules/Response/pages/AddResponsePages/AddScorePage';
import ThankyouPage from './modules/Response/pages/AddResponsePages/ThankyouPage';
import OnEnter from './modules/Surveys/components/OnEnter/OnEnter';

const PublicRoutes = store => (
  <Route>
    <Route path="response/:response/thanks" element={<ThankyouPage />} />
    <Route path="l/:link/unsubscribe/:email" element={<UnsubscribePage />} />
    <Route path="l/:link/n/:name/:score?" element={<AddResponseLinkPage />} />
    <Route path="l/:link/:score?" element={<AddResponseLinkPage />} />
    <Route path="l/:link/:score?" element={<AddResponseLinkPage />} />
    <Route path="l/:link/n/:name/:score?" element={<AddResponseLinkPage />} />
    <Route path="r/:response" element={<AddScorePage />} loader={addScorePageLoader(store)} />
    <Route path="r/:response/:score" element={<AddResponsePage />} loader={addResponsePageLoader(store)} />
    <Route path="response/:response/q/:questionIndex" element={<AdditionalQuestionPage />} loader={addScorePageLoader(store)} />
    <Route path="response/:response/:score" element={<AddResponsePage />} loader={addResponsePageLoader(store)} />
    <Route path="response/:response" element={<AddScorePage />} loader={addScorePageLoader(store)} />
    <Route path="legal/:key" element={<LegalPage />} loader={LegalPage.loader(store)} />
    <Route path="privacy" element={<Navigate to="/legal/privacy" replace />} />
    <Route path="terms" element={<Navigate to="/legal/terms" replace />} />
    <Route path="dpa" element={<Navigate to="/legal/dpa" replace />} />
    <Route path="cookies" element={<Navigate to="/legal/cookies" replace />} />
    <Route path="login" element={<LoginPage />} />
    <Route path="sign-up" element={<OnEnter func={showError}><SignupPage /></OnEnter>} isSignup />
    <Route path="people/:token/unsubscribe" element={<UnsubscribePage />} loader={UnsubscribePage.loader(store)} />
    <Route path="unsubscribe/:token" element={<UnsubscribePage />} loader={UnsubscribePage.loader(store)} />
    <Route path="confirm-email/:token" element={<VerifyEmailPage />} />
    <Route path="forgot-password/:id/:token" element={<ForgotPasswordChangePage />} />
    <Route path="forgot-password" element={<ForgotPasswordPage />} />
    <Route path="forgot-password-sent" element={<ForgotPasswordSent />} />
    <Route path="error" element={<ErrorPage />} />
    <Route path="pairwise/:survey/:locale/:user" element={<AddPairwiseResponsePage />} />
    <Route path="pairwise/:survey/:locale" element={<AddPairwiseResponsePage />} />
    <Route path="email-preferences/:payload/:expiry/:signature" element={<EmailPreferencesPage />} loader={EmailPreferencesPage.loader(store)} />
    <Route path="unsubscribe/:payload/:expiry/:signature" element={<OnEnter func={userUnsubscribe}><UserUnsubscribePage /></OnEnter>} />
    <Route path="help" element={<HelpLayout store={store} />} loader={HelpLayout.loader(store)}>
      <Route index element={<HelpHome />} />
      <Route path=":category" element={<HelpCategory />} loader={HelpCategory.loader(store)} />
      <Route path=":category/:article" element={<HelpArticle />} loader={HelpArticle.loader(store)} />
    </Route>
  </Route>
);

export default PublicRoutes;
