import homeMessages from 'client/modules/Public/pages/HomePage/homeMessages';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Layout from '../../../App/components/Layout/LayoutContainer';
import Features from './components/Features/Features';
import Home from './components/Home/Home';

import Nav from './components/Nav/NavContainer';
import Pricing from './components/Pricing/Pricing';
import Product from './components/Product/Product';
import Testimonials from './components/Testimonials/TestimonialsContainer';

import styles from './HomePage.css';

const HomePage = (props) => {
  const navigate = useNavigate();
  const handleSignup = () => {
    navigate('/sign-up');
  };

  const { intl: { formatMessage }, surveyType, locale: lang } = props;
  const title = formatMessage(homeMessages.title);
  const description = formatMessage(homeMessages.description);
  return (
    <Layout className={styles.layout} showHeader={false} title={title} description={description} disableGutters>
      <Helmet htmlAttributes={{ lang }}>
        <link rel="alternate" hrefLang="es" href="https://www.promoter.ninja/es" />
        <link rel="alternate" hrefLang="en" href="https://www.promoter.ninja/en" />
        <link rel="alternate" hrefLang="x-default" href="https://www.promoter.ninja" />
        <meta httpEquiv="Content-Language" content={lang} />
        {surveyType ? <meta name="robots" content="noindex,nofollow" /> : null}
      </Helmet>
      <Nav handleSignup={handleSignup} {...{ surveyType }} />
      <a id="sec-home-a" className={styles.anchor} />
      <div id="sec-home">
        <Home handleSignup={handleSignup} surveyType={surveyType} />
      </div>
      <a id="sec-features-a" className={styles.anchor} />
      <div id="sec-features">
        <Features />
      </div>
      <a id="sec-product-a" className={styles.anchor} />
      <div id="sec-product">
        <Product handleSignup={handleSignup} surveyType={surveyType} />
      </div>
      <a id="sec-pricing-a" className={styles.anchor} />
      <div id="sec-pricing">
        <Pricing handleSignup={handleSignup} />
      </div>
      <div id="sec-testimonials">
        <Testimonials />
      </div>
    </Layout>
  );
};

HomePage.defaultProps = {
  surveyType: undefined,
};

HomePage.propTypes = {
  intl: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  surveyType: PropTypes.string,
};

export default injectIntl(HomePage);
