import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import styles from 'client/modules/Settings/pages/IntegrationsPage/IntegrationsListPage.css';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import PropTypes from 'prop-types';
import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  surveys: {
    id: 'SelectSurvey.survey',
    defaultMessage: 'Survey',
  },
});

const SelectSurvey = ({ field, index, defaultValue }) => {
  const surveys = useSelector(getSurveys);
  const { formatMessage } = useIntl();
  const { register } = useFormContext();
  const value = useWatch({ name: `${field}.${index}` });
  return (
    <FormControl variant="standard" className={styles.ruleInput}>
      <InputLabel htmlFor={`select-survey-${value._id}`} shrink>{formatMessage(messages.surveys)}</InputLabel>
      <Select
        {...register(`${field}.${index}.survey`)}
        defaultValue={defaultValue}
        variant="standard"
        className={styles.input}
        id={`select-survey-${value._id}`}
        fullWidth
        autoWidth
      >
        {surveys?.map(s => (
          <MenuItem key={s.name} value={s.id}>
            {s.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectSurvey.propTypes = {
  index: PropTypes.number.isRequired,
  field: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

SelectSurvey.defaultProps = {
  defaultValue: undefined,
};

export default SelectSurvey;
