const NotAvailable = 'NotAvailable';
const WriteError = 'WriteError';

export const shouldRejectCopy = () => ('clipboard' in navigator ? false : NotAvailable);
const copyText = value => new Promise(async (resolve, reject) => {
  if ('clipboard' in navigator) {
    await navigator.clipboard.writeText(value).catch(() => reject(WriteError));
    resolve(value);
  } else reject(NotAvailable);
});

export default copyText;
