import {
  DialogActions, DialogContent, DialogContentText, DialogTitle,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import FlatButton from 'components/FlatButton/FlatButton';
import IconMenu from 'components/IconMenu/IconMenu';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { FaDownload, FaFileExcel, FaList } from 'react-icons/fa';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { SearchkitManager } from 'searchkit';
import styles from './SearchExportButton.css';

const SearchExportButton = ({ count, onExport, onListExports }) => {
  const [confirmOpen, setConfirmOpen] = useState(false);
  const closeConfirm = useCallback(() => setConfirmOpen(false), []);
  const openConfirm = useCallback(() => setConfirmOpen(true), []);
  const handleExport = useCallback(() => { closeConfirm(); onExport(); }, [onExport]);
  return (
    <div className={styles.main}>
      <IconMenu
        className={styles.formInput}
        icon={<FaDownload />}
      >
        {[
          <MenuItem value="export" leftIcon={<FaFileExcel />} onClick={openConfirm}><FormattedMessage id="SearchExportButton.Export" defaultMessage="Export results" /></MenuItem>,
          <MenuItem value="exportList" leftIcon={<FaList />} onClick={onListExports}><FormattedMessage id="SearchExportButton.ExportsList" defaultMessage="Previous exports list" /></MenuItem>,
        ]}
      </IconMenu>
      <Dialog
        open={confirmOpen}
        onRequestClose={closeConfirm}
      >
        <DialogTitle><h1><FormattedMessage id="SearchExportButton.Export" /></h1></DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="SearchExportButton.AboutToExport"
              defaultMessage="You are about to export {count} records. Are you sure?"
              values={{ count: <FormattedNumber value={count} /> }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatButton
            label={<FormattedMessage id="SearchExportButton.No" defaultMessage="No" />}
            primary
            onClick={closeConfirm}
          />
          <FlatButton
            label={<FormattedMessage id="SearchExportButton.Yes" defaultMessage="Yes" />}
            primary
            keyboardFocused
            onClick={handleExport}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

SearchExportButton.contextTypes = {
  searchkit: PropTypes.instanceOf(SearchkitManager),
};

SearchExportButton.propTypes = {
  onExport: PropTypes.func.isRequired,
  onListExports: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};

export default SearchExportButton;
