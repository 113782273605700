import { Button } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import FormsyText from 'components/FormsyText/FormsyText';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import FormsyPassword from '../../../../../App/components/FormsyPassword/FormsyPassword';
import Message from '../../../../../App/components/Message/Message';

import styles from './LoginWidget.css';

export const loginMessages = defineMessages({
  'Signup.Title': {
    id: 'Signup.Title',
    defaultMessage: 'Sign up',
  },
  'Signup.Submit': {
    id: 'Signup.Submit',
    defaultMessage: 'Create my account',
  },
  'Login.Title': {
    id: 'Login.Title',
    defaultMessage: 'Log in',
  },
  'Login.Submit': {
    id: 'Login.Submit',
    defaultMessage: 'Log in',
  },
  email: {
    id: 'Login.Email',
    defaultMessage: 'name@company.com',
  },
  password: {
    id: 'Login.Password',
    defaultMessage: 'password',
  },
  submit: {
    id: 'Login.Submit',
    defaultMessage: 'Log in',
  },
  invalidEmail: {
    id: 'Login.Errors.InvalidEmail',
    defaultMessage: 'This is not a valid email',
  },
  or: {
    id: 'Login.Or',
    defaultMessage: 'or',
  },
});

const LoginWidget = (props) => {
  const email = useRef();
  const password = useRef();
  const { formatMessage, locale } = useIntl();

  const { errorMessage, isSignup, onLoginError } = props;

  const handleSubmit = () => {
    const creds = { email: email.current.getValue().trim().toLowerCase(), password: password.current.getValue().trim() };
    props.onLoginClick(creds, props.isSignup);
  };

  const onGoogleSignIn = (response) => {
    props.onLoginClick({ credential: response.credential }, props.isSignup);
  };
  const onGoogleSignInError = (error) => {
    onLoginError(error.error);
  };

  const buttonLabel = props.isSignup ? formatMessage(loginMessages['Signup.Submit']) : formatMessage(loginMessages['Login.Submit']);

  return (
    <div className={styles.container}>
      <Message
        type="error"
        msg={errorMessage}
        onClose={props.onCloseError}
        className={`${styles.msg} ${errorMessage ? styles.visible : styles.hidden}`}
      />
      <Formsy.Form
        onValidSubmit={handleSubmit}
        className={styles.form}
      >
        <FormsyText
          id="email"
          name="email"
          validations="isEmail"
          ref={email}
          validationError={formatMessage(loginMessages.invalidEmail)}
          floatingLabelText={formatMessage(loginMessages.email)}
          required
          autoFocus
          fullWidth
        />
        <FormsyPassword
          id="password"
          name="password"
          type="password"
          ref={password}
          floatingLabelText={formatMessage(loginMessages.password)}
          required
          fullWidth
        />
        <Link className={styles.forgot} to="/forgot-password">
          <FormattedMessage id="Login.ForgotPassword" defaultMessage="Forgot your password?" />
        </Link>
        <Button variant="contained" className={styles.submit} type="submit" color="primary" style={{ width: '100%' }}>
          {buttonLabel}
        </Button>
      </Formsy.Form>
      <hr className={styles.hrText} data-content={formatMessage(loginMessages.or)} />
      {!!process.env.CLIENT && locale && (
        <GoogleLogin
          onSuccess={onGoogleSignIn}
          onError={onGoogleSignInError}
          locale={locale}
          useOneTap
          auto_select
          cancel_on_tap_outside
          itp_support
          shape="pill"
          size="large"
          context={isSignup ? 'signup' : 'signin'}
          text={isSignup ? 'signup_with' : 'signin_with'}
        />
      )}
    </div>
  );
};

LoginWidget.defaultProps = {
  errorMessage: null,
};

LoginWidget.propTypes = {
  onLoginClick: PropTypes.func.isRequired,
  errorMessage: PropTypes.shape({
    id: PropTypes.string,
  }),
  isSignup: PropTypes.bool.isRequired,
  onCloseError: PropTypes.func.isRequired,
  onLoginError: PropTypes.func.isRequired,
};

export default LoginWidget;
