import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithReauth from 'client/util/baseQueryWithReauth';
import qs from 'qs';


const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
export const surveyAnalyzeApiSlice = createApi({
  reducerPath: 'surveyAnalyzeReducer',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['SurveyAnalyze'],
  endpoints: builder => ({
    analyzeEvolution: builder.query({
      query: ({ survey, ...rest }) => ({
        url: `/survey-analyze/${survey}/?${qs.stringify({ ...rest, timeZone })}`,
        method: 'GET',
      }),
      providesTags: ['SurveyAnalyze'],
      transformResponse: (response, _, { filters }) => response.map(([key, series]) => {
        return ({
          key: new Date(key * 3600000),
          ...(series.reduce((acc, [average, score, positive, neutral, negative], idx) => ({
            ...acc,
            [filters[idx]]: {
              average,
              score,
              positive,
              neutral,
              negative,
              total: positive + neutral + negative,
            },
          }), {})),
        });
      }),
    }),

  }),
});

export const {
  useAnalyzeEvolutionQuery,
  reducer: surveyAnalyzeReducer,
} = surveyAnalyzeApiSlice;
