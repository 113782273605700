import { Box } from '@mui/material';
import { getToken } from 'client/modules/Auth/AuthReducer';
import ApiKeysList from 'client/modules/Settings/pages/ApiKeysPage/ApiKeysList';
import useOpenClose from 'client/util/useOpenClose';
import React from 'react';

import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  title: {
    id: 'ApiKeysSettings.Title',
    defaultMessage: 'Developer settings',
  },
  reset: {
    id: 'ApiKeysSettings.Reset',
    defaultMessage: 'Reset',
  },
  areYouSure: {
    id: 'ApiKeysSettings.AreYouSure',
    defaultMessage: 'Are you sure you want to reset your API key?',
  },
  yes: {
    id: 'ApiKeysSettings.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'ApiKeysSettings.No',
    defaultMessage: 'No',
  },
});

const ApiKeysPage = () => {
  const token = useSelector(getToken);
  const [open, handleOpen, handleClose] = useOpenClose();
  const { formatMessage } = useIntl();
  const handleReset = () => {
    handleClose();
  };
  return (
    <Box>
      <Helmet title={formatMessage(messages.title)} />
      <ApiKeysList />
      <Box sx={{ mt: 3 }}>
        <FormattedMessage
          id="ApiKeysSettings.BeSure"
          defaultMessage={
            'Be sure to keep your API key private. '
            + 'All API requests must be made over HTTPS and are authenticated via HTTP Bearer Authentication. See our {link} for more information.'
          }
          values={{
            link: (
              <a href="/apidocs">
                <FormattedMessage
                  id="ApiKeysSettings.Documentation"
                  defaultMessage="REST API documentation"
                />
              </a>
            ),
          }}
        />
      </Box>
    </Box>
  );
};
export default ApiKeysPage;
