import React from 'react';
import { Box } from '@mui/material';

const additionalQuestionsReplacements = (additionalQuestions, initialValue = {}) => additionalQuestions.reduce((acc, q, i) => {
  if (q.text) {
    acc[`answer:${q._id}`] = (
      <Box
        component="span"
        sx={{
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          borderRadius: 1,
          px: 0.5,
        }}
      >
        {`${i + 1}. ${q.text.substring(0, 15)}${q.text.length > 15 ? '...' : ''}`}
      </Box>
    );
  }
  return acc;
}, initialValue);

export default additionalQuestionsReplacements;
