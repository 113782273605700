import { TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames/bind';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { forwardRef, useMemo } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import styles from './styles.css';

const useStyles = makeStyles(() => ({
  input: {
    padding: '10px',
  },
  mentions: {
    width: '100%',
  },
  mentions__input: {
    border: 0,
    '&:focus': {
      outline: 'none',
    },
  },
  mention: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '3px',
  },
  suggestion: {
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
  },
  focused: {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
}));

const Temp = forwardRef(({ children, data, ...rest }, ref) => {
  const classes = useStyles();
  const map = useMemo(
    () => data.reduce((acc, { id, display }) => { acc[id] = display; return acc; }, {}),
    [data],
  );
  const renderUserSuggestion = (suggestion, query, highlightedDisplay, index, focused) => {
    return (
      <div className={classNames({ [classes.suggestion]: true, [classes.focused]: focused })}>
        <Typography className={classes.display}>{suggestion.display}</Typography>
      </div>
    );
  };
  return (
    <MentionsInput
      {...rest}
      ref={ref}
      className="mentions"
      classNames={classes}
    >
      <Mention
        className="mention"
        classNames={classes}
        trigger="@"
        markup="{{answer:__id__}}"
        displayTransform={id => map[id] || id}
        data={data}
        renderSuggestion={renderUserSuggestion}
      />
    </MentionsInput>
  );
});

Temp.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    display: PropTypes.string.isRequired,
  })).isRequired,
};

const MentionsTextField = forwardRef((props, ref) => {
  return (
    <TextField
      ref={ref}
      inputProps={pick(props, ['value', 'onChange', 'children', 'data'])}
      InputProps={{
        components: { Input: Temp },
      }}
      classes={{ input: styles.input }}
      {...props}
    />
  );
});

MentionsTextField.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    display: PropTypes.string.isRequired,
  })).isRequired,
};

export default MentionsTextField;
