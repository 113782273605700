import { getToken } from 'client/modules/Auth/AuthReducer';
import RefinementListFilterDivider from 'client/modules/Surveys/components/RefinementListFilterDivider/RefinementListFilterDivider';
import StartCaseCheckboxItemComponent from 'client/modules/Surveys/components/StartCaseCheckboxItemComponent/StartCaseCheckboxItemComponent';
import { setSurveyFields } from 'client/modules/Surveys/SurveyActions';
import MultiSelect from 'components/MultiSelect/MultiSelect';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  CheckboxItemList, FacetAccessor, HitsAccessor, NumericRefinementListFilter, RefinementListFilter, SearchkitComponent,
} from 'searchkit';
import { humanizeDuration } from '../../../../../server/util/durationUtils';

const messages = defineMessages({
  fromTo: {
    id: 'Dashboard.FromTo',
    defaultMessage: 'From {from} to {to}',
  },
  lessThan: {
    id: 'Dashboard.lessThan',
    defaultMessage: 'Less than {from}',
  },
  orMore: {
    id: 'Dashboard.orMore',
    defaultMessage: '{to} or more',
  },
  fromToDate: {
    id: 'Dashboard.fromToDate',
    defaultMessage: 'From {to} to {from}',
  },
  moreThan: {
    id: 'Dashboard.moreThan',
    defaultMessage: 'More than {from}',
  },
  orLess: {
    id: 'Dashboard.orLess',
    defaultMessage: '{to} or less',
  },
});

const sortFn = ([, f1], [, f2]) => (!!f1.hidden !== !!f2.hidden ? !!f1.hidden - !!f2.hidden : f1.index - f2.index);

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class PeoplePropertiesRefinementListFiltersInt extends SearchkitComponent {
  constructor(props) {
    super(props);
    this.state = PeoplePropertiesRefinementListFiltersInt.getState(props, {});
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.survey.id !== nextProps.survey.id
      || this.props.survey.updatedAt !== nextProps.survey.updatedAt
    ) {
      this.setState(PeoplePropertiesRefinementListFiltersInt.getState(nextProps));
    }
  }

  static getState(props) {
    const {
      survey: {
        hasResponses, person_properties_types: fieldsObj, updatedAt, id,
      },
    } = props;
    const defaultFieldsObj = {
      type: { type: 'terms' },
      paymentMethod: { type: 'terms' },
      age: { type: 'terms' },
    };
    const fieldsToSort = hasResponses ? fieldsObj : defaultFieldsObj;
    const fields = Object.entries(fieldsToSort).sort(sortFn);
    return { fields, updatedAt, id };
  }


  onDragEnd(result) {
    const { token, survey: { id: surveyId }, dispatch } = this.props;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    this.setState(({ fields: fieldsIn, ...rest }) => {
      const fields = reorder(
        cloneDeep(fieldsIn),
        result.source.index,
        result.destination.index,
      );
      dispatch(setSurveyFields(token, surveyId, fields.map(([key, { hidden }], index) => ({ key, hidden, index }))));
      return ({ fields, ...rest });
    });
  }

  onClose(which) {
    const { token, survey: { id: surveyId }, dispatch } = this.props;
    this.setState(({ fields: fieldsIn, ...rest }) => {
      const fields = cloneDeep(fieldsIn);
      fields[which][1].hidden = true;
      dispatch(setSurveyFields(token, surveyId, fields.map(([key, { hidden }], index) => ({ key, hidden, index }))));
      return ({ fields, ...rest });
    });
  }

  render() {
    const has = (o, key) => Object.prototype.hasOwnProperty.call(o, key);
    const { intl: { formatMessage } } = this.props;
    const { fields } = this.state;
    if (typeof fields !== 'object') {
      return null;
    }
    if (!this.searchkit) {
      return null;
    }
    return (
      <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
        <Droppable droppableId="filters">
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {fields.map(([key, { type, options, hidden }], index) => {
                let component = RefinementListFilter;
                const accessors = this.searchkit.getAccessorsByType(FacetAccessor);
                const accessor = accessors.find(a => a.key === key);
                const count = accessor ? accessor.getCount() : 0;
                const show = count > 0;
                const otherOptions = {};
                if (type === 'number' || type === 'date') {
                  component = NumericRefinementListFilter;
                  otherOptions.multiselect = true;
                } else {
                  otherOptions.itemComponent = StartCaseCheckboxItemComponent;
                }
                let listComponent = CheckboxItemList;
                if (type === 'terms') {
                  if (count > 15) {
                    listComponent = MultiSelect;
                  }
                }
                let opts;
                if (options) {
                  opts = [];
                  options.forEach((o) => {
                    let title = '';
                    if (has(o, 'from') && has(o, 'to')) {
                      let msg = 'fromTo';
                      const o2 = { ...o };
                      if (type === 'date') {
                        o2.from = humanizeDuration(o.from, formatMessage);
                        o2.to = humanizeDuration(o.to, formatMessage);
                        msg = 'fromToDate';
                      } else {
                        o2.to -= 1;
                      }
                      title = formatMessage(messages[msg], o2);
                    } else if (has(o, 'from')) {
                      let msg = 'moreThan';
                      const o2 = { ...o };
                      if (type === 'date') {
                        msg = 'lessThan';
                        o2.from = humanizeDuration(o.from, formatMessage);
                      } else {
                        o2.from -= 1;
                      }
                      title = formatMessage(messages[msg], o2);
                    } else if (has(o, 'to')) {
                      let msg = 'orLess';
                      const o2 = { ...o };
                      if (type === 'date') {
                        msg = 'orMore';
                        o2.to = humanizeDuration(o.to, formatMessage);
                      } else {
                        o2.to -= 1;
                      }
                      title = formatMessage(messages[msg], o2);
                    }
                    opts.push({ ...o, title });
                  });
                }
                return (
                  <Draggable disabled={!show} draggableId={key} key={key} index={index}>
                    {(provided2, snapshot) => (

                      <div
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                        {...provided2.dragHandleProps}
                      >
                        {hidden ? null : (
                          <RefinementListFilterDivider
                            disabled={!show}
                            component={component}
                            options={opts}
                            id={key}
                            title={key}
                            field={`person_properties.${key}`}
                            operator="OR"
                            size={1000}
                            listComponent={listComponent}
                            onClose={this.onClose.bind(this, index)}
                            isDragging={snapshot.isDragging}
                            {...otherOptions}
                          />
                        )}
                      </div>

                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  defineAccessor() {
    return new HitsAccessor({ scrollTo: false });
  }
}

PeoplePropertiesRefinementListFiltersInt.propTypes = {
  survey: PropTypes.object.isRequired,
};

const PeoplePropertiesRefinementListFilters = connect(state => ({ token: getToken(state) }))(injectIntl(PeoplePropertiesRefinementListFiltersInt));

export default PeoplePropertiesRefinementListFilters;
