import { Box, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Spinner from '../../../../App/components/Spinner/Spinner';

const SaveButton = ({ disabled, saving, ...props }) => (
  <Button
    type="submit"
    disabled={disabled || saving}
    variant="outlined"
    className={props.className}
  >
    {!!saving && (
      <Box sx={{
        position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center',
      }}
      >
        <Spinner style={{
          fontSize: '4px',
          margin: 'auto',
        }}
        />
      </Box>
    )}
    <Box sx={{ visibility: saving ? 'hidden' : 'visible' }}>
      <FormattedMessage id="Settings.Save" defaultMessage="Save" />
    </Box>
  </Button>
);

SaveButton.defaultProps = {
  disabled: false,
  className: null,
  saving: false,
};

SaveButton.propTypes = {
  disabled: PropTypes.bool,
  saving: PropTypes.bool,
  className: PropTypes.string,
};

export default SaveButton;
