import { TableContainer } from '@mui/material';
import { setError } from 'client/modules/App/AppActions';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import getErrorMessageKey from 'client/util/getErrorMessageKey';
import Formsy from 'formsy-react';
import Paper from '@mui/material/Paper';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import HttpStatusCodes from 'http-status-codes';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppError from '../../../../../server/util/AppError';
import withParams from '../../../../util/withParams';
import Layout from '../../../App/components/Layout/LayoutContainer';
import Spinner from '../../../App/components/Spinner/Spinner';
import { getToken } from '../../../Auth/AuthReducer';

import {
  clearPeople, fetchImport, listen, resetPeople, scheduleSending, stopListening,
} from '../../SurveyActions';
import {
  getId, getPeople, getTotalPeople, getUploadStatus,
} from '../../SurveyReducer';

import styles from './ReviewImport.css';

const messages = defineMessages({
  'Review.Next': {
    id: 'Review.Next',
    defaultMessage: 'Send to {total} {total, plural, one {person} other {people}} now',
  },
  'Review.Back': {
    id: 'Review.Back',
    defaultMessage: 'Back to import',
  },
});

const ReviewImport = (props) => {
  const navigate = useNavigate();
  const [updatedAt, setUpdatedAt] = useState(null);
  const isImporting = () => {
    const { people } = props;
    return !people || ['Pending', 'NotStarted'].indexOf(people.status) > -1;
  };

  useEffect(() => {
    props.resetPeople();
    let interval;
    const startImport = () => {
      if ((isImporting() && !updatedAt) || (updatedAt - new Date().getTime()) > 3000) {
        props.fetchImport(props.surveyId, props.importId, props.token);
        setUpdatedAt(new Date().getTime());
        if (props.uploadStatus === 'Done' && interval) {
          clearInterval(interval);
          interval = null;
        }
      }
    };
    startImport();
    if (props.people.status !== 'Done') {
      interval = setInterval(startImport, 1000);
    }
    setTimeout(() => props.listen(props.importId), 2000);
    return () => {
      props.stopListening(props.importId);
      clearInterval(interval);
    };
  }, []);


  const { formatMessage } = useIntl();

  if (!props.channel) {
    throw new AppError('Unknown channel');
  }

  const handleSubmit = () => {
    const date = null; // Send now
    props.scheduleSending(props.surveyId, props.importId, date, props.token, navigate).catch((e) => {
      props.setError(formatMessage(getErrorMessageKey(e)));
    });
  };

  const hasNameColumn = props.people && props.people.docs.length > 0 && props.people.docs[0].name;
  const personProperties = props.people && props.people.docs.length > 0 && typeof props.people.docs[0].properties === 'object' ? Object.keys(props.people.docs[0].properties) : [];

  let returnLinkTo = null;
  let returnLinkText = null;
  if (props._id) {
    returnLinkTo = `/surveys/${props._id}/import/${props.channel}`;
    returnLinkText = formatMessage(messages['Review.Back']);
  }

  const columnMessages = {
    email: <FormattedMessage id="Review.ColumnEmail" defaultMessage="Email" />,
    sms: <FormattedMessage id="Review.ColumnSMS" defaultMessage="Phone Number" />,
  };
  const column = columnMessages[props.channel];
  const uploaded = ((props.people && props.people.csvUploaded) || props.people.inputUploaded || props.people.total) || 0;
  if (isImporting()) {
    return (
      <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText} secondaryNav={<SecondaryNavTabs survey={props._id} />}>
        <Grid className={styles.mainGrid} fluid>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <Spinner />
              <div className={styles.counter}>
                {props.totalPeople
                  ? (
                    <FormattedMessage
                      id="Review.UploadedProgress"
                      defaultMessage="Imported {uploaded} out of {total}"
                      values={{
                        uploaded,
                        total: props.totalPeople,
                      }}
                    />
                  )
                  : (
                    <FormattedMessage
                      id="Review.UploadedPending"
                      defaultMessage="Uploading file, please wait."
                    />
                  )}
              </div>
            </Col>
          </Row>
        </Grid>
      </Layout>
    );
  }
  if (props.people.status === 'Error') {
    props.clearPeople();
    navigate(returnLinkTo);
    return null;
  }
  return (
    <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText}>
      <Grid className={styles.mainGrid} fluid>
        <Row>
          <Col xs={12} sm={5} md={4} lg={4}>
            <Paper className={`${styles.col} ${styles.left}`}>
              <div className={styles.okText}>
                <h2>
                  <FormattedMessage
                    id="Review.Title"
                    defaultMessage="Imported {total, number} {total, plural, one {person} other {people}}, ready to send"
                    values={{ total: props.people.total }}
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Review.Ready"
                    defaultMessage="Everything imported correctly."
                  />
                </p>
              </div>
              <Formsy.Form
                onValidSubmit={handleSubmit}
              >
                <RaisedButton
                  type="submit"
                  className={styles.submit}
                  label={formatMessage(messages['Review.Next'], { total: props.people.total })}
                  primary
                />
              </Formsy.Form>
            </Paper>
          </Col>
          <Col xs={12} sm={7} md={8} lg={8}>
            <div className={`${styles.col} ${styles.right}`}>
              <TableContainer component={Paper}>
                <Table selectable={false} className={styles.table} style={{ width: 'auto' }} bodyStyle={{ overflow: 'auto' }} fixedHeader={false}>
                  <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                    <TableRow>
                      <TableCell>{column}</TableCell>
                      {hasNameColumn ? <TableCell>Name</TableCell> : null}
                      {personProperties.map((p, i) => <TableCell key={i}>{p}</TableCell>)}
                    </TableRow>
                  </TableHeader>
                  <TableBody sx={{ '& .MuiTableRow-root .MuiTableCell-root': { borderBottom: 0 } }}>
                    {props.people.docs.map((p, ri) => {
                      return (
                        <TableRow key={ri}>
                          <TableCell>{p[props.channel === 'sms' ? 'phoneNumber' : 'email']}</TableCell>
                          {hasNameColumn ? <TableCell>{p.name}</TableCell> : null}
                          {personProperties.map((prop, i) => (
                            <TableCell
                              key={i}
                            >
                              {p.properties[prop]}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              {props.people.total > 5 ? <div className={styles.tornPaper} /> : null}
            </div>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

// Retrieve data from store as props
function mapStateToProps(state, ownProps) {
  const ret = {
    surveyId: ownProps.params.id,
    totalPeople: getTotalPeople(state),
    importId: ownProps.params.importId,
    channel: ['sms', 'email'].includes(ownProps.params.channel) ? ownProps.params.channel : null,
    people: getPeople(state),
    token: getToken(state),
    _id: getId(state),
    uploadStatus: getUploadStatus(state),
  };
  ret.people.uploaded = ret.people.csvUploaded + ret.people.inputUploaded;
  return ret;
}

const mapDispatchToProps = dispatch => ({
  resetPeople() {
    dispatch(resetPeople());
  },
  fetchImport(surveyId, importId, token) {
    dispatch(fetchImport(surveyId, importId, token));
  },
  listen(importId) {
    dispatch(listen(importId));
  },
  stopListening(importId) {
    dispatch(stopListening(importId));
  },
  scheduleSending(surveyId, importId, date, token, navigate) {
    return dispatch(scheduleSending(surveyId, importId, date, token, navigate));
  },
  clearPeople() {
    dispatch(clearPeople());
  },
  setError(message) {
    dispatch(setError(message));
  },
});

ReviewImport.defaultProps = {
  _id: null,
  people: {
    docs: [],
    status: 'Pending',
    uploaded: 0,
    total: 0,
    totalPeople: 0,
  },
  uploadStatus: null,
};

ReviewImport.propTypes = {
  _id: PropTypes.string,
  token: PropTypes.string.isRequired,
  channel: PropTypes.oneOf(['sms', 'email']).isRequired,
  people: PropTypes.shape({
    docs: PropTypes.array.isRequired,
    status: PropTypes.oneOf(['NotStarted', 'Pending', 'Done', 'Error']).isRequired,
    uploaded: PropTypes.number,
    total: PropTypes.number.isRequired,
    totalPeople: PropTypes.number.isRequired,
    inputUploaded: PropTypes.number.isRequired,
    csvUploaded: PropTypes.number.isRequired,
  }),
  surveyId: PropTypes.string.isRequired,
  importId: PropTypes.string.isRequired,
  totalPeople: PropTypes.number.isRequired,
  listen: PropTypes.func.isRequired,
  stopListening: PropTypes.func.isRequired,
  scheduleSending: PropTypes.func.isRequired,
  fetchImport: PropTypes.func.isRequired,
  resetPeople: PropTypes.func.isRequired,
  clearPeople: PropTypes.func.isRequired,
  uploadStatus: PropTypes.string,
  setError: PropTypes.func.isRequired,
};

export default withParams(connect(mapStateToProps, mapDispatchToProps)(ReviewImport));
