import { handleActions } from 'redux-actions';

const initialState = {
  loaded: false,
  apiKey: '',
  customMailService: {},
  useCustomMailService: false,
  minSurveyInterval: null,
  integrations: [],
};

export const SettingsReducer = handleActions(
  {
    RESET_API_KEY_CB: (state, action) => ({
      ...state,
      apiKey: action.payload.apiKey,
    }),
    USE_CUSTOM_MAIL_SERVICE_CHANGE: (state, action) => ({
      ...state,
      useCustomMailService: action.payload,
    }),
    CUSTOM_MAIL_SERVICE_TYPE_CHANGE: (state, action) => ({
      ...state,
      customMailService: { ...state.customMailService, serviceType: action.payload },
    }),
    CUSTOM_MAIL_SERVICE_APIKEY_CHANGE: (state, action) => ({
      ...state,
      customMailService: { ...state.customMailService, apiKey: action.payload },
    }),
    CUSTOM_MAIL_SERVICE_DOMAIN_CHANGE: (state, action) => ({
      ...state,
      customMailService: { ...state.customMailService, domain: action.payload },
    }),
    CUSTOM_MAIL_SERVICE_FROM_EMAIL_CHANGE: (state, action) => ({
      ...state,
      customMailService: { ...state.customMailService, fromEmail: action.payload },
    }),
    SET_SENDGRID_API_KEY_CB: (state, action) => ({
      ...state,
      sendGridApiKey: action.payload.sendGridApiKey,
    }),
    FETCH_SETTINGS_CB: (state, action) => ({
      ...state,
      loaded: true,
      ...action.payload,
    }),
    INTEGRATION_PROPERTIES_CHANGE: (state, { payload: { type, props } }) => {
      const ret = {
        ...state,
        integrations: { ...state.integrations },
      };
      ret.integrations[type] = { ...(state.integrations[type] || {}), ...props };
      return ret;
    },
    MIN_SURVEY_INTERVAL_CHANGE: (state, action) => ({
      ...state,
      minSurveyInterval: action.payload,
    }),
    ADD_CB: (state, action) => {
      return {
        ...state,
        integrations: {
          ...state.integrations,
          [action.payload.integrationType]: {
            ...state.integrations[action.payload.integrationType],
          },
        },
      };
    },
  },
  initialState,
);

export const isSettingsLoaded = state => state.settings.loaded;
export const getApiKey = state => state.settings.apiKey;
export const getAutoTagging = state => state.settings.autoTagging;
export const getSendGridApiKey = state => state.settings.sendGridApiKey;
export const getSendGridCustomDomain = state => state.settings.sendGridCustomDomain;
export const getSendGridUnsubscribeGroups = state => state.settings.sendGridUnsubscribeGroups;
export const getIntegrations = state => state.settings.integrations;
export const getCustomMailService = state => state.settings.customMailService;
export const isUseCustomMailService = state => state.settings.useCustomMailService;
export const getMinSurveyInterval = state => state.settings.minSurveyInterval;

export default SettingsReducer;
