import { NOT_FOUND } from 'http-status-codes';
import { createAction } from 'redux-actions';
import callApi from '../../util/apiCaller';

export const getPairwiseQuestionCb = createAction('GET_PAIRWISE_QUESTION_CB');
export const addPairwiseAnswerCb = createAction('ADD_PAIRWISE_ANSWER_CB');
export const setPairwiseError = createAction('SET_PAIRWISE_ERROR');
export const setResponseEnabled = createAction('SET_RESPONSE_ENABLED');

export function getPairwiseQuestion(pairwiseSurveyId, locale, when = Date.now()) {
  return (dispatch) => {
    return callApi(dispatch, `pairwise/${pairwiseSurveyId}/${locale}`, null, 'GET').then((res) => {
      const delay = when - Date.now();
      if (delay <= 0) {
        dispatch(getPairwiseQuestionCb(res));
        dispatch(setResponseEnabled(true));
      } else {
        setTimeout(() => {
          dispatch(getPairwiseQuestionCb(res));
          dispatch(setResponseEnabled(true));
        }, delay);
      }
    }).catch((err) => {
      dispatch(setResponseEnabled(true));
      if (err.status !== NOT_FOUND) {
        throw err;
      } else {
        return dispatch(setPairwiseError(err));
      }
    });
  };
}

export function addPairwiseAnswer(pairwiseSurveyId, combination, locale, user, answer, properties, reverse) {
  return (dispatch) => {
    dispatch(setResponseEnabled(false));
    return callApi(dispatch, `pairwise/${pairwiseSurveyId}/responses`, null, 'POST', {
      combination,
      user,
      answer,
      locale,
      properties,
      reverse
    }).then((res) => {
      dispatch(getPairwiseQuestion(pairwiseSurveyId, locale, Date.now() + 500));
      return dispatch(addPairwiseAnswerCb(res));
    });
  };
}
