/* eslint-disable global-require */
import { ExtraErrorData } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import ErrorPage from 'components/ErrorPage/ErrorPage';
import React from 'react';
import { Route } from 'react-router';
import AuthenticatedHome from './AuthenticatedHome';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import App from './modules/App/App';
import NotFound from './modules/App/components/NotFound/NotFound';
import { getToken } from './modules/Auth/AuthReducer';
import HomePage from './modules/Public/pages/HomePage/HomePageContainer';
import PublicRoutes from './PublicRoutes';

// require.ensure polyfill for node
if (typeof require.ensure !== 'function') {
  require.ensure = function requireModule(deps, callback) {
    callback(require);
  };
}

if (process.env.CLIENT) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: process.env.SENTRY_PUBLIC_DSN,
    release: process.env.npm_package_version,
    integrations: integrations => ([...integrations, new Sentry.BrowserTracing(), new ExtraErrorData()]),
    tracesSampleRate: 0.1,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      'Number of messages in trial period exceeded',
    ],
  });
}

export default (store) => {
  return (
    <Route
      errorElement={process.env.NODE_ENV === 'development' ? undefined : <ErrorPage />}
      path="/*"
      element={(store
        ? (
          <App store={store} />
        ) : <div>No store</div>
      )}
      key={getToken(store.getState())}
    >
      {AuthenticatedRoutes(store)}
      {PublicRoutes(store)}
      <Route path="nps" element={<HomePage surveyType="nps" />} />
      <Route path="csat" element={<HomePage surveyType="csat" />} />
      <Route path="ces" element={<HomePage surveyType="ces" />} />
      <Route path="five-star" element={<HomePage surveyType="five-star" />} />
      <Route path="en" element={<HomePage />} />
      <Route path="es" element={<HomePage />} />
      <Route index path="" element={<AuthenticatedHome />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  );
};
