import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isLoggedIn } from '../../../Auth/AuthReducer';

import LayoutFocused from '../LayoutFocused/LayoutFocused';

import styles from './NotFound.css';

const messages = defineMessages({
  title: {
    id: 'NotFound.Title',
    defaultMessage: 'Page not found',
  },
  heading: {
    id: 'NotFound.Heading',
    defaultMessage: 'This page isn\'t available',
  },
});

const NotFound = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <LayoutFocused className={styles.container} title={formatMessage(messages.title)}>
      <div>
        <h3>
          <FormattedMessage
            id="NotFound.Heading"
            defaultMessage="This page isn't available"
          />
        </h3>
        <div className={styles.body}>
          <div className={styles.error}>404</div>
          <div className={styles.alternatives}>
            <FormattedMessage
              id="NotFound.Alternatives"
              defaultMessage="Now you may want to:"
            />
            <ul>
              <li>
                <FormattedMessage
                  id="NotFound.SendEmail"
                  defaultMessage="Send us an {email}"
                  values={{
                    email: (
                      <Link to="mailto:hello@promoter.ninja">
                        <FormattedMessage
                          id="NotFound.Email"
                          defaultMessage="email"
                        />
                      </Link>
                    ),
                  }}
                />
              </li>
              {props.loggedIn ?
                <li>
                  <FormattedMessage
                    id="NotFound.GoToStart"
                    defaultMessage="Go to the {startingPage}."
                    values={{
                      startingPage: (
                        <Link to="/">
                          <FormattedMessage
                            id="NotFound.StartingPage"
                            defaultMessage="starting page"
                          />
                        </Link>
                      ),
                    }}
                  />
                </li> :
                <li>
                  <FormattedMessage
                    id="NotFound.TryOurService"
                    defaultMessage="{knowMore} about Promoter Ninja and sign up for free."
                    values={{
                      knowMore: (
                        <Link to="/">
                          <FormattedMessage
                            id="NotFound.KnowMore"
                            defaultMessage="Know more"
                          />
                        </Link>
                      ),
                    }}
                  />
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </LayoutFocused>
  );
};

NotFound.propTypes = {
  loggedIn: PropTypes.bool.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  loggedIn: isLoggedIn(state),
});

export default connect(mapStateToProps)(injectIntl(NotFound));
