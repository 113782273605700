import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Card, CardContent, CardHeader, Collapse, IconButton } from '@mui/material';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme => theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  header: {
    borderBottom: '1px solid #e0e0d9',
    borderTop: '1px solid #e0e0d9',
  },
  collapsed: {

  },
  root: {
    '$collapsed + & > $header': {
      borderTop: 0,
    },
  },
};

const AccordionDrawer = (props) => {
  const {
    title,
    children,
    expanded,
    onExpandChange,
    ...other
  } = props;

  return (
    <Card {...other} className={classnames([styles.root, { [styles.collapsed]: !expanded }])} raised={false} square elevation={0}>
      <CardHeader
        className={styles.header}
        disableTypography
        title={title}
        aria-expanded={expanded}
        aria-label="Expand"
        action={(
          <IconButton
            className={classnames(styles.expand, {
              [styles.expandOpen]: expanded,
            })}
            onClick={onExpandChange}
            size="large">
            <ExpandMoreIcon />
          </IconButton>
        )}
      />
      <span className={styles.pix} />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent expandable>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

AccordionDrawer.propTypes = {
  title: PropTypes.string.isRequired,
  expanded: PropTypes.bool,
  onExpandChange: PropTypes.func,
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
};

AccordionDrawer.defaultProps = {
  style: {},
  expanded: null,
  onExpandChange: () => {
  },
};

export default AccordionDrawer;
