import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  totalResults: {
    id: 'Dashboard.responses',
    defaultMessage: '{count} responses',
  },
});

const styles = {
  totalResults: {
    whiteSpace: 'nowrap',
    fontWeight: 700,
    fontSize: 24,
    padding: '0 16px',
    my: 0,
  },
};

const TotalResults = ({ hitsCount }) => {
  const { formatMessage } = useIntl();
  return (
    <Box component="h2" sx={styles.totalResults}>
      {formatMessage(messages.totalResults, { count: hitsCount })}
    </Box>
  );
};

TotalResults.propTypes = {
  hitsCount: PropTypes.number.isRequired,
};

export default TotalResults;
