// Import Actions
import { SET_LOCALE, TOGGLE_USER_MENU } from './AppActions';
// Setup i18n
import { enabledLanguages, localizationData } from '../Intl/setup';


export const createI18nData = (state, newLang) => {
  // If the user already selected a locale it takes preference over the navigator locale
  let locale = newLang;
  if (enabledLanguages.indexOf(locale) < 0) {
    locale = state.app && state.app.i18n && state.app.i18n.locale;
  }

  if (enabledLanguages.indexOf(locale) < 0) {
    locale = enabledLanguages[0];
  }

  const messages = localizationData[locale].messages;

  return { locale, messages };
};

// Initial State
const initialState = {
  i18n: null,
  profile: null,
  token: null,
  userMenuOpen: false,
};

const AppReducer = (state = initialState, action) => {
  const s = Object.assign({}, state);
  if (!s.i18n) {
    s.i18n = createI18nData(state);
  }
  switch (action.type) {
    case SET_LOCALE:
      s.i18n = createI18nData(s, action.locale);
      return s;
    case TOGGLE_USER_MENU:
      s.userMenuOpen = action.open;
      return s;
    default:
      return s;
  }
};

// Get i18n data
export const getI18n = (state) => {
  return state.app ? state.app.i18n : createI18nData(state);
};

export const isUserMenuOpen = state => (state.app ? state.app.userMenuOpen : false);
// Export Reducer
export default AppReducer;
