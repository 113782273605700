import {
  Box, Button, Checkbox, ListItemText,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useAnalyzeEvolution } from 'client/modules/Analyze/AnalyzeEvolutionContext';
import SearchDateRangeControl from 'client/modules/Surveys/components/SearchDateRange/SearchDateRangeControl';
import qs from 'qs';
import { Loading } from 'ra-ui-materialui';
import React, { useCallback } from 'react';
import { FaDownload } from 'react-icons/fa';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import Config from '../../../../server/config';

const groups = {
  week: 'week',
  month: 'month',
  quarter: 'quarter',
  year: 'year',
};

const messages = defineMessages({
  week: {
    id: 'analyze.group.week',
    defaultMessage: 'Week',
  },
  month: {
    id: 'analyze.group.month',
    defaultMessage: 'Month',
  },
  quarter: {
    id: 'analyze.group.quarter',
    defaultMessage: 'Quarter',
  },
  year: {
    id: 'analyze.group.year',
    defaultMessage: 'Year',
  },
  all: {
    id: 'SearchDateRange.AllTime',
    defaultMessage: 'All time',
  },
});

const styles = {
  root: {
    display: 'flex',
    gap: 2,
    marginBottom: 2,
  },
  select: {
    backgroundColor: 'white',
    '& .MuiSelect-select': { py: 1.5, minWidth: 120 },
  },
  downloadButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgba(20,30,27,0.58)',
    width: 46,
    height: 46,
    borderRadius: 1,
    backgroundColor: 'white',
    border: '1px solid #bdbdbd',
    '&:hover': {
      borderColor: '#757575',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
};

const AnalyzeEvolutionControls = () => {
  const {
    groupBy, setGroupBy, filters, setFilters, dateRange, setDateRange, survey, searches, isLoadingSearches, field,
  } = useAnalyzeEvolution();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
  const params = qs.stringify({
    groupBy, filters, dateRange, timeZone, field,
  });
  const filename = `analysis-${new Date().toISOString().substring(0, 10)}`;
  const handleChangeGroupBy = useCallback((event) => {
    setGroupBy(event.target.value);
  }, []);
  const handleChangeFilters = useCallback((event) => {
    setFilters(event.target.value);
  }, []);
  const renderValue = useCallback(selected => (
    <FormattedMessage
      id="analyze.series"
      defaultMessage="{count} series"
      values={{
        count: selected.length === 1 && selected[0] === 'all' ? 0 : selected.length,
        name: searches?.find(s => selected?.[0] === s._id)?.name,
      }}
    />
  ), [searches]);
  const { formatMessage } = useIntl();
  return (
    <Box sx={styles.root}>
      <SearchDateRangeControl
        field="created_at"
        title={<FormattedMessage id="analyze.dateRange" defaultMessage="Date range" />}
        onChange={setDateRange}
        value={dateRange}
        emptyValue={formatMessage(messages.all)}
        alwaysOpen
      />
      <Select
        value={groupBy}
        onChange={handleChangeGroupBy}
        sx={styles.select}
      >
        {Object.keys(groups).map(group => (
          <MenuItem key={group} value={group}>
            {formatMessage(messages[group])}
          </MenuItem>
        ))}
      </Select>
      <Select
        renderValue={renderValue}
        value={filters}
        onChange={handleChangeFilters}
        sx={styles.select}
        displayEmpty
        multiple
        required
      >
        <MenuItem value="all">
          <Checkbox checked={filters.indexOf('all') > -1} />
          <FormattedMessage id="analyze.allResponses" defaultMessage="All responses" />
        </MenuItem>
        <ListSubheader>
          <FormattedMessage id="analyze.savedSearches" defaultMessage="Saved searches" />
        </ListSubheader>
        {isLoadingSearches
          ? (
            <MenuItem disabled><Loading /></MenuItem>)
          : searches?.map(s => (
            <MenuItem key={s._id} value={s._id}>
              <Checkbox checked={filters.indexOf(s._id) > -1} />
              <ListItemText primary={s.name} />
            </MenuItem>
          ))}
      </Select>
      <Box component="a" sx={styles.downloadButton} href={`${Config.apiUrl}/survey-analyze/${survey.id}/${filename}.xlsx?${params}`} download>
        <FaDownload />
      </Box>
    </Box>
  );
};

export default AnalyzeEvolutionControls;
