/* eslint-disable react/prop-types */
import classNames from 'classnames/bind';
import React, { useRef } from 'react';

// eslint-disable-next-line react/prefer-stateless-function
const Tooltip = ({
  xTooltip = -10000,
  yTooltip = -10000,
  contentTooltip,
  data,
  x,
  children,
}) => {
  const divRef = useRef(null);

  // eslint-disable-next-line no-param-reassign
  if (contentTooltip?.d && !contentTooltip.point) contentTooltip.point = data.find(d => x(d).getTime() === contentTooltip.d.x.getTime());

  let contentTooltipTmpl;

  const tooltipWidth = divRef.current?.getBoundingClientRect()?.width || 0;
  const parentWidth = divRef.current?.parentElement?.getBoundingClientRect()?.width || 0;
  const left = xTooltip + tooltipWidth >= parentWidth;
  const style = {
    left: left ? xTooltip - tooltipWidth - 26 : xTooltip + 26,
    top: yTooltip - 13,
    position: 'absolute',
    zIndex: 10002,
    pointerEvents: 'none',
  };

  if (contentTooltip) {
    if (children) {
      contentTooltipTmpl = React.cloneElement(children, { contentTooltip });
    }
  }

  return (
    <div
      ref={divRef}
      style={style}
      className={classNames('react-d3-basics__tooltip_utils', { visible: !!xTooltip }, { left })}
    >
      {contentTooltipTmpl}
    </div>
  );
};

Tooltip.defaultProps = {
  gravity: 's',
  dist: 40,
};

export default Tooltip;
