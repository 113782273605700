import { Add, Comment, DragIndicator } from '@mui/icons-material';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  ToggleButton,
  Typography,
} from '@mui/material';
import { ObjectID } from 'bson';
import ListOption from 'client/modules/Surveys/pages/CustomizeSurvey/ListOption';
import { arrayMoveImmutable } from 'client/util/arrayMove';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  addOption: {
    id: 'ListOptions.AddOption',
    defaultMessage: 'Add an option',
  },
});
const ListOptions = ({ options, setOptions }) => {
  const [newOption, setNewOption] = useState('');
  const { formatMessage } = useIntl();
  const [adding, setAdding] = useState(false);
  const onDragEnd = result => {
    if (result.destination) {
      setOptions(arrayMoveImmutable(options, result.source.index, result.destination.index));
    }
  };
  const handleDelete = index => {
    setOptions(opts => [...opts.slice(0, index), ...opts.slice(index + 1, opts.length)]);
  };
  const handleToggleComment = index => () => {
    setOptions(opts => [
      ...opts.slice(0, index),
      { ...opts[index], addComment: !opts[index].addComment },
      ...opts.slice(index + 1, opts.length),
    ]);
  };
  const handleChange = event => {
    setNewOption(event.target.value);
  };
  const handleSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    setOptions(o => [...(o || []), { _id: new ObjectID().toString(), text: newOption }]);
    setNewOption('');
  };
  return (
    <Box>
      <Typography sx={{ mt: 3, mb: 1, fontWeight: 'bold' }}>
        <FormattedMessage id="ListOptions.options" defaultMessage="Options" />
      </Typography>
      <List
        sx={{
          py: 0,
          minHeight: '1px',
          mb: 1,
        }}
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="options-list">
            {provided => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
              >
                {options?.map((option, index) => (
                  <Draggable key={option._id} draggableId={option._id} index={index}>
                    {provided2 => (
                      <Box
                        sx={{
                          userSelect: 'none',
                          ...provided2.draggableProps.style,
                        }}
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                      >
                        <ListItem
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 0.5,
                            paddingLeft: 0,
                            minHeight: 40,
                          }}
                          secondaryAction={
                            <>
                              <ToggleButton
                                sx={{ border: 0 }}
                                variant="text"
                                size="small"
                                value="comment"
                                selected={option.addComment}
                                onChange={handleToggleComment(index)}
                              >
                                <Comment />
                              </ToggleButton>
                              <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => handleDelete(index)}
                              >
                                <RemoveCircleOutline />
                              </IconButton>
                            </>
                          }
                          disablePadding
                        >
                          <Box sx={{ display: 'flex' }} {...provided2.dragHandleProps}>
                            <DragIndicator sx={{ color: '#bebeb8' }} />
                          </Box>
                          <ListItemText>
                            <ListOption setOptions={setOptions} option={option} />
                          </ListItemText>
                        </ListItem>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </List>
      {adding ? (
        <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <OutlinedInput
            autoFocus
            onChange={handleChange}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                handleSubmit(event);
                event.stopPropagation();
              }
            }}
            value={newOption}
            endAdornment={
              <>
                <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                <Button variant="text" type="button" onClick={handleSubmit}>
                  <FormattedMessage id="ListOptions.Add" defaultMessage="Add" />
                </Button>
              </>
            }
            sx={{ flex: 1, backgroundColor: '#fff' }}
            placeholder={formatMessage(messages.addOption)}
          />
        </FormControl>
      ) : (
        <Button startIcon={<Add />} onClick={() => setAdding(true)}>
          <FormattedMessage id="ListOptions.AddOption" defaultMessage="Add an option" />
        </Button>
      )}
    </Box>
  );
};

ListOptions.propTypes = {
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default ListOptions;
