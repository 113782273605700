/**
 * Client entry point
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppContainer } from 'react-hot-loader';
import App from './App';
import configureStore from './configureStore';

// Initialize store
const store = configureStore(window.__PRELOADED_STATE__);

const mountApp = document.getElementById('root');
const root = createRoot(mountApp);

const errorsToWarn = [
  'Warning:',
];
const oldConsError = console.error;
global.console.error = (...args) => {
  let toWarn = false;

  if (typeof args[0] === 'string') {
    errorsToWarn.forEach((_s) => {
      if (args[0].startsWith(_s)) {
        toWarn = true;
      }
    });
  }

  if (toWarn) {
    console.warn(...args);
  } else {
    oldConsError(...args);
  }
};

root.render(
  <AppContainer>
    <App store={store} />
  </AppContainer>,
);

// For hot reloading of react components
if (module.hot) {
  module.hot.accept('./App', () => {
    // If you use Webpack 2 in ES modules mode, you can
    // use <App /> here rather than require() a <NextApp />.
    const NextApp = require('./App').default; // eslint-disable-line global-require
    root.render(
      <AppContainer>
        <NextApp store={store} />
      </AppContainer>,
    );
  });
}
