import timespan from 'jsonwebtoken/lib/timespan';
import decode from 'jwt-decode';
import * as Config from '../server/config';

export function isTokenMaxAgeExceeded(token) {
  const decoded = decode(token);
  if (!decoded.iat) {
    return null;
  }
  const clockTimestamp = Math.floor(Date.now() / 1000);
  const maxAgeTimestamp = timespan(Config.tokenMaxAge, decoded.iat);
  return clockTimestamp >= maxAgeTimestamp;
}

export function getTokenExpirationDate(token) {
  const decoded = decode(token);
  if (!decoded.exp) {
    return null;
  }

  const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
  date.setUTCSeconds(decoded.exp);
  return date;
}

export function isTokenExpired(token, offsetSeconds = 0) {
  const date = getTokenExpirationDate(token);
  if (date === null) {
    return false;
  }
  return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)));
}
