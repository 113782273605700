import React from 'react';
import Icon from 'react-icon-base';

const SMS = props => (
  <Icon viewBox="0 0 455 455" {...props}>
    <path d="M227.5 51.8c99.2 0 180 64.3 180 143.4 0 16.1-3.3 31.9-9.9 47-6.4 14.7-15.8 28.5-27.9 40.9-24.6 25.2-59.5 43.4-98.1 51.2-9.4 1.9-16.8 9.6-18.4 19.1-2.3 13.6-12.3 26.5-28.3 36.3-3 1.8-6.1 3.5-9.4 5.1 6.3-13 8.7-26.7 6.3-39-1.9-10-10-17.5-20.2-18.7-42.9-5-82.1-22.2-110.6-48.7-28-26-43.4-59.1-43.4-93.2-.1-79 80.7-143.4 179.9-143.4m0-15c-107.7 0-195 70.9-195 158.4 0 79.9 72.8 146 167.4 156.8 3.5.4 6.3 3.1 7 6.6 3.3 17.3-7.4 32.2-21.2 44.4-6.7 5.9-1.3 16.8 7.4 14.9 39.9-8.6 69.7-32.7 74.7-62 .6-3.5 3.4-6.3 6.8-7 84.8-17.2 147.7-79.5 147.7-153.7.2-87.4-87.1-158.4-194.8-158.4z"/><path d="M152.7 166.1c-6.6 0-11.3-5.5-23.2-5.5-11.5 0-17.8 3.5-17.8 11.2 0 6.2 5.3 9.5 13 12.2 9 3.1 18.9 5 27.4 10.4 6.6 4.1 11.6 10.6 11.6 21 0 18.5-15 29.1-35.3 29.1-13.7 0-33.7-5.2-33.7-14.3 0-4.3 3.5-7.6 7.6-7.6 6.3 0 11.5 8 25.9 8 13.2 0 18.5-6.4 18.5-14 0-6.4-5-10.1-12-12.9-7.8-3.1-17.6-4.9-26.3-9.7-7.6-4.2-13.2-10.8-13.2-21.7 0-15.4 12.3-25.9 35.1-25.9 6 0 29.8 1.8 29.8 12.3 0 4.2-3.2 7.4-7.4 7.4zm35.9-19.6c3.9 0 6.9 2 8.5 4.9l30.1 50.8 30.4-50.8c1.7-2.9 4.5-4.9 8.5-4.9 5.9 0 9.8 4.3 9.8 9.5v81.2c0 3.9-3.1 7.3-7.7 7.3-4.5 0-7.6-3.4-7.6-7.3v-62.3L235 217.3c-1.5 2.7-3.9 4.8-7.7 4.8-3.8 0-6.2-2.1-7.7-4.8L194 174.9v62.3c0 3.9-3.1 7.3-7.7 7.3-4.5 0-7.6-3.4-7.6-7.3V156c.1-5.1 4.1-9.5 9.9-9.5zm160.7 19.6c-6.6 0-11.3-5.5-23.2-5.5-11.5 0-17.8 3.5-17.8 11.2 0 6.2 5.3 9.5 13 12.2 9 3.1 18.9 5 27.4 10.4 6.6 4.1 11.6 10.6 11.6 21 0 18.5-15 29.1-35.3 29.1-13.7 0-33.7-5.2-33.7-14.3 0-4.3 3.5-7.6 7.6-7.6 6.3 0 11.5 8 25.9 8 13.2 0 18.5-6.4 18.5-14 0-6.4-5-10.1-12-12.9-7.8-3.1-17.6-4.9-26.3-9.7-7.6-4.2-13.2-10.8-13.2-21.7 0-15.4 12.3-25.9 35.1-25.9 6 0 29.8 1.8 29.8 12.3.1 4.2-3.2 7.4-7.4 7.4z"/>
  </Icon>
);

export default SMS;
