import React from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const withLocation = WrappedComponent => (props) => {
  const location = useLocation();
  const [query] = useSearchParams();
  location.query = Object.fromEntries(query.entries());
  return (
    <WrappedComponent
      {...props}
      location={location}
    />
  );
};

export default withLocation;
