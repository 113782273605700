import { useCallback, useMemo, useState } from 'react';

const useStack = (initialValue = []) => {
  const [stack, setStack] = useState(initialValue);

  const push = useCallback(item => setStack(prev => [...prev, item]), [setStack]);

  const pop = useCallback(() => {
    if (stack.length > 0) {
      const last = stack[stack.length - 1];
      setStack(prev => prev.slice(0, prev.length - 1));
      return last;
    }
    return undefined;
  }, [stack, setStack]);


  const peek = useMemo(() => {
    if (stack.length > 0) {
      return stack[stack.length - 1];
    }
    return undefined;
  }, [stack]);

  const clear = useCallback(() => setStack([]), [setStack]);

  const values = useMemo(() => [...stack], [stack]);

  const isEmpty = useMemo(() => stack.length === 0, [stack]);

  return {
    clear,
    isEmpty,
    peek,
    pop,
    push,
    values,
  };
};

export default useStack;
