import { Add, Close, DragIndicator } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import branch from 'client/modules/Surveys/assets/branch.svg';
import target from 'client/modules/Surveys/assets/target.svg';
import QuestionIcon from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionIcon';
import {
  contactForm,
  freeResponse,
  scale,
  selectMany,
  selectOne,
  statement,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import { getAdditionalQuestions } from 'client/modules/Surveys/SurveyReducer';
import additionalQuestionsReplacements from 'client/util/additionalQuestionsReplacements';
import replacePlaceholders from 'client/util/replacePlaceholders';
import useHover from 'client/util/useHover';
import useOpenClose from 'client/util/useOpenClose';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';

const messages = defineMessages({
  audiences: {
    id: 'AdditionalQuestions.audiences',
    defaultMessage: 'This question will be shown only to selected audiences',
  },
  logics: {
    id: 'AdditionalQuestions.logics',
    defaultMessage:
      'Upon answering, users may skip to later questions due to the defined jumping logic.',
  },
  [freeResponse]: {
    id: 'EditQuestion.types.free_response',
    defaultMessage: 'Free response',
  },
  [scale]: {
    id: 'EditQuestion.types.scale',
    defaultMessage: 'Scale',
  },
  [selectOne]: {
    id: 'EditQuestion.types.select_one',
    defaultMessage: 'Select one option',
  },
  [selectMany]: {
    id: 'EditQuestion.types.select_many',
    defaultMessage: 'Select many options',
  },
  [contactForm]: {
    id: 'EditQuestion.types.contact_form',
    defaultMessage: 'Contact form',
  },
  [statement]: {
    id: 'EditQuestion.types.statement',
    defaultMessage: 'Statement',
  },
});
const ListQuestions = ({ onAdd, onEdit, onDelete, onMove, onPreview }) => {
  const { formatMessage } = useIntl();
  const [, startHover, stopHover, { hoverIndex }] = useOpenClose({ hoverIndex: -1 });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [hoverRef, hoveredElement] = useHover({ delay: 500, query: 'li' });
  useEffect(() => {
    if (hoveredElement) {
      onPreview(hoveredElement);
    }
  }, [hoveredElement]);
  const additionalQuestions = useSelector(getAdditionalQuestions) || [];
  const onDragEnd = result => {
    // dropped outside the list
    if (result.destination) {
      onMove(result.source.index, result.destination.index);
    }
  };
  const replacements = additionalQuestionsReplacements(additionalQuestions, {
    score: 'score',
    comment: 'comment',
  });
  const openMenu = event => {
    setAnchorEl(event.currentTarget);
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <Box sx={{ width: '100%' }}>
      <Typography sx={{ fontWeight: 'bold' }}>
        <FormattedMessage
          id="AdditionalQuestions.questions"
          defaultMessage="{count} questions"
          values={{ count: additionalQuestions.length }}
        />
      </Typography>
      <List ref={hoverRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="questions-list">
            {provided => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {additionalQuestions.map((question, index) => (
                  <Draggable key={question._id} draggableId={question._id} index={index}>
                    {(provided2, snapshot2) => (
                      <Box
                        sx={{
                          userSelect: 'none',
                          boxShadow: snapshot2.isDragging
                            ? '0 -1px 0 0 #e0e0d9, 0 -1px 0 0 #e0e0d9 inset, 0 5px 20px -8px rgb(0 0 0 / 30%)'
                            : '0 -1px 0 0 #e0e0d9, 0 -1px 0 0 #e0e0d9 inset',
                          ...provided2.draggableProps.style,
                        }}
                        ref={provided2.innerRef}
                        {...provided2.draggableProps}
                      >
                        <ListItem
                          onMouseOver={startHover.bind(null, { hoverIndex: index })}
                          onMouseOut={stopHover}
                          data-index={index}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: 0.5,
                            paddingLeft: 0,
                            '&:hover .flags': {
                              visibility: 'visible',
                            },
                          }}
                          secondaryAction={
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              onClick={() => onDelete(question)}
                            >
                              <Close />
                            </IconButton>
                          }
                          disablePadding
                        >
                          <Box sx={{ display: 'flex' }} {...provided2.dragHandleProps}>
                            {hoverIndex === index ? (
                              <DragIndicator sx={{ color: '#bebeb8' }} />
                            ) : (
                              <QuestionIcon type={question.type} />
                            )}
                            <Typography
                              color="muted.main"
                              sx={{
                                fontWeight: 'bold',
                                marginLeft: 0.5,
                              }}
                            >
                              {`${index + 1}`.padStart(2, '0')}
                            </Typography>
                          </Box>
                          <ListItemButton onClick={() => onEdit(question, index)}>
                            <ListItemText
                              primary={
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                  <Box sx={{maxWidth: '100%'}}>
                                    <Typography
                                      sx={{
                                        fontSize: 12,
                                        color: 'text.secondary',
                                        flex: '0 1 auto',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {replacePlaceholders(question.intro, replacements)}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        flex: '0 1 auto',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                      }}
                                    >
                                      {replacePlaceholders(question.text, replacements)}
                                    </Typography>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      mx: 1,
                                      gap: 1,
                                      visibility: 'hidden',
                                    }}
                                    className="flags"
                                  >
                                    {['audiences', 'logics']
                                      .filter(v => question[v]?.length > 0)
                                      .map(icon => (
                                        <Tooltip title={formatMessage(messages[icon])}>
                                          <Box
                                            key={icon}
                                            sx={{
                                              color: 'muted.light',
                                              display: 'flex',
                                              '& > *': { display: 'flex' },
                                            }}
                                          >
                                            <SVGInline svg={icon === 'logics' ? branch : target} />
                                          </Box>
                                        </Tooltip>
                                      ))}
                                  </Box>
                                </Box>
                              }
                            />
                          </ListItemButton>
                        </ListItem>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </List>
      <Button
        startIcon={<Add />}
        onClick={openMenu}
        id="add-question"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
      >
        <FormattedMessage id="AdditionalQuestions.AddQuestion" defaultMessage="Add a question" />
      </Button>
      <Menu
        open={open}
        onClose={closeMenu}
        anchorEl={anchorEl}
        MenuListProps={{
          'aria-labelledby': 'add-question',
        }}
      >
        {[freeResponse, scale, selectOne, selectMany, statement].map(t => (
          <MenuItem key={t} value={t} onClick={() => onAdd(t)}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <QuestionIcon type={t} />
              <Typography>{formatMessage(messages[t])}</Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

ListQuestions.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
};

export default ListQuestions;
