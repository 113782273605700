import { Add, Check, Settings } from '@mui/icons-material';
import Copy from '@mui/icons-material/ContentCopy';
import {
  Box, Button, TableContainer, Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Grid from '@mui/material/Unstable_Grid2';
import { useCreateLinkMutation, useFindLinksQuery } from 'client/modules/Surveys/linkApiSlice';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import getLink from 'client/modules/Surveys/pages/LinkPage/getLink';
import { getSurvey, surveyMessages } from 'client/modules/Surveys/SurveyReducer';
import copyText from 'client/util/copyText';
import Layout from 'components/Layout/Layout';
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  Link, useLocation, useNavigate, useParams,
} from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styles from './SharePage.css';

const messages = defineMessages({
  saveChanges: {
    id: 'SharePage.saveChanges',
    defaultMessage: 'Save changes',
  },
  title: {
    id: 'SharePage.title',
    defaultMessage: 'Share links',
  },
  chooseADifferentChannel: {
    id: 'Survey.ChooseChannel',
    defaultMessage: 'Choose a different channel',
  },
  name: {
    id: 'SharePage.name',
    defaultMessage: 'Name',
  },
});
const SharePage = () => {
  const [copied, setCopied] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const survey = useSelector(state => getSurvey(state, id));
  const { formatMessage } = useIntl();
  const returnLinkTo = `/surveys/${id}/import`;
  const returnLinkText = formatMessage(surveyMessages.chooseADifferentChannel);
  const { data, isSuccess } = useFindLinksQuery(id);
  const [createLink] = useCreateLinkMutation();
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess && data.length === 0 && location.state?.create) {
      createLink({ survey: id, name: survey.name });
      navigate('', { state: null, replace: true });
    }
  }, [isSuccess, location.state]);
  const handleCreate = async () => {
    navigate('new');
  };
  const onCopy = useCallback(link => () => {
    copyText(link).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }).catch((e) => {
      console.log('failed to copy', e);
    });
  }, []);
  return (
    <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText} secondaryNav={<SecondaryNavTabs survey={id} />}>
      <Helmet title={formatMessage(messages.title)} />
      <Grid className={styles.mainGrid} container>
        <Grid item xs={12} className={styles.mainContent}>
          <h1 className={styles.mainTitle}>
            {formatMessage(messages.title)}
          </h1>
          <Typography>
            <FormattedMessage
              id="SharePage.description"
              defaultMessage="Share your survey in emails, on your website, social media, and more."
            />
          </Typography>
          <TableContainer component={Paper} variant="outlined" sx={{ mt: 3 }}>
            <Table size="small">
              <TableHeader sx={{ backgroundColor: 'background.body' }}>
                <TableRow>
                  <TableCell>
                    {formatMessage(messages.name)}
                  </TableCell>
                  <TableCell>
                    URL
                  </TableCell>
                  <TableCell align="right">
                    <Button variant="text" onClick={handleCreate} startIcon={<Add />}>
                      <FormattedMessage id="SharePage.createNewLink" defaultMessage="Create new Link" />
                    </Button>
                  </TableCell>
                </TableRow>
              </TableHeader>
              <TableBody sx={{ '& .MuiTableRow-root:last-of-type .MuiTableCell-root': { borderBottom: 0 } }}>
                {data?.map((row) => {
                  const link = getLink(row);
                  return (
                    <TableRow key={row._id}>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        <Box className={styles.link}>
                          <Box component="span" sx={{ verticalAlign: 'bottom' }}>{link}</Box>
                          <IconButton onClick={onCopy(link)} className={styles.copyButton}>
                            <SwitchTransition>
                              <CSSTransition
                                timeout={200}
                                key={copied}
                                classNames={{
                                  enter: styles.copyButtonEnter,
                                  enterActive: styles.copyButtonEnterActive,
                                  exit: styles.copyButtonExit,
                                  exitActive: styles.copyButtonExitActive,
                                }}
                              >
                                {copied ? <Check color="success" /> : <Copy />}
                              </CSSTransition>
                            </SwitchTransition>
                          </IconButton>
                        </Box>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton component={Link} to={row._id}>
                          <Settings />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default SharePage;
