import { connect } from 'react-redux';

import HomePage from './HomePage';
import { isLoggedIn } from '../../../Auth/AuthReducer';
import { getI18n } from '../../../../AppReducer';

const mapStateToProps = (state) => {
  return {
    isLoggedIn: isLoggedIn(state),
    locale: getI18n(state).locale,
  };
};

const HomePageContainer = connect(
  mapStateToProps
)(HomePage);

export default HomePageContainer;
