import { RefinementListFilter } from 'searchkit';

import FilteredFacetAccessor from './FilteredFacetAccessor';

class FilteredRefinementList extends RefinementListFilter {
  hasOptions() {
    return true;
  }

  getAccessorOptions() {
    const ret = super.getAccessorOptions();
    ret.filter = this.props.filter;
    return ret;
  }

  defineAccessor() {
    return new FilteredFacetAccessor(
      this.props.id, this.getAccessorOptions()
    );
  }
}

export default FilteredRefinementList;
