import {
  Box, Button, Checkbox, FormControl, FormControlLabel, Input, InputLabel, ListItemText,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { getToken } from 'client/modules/Auth/AuthReducer';
import { fetchSurveys as fetchSurveysAction } from 'client/modules/Surveys/SurveyActions';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import FlatButton from 'components/FlatButton/FlatButton';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { surveyLanguages } from '../../../../../Intl/setup';
import RadioBox from '../../../App/components/RadioBox/RadioBox';
import mailgunLogo from '../../images/mailgun.svg';

import sendgridLogo from '../../images/sendgrid.svg';

import styles from './MailProviderPage.css';

const messages = defineMessages({
  title: {
    id: 'MailProvider.Title',
    defaultMessage: 'Mail provider settings',
  },
  mailProviderType: {
    id: 'MailProvider.MailProviderTypeLabel',
    defaultMessage: 'Mail provider',
  },
  mailProviderApiKey: {
    id: 'MailProvider.MailProviderApiKeyLabel',
    defaultMessage: '{provider} API Key',
  },
  mailProviderDomain: {
    id: 'MailProvider.mailProviderDomainLabel',
    defaultMessage: 'Sender email domain',
  },
  save: {
    id: 'MailProvider.Save',
    defaultMessage: 'Save',
  },
  remove: {
    id: 'MailProvider.RemoveIntegration',
    defaultMessage: 'Remove integration',
  },
  useOwnMailProvider: {
    id: 'MailProvider.UseOwnMailProvider',
    defaultMessage: 'Use my own mail provider',
  },
  apiKey: {
    id: 'MailProvider.ApiKey',
    defaultMessage: 'API Key',
  },
  customDomain: {
    id: 'MailProvider.CustomDomain',
    defaultMessage: 'Custom email domain',
  },
  fromEmail: {
    id: 'MailProvider.FromEmail',
    defaultMessage: 'From email',
  },
  surveys: {
    id: 'MailProvider.Surveys',
    defaultMessage: 'Surveys',
  },
  languages: {
    id: 'MailProvider.Languages',
    defaultMessage: 'Languages',
  },
  all: {
    id: 'MailProvider.All',
    defaultMessage: 'All',
  },
  addRule: {
    id: 'MailProvider.AddRule',
    defaultMessage: 'Add rule',
  },
  APIKeyNoMailSend: {
    id: 'Settings.Errors.APIKeyNoMailSend',
    defaultMessage: 'The API Key you provided does not have mail sending privileges. Please enable Mail Send full access to it.',
  },
  InvalidAPIKey: {
    id: 'Settings.Errors.InvalidAPIKey',
    defaultMessage: 'The API Key you provided is not valid.',
  },
  APIKeyRequired: {
    id: 'Settings.Errors.APIKeyRequired',
    defaultMessage: 'The API Key is required.',
  }
});

const MailProviderPage = (props) => {
  const [apiKeyError, setApiKeyError] = useState('');
  const { formatMessage, locale } = props.intl;
  const surveys = useSelector(getSurveys);
  const token = useSelector(getToken);
  const [loaded, setLoaded] = useState(false);
  const fetchSurveys = useActions(fetchSurveysAction);
  const [rules, setRules] = useState(props.customMailService.rules || []);

  const setValue = (index, key) => (event, elem) => {
    setRules(rs => (rs.map((r, i) => {
      if (index !== i) {
        return r;
      }
      let { value } = event.target;
      if (key !== 'fromEmail' && elem.props?.value === 'all') {
        value = !r[key] ? [] : null;
      }
      return { ...r, [key]: value };
    })));
  };

  const addRule = () => {
    setRules(rs => ([...rs, { fromEmail: '', surveys: null, languages: null }]));
  };

  useEffect(() => {
    if (!surveys && !loaded) {
      setLoaded(true);
      fetchSurveys(token);
    }
  }, [surveys, loaded]);

  const getErrorMessage = (err) => {
    const key = err ? err.message : null;
    const defaultErr = (
      <FormattedMessage
        id="Settings.Errors.Unknown"
        defaultMessage="An unknown error occurred while validating your API key."
      />
    );
    switch (key) {
      case 'API Key without mail sending scope':
        setApiKeyError(formatMessage(messages.APIKeyNoMailSend));
        return (formatMessage(messages.APIKeyNoMailSend));
      case 'API Key is required':
        setApiKeyError(formatMessage(messages.APIKeyRequired));
        return formatMessage(messages.APIKeyRequired);
      case 'Invalid API Key':
      case 'Invalid private key':
        setApiKeyError(formatMessage(messages.InvalidAPIKey));
        return formatMessage(messages.InvalidAPIKey);
      case 'Domain not found':
        return (
          <FormattedMessage
            id="Settings.Errors.DomainNotFound"
            defaultMessage="You don't have permissions to send using the given domain name."
          />
        );
      case 'From email domain does not match':
        return (
          <FormattedMessage
            id="Settings.Errors.FromEmailMatch"
            defaultMessage="The from email domain must match your email sending domain."
          />
        );
      case 'OK':
        return (
          <FormattedMessage
            id="Settings.Messages.MailProviderPreferencesSaved"
            defaultMessage="Your mail provider preferences have been saved successfully."
          />
        );
      default:
        return defaultErr;
    }
  };

  const handleSetSettings = (event, overrides = {}) => {
    const { useCustomMailService, customMailService } = { ...props, ...overrides };
    if (customMailService && !customMailService.serviceType) {
      customMailService.serviceType = 'SENDGRID';
    }
    customMailService.rules = rules;
    if (customMailService && !customMailService.apiKey) {
      props.setError(getErrorMessage({ message: 'API Key is required' }));
    } else {
      props.onSetSettings(props.token, {
        useCustomMailService,
        customMailService,
      }, getErrorMessage);
    }
  };

  const handleToggleCustomMail = (event) => {
    props.onHandleToggleCustomMail(event.target.checked);
    if (!event.target.checked) {
      handleSetSettings(null, { useCustomMailService: false });
    }
  };

  const handleApiKeyChange = (event) => {
    props.onMailServiceApiKeyInputChange(event);
    setApiKeyError(event.target.value ? '' : getErrorMessage({ message: 'API Key is required' }));
  };

  return (
    <Paper className={styles.form} style={{ padding: '2em' }}>
      <Helmet title={formatMessage(messages.title)} />
      <div>
        <p>
          <FormattedMessage
            id="MailProviderSettings.YouCanUseYour"
            defaultMessage="When sending the emails you can use your own SendGrid or Mailgun account. All you need to do is get an API key and store it below."
          />
        </p>
        <FormControlLabel
          classes={{ root: styles.formControlLabel, label: styles.label }}
          labelPlacement="start"
          control={(
            <Switch
              color="primary"
              checked={props.useCustomMailService}
              onChange={handleToggleCustomMail}
            />
          )}
          label={formatMessage(messages.useOwnMailProvider)}
        />

      </div>
      {props.useCustomMailService
        ? (
          <div>
            <RadioGroup
              labelPosition="left"
              className={styles.radioGroup}
              name="mailProviderType"
              onChange={props.onMailServiceTypeInputChange}
              defaultSelected="SENDGRID"
              valueSelected={props.customMailService.serviceType}
            >
              <FormControlLabel
                value="SENDGRID"
                className={styles.radio}
                control={(<Radio classes={{ root: styles.icon }} />)}
                label={(
                  <RadioBox
                    name="SendGrid"
                    logo={(
                      <SVGInline
                        svg={sendgridLogo}
                        style={{ width: '50px', height: '50px', display: 'inline-block' }}
                      />
                    )}
                    selected={!props.customMailService.serviceType || props.customMailService.serviceType === 'SENDGRID'}
                  />
                )}
              />
              <FormControlLabel
                value="MAILGUN"
                className={styles.radio}
                control={(<Radio classes={{ root: styles.icon }} />)}
                label={(
                  <RadioBox
                    name="Mailgun"
                    logo={(
                      <SVGInline
                        svg={mailgunLogo}
                        style={{ width: '50px', height: '50px', display: 'inline-block' }}
                      />
                    )}
                    selected={props.customMailService.serviceType === 'MAILGUN'}
                  />
                )}
              />
            </RadioGroup>
            <TextField
              variant="standard"
              id="apiKey"
              name="apiKey"
              className={styles.long}
              value={props.customMailService.apiKey}
              onChange={handleApiKeyChange}
              label={formatMessage(messages.apiKey)}
              helperText={apiKeyError}
              error={!!apiKeyError}
            />
            <div className={styles.emailDomain}>
              <FormattedMessage
                id="MailProviderSettings.CustomMailProviderHelp"
                defaultMessage="When using your own mail provider you should use your own email sending domain."
              />
            </div>
            <TextField
              variant="standard"
              id="emailDomain"
              name="emailDomain"
              className={styles.long}
              value={props.customMailService.domain}
              onChange={props.onMailServiceDomainInputChange}
              label={formatMessage(messages.customDomain)}
            />
            <TextField
              variant="standard"
              id="fromEmail"
              name="fromEmail"
              className={styles.long}
              value={props.customMailService.fromEmail}
              onChange={props.onMailServiceFromEmailInputChange}
              label={formatMessage(messages.fromEmail)}
            />
            <Box sx={{ mt: 4 }}>
              <FormattedMessage
                id="MailProviderSettings.CustomSenderRules"
                defaultMessage="You can customize the sender email address depending on the survey and/or language by adding rules below."
              />
            </Box>
            <div>
              {rules.map((rule, index) => {
                const surveysValue = rule.surveys === null ? [...surveys?.map(s => s.id), 'all'] : rule.surveys;
                const languagesValue = rule.languages === null ? [...Object.keys(surveyLanguages[locale]).map(s => s), 'all'] : rule.languages;
                return (
                  <div key={rule._id} className={styles.rule}>
                    <div className={styles.ruleInput}>
                      <div className={styles.inputIcon}>
                        <TextField
                          variant="standard"
                          className={styles.input}
                          fullWidth
                          name="fromEmail"
                          value={rule.fromEmail}
                          inputStyle={{ paddingLeft: '1.25em' }}
                          onChange={setValue(index, 'fromEmail')}
                          label={formatMessage(messages.fromEmail)}
                        />
                      </div>
                    </div>
                    <FormControl variant="standard" className={styles.ruleInput}>
                      <InputLabel htmlFor={`select-survey-${rule._id}`}>{formatMessage(messages.surveys)}</InputLabel>
                      <Select
                        variant="standard"
                        className={styles.input}
                        id={`select-survey-${rule._id}`}
                        multiple
                        hintText={formatMessage(messages.surveys)}
                        value={surveysValue}
                        onChange={setValue(index, 'surveys')}
                        autoWidth
                        renderValue={values => (values.length > surveys?.length ? formatMessage(messages.all) : values.map(v => (surveys?.find(s => s.id === v) || {}).name)
                          .filter(v => v)
                          .join(', '))}
                      >
                        <MenuItem
                          insetChildren
                          checked={!rule.surveys}
                          value="all"
                          id="all-surveys"
                          className={styles.all}
                        >
                          <Checkbox checked={!rule.surveys} />
                          {formatMessage(messages.all)}
                        </MenuItem>
                        {surveys?.map(s => (
                          (
                            <MenuItem
                              insetChildren
                              key={s.name}
                              checked={!rule.surveys || (rule.surveys.includes(s.id))}
                              value={s.id}
                            >
                              <Checkbox checked={!rule.surveys || (rule.surveys.includes(s.id))} />
                              <ListItemText primary={s.name} />
                            </MenuItem>
                          )
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl variant="standard" className={styles.ruleInput}>
                      <InputLabel htmlFor={`select-group-${rule._id}`} shrink>{formatMessage(messages.languages)}</InputLabel>
                      <Select
                        variant="standard"
                        multiple
                        className={styles.input}
                        input={<Input id={`select-group-${rule._id}`} />}
                        fullWidth
                        hintText={formatMessage(messages.languages)}
                        value={languagesValue}
                        onChange={setValue(index, 'languages')}
                        autoWidth
                        renderValue={(values) => {
                          return (values.length > Object.keys(surveyLanguages[locale]).length ? formatMessage(messages.all) : values.map(v => surveyLanguages[locale][v])
                            .filter(v => v)
                            .join(', '));
                        }}
                      >
                        <MenuItem
                          insetChildren
                          checked={!rule.surveys}
                          value="all"
                          id="all-languages"
                          className={styles.all}
                        >
                          <Checkbox checked={!rule.languages} />
                          {formatMessage(messages.all)}
                        </MenuItem>
                        {Object.keys(surveyLanguages[locale]).map(lang => (
                          (
                            <MenuItem
                              insetChildren
                              key={lang}
                              value={lang}
                              disableRipple
                            >
                              <Checkbox checked={!rule.languages || (rule.languages.includes(lang))} />
                              {surveyLanguages[locale][lang]}
                            </MenuItem>
                          )
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                );
              })}
              <FlatButton
                primary
                label={formatMessage(messages.addRule)}
                onClick={addRule}
              />
            </div>

            <div className={styles.actions}>
              <Button variant="contained" color="primary" className={styles.button} onClick={handleSetSettings}>
                <FormattedMessage
                  id="Settings.Save"
                  defaultMessage="Save"
                />
              </Button>
            </div>
          </div>
        )
        : null
      }
    </Paper>
  );
};

MailProviderPage.propTypes = {
  intl: PropTypes.object.isRequired,
  onSetSettings: PropTypes.func.isRequired,
  onMailServiceTypeInputChange: PropTypes.func.isRequired,
  onMailServiceApiKeyInputChange: PropTypes.func.isRequired,
  onMailServiceDomainInputChange: PropTypes.func.isRequired,
  onMailServiceFromEmailInputChange: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
  customMailService: PropTypes.object.isRequired,
  useCustomMailService: PropTypes.bool.isRequired,
  onHandleToggleCustomMail: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default injectIntl(MailProviderPage);
