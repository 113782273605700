// Export Constants
export const SET_LOCALE = 'SET_LOCALE';
export const TOGGLE_USER_MENU = 'TOGGLE_USER_MENU';

// Export Actions
export function setLocale(locale) {
  return {
    type: SET_LOCALE,
    locale,
  };
}

export function toggleUserMenu(open) {
  return {
    type: TOGGLE_USER_MENU,
    open,
  };
}
