const boolMatch = (s, inMatchers) => {
  let i;
  let matcher;
  const down = s.toLowerCase();
  const matchers = [].concat(inMatchers);
  for (i = 0; i < matchers.length; i += 1) {
    matcher = matchers[i];
    if (matcher) {
      if (matcher.test && matcher.test(s)) return true;
      if (matcher.toLowerCase() === down) return true;
    }
  }
  return undefined;
};

const toBoolean = (inStr, defaultValue = false, trueValues, falseValues) => {
  let str = inStr;
  if (typeof str === 'undefined' || str === null) {
    str = defaultValue;
  }
  if (typeof str === 'number') str = `${str}`;
  if (typeof str !== 'string') return !!str;
  str = str.trim();
  if (boolMatch(str, trueValues || ['true', '1'])) return true;
  if (boolMatch(str, falseValues || ['false', '0'])) return false;
  return undefined;
};

module.exports = toBoolean;
