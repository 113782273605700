import React from 'react';
import Icon from 'react-icon-base';

const Stars = props => (
  <Icon viewBox="0 0 199 36" {...props}>
    <g>
      <path
        d="M19.2,1.9l4.4,8.9A2.2,2.2,0,0,0,25.2,12L35,13.4a.9.9,0,0,1,.5,1.6l-7,6.9a2,2,0,0,0-.7,1.9l1.7,9.7a1,1,0,0,1-1.4,1l-8.7-4.6a2.3,2.3,0,0,0-2,0L8.6,34.5a.9.9,0,0,1-1.3-1L9,23.8a2,2,0,0,0-.7-1.9L1.3,15a.9.9,0,0,1,.5-1.6L11.6,12a2.2,2.2,0,0,0,1.6-1.2l4.4-8.9A.9.9,0,0,1,19.2,1.9Z"
      />
      <path
        d="M59.8,1.9l4.3,8.9A2.6,2.6,0,0,0,65.8,12l9.8,1.4a1,1,0,0,1,.5,1.6L69,21.9a2.2,2.2,0,0,0-.6,1.9L70,33.5a.9.9,0,0,1-1.3,1L60,29.9a2.6,2.6,0,0,0-2.1,0l-8.7,4.6a.9.9,0,0,1-1.3-1l1.6-9.7a2.2,2.2,0,0,0-.6-1.9L41.8,15a1,1,0,0,1,.5-1.6L52.1,12a2.6,2.6,0,0,0,1.7-1.2l4.3-8.9A1,1,0,0,1,59.8,1.9Z"
      />
      <path
        d="M100.3,1.9l4.4,8.9a2.2,2.2,0,0,0,1.6,1.2l9.8,1.4a.9.9,0,0,1,.5,1.6l-7,6.9a2,2,0,0,0-.7,1.9l1.7,9.7a1,1,0,0,1-1.4,1l-8.7-4.6a2.3,2.3,0,0,0-2,0l-8.7,4.6a1,1,0,0,1-1.4-1l1.7-9.7a2,2,0,0,0-.7-1.9l-7-6.9a.9.9,0,0,1,.5-1.6L92.7,12a2.2,2.2,0,0,0,1.6-1.2l4.4-8.9A.9.9,0,0,1,100.3,1.9Z"
      />
      <path
        d="M140.9,1.9l4.3,8.9a2.6,2.6,0,0,0,1.7,1.2l9.8,1.4a1,1,0,0,1,.5,1.6l-7.1,6.9a2.2,2.2,0,0,0-.6,1.9l1.6,9.7a.9.9,0,0,1-1.3,1l-8.7-4.6a2.6,2.6,0,0,0-2.1,0l-8.7,4.6a.9.9,0,0,1-1.3-1l1.6-9.7a2.2,2.2,0,0,0-.6-1.9L122.9,15a1,1,0,0,1,.5-1.6l9.8-1.4a2.6,2.6,0,0,0,1.7-1.2l4.3-8.9A1,1,0,0,1,140.9,1.9Z"
      />
      <path
        d="M181.4,1.9l4.4,8.9a2.2,2.2,0,0,0,1.6,1.2l9.8,1.4a.9.9,0,0,1,.5,1.6l-7,6.9a2,2,0,0,0-.7,1.9l1.7,9.7a.9.9,0,0,1-1.3,1l-8.8-4.6a2.3,2.3,0,0,0-2,0l-8.7,4.6a1,1,0,0,1-1.4-1l1.7-9.7a2,2,0,0,0-.7-1.9l-7-6.9a.9.9,0,0,1,.5-1.6l9.8-1.4a2.2,2.2,0,0,0,1.6-1.2l4.4-8.9A.9.9,0,0,1,181.4,1.9Z"
      />
    </g>
  </Icon>
);

export default Stars;
