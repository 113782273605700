import { Add, Close } from '@mui/icons-material';
import RemoveCircleOutline from '@mui/icons-material/RemoveCircleOutline';
import {
  Box, Button, FormControl, IconButton, InputLabel, Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { darken } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { getId, getSurveySelector } from 'client/modules/Surveys/SurveyReducer';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import classes from './styles.css';

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    border: '1px solid #e0e0d9',
    borderRadius: 2,
    p: 2,
    alignItems: 'baseline',
    pr: 3,
  },
  condition: showValue => ({
    display: 'grid',
    gap: '0 0.5rem',
    gridTemplateColumns: showValue ? '35% 15% 35% min-content' : '35% auto min-content',
    '&  .MuiSelect-select': {
      p: 1,
    },
    width: '100%',
  }),
  remove: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translate(50%, -50%)',
    backgroundColor: 'background.paper',
    '&:hover': {
      backgroundColor: theme => darken(theme.palette.background.body, 0.05),
    },
  },
  button: {
    textTransform: 'none',
  },
  select: {
    width: '100%',
    maxWidth: '100%',
  },
};

const messages = defineMessages({
  score: {
    id: 'AudienceCard.Property.score',
    defaultMessage: 'Score',
  },
  group: {
    id: 'AudienceCard.Property.group',
    defaultMessage: 'Group',
  },
  standard: {
    id: 'AudienceCard.Group.Standard',
    defaultMessage: 'Standard',
  },
  custom: {
    id: 'AudienceCard.Group.Custom',
    defaultMessage: 'Custom',
  },
});

const AudienceCard = ({ index, remove: removeMe }) => {
  const { formatMessage } = useIntl();
  const name = `audiences.${index}.conditions`;
  const {
    fields, append, remove,
  } = useFieldArray({
    name,
  });
  const { watch } = useFormContext();
  const surveyId = useSelector(getId);
  const survey = useSelector(getSurveySelector(surveyId));
  const properties = Object.entries(survey.person_properties_types||{})
    .filter(([, p]) => !p.hidden && p.type === 'terms')
    .map(([k]) => k)
    .sort((a, b) => a.localeCompare(b));
  properties.unshift('_standard.score', '_standard.group');
  return (
    <>
      {index > 0 && (
        <Typography color="muted.main" variant="caption" sx={{ alignSelf: 'center' }}>
          <FormattedMessage id="AudienceCard.Or" defaultMessage="Or" />
        </Typography>
      )}
      <Box sx={styles.root}>
        {fields.map((field, index2) => {
          const showValue = !['has_any_value', 'is_empty'].includes(watch(`audiences.${index}.conditions.${index2}.operator`));
          return (
            <>
              {index2 > 0 && (
                <Typography color="muted.main" variant="caption" sx={{ alignSelf: 'center', marginBottom: -1.5 }}>
                  <FormattedMessage id="AudienceCard.And" defaultMessage="And" />
                </Typography>
              )}
              <Box key={field.id} sx={styles.condition(showValue)}>
                <Controller
                  render={
                    ({ field: { onChange, value: fieldValue } }) => (
                      <Autocomplete
                        freeSolo
                        defaultValue={fieldValue}
                        onInputChange={(event, value) => {
                          if (event) onChange({ target: { name: event.target.name, value }, type: event.type });
                        }}
                        onChange={(event, value) => {
                          onChange({ target: { name: event.target.name, value }, type: event.type });
                        }}
                        groupBy={o => (o.startsWith('_standard.')
                          ? formatMessage(messages.standard)
                          : formatMessage(messages.custom)
                        )}
                        getOptionLabel={(option) => {
                          const [prop, standard] = option.split('_standard.');
                          return standard ? formatMessage(messages[standard]) : prop;
                        }}
                        classes={{ root: classes.root, input: classes.input, popper: classes.popper }}
                        fullWidth
                        disableClearable
                        options={properties}
                        sx={styles.select}
                        renderInput={
                          params => (
                            <TextField
                              {...params}
                              label={
                                <FormattedMessage id="AudienceCard.Field" defaultMessage="Field" />
                              }
                            />
                          )
                        }
                      />
                    )
                  }
                  name={`${name}.${index2}.field`}
                />
                <Controller
                  render={
                    ({ field: { onChange, value, name: fieldName } }) => (
                      <FormControl fullWidth sx={{ minWidth: 0 }}>
                        <InputLabel id={fieldName} />
                        <Select
                          classes={{ select: classes.select }}
                          labelId={fieldName}
                          sx={styles.select}
                          value={value}
                          onChange={onChange}
                        >
                          <MenuItem value="in">
                            <FormattedMessage id="AudienceCard.in" defaultMessage="is" />
                          </MenuItem>
                          <MenuItem value="not_in">
                            <FormattedMessage id="AudienceCard.notIn" defaultMessage="not" />
                          </MenuItem>
                          <MenuItem value="has_any_value">
                            <FormattedMessage id="AudienceCard.hasAnyValue" defaultMessage="has any value" />
                          </MenuItem>
                          <MenuItem value="is_empty">
                            <FormattedMessage id="AudienceCard.isEmpty" defaultMessage="is empty" />
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )
                  }
                  name={`${name}.${index2}.operator`}
                />

                {showValue && (
                  <Controller
                    render={
                      ({ field: { onChange, value: fieldValue } }) => {
                        const valueOptions = [];
                        return (
                          <Autocomplete
                            multiple
                            freeSolo
                            ChipProps={{
                              size: 'small',
                              sx: { height: 18, margin: '2.5px !important', borderRadius: 1 },
                              deleteIcon: <Close />,
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                e.stopPropagation();
                              }
                            }}
                            value={[...fieldValue]}
                            onBlur={(event) => {
                              const val = event.target.value;
                              if (val) {
                                // eslint-disable-next-line no-param-reassign
                                event.target.value = '';
                                onChange({ target: { name: event.target.name, value: [...fieldValue, val] }, type: event.type });
                              }
                            }}
                            clearOnBlur
                            onChange={(event, value) => {
                              onChange({ target: { name: event.target.name, value }, type: event.type });
                            }}
                            classes={{
                              root: classes.root,
                              inputRoot: classes.inputRoot,
                              popper: classes.popper,
                            }}
                            fullWidth
                            disableClearable
                            options={valueOptions}
                            sx={styles.select}
                            renderInput={
                              params => (
                                <TextField
                                  {...params}
                                  label={
                                    <FormattedMessage id="AudienceCard.Value" defaultMessage="Value" />
                                  }
                                />
                              )
                            }
                          />
                        );
                      }
                    }
                    name={`${name}.${index2}.value`}
                  />

                )}
                <IconButton
                  sx={{
                    transform: 'translateY(calc(-50% + 7px))', top: '50%', height: 40, visibility: index2 === 0 ? 'hidden' : 'visible',
                  }}
                  onClick={() => remove(index2)}
                >
                  <Close />
                </IconButton>
              </Box>
            </>
          );
        })}
        <Button sx={styles.button} startIcon={<Add />} onClick={() => append({ operator: '', value: [], field: '' })}>
          <FormattedMessage
            id="AudienceCard.AddCondition"
            defaultMessage="Add condition"
          />
        </Button>
        <IconButton sx={styles.remove} onClick={removeMe}>
          <RemoveCircleOutline />
        </IconButton>
      </Box>
    </>
  );
};

AudienceCard.propTypes = {
  index: PropTypes.number.isRequired,
  remove: PropTypes.func.isRequired,
};

export default AudienceCard;
