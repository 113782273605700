import RaisedButton from 'components/RaisedButton/RaisedButton';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './TrialExpired.css';

const messages = defineMessages({
  choosePlan: {
    id: 'TrialExpired.Button.ChoosePlan',
    defaultMessage: 'Choose a plan',
  },
});

const TrialExpired = (props) => {
  const { formatMessage } = props.intl;

  return (
    <Grid fluid>
      <Row>
        <Col sm={9} xs={12}>
          <h1><FormattedMessage id="Import.Errors.TrialFinished" defaultMessage="Your trial is finished" /></h1>
          <p>
            <FormattedMessage
              id="Import.Errors.TrialFinishedInfo"
              defaultMessage="You can still see your responses, but you'll need to choose a plan to survey more people"
            />
          </p>
        </Col>
        <Col sm={3} xs={12} className={styles.msgButton}>
          <RaisedButton
            label={formatMessage(messages.choosePlan)}
            containerElement={<Link to="/pricing" />}
            primary
          />
        </Col>
      </Row>
    </Grid>
  );
};

TrialExpired.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(TrialExpired);
