import { Button } from '@mui/material';
import React from 'react';
import styles from './RaisedButton.css';

const RaisedButton = ({
  label, primary, icon, children, ...props // eslint-disable-line react/prop-types
}) => (
  <Button variant="contained" color={primary ? 'primary' : 'secondary'} {...props}>
    {icon ? <div className={styles.leftIcon}>{icon}</div> : null}
    {label}
    {children}
  </Button>
);

export default RaisedButton;
