import { createAction } from 'redux-actions';
import callApi from '../../util/apiCaller';

// eslint-disable-next-line import/prefer-default-export
export const unsubscribeSuccess = createAction('UNSUBSCRIBE_SUCCESS');
export const unsubscribeFailure = createAction('UNSUBSCRIBE_FAILURE');


export function unsubscribe(token) {
  return (dispatch) => {
    return callApi(dispatch, `people/${token}/unsubscribe`, null, 'get')
      .then((res) => {
        const {
          language, brand, topColor, buttonColor, logoUrl,
        } = res;
        dispatch(unsubscribeSuccess({
          person: {
            language, brand, topColor, buttonColor, logoUrl,
          },
        }));
        return true;
      })
      .catch((err) => {
        dispatch(unsubscribeFailure(err));
        return false;
      });
  };
}


export function unsubscribeFromLink(linkId, email) {
  return (dispatch) => {
    return callApi(dispatch, `link/${linkId}/unsubscribe`, null, 'post', { email })
      .then((res) => {
        const {
          language, brand, topColor, buttonColor, logoUrl,
        } = res;
        dispatch(unsubscribeSuccess({
          person: {
            language, brand, topColor, buttonColor, logoUrl,
          },
        }));
        return true;
      })
      .catch((err) => {
        dispatch(unsubscribeFailure(err));
        return false;
      });
  };
}
