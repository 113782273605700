import {
  CardinalityMetric, FacetAccessor, FilterBucket, TermsBucket,
} from 'searchkit';

import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

class FilteredFacetAccessor extends FacetAccessor {
  buildOwnQuery(query) {
    if (!this.loadAggregations) {
      return query;
    }
    const excludedKey = (this.isOrOperator()) ? this.uuid : undefined;
    const q = query.addFilter(`${this.uuid}-filter`, this.options.filter);
    q.buildQuery();
    return query
      .setAggs(FilterBucket(
        this.uuid,
        q.getFiltersWithoutKeys(excludedKey),
        ...this.fieldContext.wrapAggregations(
          TermsBucket(this.options.field, this.options.field, omitBy({
            size: this.size,
            order: this.getOrder(),
            include: this.options.include,
            exclude: this.options.exclude,
            min_doc_count: this.options.min_doc_count,
          }, isUndefined)),
          CardinalityMetric(`${this.options.field}_count`, this.options.field)
        )
      ));
  }
}

export default FilteredFacetAccessor;
