import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import debounce from 'client/util/debounce';
import setMuiComponentAndMaybeFocus from 'client/util/setMuiComponentAndMaybeFocus';
import createClass from 'create-react-class';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React from 'react';

const FormsySwitch = createClass({
  propTypes: {
    convertValue: PropTypes.func,
    defaultValue: PropTypes.any,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
    requiredError: PropTypes.string,
    underlineFocusStyle: PropTypes.object,
    underlineStyle: PropTypes.object,
    updateImmediately: PropTypes.bool,
    validationColor: PropTypes.string,
    validationError: PropTypes.string,
    validationErrors: PropTypes.object,
    validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    value: PropTypes.any,
  },
  mixins: [Formsy.Mixin],
  getDefaultProps() {
    return {
      underlineFocusStyle: {},
      underlineStyle: {},
      validationColor: '#4CAF50',
    };
  },
  getInitialState() {
    const value = this.controlledValue();
    return { value };
  },

  componentWillMount() {
    this.setValue(this.controlledValue());
  },

  componentWillReceiveProps(nextProps) {
    const isValueChanging = nextProps.value !== this.props.value;
    if (isValueChanging || nextProps.defaultValue !== this.props.defaultValue) {
      const value = this.controlledValue(nextProps);
      const isValid = this.isValidValue(value);

      if (isValueChanging || this.props.defaultValue === this.getValue()) {
        this.setState({ value, isValid });
        if (this.getValue() !== value) this.setValue(value);
      }
    }
  },

  componentWillUpdate(nextProps, nextState) {
    if (
      nextState._isPristine // eslint-disable-line no-underscore-dangle
      && nextState._isPristine !== this.state._isPristine
    ) {
      // eslint-disable-line no-underscore-dangle
      // Calling state here is valid, as it cannot cause infinite recursion.
      const value = this.controlledValue(nextProps);
      const isValid = this.isValidValue(value);
      this.setValue(value);
      this.setState({ value, isValid });
    }
  },

  controlledValue(props = this.props) {
    return props.value || props.defaultValue || this.convertValue(false);
  },

  handleChange(event) {
    this.setValue(this.convertValue(event.currentTarget.checked));

    // Controlled component
    this.setState({
      value: event.currentTarget.checked,
      isValid: this.isValidValue(event.currentTarget.checked),
    });
    if (this.props.onChange) this.props.onChange(event, event.currentTarget.checked);
  },

  convertValue(value) {
    if (this.props.convertValue) {
      return this.props.convertValue(value);
    }
    return value;
  },

  setMuiComponentAndMaybeFocus,

  render() {
    const {
      defaultValue, // eslint-disable-line no-unused-vars
      convertValue, // eslint-disable-line no-unused-vars
      requiredError,
      underlineFocusStyle,
      underlineStyle,
      updateImmediately, // eslint-disable-line no-unused-vars
      validations, // eslint-disable-line no-unused-vars
      validationError, // eslint-disable-line no-unused-vars
      validationErrors, // eslint-disable-line no-unused-vars
      value, // eslint-disable-line no-unused-vars
      validationColor,
      sx,
      label,
      ...rest
    } = this.props;

    const {
      isRequired, isPristine, isValid, isFormSubmitted,
    } = this;
    const isRequiredError = isRequired() && !isPristine() && !isValid() && isFormSubmitted() && requiredError;
    const errorText = this.getErrorMessage() || isRequiredError;

    return (
      <FormControlLabel
        sx={{
          ml: 0,
          mt: 2,
          width: '100%',
          justifyContent: 'space-between',
          ...sx,
        }}
        label={label}
        labelPlacement="start"
        error={!!errorText}
        helperText={errorText}
        control={(
          <Switch
            color="primary"
            {...rest}
            disabled={this.isFormDisabled()}
            checked={this.state.value}
            onChange={this.handleChange}
            ref={this.setMuiComponentAndMaybeFocus}
          />
        )}
      />
    );
  },
});

export default FormsySwitch;
