import React from 'react';

import styles from './Typing.css';

const Typing = () => {
  return (
    <svg
      version="1.1" className={styles.loader} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px"
      y="0px"
      viewBox="-49.5 -1.8 129 74.5" style={{ 'enable-background': 'new -49.5 -1.8 129 84.5' }} xmlSpace="preserve"
    >
      <path
        style={{ fill: '#E9E9E9' }} d="M42.4,73.3H-0.4c-18.1,0-33-14.8-33-33v-4.7c0-18.1,14.9-33,33-33h42.8c18.2,0,33,14.9,33,33v4.7
 C75.4,58.5,60.5,73.3,42.4,73.3z"
      />
      <circle style={{ fill: '#C8C8C8' }} cx="1" cy="38" r="9" />
      <circle style={{ fill: '#C8C8C8' }} cx="21" cy="38" r="9" />
      <circle style={{ fill: '#C8C8C8' }} cx="41" cy="38" r="9" />
      <polygon points="-30,30 -50,40 -30,50" style={{ fill: '#E9E9E9' }} />
    </svg>
  );
};

export default Typing;

