import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

const HashLink = ({ to: { hash }, children, smooth }) => {
  const handleClick = useCallback(() => {
    setTimeout(() => {
      const el = document.getElementById(hash);
      if (smooth) {
        el.scrollIntoView({ behavior: 'smooth' });
      } else {
        el.scrollIntoView();
      }
    }, 1);
  }, []);
  return <Link to={{ hash }} onClick={handleClick}>{children}</Link>;
};

HashLink.propTypes = {
  to: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.element.isRequired,
  smooth: PropTypes.bool,
};

HashLink.defaultProps = {
  smooth: false,
};

export default HashLink;
