import capitalizeFirstLetter from 'client/util/capitalizeFirstLetter';

const formatDate = (dateIn, locale, showDay) => {
  const date = dateIn instanceof Date ? dateIn : new Date(dateIn);
  const options = {
    month: 'long',
    year: 'numeric',
  };
  if (showDay) {
    options.day = 'numeric';
  }
  return capitalizeFirstLetter(date.toLocaleDateString(locale, options));
};

export default formatDate;
