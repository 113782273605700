import { Box, Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import EditQuestionAudience from 'client/modules/Surveys/pages/CustomizeSurvey/EditQuestionAudience';
import EditQuestionContent from 'client/modules/Surveys/pages/CustomizeSurvey/EditQuestionContent';
import EditQuestionLogic from 'client/modules/Surveys/pages/CustomizeSurvey/EditQuestionLogic';
import EditQuestionSettings from 'client/modules/Surveys/pages/CustomizeSurvey/EditQuestionSettings';
import {
  freeResponse,
  statement,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import TabPanel from 'components/TabPanel/TabPanel';
import TabPanels from 'components/TabPanel/TabPanels';
import pickBy from 'lodash/pickBy';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  edit: {
    id: 'EditQuestion.tabs.Edit',
    defaultMessage: 'Edit',
  },
  audience: {
    id: 'EditQuestion.tabs.Audience',
    defaultMessage: 'Audience',
  },
  logic: {
    id: 'EditQuestion.tabs.Logic',
    defaultMessage: 'Logic',
  },
  settings: {
    id: 'EditQuestion.tabs.Settings',
    defaultMessage: 'Settings',
  },
});

EditQuestionLogic.propTypes = {};
const EditQuestionTabs = ({ onSave, onCancel, additionalQuestions, questionIndex }) => {
  const { handleSubmit, watch } = useFormContext();
  const { formatMessage } = useIntl();
  const tabs = ['edit', 'audience'];

  const type = watch('type');
  if (questionIndex < additionalQuestions.length - 1 && ![freeResponse, statement].includes(type)) {
    tabs.push('logic');
  }

  if (type !== statement) {
    tabs.push('settings');
  }

  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const onSubmit = dataIn => {
    const data = { ...dataIn };
    if (data.optionsAttributes) {
      data.optionsAttributes = data.optionsAttributes.map(o =>
        pickBy(o, (value, key) => {
          return key !== '_id' || !value.includes('.');
        }),
      );
    }
    onSave(data);
  };

  return (
    <Box>
      {tabs.length > 1 && (
        <Tabs value={selectedTab} sx={{ mb: 3 }}>
          {tabs.map(tab => (
            <Tab
              key={tab}
              label={formatMessage(messages[tab])}
              value={tab}
              onClick={() => setSelectedTab(tab)}
            />
          ))}
        </Tabs>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <TabPanels labelledby="tab" id="tabpanel" value={selectedTab}>
          <TabPanel value="edit">
            <EditQuestionContent
              additionalQuestions={additionalQuestions}
              questionIndex={questionIndex}
            />
          </TabPanel>
          <TabPanel value="audience">
            <EditQuestionAudience />
          </TabPanel>
          <TabPanel value="logic">
            <EditQuestionLogic questions={additionalQuestions} questionIndex={questionIndex} />
          </TabPanel>
          <TabPanel value="settings">
            <EditQuestionSettings questions={additionalQuestions} questionIndex={questionIndex} />
          </TabPanel>
        </TabPanels>
        <Button type="submit" sx={{ my: 4 }} variant="contained">
          <FormattedMessage id="EditQuestion.Save" defaultMessage="Save" />
        </Button>
        <Button type="button" sx={{ my: 4 }} variant="text" onClick={onCancel}>
          <FormattedMessage id="EditQuestion.Cancel" defaultMessage="Cancel" />
        </Button>
      </form>
    </Box>
  );
};

EditQuestionTabs.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  additionalQuestions: PropTypes.array.isRequired,
  questionIndex: PropTypes.number.isRequired,
};

export default EditQuestionTabs;
