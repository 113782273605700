import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import logo from '../Header/promoter-ninja.svg';
import { setError, setOk } from '../../AppActions';
import { getError, getOk } from '../../AppReducer';
import Message from '../Message/Message';

import styles from './LayoutFocused.css';

const LayoutFocused = (props) => {
  const location = useLocation();
  useEffect(() => {
    props.setError();
    props.setOk();
  }, [location]);

  return (
    <div className={styles.iefix}>
      <div className={styles.container}>
        <div className={styles.main}>
          <div className={styles.logo}>
            <Link to="/">
              <SVGInline svg={logo} />
            </Link>
          </div>
          <Helmet title={props.title}>
            <body className={styles.body} />
          </Helmet>
          {props.error
            ? (
              <Message
                type="error"
                msg={props.error}
                onClose={props.setError}
                className={styles.msg}
                maxWidth={props.maxWidth}
              />
            )
            : null}
          {props.ok
            ? (
              <Message
                type="ok"
                msg={props.ok}
                onClose={props.setOk}
                className={styles.msg}
                maxWidth={props.maxWidth}
              />
            )
            : null
          }
          <div
            className={props.className}
            style={{
              alignSelf: 'center', minWidth: '268px', maxWidth: props.maxWidth, margin: 'auto',
            }}
          >
            {props.children}
          </div>
        </div>
        <div className={styles.footer}>
          <p>
            &copy;
            {new Date().getFullYear()}
            {' '}
            &middot; Promoter Ninja
          </p>
          <p><Link to="/privacy"><FormattedMessage id="Footer.Privacy" defaultMessage="Privacy Policy" /></Link></p>
        </div>
      </div>
    </div>
  );
};

LayoutFocused.defaultProps = {
  children: null,
  maxWidth: '435px',
  error: null,
  ok: null,
  className: undefined,
};

LayoutFocused.propTypes = {
  maxWidth: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string.isRequired,
  error: PropTypes.string,
  ok: PropTypes.string,
  setError: PropTypes.func.isRequired,
  setOk: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    ok: getOk(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setError: msg => dispatch(setError(msg)),
    setOk: msg => dispatch(setOk(msg)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LayoutFocused);
