import { DescriptionOutlined } from '@mui/icons-material';
import {
  Grid, ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { getI18n } from 'client/AppReducer';
import {
  helpSlice, useListCategoriesQuery, useListContentsQuery, useReadContentQuery,
} from 'client/modules/Help/helpSlice';
import Spinner from 'components/Spinner/Spinner';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styles from './Help.css';

const HelpArticle = () => {
  const { article, category } = useParams();
  const { locale } = useIntl();
  const { data: categories } = useListCategoriesQuery(locale);
  const { data, isLoading } = useReadContentQuery({ article, category, locale });
  const { data: contents, isLoading: isLoadingContents } = useListContentsQuery({ category, locale });
  const categoryObj = categories?.find(c => c.slug === category);
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <List>
          <Typography variant="h6" sx={{ mb: 1 }}>
            {categoryObj?.name}
          </Typography>
          {isLoadingContents ? (
            <Spinner />
          ) : (contents?.map(({
            slug, title,
          }) => {
            return (
              <ListItemButton
                key={slug}
                selected={article === slug}
                component={RouterLink}
                to={`/help/${category}/${slug}`}
              >
                <ListItemIcon sx={{ minWidth: 32, justifyContent: 'flex-start' }}>
                  <DescriptionOutlined sx={{ fontSize: 20, color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            );
          }))}
        </List>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Paper className={styles.paper}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {data?.title}
          </Typography>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <Typography dangerouslySetInnerHTML={{ __html: data?.value }} />
        </Paper>
      </Grid>
    </Grid>
  );
};


HelpArticle.loader = ({ dispatch, getState }) => ({ params: { article, category } }) => {
  const { locale } = getI18n(getState());
  dispatch(helpSlice.endpoints.readContent.initiate({ article, category, locale }));
  return Promise.all(dispatch(helpSlice.util.getRunningQueriesThunk()));
};

export default HelpArticle;
