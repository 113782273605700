import { handleActions } from 'redux-actions';

// Initial State
const initialState = {
  error: null,
  info: null,
  ok: null,
};

const AppReducer = handleActions({
  SET_ERROR: (state, action) => {
    return {
      ...state,
      error: action.payload,
    };
  },
  SET_INFO: (state, action) => {
    return {
      ...state,
      info: action.payload,
    };
  },
  SET_OK: (state, action) => ({
    ...state,
    ok: action.payload,
  }),
  LOCATION_CHANGE: state => ({
    ...state,
    error: null,
    info: null,
    ok: null,
  }),
}, initialState);

export const getError = state => state.app2.error;
export const getInfo = state => state.app2.info;
export const getOk = state => state.app2.ok;

// Export Reducer
export default AppReducer;
