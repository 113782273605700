import PropTypes from 'prop-types';
import React from 'react';
import { IoMdCheckmark } from 'react-icons/io';

import styles from './RadioBox.css';

const RadioBox = props => (
  <div className={styles.radioBox + (props.selected ? ` ${styles.isActive}` : '')}>
    {props.selected ? <div className={styles.selected}><IoMdCheckmark /></div> : null}
    <div className={styles.logoContainer}>
      <div className={styles.logo}>{props.logo}</div>
    </div>
    <h2>{props.name}</h2>
    {props.description
      ? (
        <div className={styles.description}>
          {props.description}
        </div>
      ) : null}
    {props.actionUrl && props.actionName
      ? (
        <div className={styles.action}>
          Set up
        </div>
      )
      : null}
  </div>
);

RadioBox.defaultProps = {
  actionUrl: null,
  actionName: null,
  description: null,
  selected: false,
};

RadioBox.propTypes = {
  name: PropTypes.any.isRequired,
  description: PropTypes.any,
  logo: PropTypes.any.isRequired,
  actionUrl: PropTypes.string,
  actionName: PropTypes.string,
  selected: PropTypes.bool,
};

export default RadioBox;
