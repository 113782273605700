import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const AnalyzeTitle = () => {
  return (
    <Box sx={{
      display: 'flex', maxWidth: 1280, width: '100%', alignSelf: 'center', mt: 3, mb: 2,
    }}
    >
      <Box>
        <Typography variant="h1">
          <FormattedMessage id="analyze.title" defaultMessage="Analyze" />
        </Typography>
      </Box>
    </Box>
  );
};

export default AnalyzeTitle;
