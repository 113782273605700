import { clearExport as clearExportAction, listen as listenAction, stopListening as stopListeningAction } from 'client/modules/Surveys/SurveyActions';
import useActions from 'client/util/useActions';
import { useEffect } from 'react';

const useListenToSurveySocket = (surveyId) => {
  const [listen, stopListening, clearExport] = useActions([listenAction, stopListeningAction, clearExportAction]);
  useEffect(() => {
    listen(surveyId);
    return () => {
      stopListening(surveyId);
      clearExport();
    };
  }, [surveyId]);
};

export default useListenToSurveySocket;
