import { FormControlLabel } from '@mui/material';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { getToken } from 'client/modules/Auth/AuthReducer';
import { fetchEmailPreferences, setEmailPreferences } from 'client/modules/EmailPreferences/EmailPreferencesActions';
import { getUnsubscribes } from 'client/modules/EmailPreferences/EmailPreferencesReducer';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Layout from '../../../App/components/Layout/LayoutContainer';

import styles from './EmailPrefrencesPage.css';

const messages = defineMessages({
  title: {
    id: 'EmailPreferences.Title',
    defaultMessage: 'Developer settings',
  },
  system: {
    id: 'EmailPreferences.System',
    defaultMessage: 'System messages (such as password reminders)',
  },
  features: {
    id: 'EmailPreferences.Features',
    defaultMessage: 'Promoter Ninja features and services communications',
  },
});

const EmailPreferencesPage = () => {
  const dispatch = useDispatch();
  const { expiry, signature } = useParams();
  const unsubscribes = useSelector(getUnsubscribes);
  const token = useSelector(getToken);
  const { formatMessage } = useIntl();

  const getErrorMessage = (err) => {
    const key = err ? err.message : null;
    const defaultErr = (
      <FormattedMessage
        id="EmailPreferences.Errors.Unknown"
        defaultMessage="An unknown error occurred while saving email preferences."
      />
    );
    switch (key) {
      default:
        return defaultErr;
    }
  };

  const handleToggle = (group, event) => {
    dispatch(setEmailPreferences(token, group, expiry, signature, group, !event.target.checked, getErrorMessage));
  };

  return (
    <Layout>
      <Grid className={styles['main-grid']} fluid>
        <Row className={styles['main-content']}>
          <Col className={styles.controls} xs={12} sm={12} md={10} lg={8}>
            <Helmet title={formatMessage(messages.title)} />
            <Paper className={styles.form} style={{ padding: '2em' }}>
              <p>
                <FormattedMessage
                  id="EmailPreferences.WhichCommunications"
                  defaultMessage="Which communications do you want to receive?"
                />
              </p>
              {Object.keys(unsubscribes).map(key => (
                messages[key]
                  ? (
                    <div key={key}>
                      <FormControlLabel
                        control={(
                          <Switch
                            color="primary"
                            className={styles.toggle}
                            checked={!unsubscribes[key]}
                            onChange={handleToggle.bind(this, key)}
                          />
                        )}
                        label={formatMessage(messages[key])}
                        labelPlacement="start"
                      />

                    </div>
                  ) : null
              ))}
            </Paper>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};

EmailPreferencesPage.loader = store => ({ payload, expiry, signature } = {}) => {
  const state = store.getState();
  return store.dispatch(fetchEmailPreferences(getToken(state), payload, expiry, signature));
};


export default EmailPreferencesPage;
