import { FormControlLabel, Typography } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import SelectField from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useOutletContext } from 'react-router';
import RemoveButton from './RemoveButton';

const messages = defineMessages({
  syncResponses: {
    id: 'IntegrationsSettings.ZendeskSyncResponses',
    defaultMessage: 'Sync responses to people',
  },
  sendEmailsOnTicketClose: {
    id: 'IntegrationsSettings.sendEmailsOnTicketClose',
    defaultMessage: 'Send emails on ticket close',
  },
  closedStates: {
    id: 'IntegrationsSettings.zendeskClosedStates',
    defaultMessage: 'Send emails for states',
  },
  closed: {
    id: 'IntegrationsSettings.zendeskStateClosed',
    defaultMessage: 'Closed',
  },
  solved: {
    id: 'IntegrationsSettings.zendeskStateSolved',
    defaultMessage: 'Solved',
  },
  openTicketsOnAnswer: {
    id: 'IntegrationsSettings.openTicketsOnAnswer',
    defaultMessage: 'Open tickets on answer',
  },
  answerType: {
    id: 'IntegrationsSettings.answerType',
    defaultMessage: 'Open tickets for',
  },
  all: {
    id: 'IntegrationsSettings.zendeskAnswerAll',
    defaultMessage: 'All',
  },
  withComments: {
    id: 'IntegrationsSettings.withComments',
    defaultMessage: 'With comments',
  },
  withoutComments: {
    id: 'IntegrationsSettings.withoutComments',
    defaultMessage: 'Without comments',
  },
  detractors: {
    id: 'IntegrationsSettings.zendeskAnswerDetractors',
    defaultMessage: 'Detractors',
  },
  passives: {
    id: 'IntegrationsSettings.zendeskAnswerPassives',
    defaultMessage: 'Passives',
  },
  promoters: {
    id: 'IntegrationsSettings.zendeskAnswerPromoters',
    defaultMessage: 'Promoters',
  },
  detractorsWithComments: {
    id: 'IntegrationsSettings.zendeskAnswerDetractorsWithComments',
    defaultMessage: 'Detractors with comments',
  },
  passivesWithComments: {
    id: 'IntegrationsSettings.zendeskAnswerPassivesWithComments',
    defaultMessage: 'Passives with comments',
  },
  promotersWithComments: {
    id: 'IntegrationsSettings.zendeskAnswerPromotersWithComments',
    defaultMessage: 'Promoters with comments',
  },
  detractorsWithoutComments: {
    id: 'IntegrationsSettings.zendeskAnswerDetractorsWithoutComments',
    defaultMessage: 'Detractors without comments',
  },
  passivesWithoutComments: {
    id: 'IntegrationsSettings.zendeskAnswerPassivesWithoutComments',
    defaultMessage: 'Passives without comments',
  },
  promotersWithoutComments: {
    id: 'IntegrationsSettings.zendeskAnswerPromotersWithoutComments',
    defaultMessage: 'Promoters without comments',
  },
  survey: {
    id: 'IntegrationsSettings.zendeskSurvey',
    defaultMessage: 'Survey',
  },
});

const IntegrationConfigZendesk = () => {
  const { formatMessage } = useIntl();
  const {
    onIntegrationsPropertiesChange, surveys, integration,
  } = useOutletContext();
  const {
    account, sendEmailsOnTicketClose = false, closedStates, triggeredSurvey,
  } = integration || {};
  const onToggle = name => event => onIntegrationsPropertiesChange(event, 'zendesk', { [name]: event.target.checked });
  if (!triggeredSurvey && surveys) {
    onIntegrationsPropertiesChange(null, 'zendesk', { triggeredSurvey: surveys[0].id });
  }
  if ((!closedStates || closedStates.length === 0) && surveys) {
    onIntegrationsPropertiesChange(null, 'zendesk', { closedStates: ['solved', 'closed'] });
  }
  return (
    <div>
      <Typography>
        <FormattedMessage
          id="IntegrationsSettings.ZendeskAccount"
          defaultMessage="Zendesk account: {account}"
          values={{
            account,
          }}
        />
      </Typography>
      <br />
      <div>
        <FormControlLabel
          control={(
            <Switch
              color="primary"
              checked={sendEmailsOnTicketClose}
              onChange={onToggle('sendEmailsOnTicketClose')}
            />
          )}
          label={formatMessage(messages.sendEmailsOnTicketClose)}
          labelPlacement="start"
        />
        {sendEmailsOnTicketClose
          ? (
            <div>
              <SelectField
                variant="standard"
                id="closedStates"
                label={formatMessage(messages.closedStates)}
                value={closedStates}
                onChange={((event) => {
                  onIntegrationsPropertiesChange(event, 'zendesk', { closedStates: event.target.value });
                })}
                multiple
              >
                {['closed', 'solved'].map(state => (
                  (
                    <MenuItem
                      key={state}
                      insetChildren
                      checked={closedStates && closedStates.indexOf(state) > -1}
                      value={state}
                    >
                      {formatMessage(messages[state])}
                    </MenuItem>
                  )
                ))}
              </SelectField>
              <SelectField
                variant="standard"
                id="triggeredSurvey"
                label={formatMessage(messages.survey)}
                value={triggeredSurvey}
                onChange={((event) => {
                  onIntegrationsPropertiesChange(event, 'zendesk', { triggeredSurvey: event.target.value });
                })}
                style={{ marginLeft: '3em' }}
              >
                {surveys ? surveys.map(s => <MenuItem key={s.id} value={s.id}>{s.name}</MenuItem>) : null}
              </SelectField>
            </div>
          )
          : null}
      </div>
    </div>
  );
};

export default IntegrationConfigZendesk;
