import { useEffect, useRef, useState } from 'react';

const useHover = ({ delay = 300, query } = { delay: 300 }) => {
  let timeout;
  const [element, setElement] = useState(null);
  const ref = useRef(null);
  const handleMouseEnter = (event) => {
    timeout = setTimeout(() => {
      setElement(event.target);
    }, delay);
  };
  const handleMouseLeave = () => {
    clearInterval(timeout);
    setElement(null);
  };
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        const nodes = query ? node.querySelectorAll(query) : [node]
        nodes.forEach(n => n.addEventListener('mouseenter', handleMouseEnter));
        nodes.forEach(n => n.addEventListener('mouseleave', handleMouseLeave));
        return () => {
          nodes.forEach(n => n.removeEventListener('mouseenter', handleMouseEnter));
          nodes.forEach(n => n.removeEventListener('mouseleave', handleMouseLeave));
        };
      }
      return () => {};
    },
    [ref.current], // Recall only if ref changes
  );
  return [ref, element];
};

export default useHover;
