import React from 'react';
import { FormControlLabel } from '@mui/material';
import Switch from '@mui/material/Switch';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const RequiredController = props => (
  <Controller
    name="required"
    control={props.control}
    render={({ field: { onChange, value } }) => (
      <FormControlLabel
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
        control={
          <Switch checked={value} onChange={e => onChange(e.target.checked)} color="primary" />
        }
        label={<FormattedMessage id="EditQuestion.required" defaultMessage="Answer required" />}
      />
    )}
  />
);

RequiredController.propTypes = {
  control: PropTypes.any.isRequired,
};

export default RequiredController;
