import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  defineMessages, FormattedMessage, injectIntl, useIntl,
} from 'react-intl';
import { useNavigate } from 'react-router-dom';
import RaisedButton from '../../../App/components/RaisedButton/RaisedButton';
import { resendEmailConfirmation } from '../../AuthActions';
import LayoutFocused from '../../../App/components/LayoutFocused/LayoutFocused';
import { getToken } from '../../AuthReducer';

const messages = defineMessages({
  title: {
    id: 'VerifyEmailResend.Title',
    defaultMessage: 'Resend email address verification',
  },
  resend: {
    id: 'VerifyEmailResend.SendAnother',
    defaultMessage: 'Resend verification email',
  },
});

const VerifyEmailResendPage = (props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const handleResend = () => {
    props.resendEmailConfirmation(props.token);
    navigate('/verify-email');
  };
  return (
    <LayoutFocused title={formatMessage(messages.title)}>
      <h1>
        <FormattedMessage
          id="VerifyEmailResend.Heading"
          defaultMessage="Email address not verified"
        />
      </h1>
      <p>
        <FormattedMessage
          id="VerifyEmailResend.YourEmailIsNot"
          defaultMessage="We need to confirm your email address to grant you access."
        />
      </p>
      <p>
        <FormattedMessage
          id="VerifyEmailResend.IfYouHavent"
          defaultMessage="If you don't have the verification email, click below to get another one."
        />
      </p>
      <RaisedButton onClick={handleResend} label={formatMessage(messages.resend)} primary fullWidth />
    </LayoutFocused>
  );
};

VerifyEmailResendPage.propTypes = {
  intl: PropTypes.any.isRequired,
  token: PropTypes.string.isRequired,
  resendEmailConfirmation: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  token: getToken(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    resendEmailConfirmation: token => dispatch(resendEmailConfirmation(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VerifyEmailResendPage));
