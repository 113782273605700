import { handleActions } from 'redux-actions';
import { getI18n } from '../../AppReducer';

// Initial State
const initialState = {};

export const ContentReducer = handleActions({
  FETCH_CONTENT_CB: (state, action) => {
    return ({
      ...state,
      [`${action.payload.key}_${action.payload.locale}`]: action.payload.value,
    });
  },
  LIST_CATEGORIES_CB: (state, action) => {
    return ({
      ...state,
      categories: action.payload,
    });
  },
}, initialState);

// Is the received token valid?
export const getContent = key => state => state.content[`${key}_${getI18n(state).locale}`];

// Export Reducer
export default ContentReducer;
