import Cross from '@mui/icons-material/Close';
import Tick from '@mui/icons-material/Done';
import Email from '@mui/icons-material/Email';
import InfoIcon from '@mui/icons-material/Info';
import Phone from '@mui/icons-material/Phone';
import { Box, Tooltip, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from '@mui/material/Pagination';
import Grid from '@mui/material/Unstable_Grid2';
import LeftNav from 'client/modules/Surveys/pages/DashboardPage/LeftNav';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import Layout from 'components/Layout/Layout';
import NotFound from 'components/NotFound/NotFound';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useElementSize } from 'use-element-size';
import { useGetActivitiesQuery, useGetPersonQuery } from '../../peopleApiSlice';
import messages from './messages';
import styles from './styles';

const PersonView = () => {
  const [outerHeight, setOuterHeight] = useState(0);
  const rowHeight = 48;
  const outerRef = useElementSize((size) => {
    setOuterHeight(size.height - 20);
  });
  const surveys = useSelector(getSurveys);
  const { id: survey, personId } = useParams();
  const { formatMessage, locale } = useIntl();
  const { returnLinkTo, returnLinkText } = useLocation().state
  || {
    returnLinkTo: `/surveys/${survey}/people/?status=active`,
    returnLinkText: formatMessage(messages.back),
  };
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  useEffect(() => {
    if (rowHeight) {
      setLimit(Math.floor(outerHeight / rowHeight));
    }
  }, [rowHeight, outerHeight]);
  const { data: person, isLoading: isLoadingPerson } = useGetPersonQuery({ survey, personId });
  const { data: activities, isLoading: isLoadingActivities } = useGetActivitiesQuery({
    survey, personId, limit, skip: (page - 1) * limit,
  });
  const pages = Math.ceil(activities?.total / limit) || 1;
  useEffect(() => {
    if (page > pages) {
      setPage(pages);
    }
  }, [limit]);
  const handlePageChange = useCallback((event, newPage) => setPage(newPage), [setPage]);

  const isLoading = isLoadingPerson || isLoadingActivities;
  if (!isLoadingPerson && !person) {
    return <NotFound />;
  }
  let status;
  if (person?.unsubscribed_at) {
    status = formatMessage(messages.personStatusUnsubscribed);
  } else if (person?.bounced_at) {
    status = formatMessage(messages.personStatusBounced);
  } else if (person?.blocked_at) {
    status = formatMessage(messages.personStatusBlocked);
  }

  return (
    <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText} secondaryNav={<SecondaryNavTabs survey={survey} />}>
      <Box sx={styles.root}>
        <Typography variant="h1">{person?.name || person?.email}</Typography>
        <Grid container spacing={2} sx={styles.container}>
          <Grid xs={4}>
            {person && (
              <Box sx={styles.personInfo}>
                {!!person.email && (
                  <Box component="a" href={`mailto:${person.email}`}>
                    <Email />
                    <Typography variant="body1">{person.email}</Typography>
                  </Box>
                )}
                {!!person.phone && (
                  <Box>
                    <Phone />
                    <Typography variant="body1">{person.phone}</Typography>
                  </Box>
                )}
                <Box component="dl" sx={styles.defs}>
                  <Box component="dt" sx={styles.status(status)}>
                    {formatMessage(messages.status)}
                    <Tooltip
                      title={formatMessage(messages.statusHint)}
                      arrow
                      placement="right-start"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </Box>
                  <Box component="dd">
                    {status || formatMessage(messages.personStatusActive)}
                    {status ? <Cross /> : <Tick />}
                  </Box>
                  <dt>{formatMessage(messages.timesSurveyed)}</dt>
                  <dd>{+person.totalSendCount}</dd>
                  <dt>{formatMessage(messages.lastSurveyed)}</dt>
                  <dd>
                    {person.lastSurveyed ? `${moment(person.lastSurveyed)
                      .locale(locale)
                      .format('LL')} - ${moment(person.lastSurveyed)
                        .locale(locale)
                        .format('LT')}` : '-'}
                  </dd>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid xs={8} sx={styles.outerGrid}>
            {isLoading && (
              <Box sx={styles.circularBackdrop}>
                <CircularProgress />
              </Box>
            )}
            {!isLoading && !activities?.total && (
              <Typography variant="body1">{formatMessage(messages.noActivities)}</Typography>
            )}
            {!isLoading && activities?.total > 0 && (
              <>
                <Box component="ul" sx={{ ...styles.listTimeline, ...styles.outer }} ref={outerRef}>
                  {activities.docs.map((activity) => {
                    const timestamp = moment(activity.timestamp).locale(locale);
                    return (
                      <Box
                        component="li"
                        sx={{ ...styles.listTimelineItem, ...styles.item }}
                        key={activity._id}
                      >
                        <Typography>
                          {formatMessage(messages[`status_${activity.action}`])}
                          <Box sx={styles.badge}>{activity.metadata.channel}</Box>
                        </Typography>
                        <Typography color="muted.main">{`${timestamp.format('LL')} - ${timestamp.format('LT')}`}</Typography>
                      </Box>
                    );
                  })}
                </Box>
                <Box sx={{ display: 'flex', gap: 4, alignItems: 'baseline' }}>
                  <Pagination
                    count={pages}
                    page={page}
                    onChange={handlePageChange}
                  />
                  {true && (
                    <Typography variant="caption" color="muted.main">
                      {formatMessage(messages.disclaimer)}
                    </Typography>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default PersonView;
