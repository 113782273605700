import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getI18n } from '../../../../../AppReducer';
import withParams from '../../../../../util/withParams';
import { getToken } from '../../../../Auth/AuthReducer';
import { getCheckoutPage } from '../../../../Subscriptions/SubscriptionActions';

import { fetchPlans } from '../../PricingActions';
import { getPlans } from '../../PricingReducer';

import PricingPage from './PricingPage';

const ALLOWED_PERIODS = ['1m', '6m', '1y'];

const mapStateToProps = (state, ownProps) => ({
  period: ALLOWED_PERIODS.indexOf(ownProps.params.period) >= 0 ? ownProps.params.period : '1m',
  plans: getPlans(state),
  token: getToken(state),
  locale: getI18n(state).locale,
});

const mapDispatchToProps = dispatch => ({
  getCheckoutPage: (token, planId, locale, navigate) => {
    dispatch(getCheckoutPage(token, planId, locale, navigate));
  },
  fetchPlans: (period) => {
    dispatch(fetchPlans(period));
  },
});

class PricingPageContainer extends React.Component {
  componentDidMount() {
    if (this.props.plans.length === 0) {
      this.props.fetchPlans(this.props.period);
    }
  }

  render() {
    return <PricingPage {...this.props} />;
  }
}

PricingPageContainer.defaultProps = {
  plans: [],
};

PricingPageContainer.propTypes = {
  period: PropTypes.oneOf(ALLOWED_PERIODS).isRequired,
  plans: PropTypes.array,
  fetchPlans: PropTypes.func.isRequired,
};

export default withParams(connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(PricingPageContainer)));
