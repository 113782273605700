import classnames from 'classnames';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import styles from './Spinner.css';

const messages = defineMessages({
  loading: {
    id: 'Spinner.Loading',
    defaultMessage: 'Loading...',
  },
});

const Spinner = (props) => {
  const { intl: { formatMessage }, className, ...rest } = props;
  return (
    <div className={classnames(styles.loader, className)} {...rest}>{formatMessage(messages.loading)}</div>
  );
};

export default injectIntl(Spinner);
