import { Layers } from '@mui/icons-material';
import {
  Box, Card, CardActionArea, CardContent, Typography,
} from '@mui/material';
import CardMedia from '@mui/material/CardMedia';
import { useListCategoriesQuery } from 'client/modules/Help/helpSlice';
import icons from 'client/modules/Help/icons';
import Spinner from 'components/Spinner/Spinner';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './Help.css';

const HelpHome = () => {
  const { locale } = useIntl();
  const { data: categories, isLoading } = useListCategoriesQuery(locale);
  return (
    isLoading ? (
      <Spinner />
    ) : (
      <Box className={styles.grid}>
        {categories?.map(({
          slug, name, description, icon,
        }) => {
          const Icon = icons[icon || Layers];
          return (
            <Card className={styles.card} key={slug}>
              <CardActionArea component={Link} to={`/help/${slug}`}>
                <CardMedia>
                  <Icon className={styles.icon} sx={{ fontSize: 40, color: 'primary.main' }} />
                </CardMedia>
                <CardContent>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    {name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          );
        })}
      </Box>
    ));
};

export default HelpHome;
