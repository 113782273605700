import { LooksOne } from '@mui/icons-material';
import FreeResponseTypes from 'client/modules/Surveys/pages/CustomizeSurvey/FreeResponseTypes';
import Face from 'client/modules/Surveys/pages/WebSurveyWidget/images/csat-4.svg';
import Heart from 'client/modules/Surveys/pages/WebSurveyWidget/images/heart.svg';
import Star from 'client/modules/Surveys/pages/WebSurveyWidget/images/star.svg';
import Thumb from 'client/modules/Surveys/pages/WebSurveyWidget/images/thumb.svg';
import React from 'react';
import {
  Box,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import {
  freeResponse,
  scale,
  selectMany,
  selectOne,
  statement,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import RequiredController from 'client/modules/Surveys/pages/CustomizeSurvey/RequiredController';
import { Controller, useFormContext } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  minMax: {
    id: 'Errors.MinMax',
    defaultMessage: "The minimum can't be greater than the maximum",
  },
  maxMin: {
    id: 'Errors.MaxMin',
    defaultMessage: "The maximum can't be less than the minimum",
  },
  style: {
    id: 'EditQuestion.style',
    defaultMessage: 'Style',
  },
  range: {
    id: 'EditQuestion.range',
    defaultMessage: 'Range',
  },
  [FreeResponseTypes.date]: {
    id: 'EditQuestion.freeResponseType.date',
    defaultMessage: 'Date',
  },
  [FreeResponseTypes.email]: {
    id: 'EditQuestion.freeResponseType.email',
    defaultMessage: 'Email',
  },
  [FreeResponseTypes.phone]: {
    id: 'EditQuestion.freeResponseType.phone',
    defaultMessage: 'Phone',
  },
  [FreeResponseTypes.number]: {
    id: 'EditQuestion.freeResponseType.number',
    defaultMessage: 'Number',
  },
  [FreeResponseTypes.shortText]: {
    id: 'EditQuestion.freeResponseType.shortText',
    defaultMessage: 'Short text',
  },
  [FreeResponseTypes.longText]: {
    id: 'EditQuestion.freeResponseType.longText',
    defaultMessage: 'Long text',
  },
});

const EditQuestionSettings = () => {
  const { control, watch, getValues, setValue } = useFormContext();
  const { formatMessage, locale } = useIntl();
  const collator = new Intl.Collator(locale, { sensitivity: 'base' });
  const type = watch('type');
  const randomizeOptionOrder = watch('randomizeOptionOrder');
  const choiceLimit = watch('choiceLimit');
  const options = watch('optionsAttributes');
  const rangeMax = watch('style') === 'numerical' ? 11 : 5;
  if (type === statement) {
    return null;
  }
  return (
    <Box>
      <RequiredController control={control} />
      {type === freeResponse && (
        <Box>
          <Controller
            name="freeResponseType"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControl fullWidth sx={{ backgroundColor: '#fff' }}>
                <InputLabel id="question-type-label">
                  <FormattedMessage id="EditQuestion.freeResponseType" defaultMessage="Type" />
                </InputLabel>
                <Select
                  defaultValue="text"
                  value={value}
                  onChange={onChange}
                  labelId="question-type-label"
                  label={
                    <FormattedMessage id="EditQuestion.freeResponseType" defaultMessage="Type" />
                  }
                >
                  {Object.values(FreeResponseTypes)
                    .map(v => [formatMessage(messages[v]), v])
                    .sort(([a], [b]) => collator.compare(a, b))
                    .map(([label, v]) => (
                      <MenuItem key={v} value={v}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <Typography>{label}</Typography>
                        </Box>
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
          />
        </Box>
      )}
      {type === scale && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Controller
              name="style"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  sx={{
                    backgroundColor: '#fff',
                    flexGrow: 1,
                    minWidth: 0,
                    width: '40%',
                  }}
                >
                  <InputLabel id="style-label">
                    <FormattedMessage id="EditQuestion.style" defaultMessage="Style" />
                  </InputLabel>
                  <Select
                    value={value}
                    onChange={onChange}
                    labelId="style-label"
                    label={formatMessage(messages.style)}
                  >
                    <MenuItem value="numerical">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <LooksOne style={{ color: '#b3b3b3' }} />
                        <Typography>
                          <FormattedMessage
                            id="EditQuestion.styles.numbers"
                            defaultMessage="Numerical"
                          />
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value="stars">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <div
                          style={{ width: 24, height: 24, color: '#e2c789' }}
                          dangerouslySetInnerHTML={{ __html: Star }}
                        />
                        <Typography>
                          <FormattedMessage id="EditQuestion.styles.stars" defaultMessage="Stars" />
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value="hearts">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <div
                          style={{ width: 24, height: 24, color: '#e38a8c' }}
                          dangerouslySetInnerHTML={{ __html: Heart }}
                        />
                        <Typography>
                          <FormattedMessage
                            id="EditQuestion.styles.hearts"
                            defaultMessage="Hearts"
                          />
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value="thumbs">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <div
                          style={{ width: 24, height: 24, color: '#8ab1e3' }}
                          dangerouslySetInnerHTML={{ __html: Thumb }}
                        />
                        <Typography>
                          <FormattedMessage
                            id="EditQuestion.styles.thumbs"
                            defaultMessage="Thumbs"
                          />
                        </Typography>
                      </Box>
                    </MenuItem>
                    <MenuItem value="faces">
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <div
                          style={{ width: 24, height: 24, color: '#a3e38a' }}
                          dangerouslySetInnerHTML={{ __html: Face }}
                        />
                        <Typography>
                          <FormattedMessage id="EditQuestion.styles.faces" defaultMessage="Faces" />
                        </Typography>
                      </Box>
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
            />
            <Controller
              name="range"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControl
                  sx={{
                    backgroundColor: '#fff',
                    flexGrow: 1,
                    minWidth: 0,
                    width: '40%',
                  }}
                >
                  <InputLabel id="range-label">
                    <FormattedMessage id="EditQuestion.range" defaultMessage="Range" />
                  </InputLabel>
                  <Select
                    value={value}
                    onChange={e => {
                      onChange(e);
                      const val = e.target.value;
                      setValue('scaleMin', val === 11 ? 0 : 1);
                      setValue('scaleMax', val === 11 ? val - 1 : val);
                    }}
                    labelId="range-label"
                    label={formatMessage(messages.range)}
                  >
                    {[...Array(rangeMax - 1).keys()].map(o => (
                      <MenuItem key={o} value={o + 2}>
                        {o + 2}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Controller
              name="scaleMinLabel"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  sx={{
                    backgroundColor: '#fff',
                    flexGrow: 1,
                    minWidth: 0,
                    width: '40%',
                  }}
                  label={
                    <FormattedMessage id="EditQuestion.leftLabel" defaultMessage="Left label" />
                  }
                />
              )}
            />
            <Controller
              name="scaleMaxLabel"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  value={value}
                  onChange={onChange}
                  sx={{
                    backgroundColor: '#fff',
                    flexGrow: 1,
                    minWidth: 0,
                    width: '40%',
                  }}
                  label={
                    <FormattedMessage id="EditQuestion.rightLabel" defaultMessage="Right label" />
                  }
                />
              )}
            />
          </Box>
        </>
      )}
      {[selectOne, selectMany].includes(type) && (
        <Box>
          <Controller
            name="requiredComments"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                control={
                  <Switch
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <FormattedMessage
                    id="EditQuestion.requiredComments"
                    defaultMessage="Comment field(s) required"
                  />
                }
              />
            )}
          />
          <Controller
            name="randomizeOptionOrder"
            control={control}
            render={({ field: { onChange, value } }) => (
              <FormControlLabel
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                control={
                  <Switch
                    checked={value}
                    onChange={e => onChange(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <FormattedMessage
                    id="EditQuestion.randomizeOptionOrder"
                    defaultMessage="Randomize option order"
                  />
                }
              />
            )}
          />
          {!randomizeOptionOrder || (
            <Controller
              name="randomizeExceptLast"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  control={
                    <Switch
                      checked={value}
                      onChange={e => onChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={
                    <FormattedMessage
                      id="EditQuestion.randomizeExceptLast"
                      defaultMessage="Randomize except last"
                    />
                  }
                />
              )}
            />
          )}
          {type === selectMany && (
            <Box
              sx={{
                display: 'flex',
                gap: 2,
                '& .MuiFormControl-root': { minWidth: '30%' },
              }}
            >
              <Controller
                name="choiceLimit"
                defaultValue="unlimited"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormControl fullWidth>
                    <InputLabel id="choice-limit-type-label">
                      <FormattedMessage
                        id="EditQuestion.choiceLimit"
                        defaultMessage="Choice limit"
                      />
                    </InputLabel>
                    <Select
                      value={value}
                      onChange={onChange}
                      sx={{ backgroundColor: '#fff' }}
                      labelId="choice-limit-type-label"
                      label={
                        <FormattedMessage
                          id="EditQuestion.choiceLimit"
                          defaultMessage="Choice limit"
                        />
                      }
                    >
                      <MenuItem value="unlimited">
                        <FormattedMessage
                          id="EditQuestion.choiceLimit.unlimited"
                          defaultMessage="Unlimited"
                        />
                      </MenuItem>
                      <MenuItem value="exact">
                        <FormattedMessage
                          id="EditQuestion.choiceLimit.exact"
                          defaultMessage="Exact number"
                        />
                      </MenuItem>
                      <MenuItem value="range">
                        <FormattedMessage
                          id="EditQuestion.choiceLimit.range"
                          defaultMessage="Range"
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              />
              {choiceLimit === 'exact' && (
                <Controller
                  name="minChoices"
                  rules={{
                    min: 1,
                    max: options.length,
                  }}
                  control={control}
                  sx={{
                    backgroundColor: '#fff',
                    width: 150,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="number"
                      inputProps={{
                        min: 1,
                        max: options.length,
                        style: { backgroundColor: '#fff' },
                      }}
                      placeholder={`1-${options.length}`}
                      label={
                        <FormattedMessage id="EditQuestion.minChoices" defaultMessage="Minimum" />
                      }
                    />
                  )}
                />
              )}
              {choiceLimit === 'range' && (
                <>
                  <Controller
                    name="minChoices"
                    rules={{
                      min: 0,
                      max: options.length,
                      validate: {
                        lessThanMax: value =>
                          getValues('choiceLimit') !== 'range' ||
                          !getValues('maxChoices') ||
                          getValues('maxChoices') >= value ||
                          formatMessage(messages.minMax),
                      },
                    }}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        type="number"
                        helperText={error?.message}
                        error={!!error}
                        inputProps={{
                          min: 0,
                          max: options.length,
                          style: { backgroundColor: '#fff' },
                        }}
                        label={
                          <FormattedMessage id="EditQuestion.minChoices" defaultMessage="Minimum" />
                        }
                      />
                    )}
                  />
                  <Controller
                    name="maxChoices"
                    control={control}
                    rules={{
                      min: 0,
                      max: options.length,
                      validate: {
                        moreThanMin: value =>
                          getValues('choiceLimit') !== 'range' ||
                          !getValues('minChoices') ||
                          getValues('minChoices') <= value ||
                          formatMessage(messages.maxMin),
                      },
                    }}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        fullWidth
                        type="number"
                        helperText={error?.message}
                        error={!!error}
                        inputProps={{
                          min: 0,
                          max: options.length,
                          style: { backgroundColor: '#fff' },
                        }}
                        label={
                          <FormattedMessage id="EditQuestion.maxChoices" defaultMessage="Maximum" />
                        }
                      />
                    )}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default EditQuestionSettings;
