import {
  Check, Close, Facebook, LinkedIn,
} from '@mui/icons-material';
import Copy from '@mui/icons-material/ContentCopy';
import {
  Box, Button, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, InputLabel, Modal, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { ObjectID } from 'bson';
import SurveyEmail from 'client/modules/Emails/Survey/SurveyEmail';
import {
  useCreateLinkMutation,
  useReadLinkQuery,
  useRemoveLinkMutation,
  useUpdateLinkMutation,
} from 'client/modules/Surveys/linkApiSlice';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import getLink from 'client/modules/Surveys/pages/LinkPage/getLink';
import { getId, getRand, getSurvey } from 'client/modules/Surveys/SurveyReducer';
import copyText from 'client/util/copyText';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import Layout from 'components/Layout/Layout';
import React, { useCallback, useEffect, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Helmet } from 'react-helmet';
import { useFieldArray, useForm } from 'react-hook-form';
import {
  defineMessages, FormattedMessage, IntlProvider, useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { localizationData } from '../../../../../Intl/setup';
import styles from './LinkPage.css';

const messages = defineMessages({
  saveChanges: {
    id: 'LinkPage.saveChanges',
    defaultMessage: 'Save changes',
  },
  savingChanges: {
    id: 'LinkPage.savingChanges',
    defaultMessage: 'Saving changes...',
  },
  chooseADifferentChannel: {
    id: 'Survey.ChooseChannel',
    defaultMessage: 'Choose a different channel',
  },
  name: {
    id: 'LinkPage.name',
    defaultMessage: 'Name',
  },
  value: {
    id: 'LinkPage.value',
    defaultMessage: 'Value',
  },
  surveyLink: {
    id: 'LinkPage.surveyLink',
    defaultMessage: 'Survey link',
  },
  completeSurvey: {
    id: 'LinkPage.completeSurvey',
    defaultMessage: 'Would you be able to spare a moment to answer this survey? Your input is extremely valuable and would be greatly appreciated.',
  },
  embed: {
    id: 'LinkPage.embed',
    defaultMessage: 'Embed in an email',
  },
  emailSoftware: {
    id: 'LinkPage.emailSoftware',
    defaultMessage: 'Email software',
  },
  addProperties: {
    id: 'LinkPage.addProperties',
    defaultMessage: 'Add properties to this link',
  },
  addPropertiesDescription: {
    id: 'LinkPage.addPropertiesDescription',
    defaultMessage: 'The properties you add will be included in every survey response that comes from this link.',
  },
  addPropertiesUnique: {
    id: 'LinkPage.addPropertiesUnique',
    defaultMessage: 'If you want to add unique properties to each response, you can do so by adding them to your survey link. Check out our {link} for more information.',
  },
  addPropertiesUniqueLink: {
    id: 'LinkPage.addPropertiesUniqueLink',
    defaultMessage: 'help center',
  },
  addNameToLink: {
    id: 'LinkPage.addNameToLink',
    defaultMessage: 'Add name to link',
  },
  backToSharePage: {
    id: 'LinkPage.backToSharePage',
    defaultMessage: 'Back',
  },
  errorNameAlreadyExists: {
    id: 'LinkPage.errorNameAlreadyExists',
    defaultMessage: 'A link with this name already exists',
  },
});

const providers = [
  'Customer.io',
  'Hubspot',
  'Intercom Emails',
  'Mailchimp',
  'Mailgun',
  'Marketo',
  'Sendgrid',
  'Vero',
  'Zendesk',
];

const LinkPage = () => {
  const [isCodeOpen, openCode, closeCode] = useOpenClose();
  const [provider, setProvider] = useState('');
  const { id, link } = useParams();
  const { formatMessage } = useIntl();
  const returnLinkTo = `/surveys/${id}/import/share`;
  const returnLinkText = formatMessage(messages.backToSharePage);
  const navigate = useNavigate();
  const { data, isSuccess } = useReadLinkQuery({ link }, { skip: link === 'new', refetchOnMountOrArgChange: true });
  const [deleteLink] = useRemoveLinkMutation();
  const [error, setError] = useState(null);
  const [editLink, { error: updateError, isLoading: isUpdating }] = useUpdateLinkMutation();
  const [createLink, { error: createError, isLoading: isCreating }] = useCreateLinkMutation();
  const location = useLocation();
  const [copied, setCopied] = useState(false);
  const surveyId = useSelector(getId);
  const survey = useSelector(state => getSurvey(state, surveyId));
  const rand = useSelector(getRand);

  useEffect(() => {
    if ((createError || updateError)?.status === 409) {
      setError(formatMessage(messages.errorNameAlreadyExists));
    }
  }, [createError, updateError]);

  const form = useForm({
    defaultValues: {
      name: data?.name || '',
      properties: data?.properties || [],
      addName: data?.addName || false,
    },
  });

  useEffect(() => {
    form.reset({
      name: data?.name || '',
      properties: data?.properties || [],
      addName: data?.addName || false,
    });
  }, [location]);

  const addName = form.watch('addName');
  const name = form.watch('name');
  const value = getLink({ _id: link, name, addName });
  useEffect(() => {
    if (isSuccess) {
      form.reset({
        name: data?.name,
        properties: data?.properties,
        addName: data?.addName,
      });
    }
  }, [data, isSuccess]);
  const {
    fields, append, remove,
  } = useFieldArray({
    control: form.control,
    name: 'properties',
  });
  const onSubmit = async (formData) => {
    if (link === 'new') {
      await createLink({ survey: id, ...formData });
    } else {
      await editLink({
        survey: id,
        _id: link,
        ...formData,
      });
    }
    navigate(returnLinkTo);
  };
  const onCopy = useCallback(val => () => {
    copyText(val).then(() => {
      setCopied(true);
      setTimeout(() => setCopied(false), 3000);
    }).catch((e) => {
      console.log('failed to copy', e);
    });
  }, []);
  const addNameProps = form.register('addName');
  const handleOpenConfirm = useActions(openConfirm);
  const code = renderToStaticMarkup(
    <IntlProvider
      locale={survey.language}
      messages={localizationData[survey.language].messages}
    >
      <SurveyEmail
        onClick={() => {}}
        preview
        linkId={link}
        creatorId={survey.creatorId}
        provider={provider}
        surveyType={survey.surveyType}
        surveyCsatType={survey.surveyCsatType}
        csatFacesNum={survey.csatFacesNum}
        brand={survey.brand}
        topColor={survey.topColor}
        buttonColor={survey.buttonColor}
        from={survey.fromWithDefault}
        replyTo={survey.replyTo}
        question={survey.question}
        intro={survey.intro}
        footer={survey.footer}
        subject={survey.subjectWithDefault}
        language={survey.language}
        logoUrl={survey.logoUrl ? `${survey.logoUrl}?r=${rand}` : null}
      />
    </IntlProvider>,
  );
  return (
    <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText} secondaryNav={<SecondaryNavTabs survey={id} />}>
      <Helmet title={data?.name} />
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <Grid className={styles.mainGrid} container>
          <Grid item xs={12} gap={2} my={2} className={styles.mainContent}>
            <Card variant="outlined">
              <CardHeader title={formatMessage(messages.surveyLink)} className={styles.cardHeader} />
              <CardContent className={styles.cardContent}>
                <Typography>
                  <FormattedMessage
                    id="LinkPage.shareYourSurvey"
                    defaultMessage="Share your survey by sending this link to your customers."
                  />
                </Typography>
                <TextField
                  size="small"
                  value={name}
                  {...form.register('name')}
                  label={formatMessage(messages.name)}
                  variant="outlined"
                  fullWidth
                  className={styles.nameField}
                  required
                  error={!!error}
                  helperText={error}
                />
                <Box className={styles.copyField}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCopy(value)}
                    startIcon={(
                      <SwitchTransition>
                        <CSSTransition
                          timeout={200}
                          key={copied}
                          classNames={{
                            enter: styles.copyButtonEnter,
                            enterActive: styles.copyButtonEnterActive,
                            exit: styles.copyButtonExit,
                            exitActive: styles.copyButtonExitActive,
                          }}
                        >
                          {copied ? <Check color="success" /> : <Copy />}
                        </CSSTransition>
                      </SwitchTransition>
                    )}
                  >
                    <FormattedMessage
                      id="LinkPage.copySuveyLink"
                      defaultMessage="Copy survey link"
                    />
                  </Button>
                  <OutlinedInput value={value} className={styles.copyFieldInput} readOnly />
                </Box>
                <FormControlLabel
                  control={<Checkbox checked={!!addName} {...addNameProps} />}
                  label={formatMessage(messages.addNameToLink)}
                />
                <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <IconButton href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(value)}`} target="_blank">
                    <Facebook />
                  </IconButton>
                  <IconButton href={`https://x.com/intent/tweet?url=${encodeURIComponent(value)}&text=${formatMessage(messages.completeSurvey)}`}>
                    <Box sx={{ lineHeight: 1, width: 24 }}>𝕏</Box>
                  </IconButton>
                  <IconButton href={`http://www.linkedin.com/shareArticle?url=${encodeURIComponent(value)}`} target="_blank">
                    <LinkedIn />
                  </IconButton>
                </Box>
              </CardContent>
            </Card>

            <Card variant="outlined">
              <CardHeader title={formatMessage(messages.embed)} className={styles.cardHeader} />
              <CardContent className={styles.cardContent}>
                <FormControl variant="outlined" size="small">
                  <InputLabel>
                    {formatMessage(messages.emailSoftware)}
                  </InputLabel>
                  <Select
                    onChange={(event) => {
                      setProvider(event.target.value);
                    }}
                    value={provider}
                    label={formatMessage(messages.emailSoftware)}
                  >
                    {providers.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {!provider || (
                  <>
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={openCode}
                      >
                        <FormattedMessage
                          id="LinkPage.getYourCode"
                          defaultMessage="Get your code"
                        />
                      </Button>
                    </Box>
                    <Dialog open={isCodeOpen} onClose={closeCode} fullWidth maxWidth="sm">
                      <DialogTitle sx={{ m: 0, p: 2, pr: 4 }}>
                        <FormattedMessage
                          id="LinkPage.copyAndPaste"
                          defaultMessage="Copy and paste this code into your {name} campaign."
                          values={{ name: provider }}
                        />
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={closeCode}
                        sx={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: theme => theme.palette.grey[500],
                        }}
                      >
                        <Close />
                      </IconButton>
                      <DialogContent className={styles.code}>
                        <OutlinedInput value={code} readOnly multiline rows={10} />
                      </DialogContent>
                      <DialogActions>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={onCopy(code)}
                          startIcon={(
                            <SwitchTransition>
                              <CSSTransition
                                timeout={200}
                                key={copied}
                                classNames={{
                                  enter: styles.copyButtonEnter,
                                  enterActive: styles.copyButtonEnterActive,
                                  exit: styles.copyButtonExit,
                                  exitActive: styles.copyButtonExitActive,
                                }}
                              >
                                {copied ? <Check color="success" /> : <Copy />}
                              </CSSTransition>
                            </SwitchTransition>
                          )}
                        >
                          <FormattedMessage
                            id="LinkPage.copy"
                            defaultMessage="Copy"
                          />
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </CardContent>
            </Card>

            <Card variant="outlined">
              <CardHeader title={formatMessage(messages.addProperties)} className={styles.cardHeader} />
              <CardContent className={styles.cardContent}>
                <Typography>
                  {formatMessage(messages.addPropertiesDescription)}
                </Typography>
                <Typography>
                  {formatMessage(messages.addPropertiesUnique, {
                    link: <a href="https://www.promoter.ninja/help/customization/adding-url-parameters-to-a-promoter-ninja-survey-link" target="_blank" rel="noopener noreferrer">{formatMessage(messages.addPropertiesUniqueLink)}</a>,
                  })}
                </Typography>
                <Box className={styles.properties}>
                  {fields.map((item, index) => (
                    <Box key={item.id} className={styles.property}>
                      <TextField
                        size="small"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                            e.preventDefault();
                            append({ _id: new ObjectID().toString(), name: '', value: '' });
                          }
                        }}
                        {...form.register(`properties.${index}.name`)}
                        label={formatMessage(messages.name)}
                      />
                      <TextField
                        size="small"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            e.stopPropagation();
                            e.preventDefault();
                            append({ _id: new ObjectID().toString(), name: '', value: '' });
                          }
                        }}
                        {...form.register(`properties.${index}.value`)}
                        label={formatMessage(messages.value)}
                      />
                      <IconButton onClick={() => remove(index)}>
                        <Close />
                      </IconButton>
                    </Box>
                  ))}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => {
                      append({
                        _id: new ObjectID().toString(),
                        name: '',
                        value: '',
                      });
                      document.querySelector(`.${styles.properties} .${styles.property} input`).focus();
                    }}
                  >
                    <FormattedMessage
                      id="LinkPage.addProperty"
                      defaultMessage="Add property"
                    />
                  </Button>
                </Box>
              </CardContent>
            </Card>
            <Box className={styles.actions}>
              <Button
                className={styles.saveChanges}
                variant="contained"
                color="primary"
                type="submit"
              >
                {formatMessage(isUpdating || isCreating ? messages.savingChanges : messages.saveChanges)}
              </Button>
              <Button
                variant="text"
                color="error"
                onClick={() => handleOpenConfirm({
                  title: (<FormattedMessage
                    id="SharePage.Are you sure"
                    defaultMessage="Are you sure you want to delete {name}?"
                    values={{ name }}
                  />),
                  text: (<FormattedMessage
                    id="SharePage.This will remove"
                    defaultMessage="This action will remove the link. If you have already shared it, it will no longer work. This action cannot be undone."
                  />),
                  onOk: async () => {
                    await deleteLink({ survey: id, _id: link });
                    navigate(returnLinkTo);
                  },
                })}
              >
                <FormattedMessage
                  id="LinkPage.deleteLink"
                  defaultMessage="Delete link"
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>
    </Layout>
  );
};

export default LinkPage;
