import { useAnalyzeEvolution } from 'client/modules/Analyze/AnalyzeEvolutionContext';
import formatDate from 'client/util/formatDate';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './AnalyzeEvolutionTooltip.css';

const AnalyzeEvolutionTooltip = (props) => {
  const {
    contentTooltip: {
      point: {
        key, ...series
      },
    },
    chartSeries,
    chartPeriod,
    field,
  } = props;
  const { searches } = useAnalyzeEvolution();
  const { locale, formatNumber } = useIntl();
  return (
    <div className={styles.tooltip}>
      <dl>
        <dt className={styles.date}>
          {formatDate(key, locale, chartPeriod === 'week')}
        </dt>
        <dd />
        {chartSeries.map((s) => {
          const search = searches.find(sr => sr._id === s.field);
          const seriesName = search?.name || s.field;
          return (
            <Fragment key={s.field}>
              <dt>
                <svg viewBox="0 0 15 10">
                  <path
                    stroke={s.color}
                    strokeWidth={s.strokeWidth}
                    strokeDasharray={s.strokeDasharray}
                    d="M0 5L15 5"
                  />
                </svg>
                {seriesName === 'all' ? (
                  <FormattedMessage
                    id="AnalyzeEvolutionTooltip.all"
                    defaultMessage="All"
                  />
                ) : seriesName}
              </dt>
              <dd>
                <FormattedMessage
                  id="AnalyzeEvolutionTooltip.score"
                  defaultMessage="{score} ({count} responses)"
                  values={{
                    score: formatNumber(series[s.field]?.[field]),
                    count: formatNumber(series[s.field]?.total),
                  }}
                />
              </dd>
            </Fragment>
          );
        })}
      </dl>
    </div>
  );
};

AnalyzeEvolutionTooltip.propTypes = {
  chartSeries: PropTypes.arrayOf(PropTypes.string).isRequired,
  chartPeriod: PropTypes.string.isRequired,
  contentTooltip: PropTypes.shape({
    point: PropTypes.shape({
      key: PropTypes.string,
    }),
  }),
  field: PropTypes.string.isRequired,
};

AnalyzeEvolutionTooltip.defaultProps = {
  contentTooltip: { point: {} },
};
export default AnalyzeEvolutionTooltip;
