import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

import Header from '../Header/Header';
import Message from '../Message/Message';

import styles from './Layout.css';

const Layout = (props) => {
  const navigate = useNavigate();
  return (
    <div className={props.className || styles.innerContainer}>
      {props.showHeader ? (
        <Header
          className={styles.header}
          leftNav={props.leftNav}
          rightNav={props.rightNav}
          hasReturn={props.returnLinkTo && props.returnLinkText}
        />
      ) : null}
      {props.secondaryNav
        ? (
          <div className={styles.secondaryNav}>
            <div className={styles.container}>
              {props.secondaryNav}
            </div>
          </div>
        )
        : null}
      {props.backContent || (props.returnLinkTo && props.returnLinkText)
        ? (
          <div className={styles.backContainer}>
            <div className={styles.container}>
              {!props.backContent || (
                props.backContent
              )}
              {!props.backContent && (
                (!props.backContent && props.returnLinkTo === 'back')
                  ? (
                    <button
                      className={styles.back}
                      type="button"
                      onClick={() => navigate(-1)}
                      tabIndex="0"
                    >
                      {props.returnLinkText}
                    </button>
                  )
                  : <Link className={styles.back} to={props.returnLinkTo}>{props.returnLinkText}</Link>
              )}
            </div>
          </div>
        )
        : null}
      {props.error
        ? (
          <Message
            type="error"
            msg={props.error}
            onClose={props.setError}
          />
        )
        : null}
      {props.ok ? (
        <Message
          type="ok"
          msg={props.ok}
          onClose={props.setOk}
        />
      ) : null}

      {props.info
        ? (
          <Message
            type="info"
            msg={props.info}
            onClose={props.setInfo}
          />
        )
        : null
      }
      <div id="content" className={cx({ [styles.content]: true, [styles.gutters]: !props.disableGutters })}>
        {props.children}
      </div>
      <Footer className={styles.footer} />
    </div>
  );
};

Layout.defaultProps = {
  leftNav: null,
  rightNav: null,
  children: null,
  returnLinkTo: null,
  returnLinkText: null,
  className: null,
  showHeader: true,
  error: null,
  setError: null,
  ok: null,
  setOk: null,
  info: null,
  setInfo: null,
  secondaryNav: null,
  disableGutters: false,
  backContent: null,
};

Layout.propTypes = {
  children: PropTypes.any,
  leftNav: PropTypes.object,
  rightNav: PropTypes.object,
  returnLinkTo: PropTypes.string,
  returnLinkText: PropTypes.string,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  error: PropTypes.object,
  ok: PropTypes.object,
  setError: PropTypes.func,
  setOk: PropTypes.func,
  info: PropTypes.object,
  setInfo: PropTypes.func,
  secondaryNav: PropTypes.node,
  disableGutters: PropTypes.bool,
  backContent: PropTypes.node,
};

export default Layout;
