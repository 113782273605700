import { createReducer } from '@reduxjs/toolkit';
import { closeConfirm, openConfirm } from 'components/Confirm/ConfirmActions';

export const confirmSelector = state => state.confirm;

const initialState = {
  open: false,
  title: '',
  text: '',
  onOk: () => {},
  textOk: null,
  textCancel: null,
  danger: false,
};

const ConfirmReducer = createReducer(initialState, (builder) => {
  builder.addCase(openConfirm, (state, action) => {
    const {
      payload: {
        open, title, text, onOk, textOk, textCancel, danger
      },
    } = action;
    return ({
      open,
      title,
      text,
      onOk,
      textOk,
      textCancel,
      danger,
    });
  });
  builder.addCase(closeConfirm, () => initialState);
});

export default ConfirmReducer;
