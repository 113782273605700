import { Close, OpenInNew } from '@mui/icons-material';
import {
  Alert,
  Box, Button, FormControl, InputLabel, Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { ObjectID } from 'bson';
import SelectSurvey from 'client/modules/Settings/pages/IntegrationsPage/components/SelectSurvey';
import styles from 'client/modules/Settings/pages/IntegrationsPage/IntegrationsListPage.css';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import DefaultCopyField from 'client/util/CopyField';
import generateToken from 'client/util/generateToken';
import React, { useEffect } from 'react';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import Config from '../../../../../../server/config';

const messages = defineMessages({
  trigger: {
    id: 'IntegrationConfigSalesforce.Trigger',
    defaultMessage: 'Trigger',
  },
  delay: {
    id: 'IntegrationConfigSalesforce.Delay',
    defaultMessage: 'Send survey after',
  },
  createdCase: {
    id: 'IntegrationConfigSalesforce.CreatedCase',
    defaultMessage: 'Case created',
  },
  closedCase: {
    id: 'IntegrationConfigSalesforce.ClosedCase',
    defaultMessage: 'Case closed',
  },
  createdOpportunity: {
    id: 'IntegrationConfigSalesforce.CreatedOpportunity',
    defaultMessage: 'Opportunity created',
  },
  wonOpportunity: {
    id: 'IntegrationConfigSalesforce.WonOpportunity',
    defaultMessage: 'Opportunity won',
  },
  lostOpportunity: {
    id: 'IntegrationConfigSalesforce.LostOpportunity',
    defaultMessage: 'Opportunity lost',
  },
  Custom: {
    id: 'IntegrationConfigSalesforce.Custom',
    defaultMessage: 'Custom',
  },
  Immediately: {
    id: 'IntegrationConfigSalesforce.Immediately',
    defaultMessage: 'Immediately',
  },
  '1 Hour': {
    id: 'IntegrationConfigSalesforce.1Hour',
    defaultMessage: '1 Hour',
  },
  '1 Day': {
    id: 'IntegrationConfigSalesforce.1Day',
    defaultMessage: '1 Day',
  },
  '1 Week': {
    id: 'IntegrationConfigSalesforce.1Week',
    defaultMessage: '1 Week',
  },
  noTriggers: {
    id: 'IntegrationConfigSalesforce.NoTriggers',
    defaultMessage: 'No triggers',
  },
  outboundMessageUrl: {
    id: 'IntegrationConfigSalesforce.OutboundMessageUrl',
    defaultMessage: 'The target URL for your Salesforce outbound message.',
  },
});

const objectEvents = {
  Case: ['created', 'closed'],
  Opportunity: ['created', 'won', 'lost'],
  Custom: [''],
};
const triggerTypes = Object.keys(objectEvents).map(object => objectEvents[object].map(event => ({
  value: [object, event].filter(v => v).join(' '),
  label: `${event}${object}`,
}))).flat();
const IntegrationConfigSalesforce = () => {
  const { formatMessage } = useIntl();
  const {
    integration: {
      instanceUrl,
      notificationToken,
    }, onIntegrationsPropertiesChange,
  } = useOutletContext();
  const [searchParams] = useSearchParams();
  const installPackage = searchParams.get('installPackage');
  const surveys = useSelector(getSurveys);
  const survey = surveys.length === 1 ? surveys[0].id : null;
  const { register } = useFormContext();
  const triggers = useWatch({ name: 'triggers' });
  useEffect(() => {
    onIntegrationsPropertiesChange(null, 'salesforce', { triggers });
  }, [triggers]);
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'triggers',
  });
  const [host] = instanceUrl && new URL(instanceUrl).host.split('.');
  return (
    <Box sx={{
      display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 3, mb: 3,
    }}
    >
      <Typography sx={{ gap: 1, display: 'inline-flex' }}>
        Site:
        <a href={instanceUrl} target="_blank" rel="noreferrer">
          {host}
          <OpenInNew sx={{ fontSize: 16, verticalAlign: 'middle', ml: 0.5 }} />
        </a>
      </Typography>

      {!!installPackage && (
        <Alert severity="warning" sx={{ width: '100%' }}>
          <FormattedMessage
            id="IntegrationConfigSalesforce.PackageNotInstalled"
            defaultMessage="Promoter Ninja package must be installed in Salesforce to enable this integration. {link}."
            values={{
              link: (
                <a href={installPackage} target="_blank" rel="noreferrer">
                  <FormattedMessage
                    id="IntegrationConfigSalesforce.PackageNotInstalledLink"
                    defaultMessage="Install package"
                  />
                </a>
              ),
            }}
          />
        </Alert>
      )}

      {!fields.length && (
        <Typography color="muted.main" sx={{ alignSelf: 'center' }}>
          {formatMessage(messages.noTriggers)}
        </Typography>
      )}

      {fields.map((trigger, index) => {
        return (
          <Box sx={{ width: '100%' }}>
            <Box
              key={trigger._id}
              sx={{
                display: 'flex',
                gap: 1,
                alignItems: 'center',
                width: '100%',
              }}
            >
              <FormControl variant="standard" className={styles.ruleInput}>
                <InputLabel
                  htmlFor={`select-trigger-${trigger._id}`}
                  shrink
                >
                  {formatMessage(messages.trigger)}
                </InputLabel>
                <Select
                  required
                  {...register(`triggers.${index}.trigger`)}
                  defaultValue={trigger.trigger}
                  id={`select-trigger-${trigger._id}`}
                  variant="standard"
                  className={styles.input}
                  fullWidth
                  autoWidth
                >
                  {triggerTypes.map(({ label, value }) => (
                    <MenuItem value={value}>
                      {formatMessage(messages[label])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <SelectSurvey field="triggers" index={index} defaultValue={trigger.survey} />
              <FormControl variant="standard" className={styles.ruleInput}>
                <InputLabel
                  htmlFor={`select-delay-${trigger._id}`}
                  shrink
                >
                  {formatMessage(messages.delay)}
                </InputLabel>
                <Select
                  required
                  {...register(`triggers.${index}.delay`)}
                  defaultValue={trigger.delay}
                  id={`select-delay-${trigger._id}`}
                  variant="standard"
                  className={styles.input}
                  fullWidth
                  autoWidth
                >
                  {['Immediately', '1 Hour', '1 Day', '1 Week'].map(value => (
                    <MenuItem value={value}>
                      {formatMessage(messages[value])}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <IconButton sx={{ alignSelf: 'flex-start', top: 25 }} onClick={() => remove(index)}>
                <Close />
              </IconButton>
            </Box>
            { triggers[index]?.trigger === 'Custom' && (
              <>
                <Typography variant="caption">
                  <FormattedMessage
                    id="IntegrationConfigSalesforce.CustomTrigger"
                    defaultMessage={'Establish personalized triggers in Salesforce for specific entity categories such as '
                        + 'Account, Case, Contact, and Opportunity by following the guidelines outlined in our {documentationLink}.'}
                    values={{
                      documentationLink: (
                        <a
                          href="https://www.promoter.ninja/help/integrations/salesforce-integration"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <FormattedMessage
                            id="IntegrationConfigSalesforce.CustomTriggerDocumentation"
                            defaultMessage="documentation"
                          />
                        </a>
                      ),
                    }}
                  />
                </Typography>
                <DefaultCopyField
                  label={formatMessage(messages.outboundMessageUrl)}
                  variant="standard"
                  value={`${Config.rootUrl}/api/public/integrations/salesforce/notification/${notificationToken}/${triggers[index]?.suffix}`}
                  fullWidth
                />
              </>
            )}
          </Box>
        );
      })}

      <Button onClick={() => append({
        _id: new ObjectID().toString(), survey, delay: 'Immediately', suffix: generateToken(6),
      })}
      >
        <FormattedMessage id="IntegrationConfigSalesforce.AddTrigger" defaultMessage="Add Trigger" />
      </Button>
    </Box>
  );
};

export default IntegrationConfigSalesforce;
