import { AggsContainer, BoolMust, BoolMustNot, FilterBasedAccessor, State } from 'searchkit';

export const ExistsQuery = (field) => {
  return {
    exists: {
      field,
    },
  };
};

const addFilter = (filter, filters) => (filters ? BoolMust([filters, filter]) : filter);
const ExistsFilter = (field, filters) => addFilter(BoolMust([ExistsQuery(field)]), filters);
const NotExistsFilter = (field, filters) => addFilter(BoolMustNot([ExistsQuery(field)]), filters);

class ExistsFieldFilterAccessor extends FilterBasedAccessor {

  constructor(key, options) {
    super(key, options.id);
    this.state = new State('');
    this.options = options;
    this.field = options.field;
    this.translations = Object.assign({}, options.translations);
  }

  getWithDocCount() {
    return this.getAggregations([this.uuid, 'buckets', '0', 'doc_count'], 0);
  }

  getWithoutDocCount() {
    return this.getAggregations([this.uuid, 'buckets', '1', 'doc_count'], 0);
  }

  buildSharedQuery(query) {
    let ret = query;
    if (['with', 'without'].indexOf(this.state.getValue()) > -1) {
      const filter = this.state.getValue() === 'with' ? ExistsFilter(this.field) : NotExistsFilter(this.field);
      const value = this.state.getValue() === 'with' ?
        this.options.labelWith || this.translate('exists.with') :
        this.options.labelWithout || this.translate('exists.without');
      ret = ret.addFilter(this.uuid, filter)
        .addSelectedFilter({
          name: this.options.title || this.translate(this.key),
          value,
          id: this.options.id,
          remove: () => {
            this.state = this.state.setValue('');
            return this.state;
          },
        });
    }

    return ret;
  }

  buildOwnQuery(query) {
    const FiltersBucket = (key, filters, ...childAggs) => {
      return AggsContainer(key, { filters: { filters } }, childAggs);
    };
    const filters = query.getFilters(this.uuid);
    return query
      .setAggs(
        FiltersBucket(
          this.uuid,
          [ExistsFilter(this.field, filters), NotExistsFilter(this.field, filters)]
        ));
  }
}

export default ExistsFieldFilterAccessor;
