/* eslint-disable react/prop-types */
import React, { Component } from 'react';

import {
  Xaxis, Xgrid, Yaxis, Ygrid,
} from 'mhs-d3-core';

import LineDot from './LineDot';
import CommonProps from '../CommonProps';
import isTooltipUpdate from '../utils/isTooltipUpdate';

class LineChart extends Component {
  render() {
    const {
      showXGrid,
      showYGrid,
      xTooltip,
      yRange,
      showTooltipLine,
    } = this.props;

    let xgrid;
    let ygrid;

    if (showXGrid) xgrid = <Xgrid {...this.props} />;
    if (showYGrid) ygrid = <Ygrid {...this.props} />;

    return (
      <g>
        <rect
          className={this.props.backClassName}
          fill="#fff"
          stroke="#ecece8"
          width={this.props.width - this.props.margins.left - this.props.margins.right}
          height={this.props.height - this.props.margins.top - this.props.margins.bottom}
          rx="5"
          ry="5"
        />
        {xgrid}
        {ygrid}
        <Xaxis {...this.props} />
        <Yaxis {...this.props} />
        {xTooltip && showTooltipLine && (
          <line
            stroke="#2196f3"
            x1={xTooltip - this.props.margins.left}
            y1={yRange[1]}
            x2={xTooltip - this.props.margins.left}
            y2={yRange[0]}
          />
        )}
        <LineDot {...this.props} />
      </g>
    );
  }
}

LineChart.defaultProps = CommonProps;

export default LineChart;
