import { Add } from '@mui/icons-material';
import {
  Box, Button, CardContent, Typography,
} from '@mui/material';
import AudienceCard from 'client/modules/Surveys/pages/CustomizeSurvey/AudienceCard';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

const messages = defineMessages({
  subtitle: {
    id: 'TargetingAudiences.Subtitle',
    defaultMessage: 'Set up audiences to show your survey only to specific groups of visitors.',
  },
});

const styles = {
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    my: 2,
    mx: 1,
  },
  button: {
    borderRadius: 2,
    border: '1px dashed #e0e0d9',
    width: '100%',
    p: 4,
  },
};

const TargetingAudiences = () => {
  const { formatMessage } = useIntl();
  const {
    fields, append, remove,
  } = useFieldArray({
    name: 'audiences',
  });
  const handleAdd = useCallback(() => {
    append({ conditions: [{ operator: 'in', value: [], field: '' }] });
  }, []);
  return (
    <CardContent sx={{
      borderWidth: 0,
      borderColor: 'muted.lighter',
      borderStyle: 'solid',
      borderTopWidth: 1,
    }}
    >
      <Box>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
          {formatMessage(messages.subtitle)}
        </Typography>
      </Box>
      <Box sx={styles.list}>
        {fields.map((field, index) => {
          return (
            <AudienceCard
              key={field.id}
              index={index}
              remove={() => remove(index)}
            />
          );
        })}
        <Button sx={styles.button} startIcon={<Add />} onClick={handleAdd}>
          <FormattedMessage
            id="TargetingAudiences.AddNewAudience"
            defaultMessage="Add new audience"
          />
        </Button>
      </Box>
    </CardContent>
  );
};

export default TargetingAudiences;
