/* eslint-disable react/prop-types, react/destructuring-assignment */
import React from 'react';

import Select from 'react-select';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(selectedOptions = []) {
    this.props.setItems(selectedOptions.map(el => (el.value ? el.value : el)));
  }

  render() {
    const {
      placeholder, clearable = true, items, selectedItems = [], disabled, showCount,
    } = this.props;

    const options = items.map((option) => {
      let label = option.title || option.label || option.key;
      if (showCount) label += ` (${option.doc_count}) `;
      return { value: option.key, label };
    });

    return (
      <Select
        isMulti
        disabled={disabled}
        value={selectedItems.map(value => ({ value, label: value }))}
        placeholder={placeholder}
        options={options}
        clearable={clearable}
        onChange={this.handleChange}
        styles={{ menu: provided => ({ ...provided, position: 'relative', zIndex: '999 !important' }) }}
      />
    );
  }
}

export default MultiSelect;
