import React from 'react';
import { getToken } from '../../../Auth/AuthReducer';
import { redirectToCustomerPortal } from '../../SubscriptionActions';

export const redirect = (store, nextState, replace, callback) => {
  store.dispatch(redirectToCustomerPortal(getToken(store.getState()), replace)).then(() => {
    callback();
  }).catch((err) => {
    callback();
    throw err;
  });
};

const SubscriptionsPageContainer = () => <div />;

export default SubscriptionsPageContainer;
