import React from 'react';
import Icon from 'react-icon-base';

const Csat = props => (
  <Icon viewBox="0 0 127 36" {...props}>
    <g>
      <path
        d="M31.6,24.3a10.2,10.2,0,0,1-.8,3.6,12.5,12.5,0,0,1-2.6,3.7,10.8,10.8,0,0,1-4.4,3,15.4,15.4,0,0,1-6.3,1.2,18.8,18.8,0,0,1-4.9-.6,11.3,11.3,0,0,1-4.1-1.6,12.3,12.3,0,0,1-3.3-2.8,16.9,16.9,0,0,1-2.3-3.6,21.9,21.9,0,0,1-1.5-4.3A24.9,24.9,0,0,1,1,18a21.7,21.7,0,0,1,1.2-7.4A15.9,15.9,0,0,1,5.6,5a14.4,14.4,0,0,1,5.3-3.5A15.6,15.6,0,0,1,17.3.2a16.6,16.6,0,0,1,7.5,1.7,14.7,14.7,0,0,1,5,4.1,8.4,8.4,0,0,1,1.7,4.6,3.7,3.7,0,0,1-.8,2.2,2.9,2.9,0,0,1-2.1.9,2.6,2.6,0,0,1-2-.7,11.6,11.6,0,0,1-1.5-2.1A10.7,10.7,0,0,0,21.9,7a7.9,7.9,0,0,0-4.5-1.2A8.1,8.1,0,0,0,10.6,9C8.9,11.2,8,14.3,8,18.3a17.5,17.5,0,0,0,1.1,6.6,8.7,8.7,0,0,0,3.2,4,8.8,8.8,0,0,0,4.9,1.3,8.2,8.2,0,0,0,5-1.5,8.5,8.5,0,0,0,3.2-4.3,5.7,5.7,0,0,1,1.1-2.2,2.3,2.3,0,0,1,2.1-.9,3.1,3.1,0,0,1,3,3Z"
      />
      <path
        d="M64,24.9a11,11,0,0,1-1.6,5.6,12.2,12.2,0,0,1-4.7,3.9,18.8,18.8,0,0,1-7.4,1.4,16.3,16.3,0,0,1-8.4-2,11.9,11.9,0,0,1-3.8-3.6,8.4,8.4,0,0,1-1.4-4.5,2.9,2.9,0,0,1,.8-2.2,3.2,3.2,0,0,1,2.3-.9,2.4,2.4,0,0,1,1.8.7,5.4,5.4,0,0,1,1.3,2.1,11.3,11.3,0,0,0,1.5,2.7,4.6,4.6,0,0,0,2.1,1.8,7.4,7.4,0,0,0,3.6.8,8.1,8.1,0,0,0,5.1-1.5,4.4,4.4,0,0,0,1.9-3.6A4.1,4.1,0,0,0,56,22.8a6.1,6.1,0,0,0-2.6-1.6A39.6,39.6,0,0,0,48.9,20a34.8,34.8,0,0,1-6.2-2,9.5,9.5,0,0,1-3.9-3.2,8,8,0,0,1-1.5-5,8.3,8.3,0,0,1,1.6-5,9.5,9.5,0,0,1,4.4-3.4A18.5,18.5,0,0,1,50.2.2a17.7,17.7,0,0,1,5.5.8,10.7,10.7,0,0,1,3.8,2.1,11,11,0,0,1,2.3,2.7,7.7,7.7,0,0,1,.7,2.8,3.3,3.3,0,0,1-.9,2.3,3,3,0,0,1-2.2,1,2.4,2.4,0,0,1-1.8-.6,9.5,9.5,0,0,1-1.4-2,9,9,0,0,0-2.2-3c-.9-.8-2.3-1.1-4.2-1.1a7.4,7.4,0,0,0-4.4,1.2,3.3,3.3,0,0,0-1.6,2.8,2.7,2.7,0,0,0,.5,1.8,5.3,5.3,0,0,0,1.6,1.3l2,.8,3.3.9,5.2,1.5a17.4,17.4,0,0,1,4.1,2,8.1,8.1,0,0,1,2.5,3A9.5,9.5,0,0,1,64,24.9Z"
      />
      <path
        d="M91.8,31.3,90.2,27h-14l-1.6,4.4A17.2,17.2,0,0,1,73,34.9a2.7,2.7,0,0,1-2.3.9,3.7,3.7,0,0,1-2.3-1,2.8,2.8,0,0,1-1-2.2,7.8,7.8,0,0,1,.2-1.4c.2-.5.5-1.2.8-2.1L77.2,6.8a17.8,17.8,0,0,1,.9-2.3,16.7,16.7,0,0,1,1.1-2.3A4.4,4.4,0,0,1,80.8.8a5.1,5.1,0,0,1,4.8,0,4.4,4.4,0,0,1,1.6,1.4,11.4,11.4,0,0,1,1,1.9c.3.6.6,1.5,1,2.6l9,22.2a11.6,11.6,0,0,1,1.1,3.7,3.4,3.4,0,0,1-1,2.2,3.7,3.7,0,0,1-2.4,1,2.9,2.9,0,0,1-1.4-.3,3.9,3.9,0,0,1-1-.8,7.6,7.6,0,0,1-.9-1.6A15.9,15.9,0,0,0,91.8,31.3ZM78.1,21.8H88.3L83.2,7.6Z"
      />
      <path
        d="M122.5,6.6h-7.6V31.5a4.2,4.2,0,0,1-1,3.2,3.1,3.1,0,0,1-2.5,1.1,3.4,3.4,0,0,1-2.5-1.1,4.9,4.9,0,0,1-1-3.2V6.6h-7.5a4.1,4.1,0,0,1-2.7-.8,2.6,2.6,0,0,1-.9-2.1,2.6,2.6,0,0,1,.9-2.1,4.1,4.1,0,0,1,2.7-.8h22.1a4.1,4.1,0,0,1,2.7.8,2.5,2.5,0,0,1,.8,2.1,2.9,2.9,0,0,1-.8,2.1A4.1,4.1,0,0,1,122.5,6.6Z"
      />
    </g>
  </Icon>
);

export default Csat;
