import defaults from 'lodash/defaults';
import { AbstractItemList } from 'searchkit';

import styles from './Progress.css';
import ProgressBarItem from './ProgressBarItem';

class Progress extends AbstractItemList {
}

Progress.defaultProps = defaults({
  itemComponent: ProgressBarItem,
  mod: styles.progress,
}, AbstractItemList.defaultProps);

export default Progress;
