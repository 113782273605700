import { createAction } from 'redux-actions';

export const setError = (msg, timeout) => {
  return (dispatch) => {
    dispatch(createAction('SET_ERROR')(msg));
    if (timeout) {
      setTimeout(() => {
        dispatch(createAction('SET_ERROR')(''));
      }, timeout);
    }
  };
};

export const setInfo = (msg) => {
  return (dispatch) => {
    dispatch(createAction('SET_INFO')(msg));
  };
};

export const setOk = (msg, timeout) => {
  return (dispatch) => {
    dispatch(createAction('SET_OK')(msg));
    if (timeout) {
      setTimeout(() => {
        dispatch(createAction('SET_OK')(''));
      }, timeout);
    }
  };
};
