import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import style from './Testimonial.css';

import bsStyle from '../../../../../App/components/Bootstrap/Bootstrap.css';

const bs = classNames.bind(bsStyle);

const Testimonial = (props) => {
  const { author, pic, pic2x, company, comment, ...otherProps } = props;
  return (
    <div {...otherProps}>
      <p className={style.comment}>{props.comment}</p>
      <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
        <img
          src={props.pic}
          alt={props.author}
          className={bs('mbr-figure__img')}
          srcSet={`${props.pic} ${props.width}w, ${props.pic2x} ${props.width * 2}w`}
        />
      </figure>
      <span className={style.author}>{props.author} - {props.company}</span>
    </div>
  );
};

Testimonial.propTypes = {
  author: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  pic: PropTypes.string.isRequired,
  pic2x: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export default Testimonial;
