import Spinner from 'components/Spinner/Spinner';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';

const AsyncComponent = ({ children }) => (
  <Suspense fallback={<Spinner />}>
    {children}
  </Suspense>
);

AsyncComponent.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AsyncComponent;
