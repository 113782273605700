import { defineMessages } from 'react-intl';

const integrationMessages = defineMessages({
  gsheets: {
    id: 'IntegrationsSettings.GoogleSheets',
    defaultMessage: 'Google Sheets',
  },
  gsheetsDescription: {
    id: 'IntegrationsSettings.GoogleSheetsDescription',
    defaultMessage: 'Send Promoter Ninja scores and comments to Google Sheets.',
  },
  hubspotDescription: {
    id: 'IntegrationsSettings.HubspotDescription',
    defaultMessage: 'Sync Promoter Ninja scores and comments with contacts in HubSpot.',
  },
  zendeskDescription: {
    id: 'IntegrationsSettings.ZendeskDescription',
    defaultMessage:
      'Automatically open tickets in Zendesk. Sync Promoter Ninja scores with people in Zendesk. Survey customers automatically after a ticket is solved or closed.',
  },
  slackDescription: {
    id: 'IntegrationsSettings.SlackDescription',
    defaultMessage: 'Send Promoter Ninja scores and comments to a Slack channel.',
  },
  hubspot: {
    id: 'IntegrationsSettings.Hubspot',
    defaultMessage: 'Hubspot',
  },
  zendesk: {
    id: 'IntegrationsSettings.Zendesk',
    defaultMessage: 'Zendesk',
  },
  slack: {
    id: 'IntegrationsSettings.Slack',
    defaultMessage: 'Slack',
  },
  salesforce: {
    id: 'IntegrationsSettings.Salesforce',
    defaultMessage: 'Salesforce',
  },
  salesforceDescription: {
    id: 'IntegrationsSettings.SalesforceDescription',
    defaultMessage: 'Trigger Promoter Ninja surveys from Salesforce.',
  },
  webhooks: {
    id: 'IntegrationsSettings.Webhooks',
    defaultMessage: 'Webhooks',
  },
  webhooksDescription: {
    id: 'IntegrationsSettings.WebhooksDescription',
    defaultMessage: 'Send an HTTP Post with any new or updated survey response.',
  },
});

export default integrationMessages;
