import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box, Button, DialogActions, DialogContent, DialogContentText, DialogTitle, InputAdornment, TextField, Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import {
  deleteMeAction, logoutUser as logoutUserAction, updateEmailAction, updatePassword as updatePasswordAction,
} from 'client/modules/Auth/AuthActions';
import { getProfile } from 'client/modules/Auth/AuthReducer';
import { checkSubscriptionAction } from 'client/modules/Subscriptions/SubscriptionActions';
import { getSubscription } from 'client/modules/Subscriptions/SubscriptionReducer';
import useActions from 'client/util/useActions';
import FlatButton from 'components/FlatButton/FlatButton';
import HttpStatusCodes from 'http-status-codes';
import React, { useCallback, useEffect, useState } from 'react';

import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './AccountSettingsPage.css';

const messages = defineMessages({
  title: {
    id: 'AccountSettings.Title',
    defaultMessage: 'Account settings',
  },
  changePassword: {
    id: 'AccountSettings.ChangePassword',
    defaultMessage: 'Change password',
  },
  changeEmail: {
    id: 'AccountSettings.ChangeEmail',
    defaultMessage: 'Change email',
  },
  deleteAccount: {
    id: 'AccountSettings.DeleteAccount',
    defaultMessage: 'Delete account',
  },
  areYouSure: {
    id: 'AccountSettings.AreYouSure',
    defaultMessage: 'Are you sure you want to permanently delete your account?',
  },
  yes: {
    id: 'AccountSettings.Yes',
    defaultMessage: 'Yes',
  },
  no: {
    id: 'AccountSettings.No',
    defaultMessage: 'No',
  },
  wrongPassword: {
    id: 'AccountSettings.WrongPassword',
    defaultMessage: 'Wrong password',
  },
});

const AccountSettingsPage = () => {
  const [checkEmailOpen, setCheckEmailOpen] = useState(false);
  const closeCheckEmail = useCallback(() => setCheckEmailOpen(false), [setCheckEmailOpen]);
  const [updatePassword, updateEmail, logoutUser, deleteMe, checkSubscription] = useActions([updatePasswordAction, updateEmailAction, logoutUserAction, deleteMeAction, checkSubscriptionAction]);
  const profile = useSelector(getProfile);
  const subscription = useSelector(getSubscription);
  const [email, setEmail] = useState(profile.email);
  const [currentPassword, setCurrentPassword] = useState();
  const [currentPasswordVisible, setcurrentPasswordVisible] = useState(false);
  const [newPassword, setNewPassword] = useState();
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => setOpen(true), [setOpen]);
  const close = useCallback(() => setOpen(false), [setOpen]);
  const { formatMessage } = useIntl();
  const confirmDelete = useCallback(() => {
    deleteMe().then(() => {
      logoutUser();
    });
  }, []);
  const changeEmail = useCallback(() => {
    updateEmail(email).then(() => setCheckEmailOpen(true));
  }, [updateEmail, email]);
  const [passwordError, setPasswordError] = useState('');
  const changePassword = useCallback(() => {
    updatePassword(currentPassword, newPassword).catch((e) => {
      if (e.status === HttpStatusCodes.UNAUTHORIZED) {
        setPasswordError(formatMessage(messages.wrongPassword));
      }
    });
  }, [updatePassword, currentPassword, newPassword]);
  useEffect(() => {
    checkSubscription();
  }, []);
  return (
    <Paper className={styles.form} style={{ padding: '2em' }}>
      <Helmet title={formatMessage(messages.title)} />
      <Box>
        <Box component="h2">{formatMessage(messages.changeEmail)}</Box>
        <TextField
          variant="standard"
          label={(
            <FormattedMessage
              id="AccountSettings.Email"
              defaultMessage="email"
            />
          )}
          id="email"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <FlatButton
          label={formatMessage(messages.changeEmail)}
          primary
          onClick={changeEmail}
        />
        <Box component="h2" sx={{ mt: 12 }}>{formatMessage(messages.changePassword)}</Box>
        <TextField
          error={!!passwordError}
          helperText={passwordError}
          type={currentPasswordVisible ? 'text' : 'password'}
          variant="standard"
          label={(
            <FormattedMessage
              id="AccountSettings.CurrentPassword"
              defaultMessage="Current password"
            />
          )}
          id="currentPassword"
          value={currentPassword}
          onChange={e => setCurrentPassword(e.target.value)}
          sx={{ display: 'flex', width: 256 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setcurrentPasswordVisible(v => !v)}
                  onMouseDown={e => e.preventDefault()}
                  tabIndex={-1}
                  size="large"
                >
                  {currentPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type={newPasswordVisible ? 'text' : 'password'}
          variant="standard"
          label={(
            <FormattedMessage
              id="AccountSettings.NewPassword"
              defaultMessage="New password"
            />
          )}
          id="newPassword"
          value={newPassword}
          onChange={e => setNewPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setNewPasswordVisible(v => !v)}
                  onMouseDown={e => e.preventDefault()}
                  tabIndex={-1}
                  size="large"
                >
                  {newPasswordVisible ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FlatButton
          label={formatMessage(messages.changePassword)}
          primary
          onClick={changePassword}
        />
        <Box component="h2" sx={{ color: 'error.main', mt: 12 }}>{formatMessage(messages.deleteAccount)}</Box>
        <Button
          disabled={!!subscription}
          variant="outlined"
          color="error"
          onClick={open}
          sx={{ mt: 2 }}
        >
          {formatMessage(messages.deleteAccount)}
        </Button>
        {!!subscription && (
          <Typography sx={{ mt: 2 }}>
            <FormattedMessage
              id="AccountSettings.ActiveSubscription"
              defaultMessage="You can't delete your account while you have an active subscription. Please cancel your subscription first by going to the {link}."
              values={{
                link: <Link to="/subscriptions"><FormattedMessage id="AccountSettings.SubscriptionsPage" defaultMessage="subscriptions page" /></Link>,
              }}
            />
          </Typography>
        )}
      </Box>
      <Dialog open={checkEmailOpen} onClose={closeCheckEmail}>
        <DialogTitle>
          <FormattedMessage
            id="AccountSettings.VerifyEmail.Title"
            defaultMessage="Check your email"
          />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="AccountSettings.VerifyEmail.PleaseCheck"
              defaultMessage="We've sent you an email to {email}. Please click the link in the email to verify your email address."
              values={{ email }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCheckEmail} color="primary" autoFocus>
            <FormattedMessage
              id="AccountSettings.Ok"
              defaultMessage="Ok"
            />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        modal={false}
        open={isOpen}
        onRequestClose={close}
      >
        <DialogTitle>{formatMessage(messages.areYouSure)}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              id="AccountSettings.Irreversible"
              defaultMessage="Deleting your account will permanently delete all your data including surveys and answers. This action can NOT be undone. Are you sure?"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatButton
            label={formatMessage(messages.yes)}
            color="error"
            onClick={confirmDelete}
          />
          <FlatButton
            label={formatMessage(messages.no)}
            color="inherit"
            autoFocus
            onClick={close}
          />
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default AccountSettingsPage;
