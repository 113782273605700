import { createAction } from 'redux-actions';
import { getI18n } from '../../AppReducer';
import callApi from '../../util/apiCaller';

export const fetchContentCb = createAction('FETCH_CONTENT_CB');
export const fetchContentErrorCb = createAction('FETCH_CONTENT_ERROR_CB');

export const listCategoriesCb = createAction('LIST_CATEGORIES_CB');
export const listCategoriesErrorCb = createAction('LIST_CATEGORIES_ERROR_CB');

export function fetchContent(key) {
  return (dispatch, getState) => {
    const { locale } = getI18n(getState());
    return callApi(dispatch, `content/${key}/${locale}`, null, 'GET').then((res) => {
      dispatch(fetchContentCb({ locale, ...res }));
      return { locale, ...res };
    }).catch((err) => {
      dispatch(fetchContentErrorCb(err));
      throw err;
    });
  };
}

export function listCategories() {
  return (dispatch, getState) => {
    const { locale } = getI18n(getState());
    return callApi(dispatch, `category/${locale}`, null, 'GET').then((res) => {
      dispatch(listCategoriesCb({ locale, ...res }));
      return { locale, ...res };
    }).catch((err) => {
      dispatch(listCategoriesErrorCb(err));
      throw err;
    });
  };
}
