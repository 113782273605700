import { Add } from '@mui/icons-material';
import MoreVert from '@mui/icons-material/MoreVert';
import { Button, TableContainer } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ApiKeyCreatedDialog from 'client/modules/Settings/pages/ApiKeysPage/ApiKeyCreatedDialog';
import ApiKeyDialog from 'client/modules/Settings/pages/ApiKeysPage/ApiKeyDialog';
import { useDeleteApiKeyMutation, useReadApiKeysQuery, useResetApiKeyMutation } from 'client/modules/Surveys/apiKeysSlice';
import FormatDateCalendar from 'client/util/FormatDateCalendar';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import IconMenu from 'components/IconMenu/IconMenu';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ApiKeysList = () => {
  const [isOpen, open, close, args] = useOpenClose();
  const [createdOpen, openCreated, closeCreated, argsCreated] = useOpenClose();
  const [reset] = useResetApiKeyMutation();
  const [remove] = useDeleteApiKeyMutation();
  const { data: apiKeys } = useReadApiKeysQuery();
  const handleOpenConfirm = useActions(openConfirm);
  const handleDelete = (apiKey) => {
    handleOpenConfirm({
      title: (<FormattedMessage
        id="ApiKeysSettings.ApiKeys.ConfirmDelete"
        defaultMessage="Are you sure you want to delete the API key {name}?"
        values={{ name: apiKey.name }}
      />),
      text: (
        <FormattedMessage
          id="ApiKeysSettings.ApiKeys.ConfirmDeleteText"
          defaultMessage="This will remove access for all apps that use this key. This action cannot be undone."
        />
      ),
      onOk: async () => {
        await remove(apiKey);
      },
      textOk: (<FormattedMessage
        id="ApiKeysSettings.ApiKeys.Delete"
        defaultMessage="Delete"
      />),
      danger: true,
    });
  };
  const handleReset = (apiKey) => {
    handleOpenConfirm({
      title: (<FormattedMessage
        id="ApiKeysSettings.ApiKeys.ConfirmReset"
        defaultMessage="Are you sure you want to reset the API key {name}?"
        values={{ name: apiKey.name }}
      />),
      text: (<FormattedMessage
        id="ApiKeysSettings.ApiKeys.ConfirmResetText"
        defaultMessage="This will invalidate the current key and remove access for all apps that use this key. This cannot be undone."
      />),
      onOk: async () => {
        const { data } = await reset(apiKey);
        openCreated({ apiKey: data });
      },
      textOk: (<FormattedMessage
        id="ApiKeysSettings.ApiKeys.Reset"
        defaultMessage="Reset"
      />),
      danger: true,
    });
  };
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHeader sx={{ backgroundColor: 'background.body' }}>
          <TableRow>
            <TableCell>
              <FormattedMessage
                id="ApiKeysSettings.ApiKeys.Name"
                defaultMessage="Name"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="ApiKeysSettings.ApiKeys.Token"
                defaultMessage="Token"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="ApiKeysSettings.ApiKeys.Type"
                defaultMessage="Type"
              />
            </TableCell>
            <TableCell>
              <FormattedMessage
                id="ApiKeysSettings.ApiKeys.Created"
                defaultMessage="Created"
              />
            </TableCell>
            <TableCell align="right">
              <Button
                variant="text"
                color="primary"
                onClick={open}
                startIcon={<Add />}
              >
                <FormattedMessage
                  id="ApiKeysSettings.ApiKeys.Create"
                  defaultMessage="Create new"
                />
              </Button>
            </TableCell>
          </TableRow>
        </TableHeader>
        <TableBody sx={{ '& .MuiTableRow-root:last-of-type .MuiTableCell-root': { borderBottom: 0 } }}>
          {apiKeys?.map(apiKey => (
            <TableRow key={apiKey._id}>
              <TableCell>{apiKey.name}</TableCell>
              <TableCell>{apiKey.token}</TableCell>
              <TableCell>
                {apiKey.fullAccess ? (
                  <FormattedMessage
                    id="ApiKeysSettings.ApiKeys.FullAccess"
                    defaultMessage="Full access"
                  />
                ) : (
                  <FormattedMessage
                    id="ApiKeysSettings.ApiKeys.Restricted"
                    defaultMessage="Restricted"
                  />
                )}
              </TableCell>
              <TableCell>
                <FormatDateCalendar date={apiKey.createdAt} />
              </TableCell>
              <TableCell align="right">
                <IconMenu
                  size="small"
                  icon={<MoreVert />}
                >
                  <MenuItem
                    onClick={() => {
                      open({ apiKey });
                    }}
                  >
                    <FormattedMessage
                      id="ApiKeysSettings.ApiKeys.Edit"
                      defaultMessage="Edit"
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleReset(apiKey);
                    }}
                  >
                    <FormattedMessage
                      id="ApiKeysSettings.ApiKeys.Reset"
                      defaultMessage="Reset"
                    />
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleDelete(apiKey);
                    }}
                  >
                    <FormattedMessage
                      id="ApiKeysSettings.ApiKeys.Delete"
                      defaultMessage="Delete"
                    />
                  </MenuItem>
                </IconMenu>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <ApiKeyDialog
        isOpen={isOpen}
        apiKey={args?.apiKey}
        close={close}
        onCreated={openCreated}
      />
      <ApiKeyCreatedDialog isOpen={createdOpen} apiKey={argsCreated?.apiKey} close={closeCreated} />
    </TableContainer>
  );
};

export default ApiKeysList;
