import { Close, Search } from '@mui/icons-material';
import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  placeholder: {
    id: 'search.placeholder',
    defaultMessage: 'Search...',
  },
});

const SearchBox = (props) => {
  const { value, setValue } = props;
  const { formatMessage } = useIntl();
  return (
    <OutlinedInput
      sx={{
        marginLeft: 'auto',
        alignSelf: 'flex-end',
        width: '16ch',
        '&.Mui-focused': {
          width: '100%',
        },
        '@media (max-width: 600px)': {
          width: '100%',
        },
        backgroundColor: 'white',
        transition: 'width 200ms ease',
        pr: 0,
        '& input': { p: 1 },
      }}
      type="text"
      data-qa="query"
      placeholder={props.placeholder || formatMessage(messages.placeholder)}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      startAdornment={<Search />}
      endAdornment={value && (
        <InputAdornment position="end">
          <IconButton onClick={() => { setValue(''); }}>
            <Close />
          </IconButton>
        </InputAdornment>
      )}
    />
  );
};

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
};

SearchBox.defaultProps = {
  placeholder: '',
  value: '',
  setValue: () => {},
};

export default SearchBox;
