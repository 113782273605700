import { defineMessages } from 'react-intl';

const homeMessages = defineMessages({
  title: {
    id: 'HomePage.Title',
    defaultMessage: 'Promoter Ninja - Try for Free NPS E-mail Surveys',
  },
  description: {
    id: 'HomePage.Description',
    defaultMessage: 'Send Net Promoter Score ® surveys to your customers and gather actionable feedback. '
      + 'Customize brand and language. Add their preferred language to your CSV and Promoter Ninja will do the rest.',
  },
  nps: {
    id: 'Home.NPS',
    defaultMessage: 'Net Promoter Score®',
  },
  ces: {
    id: 'Home.CES',
    defaultMessage: 'Customer Effort Score',
  },
  csat: {
    id: 'Home.CSAT',
    defaultMessage: 'Customer Satisfaction',
  },
  'five-star': {
    id: 'Home.FiveStar',
    defaultMessage: '5-star rating',
  },
  npsShort: {
    id: 'Home.NPSShort',
    defaultMessage: 'NPS',
  },
  cesShort: {
    id: 'Home.CESShort',
    defaultMessage: 'CES',
  },
  csatShort: {
    id: 'Home.CSATShort',
    defaultMessage: 'CSAT',
  },
  'five-starShort': {
    id: 'Home.FiveStarShort',
    defaultMessage: '5-star',
  },
});

export default homeMessages;
