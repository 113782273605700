import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import prepareHeaders from 'client/util/prepareHeaders';
import Config from '../../../server/config';

export const savedSearchApiSlice = createApi({
  reducerPath: 'savedSearchesReducer',
  baseQuery: fetchBaseQuery({ baseUrl: Config.apiUrl, prepareHeaders }),
  tagTypes: ['SavedSearch'],
  endpoints: builder => ({
    findBySurvey: builder.query({
      query: survey => `/surveys/${survey}/saved-searches`,
      type: 'SavedSearch',
      providesTags: (savedSearches) => {
        return [
          ...(savedSearches || []).map(({ _id: id }) => ({
            type: 'SavedSearch',
            id,
          })),
          {
            type: 'SavedSearch',
            id: 'LIST',
          },
        ];
      },
    }),
    create: builder.mutation({
      query: ({
        name,
        survey,
        query,
        parsedQuery,
      }) => ({
        url: '/saved-searches',
        method: 'POST',
        body: {
          name, survey, query, parsedQuery,
        },
      }),
      invalidatesTags: [{ type: 'SavedSearch', id: 'LIST' }],
    }),
    read: builder.query({
      query: id => `/saved-searches/${id}`,
      providesTags: savedSearch => [{ type: 'SavedSearch', id: savedSearch._id }],
    }),
    update: builder.mutation({
      query: ({
        _id,
        name,
        query,
        parsedQuery,
      }) => ({
        url: `/saved-searches/${_id}`,
        method: 'PATCH',
        body: { name, query, parsedQuery },
      }),
      invalidatesTags:
        (result, error, { _id }) => {
          return [{
            type: 'SavedSearch',
            id: _id,
          }];
        },
    }),
    remove: builder.mutation({
      query: id => ({
        url: `/saved-searches/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, id) => [{ type: 'SavedSearch', id }],
    }),
  }),
});

export const {
  useFindBySurveyQuery,
  useCreateMutation,
  useReadQuery,
  useUpdateMutation,
  useRemoveMutation,
  reducer: savedSearchesReducer,
} = savedSearchApiSlice;
