import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';

const messages = defineMessages({
  yesterday: {
    id: 'SearchDateRange.yesterday',
    defaultMessage: 'Yesterday',
  },
  today: {
    id: 'SearchDateRange.today',
    defaultMessage: 'Today',
  },
  tomorrow: {
    id: 'SearchDateRange.tomorrow',
    defaultMessage: 'Tomorrow',
  },
  dateAtTime: {
    id: 'SearchDateRange.dateAtTime',
    defaultMessage: '{date} at {time}',
  },
  this: {
    id: 'SearchDateRange.this',
    defaultMessage: 'This',
  },
  last: {
    id: 'SearchDateRange.last',
    defaultMessage: 'Last',
  },
  next: {
    id: 'SearchDateRange.next',
    defaultMessage: 'Next',
  },
});


export const formatDateCalendar = (intl, dateIn, addTime = false) => {
  const { formatMessage, formatDate, formatTime } = intl;
  const currentYear = moment().year();

  // ensure the date is displayed with today and yesterday
  function fmt() {
    const dateStr = dateIn && formatDate(this.toDate(), currentYear === this.year() ? { day: 'numeric', month: 'short' } : { day: 'numeric', month: 'short', year: 'numeric' });
    return `[${dateStr}]`;
  }

  const date = moment(dateIn)
    .calendar(null, {
      // when the date is closer, specify custom values
      lastDay: `[${formatMessage(messages.yesterday)}]`,
      sameDay: `[${formatMessage(messages.today)}]`,
      nextDay: `[${formatMessage(messages.tomorrow)}]`,
      // when the date is further away, use formatter
      lastWeek: fmt,
      nextWeek: fmt,
      sameElse: fmt,
    });

  if (addTime) {
    const time = formatTime(dateIn);
    return formatMessage(messages.dateAtTime, { date, time });
  }

  return date;
};

class FormatDateCalendar extends React.PureComponent {
  render() {
    const {
      date, addTime, intl,
    } = this.props;
    if (!date) {
      return '∞';
    }
    return <React.Fragment>{formatDateCalendar(intl, date, addTime)}</React.Fragment>;
  }
}

FormatDateCalendar.propTypes = {
  date: PropTypes.object,
  addTime: PropTypes.bool,
  intl: PropTypes.object.isRequired,
};

FormatDateCalendar.defaultProps = {
  date: new Date(),
  addTime: false,
};

export default injectIntl(FormatDateCalendar);
