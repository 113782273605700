const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1280,
    minHeight: '65vh',
    maxHeight: '65vh',
    width: '100%',
    alignSelf: 'center',
    mt: 3,
    mb: 2,
    gap: 3,
  },
  container: {
    width: '100%',
    flexGrow: 1,
  },
  outerGrid: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  outer: {
    flexGrow: 1,
    '& iframe': {
      border: 0,
    },
  },
  listTimeline: {
    listStyle: 'none',
    padding: '0 0 18px 0',
    boxShadow: 'none',
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      width: 6,
      top: 24,
      bottom: 3,
      left: 16,
      backgroundColor: '#ecece8',
    },
    '&:after': {
      borderRadius: '50%',
      content: '""',
      position: 'absolute',
      width: 6,
      height: 6,
      backgroundColor: '#ecece8',
      left: 16,
      bottom: 0,
    },
  },
  listTimelineItem: {
    minHeight: 48,
    verticalAlign: 'baseline',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginLeft: 4.75,
    paddingTop: 0,
    paddingBottom: 0,
    lineHeight: '48px',
    boxShadow: '0 -1px 0 0 #e0e0d9,0 -1px 0 0 #e0e0d9 inset',
    '&:first-of-type': {
      boxShadow: 'none',
    },
    '&:before': {
      boxSizing: 'border-box',
      borderRadius: '50%',
      content: '""',
      position: 'absolute',
      width: 18,
      height: 18,
      backgroundColor: '#fff',
      border: '2px solid #ecece8',
      left: -28,
    },
    '&:after': {
      boxSizing: 'border-box',
      borderRadius: '50%',
      content: '""',
      position: 'absolute',
      width: 8,
      height: 8,
      backgroundColor: '#ecece8',
      left: -23,
      display: 'table',
      clear: 'both',
    },
  },
  item: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

  },
  badge: {
    display: 'inline-block',
    verticalAlign: 'middle',
    color: 'muted.light',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'muted.lighter',
    backgroundColor: 'white',
    borderRadius: 0.5,
    textTransform: 'uppercase',
    fontSize: '0.7rem',
    marginLeft: 2,
    padding: '0 0.5rem',
  },
  circularBackdrop: {
    backgroundColor: 'rgba(250, 250, 249, 0.8)',
    position: 'absolute',
    zIndex: 1,
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',

  },
  personInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexGrow: 1,
    flexBasis: '50%',
    gap: 1,
    '& > *': {
      display: 'flex',
      gap: 1,
    },
    '& a, & a:hover': {
      color: 'inherit',
    },
  },
  defs: {
    mt: 2,
    flexDirection: 'column',
    gap: 0,
    '& > dt': {
      fontWeight: 500,
      mt: 3,
    },
    '& > dt:first-of-type': {
      mt: 0,
    },
  },
  status: status => ({
    display: 'flex',
    alignItems: 'flex-end',
    gap: 1,
    '& > svg': {
      width: '1rem',
      color: 'muted.lighter',
      fill: 'currentColor',
    },
    '& + dd': {
      ...(status ? { color: 'error.main' } : { color: 'success.light' }),
      fontWeight: 600,
      display: 'flex',
      alignItems: 'flex-end',
      gap: 1,
      '& > svg': {
        width: '1rem',
        strokeWidth: 3,
        stroke: 'currentColor',
      },
    },
  }),
};

export default styles;
