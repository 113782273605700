import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import classNames from 'classnames/bind';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import Testimonial from '../Testimonial/Testimonial';

import bsStyle from '../../../../../App/components/Bootstrap/Bootstrap.css';
import style from './Testimonials.css';

import albert from './images/albert.jpg';
import gabriel from './images/gabriel.jpg';
import miguel from './images/miguel.jpg';

import albert140 from './images/albert140.jpg';
import gabriel140 from './images/gabriel140.jpg';
import miguel140 from './images/miguel140.jpg';

const bs = classNames.bind(bsStyle);

const messages = defineMessages({
  t1Comment: {
    id: 'Testimonials.T1.Comment',
    defaultMessage: 'The easiest way to capture our customer\'s satisfaction over time.',
  },
  t2Comment: {
    id: 'Testimonials.T2.Comment',
    defaultMessage: 'A product that does what it says in the easiest and cheapest manner.',
  },
  t3Comment: {
    id: 'Testimonials.T3.Comment',
    defaultMessage: 'We have consistently improved our product with Promoter Ninja\'s feedback.',
  },
});

const Testimonials = (props) => {
  const formatMessage = props.intl.formatMessage;

  const sliderSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    lazyLoad: true,
    responsive: [{
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: false,
      },
    }, {
      breakpoint: 600,
      settings: {
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    }, {
      breakpoint: 480,
      settings: {
        dots: true,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    ],
  };


  return (
    <section className={`${style.testimonialsMain} ${bs('mbr-section--bg-adapted', 'mbr-section--relative')}`}>
      <div className={`${style.testimonials3} ${style.testimonials} ${style.testimonialsWhite}`}>
        <div className={bs('container')}>
          <div className={bs('section-head', 'text-center', 'col-md-8', 'col-md-offset-2', 'space50')}>
            <h1 className={`${style.head} ${bs('mbr-title-font')}`}>
              <FormattedMessage
                id="Testimonials.Title"
                defaultMessage="What our customers say"
              />
            </h1>
          </div>
          <div className={bs('row')}>
            <div className={bs('col-md-12', 'text-center')}>
              <Slider {...sliderSettings} className={style.quote3}>
                <Testimonial
                  author="Miguel Fresneda"
                  pic={miguel}
                  pic2x={miguel140}
                  width={70}
                  company="Woffu"
                  comment={formatMessage(messages.t1Comment)}
                />
                <Testimonial
                  author="Albert Armengol"
                  pic={albert}
                  pic2x={albert140}
                  width={70}
                  company="Docplanner"
                  comment={formatMessage(messages.t2Comment)}
                />
                <Testimonial
                  author="Gabriel Prat"
                  pic={gabriel}
                  pic2x={gabriel140}
                  width={70}
                  company="Hoshinplan"
                  comment={formatMessage(messages.t3Comment)}
                />
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Testimonials.defaultProps = {
  intl: null,
};

Testimonials.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }),
};

export default injectIntl(Testimonials);
