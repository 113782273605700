import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Markdown from 'react-remarkable';

import { localizationData } from '../../../../../Intl/setup';

import {
  getBrand,
  getLanguage,
  getLinkText,
  getLinkURL,
  getLogoUrl,
  getMessage,
  getScore, getThankyouTitle,
  getTopColor,
} from '../../ResponseReducer';

import styles from './AddResponsePages.css';

const cx = classNames.bind(styles);

const ThankyouPage = (props) => {
  const {
    language, brand, logoUrl, message, linkURL, score, topColor, rand, linkText, preview, title,
  } = props;
  const emailMessages = localizationData[language].messages;
  const thanks = title || emailMessages['nps.thanks'];
  const powered = emailMessages['nps.powered'].replace('{promoter_ninja}', '<a target="_blank" rel="noopener noreferrer" href="https://www.promoter.ninja/">Promoter Ninja</a>');
  const dir = ['ar', 'he'].indexOf(language) > -1 ? 'rtl' : 'ltr';
  const messageRep = message ? message.replace(/{num}/g, score) : null;
  return (
    <div className={cx('main', { preview })}>
      <div className={styles['survey-response']}>
        <div className={styles['survey-response-header']} style={{ borderColor: topColor }}>
          {logoUrl
            ? <img alt={brand} className={styles.l12} src={`${logoUrl}?r=${rand}`} />
            : <div className={styles.l11} dir={dir}><span className="val-display-name">{brand}</span></div>}
        </div>
        <div className={styles['survey-response-body']}>
          <div className={styles['survey-response-body-inner']}>
            <p
              className={`${styles['survey-response-text']} ${styles['survey-response-text-confirmation']}`}
              dir={dir}
            >
              {thanks}
            </p>
            <div className={styles['survey-response-thank-you']}>
              {messageRep
                ? (
                  <div className={styles['survey-response-thank-you-message']}>
                    <Markdown source={messageRep} />
                  </div>
                ) : null}
              {linkText && (linkURL || preview)
                ? (
                  <a
                    className={styles['survey-response-thank-you-link']}
                    style={{ color: '#0079BF' }}
                    href={linkURL}
                    {...{ target: preview ? '_blank' : null }}
                  >
                    {linkText}
                  </a>
                ) : null}
            </div>
          </div>
        </div>
        <div dir={dir} className={styles['survey-response-footer']} dangerouslySetInnerHTML={{ __html: powered }} />
      </div>
    </div>
  );
};

// Specifies the default values for props:
ThankyouPage.defaultProps = {
  brand: '',
  topColor: '#333',
  language: 'en',
  logoUrl: null,
  message: null,
  title: null,
  linkText: null,
  linkURL: null,
  preview: false,
  score: '{num}',
};

ThankyouPage.propTypes = {
  brand: PropTypes.string,
  topColor: PropTypes.string,
  language: PropTypes.string,
  logoUrl: PropTypes.string,
  message: PropTypes.string,
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  preview: PropTypes.bool,
  score: PropTypes.string,
  rand: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    brand: getBrand(state),
    topColor: getTopColor(state),
    language: getLanguage(state),
    logoUrl: getLogoUrl(state),
    title: getThankyouTitle(state),
    message: getMessage(state),
    linkText: getLinkText(state),
    linkURL: getLinkURL(state),
    score: getScore(state),
    rand: Math.random(),
  };
}

export default connect(mapStateToProps)(ThankyouPage);

export const ThankyouPageDisconnected = ThankyouPage;
