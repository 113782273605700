import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getToken } from '../../../Auth/AuthReducer';
import { addSubscription } from '../../SubscriptionActions';

import { getHostedPage } from '../../SubscriptionReducer';

import CheckoutOkPage from './CheckoutOkPage';

const mapStateToProps = (state) => {
  return {
    hostedPage: getHostedPage(state),
  };
};

export const validateSubscription = (store, nextState, replace, callback) => {
  if (nextState.location.query.state === 'succeeded') {
    store.dispatch(addSubscription(getToken(store.getState()), nextState.location.query.id, replace)).then(() => {
      callback();
    }).catch((err) => {
      callback();
      throw err;
    });
  } else {
    replace({
      pathname: '/subscriptions/error',
    });
  }
};

const CheckoutPageContainer = connect(
  mapStateToProps,
)(injectIntl(CheckoutOkPage));

export default CheckoutPageContainer;
