import { useMemo, useRef } from 'react';

const useCreateEvents = () => {
  const handlers = useRef([]);
  return useMemo(() => ({
    get length() {
      return handlers.current.length;
    },
    push(fn) {
      handlers.current.push(fn);
      return () => {
        handlers.current = handlers.current?.filter(handler => handler !== fn);
      };
    },
    clear() {
      handlers.current = [];
    },
    call(...args) {
      handlers.current.forEach(fn => fn && fn(...args));
    },
  }), []);
};

export default useCreateEvents;
