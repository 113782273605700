import SearchDateRangeControl, { datePresets, presets } from 'client/modules/Surveys/components/SearchDateRange/SearchDateRangeControl';
import defaults from 'lodash/defaults';
import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { SearchkitComponent } from 'searchkit';
import RangeQueryAccessor from './RangeQueryAccessor';
import styles from './SearchDateRange.css';

class SearchDateRange extends SearchkitComponent {
  constructor(props) {
    super(props);
    this.translations = SearchDateRange.translations;
    this.accessor = null;
    this.initialState = {
      focused: false,
      input: [],
    };
    this.state = this.initialState;
    this.lastSearchMs = 0;
  }

  defineBEMBlocks() {
    return { container: this.props.mod };
  }

  defineAccessor() {
    const {
      id, queryBuilder, queryOptions, prefixQueryOptions, title, field,
    } = this.props;
    const dateFormatter = this.getDateFormatter();
    return new RangeQueryAccessor(id, field, {
      title,
      format: 'YYYY-MM-DD',
      prefixQueryOptions: { ...prefixQueryOptions },
      queryOptions: { ...queryOptions },
      queryBuilder,
      dateFormatter,
      onQueryStateChange: (inp) => {
        const input = inp || [];
        let status = 'closed';
        if (input.length) {
          const [min, max] = input;
          this.accessor.setValues(min, max, false);
          status = presets.find(p => p[1] === min && p[2] === max) ? 'presets' : 'custom';
        }
        if (!this.unmounted) {
          this.setState({ input, status });
          if (this.props.onChange) {
            this.props.onChange(inp);
          }
        }
      },
    });
  }

  onSubmit(event) {
    event.preventDefault();
    this.searchQuery(this.getValues());
  }

  searchQuery(min, max) {
    if (this.accessor) {
      const shouldResetOtherState = false;
      if (typeof min === 'undefined' && typeof max === 'undefined') {
        this.accessor.clear();
      } else {
        this.accessor.setValues(min, max, shouldResetOtherState);
      }
      const now = +new Date();
      const newSearch = now - this.lastSearchMs <= 2000;
      this.lastSearchMs = now;
      this.searchkit.performSearch(newSearch);
    }
  }

  getValue() {
    const { input } = this.state;
    if (!input || !input.length) {
      return this.getAccessorValue();
    }
    return input;
  }

  getAccessorValue() {
    return `${this.accessor.state.getValue() || []}`;
  }

  setFocusState(focused) {
    if (!focused) {
      this.setState({
        focused,
        input: [], // Flush (should use accessor's state now)
      });
    } else {
      this.setState({ focused });
    }
  }

  close() {
    this.setState({ input: [] });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.status !== 'closed' && this.state.input && this.state.input.length && prevState.input.length && (!this.accessor || !this.accessor.getValues())) {
      this.close();
      this.props.onChange([]);
    }
  }

  getDateFormatter() {
    const { intl: { formatDate } } = this.props;

    return (d, addYear = true) => {
      const opts = {
        day: 'numeric',
        month: 'short',
      };
      if (addYear) {
        opts.year = 'numeric';
      }
      return formatDate(datePresets()[d] || d, opts);
    };
  }

  handleChange([min, max]) {
    if ((min === '' && max !== 'now') || max === '') {
      if (this.props.onChange) {
        this.props.onChange([]);
      }
    } else {
      if (typeof min === 'undefined' && typeof max === 'undefined') {
        this.accessor.clear();
      } else {
        this.accessor.setValues(min, max);
      }
      this.searchQuery(...this.accessor.getValues());
      this.forceUpdate();
      if (this.props.onChange) {
        this.props.onChange([min, max]);
      }
    }
  }

  render() {
    const block = this.bemBlocks.container;
    const { focused } = this.state;
    if (!this.accessor) {
      return null;
    }
    const value = this.accessor.getValues() || [];
    return (
      <SearchDateRangeControl value={value} onChange={this.handleChange.bind(this)} className={block().state({ focused })} />
    );
  }
}

SearchDateRange.translations = {
  'searchbox.placeholder': 'Search',
  'searchbox.button': 'search',
};

SearchDateRange.defaultProps = {
  id: 'dr',
  mod: 'sk-search-box-date-range',
};

SearchDateRange.propTypes = defaults({
  id: PropTypes.string,
  intl: PropTypes.object.isRequired,
  searchOnChange: PropTypes.bool,
  searchThrottleTime: PropTypes.number,
  queryBuilder: PropTypes.func,
  queryFields: PropTypes.arrayOf(PropTypes.string),
  autofocus: PropTypes.bool,
  queryOptions: PropTypes.object,
  prefixQueryFields: PropTypes.arrayOf(PropTypes.string),
  prefixQueryOptions: PropTypes.object,
  translations: SearchkitComponent.translationsPropType(
    SearchDateRange.translations,
  ),
  mod: PropTypes.string,
  placeholder: PropTypes.string,
  blurAction: PropTypes.string,
  initialStatus: PropTypes.string,
  initialValue: PropTypes.arrayOf(PropTypes.string),
}, SearchkitComponent.propTypes);

export default injectIntl(SearchDateRange);
