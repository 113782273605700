import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import useUniqueId from 'client/util/useUniqueId';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const IconMenu = ({
  icon, children, onOpen, onClose, className, isOpen, size
}) => {
  const [open, setOpen] = React.useState(typeof isOpen === 'undefined' ? false : isOpen);
  useEffect(() => {
    if (typeof isOpen !== 'undefined') {
      setOpen(isOpen);
    }
  }, [isOpen]);
  const anchorRef = React.useRef(null);
  const id = useUniqueId();
  const handleClick = (event) => {
    if (typeof isOpen === 'undefined') {
      setOpen(true);
    }
    onOpen(event);
  };
  const handleClose = () => {
    if (typeof isOpen === 'undefined') {
      setOpen(false);
    }
    onClose();
  };
  const componentId = `icon-menu-${id}`;

  const onClick = origOnClick => (event) => {
    if (typeof isOpen === 'undefined') {
      setOpen(false);
      onClose(event);
    }
    if (typeof origOnClick === 'function') {
      origOnClick(event);
    }
  };

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a
    // typescript error too.
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { onClick: onClick(child.props?.onClick) });
    }
    return child;
  });

  return (
    <>
      <IconButton
        className={className}
        style={{ fontSize: 'inherit' }}
        onClick={handleClick}
        aria-haspopup="true"
        aria-owns={open ? componentId : undefined}
        size={size}
        ref={anchorRef}
      >
        {icon}
      </IconButton>
      <Menu id={componentId} open={open} anchorEl={anchorRef.current} onClose={handleClose}>
        {childrenWithProps}
      </Menu>
    </>
  );
};

IconMenu.propTypes = {
  icon: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  size: PropTypes.string,
};

IconMenu.defaultProps = {
  onClose: () => {},
  onOpen: () => {},
  className: undefined,
  isOpen: undefined,
  size: 'large',
};
export default IconMenu;
