import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const ConditionalLogic = () => {
  return null;
  return (
    <Box sx={{ mt: 2 }}>
      <Typography component="h3" sx={{ fontWeight: 'bold' }}>
        <FormattedMessage id="EditQuestion.Show question to" defaultMessage="Show question to" />
      </Typography>

    </Box>
  );
};

export default ConditionalLogic;
