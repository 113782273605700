import { signInAs } from '../../AuthActions';

export const onSignInAsEnter = (store, nextState, replace, callback) => {
  store.dispatch(signInAs(nextState.params.email, replace)).then(() => {
    callback();
  }).catch((err) => {
    callback();
    throw err;
  });
};

export default () => null;
