import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { defineMessages } from 'react-intl';

import Layout from '../../../App/components/Layout/LayoutContainer';

const messages = defineMessages({
  title: {
    id: 'CheckoutOk.Title',
    defaultMessage: 'Thank you for your purchase.',
  },
});

const CheckoutOkPage = (props) => {
  const formatMessage = props.intl.formatMessage;
  return (
    <Layout>
      <div>
        <Helmet title={formatMessage(messages.title)} />
        ok!
      </div>
    </Layout>
  );
};
CheckoutOkPage.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default CheckoutOkPage;
