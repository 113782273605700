// need Intl polyfill, Intl not supported in Safari
import Intl from 'intl';
import areIntlLocalesSupported from 'intl-locales-supported';
// here you bring in 'intl' browser polyfill and language-specific polyfills
// (needed as safari doesn't have native intl: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl)
// as well as react-intl's language-specific data
// be sure to use static imports for language or else every language will be included in your build (adds ~800 kb)

import * as Config from '../server/config';

// list of available languages
export const enabledLanguages = Config.availableLanguages;

export const surveyLanguages = {
  en: {
    ar: 'Arabic',
    bg: 'Bulgarian',
    'zh-cn': 'Chinese (Simplified)',
    'zh-hk': 'Chinese (Traditional, Hong Kong)',
    'zh-tw': 'Chinese (Traditional, Taiwan)',
    hr: 'Croatian',
    cs: 'Czech',
    da: 'Danish',
    nl: 'Dutch',
    en: 'English',
    et: 'Estonian',
    fi: 'Finnish',
    fr: 'French',
    ka: 'Georgian',
    de: 'German',
    el: 'Greek',
    he: 'Hebrew',
    hu: 'Hungarian',
    id: 'Indonesian',
    it: 'Italian',
    ja: 'Japanese',
    ko: 'Korean',
    lv: 'Latvian',
    lt: 'Lithuanian',
    ms: 'Malaysian',
    nb: 'Norwegian Bokmål',
    pl: 'Polish',
    'pt-br': 'Portuguese (Brazil)',
    pt: 'Portuguese (Portugal)',
    ro: 'Romanian',
    ru: 'Russian',
    so: 'Somali',
    sr: 'Serbian',
    es: 'Spanish (Spain)',
    'es-419': 'Spanish (Latin America)',
    sl: 'Slovenian',
    sv: 'Swedish',
    th: 'Thai',
    tr: 'Turkish',
    vi: 'Vietnamese',
  },
  es: {
    de: 'Alemán',
    ar: 'Árabe',
    bg: 'Búlgaro',
    'zh-cn': 'Chino (Simplificado)',
    'zh-hk': 'Chino (Tradicional, Hong Kong)',
    'zh-tw': 'Chino (Tradicional, Taiwan)',
    hr: 'Croata',
    cs: 'Checo',
    ko: 'Coreano',
    da: 'Danés',
    es: 'Español (España)',
    'es-419': 'Español (América Latina)',
    sl: 'Esloveno',
    et: 'Estonio',
    fi: 'Finés',
    fr: 'Francés',
    ka: 'Georgiano',
    el: 'Griego',
    he: 'Hebreo',
    nl: 'Holandés',
    hu: 'Húngaro',
    id: 'Indonesio',
    it: 'Italiano',
    ja: 'Japonés',
    en: 'Inglés',
    lv: 'Letón',
    lt: 'Lituano',
    ms: 'Malayo',
    nb: 'Noruego Bokmål',
    pl: 'Polaco',
    'pt-br': 'Portugués (Brazil)',
    pt: 'Portugués (Portugal)',
    ro: 'Rumano',
    ru: 'Ruso',
    so: 'Somalí',
    sr: 'Serbio',
    sv: 'Sueco',
    th: 'Tailandés',
    tr: 'Turco',
    vi: 'Vietnamita',
  },
};

export const validSurveyLanguages = Object.keys(surveyLanguages.en);

export const isValidSurveyLanguage = language => validSurveyLanguages.includes(language);

let locale = 'en';

export function setLocale(l) {
  locale = l;
}

export function getLocale() {
  return locale;
}

if (global.Intl) {
  // Determine if the built-in `Intl` has the locale data we need.
  if (!areIntlLocalesSupported(enabledLanguages)) {
    // `Intl` exists, but it doesn't have the data we need, so load the
    // polyfill and patch the constructors we need with the polyfill's.
    global.Intl.NumberFormat = Intl.NumberFormat;
    global.Intl.DateTimeFormat = Intl.DateTimeFormat;
  }
} else {
  // No `Intl`, so use and load the polyfill.
  global.Intl = Intl;
}

// use this to allow nested messages, taken from docs:
// https://github.com/yahoo/react-intl/wiki/Upgrade-Guide#flatten-messages-object
function flattenMessages(nestedMessages = {}, prefix = '') {
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      messages[prefixedKey] = value; // eslint-disable-line no-param-reassign
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
}

const surveyKeys = Object.keys(require(`${__dirname}/localizationData/ar.json`));

const getLocalizationData = (langIn, onlySurvey) => {
  const lang = ['cl'].includes(langIn) ? 'es-419' : langIn;
  const allMessages = require(`${__dirname}/localizationData/${lang}.json`);
  let messages = {};
  if (onlySurvey) {
    surveyKeys.forEach((key) => {
      messages[key] = allMessages[key];
    });
  } else {
    messages = allMessages;
  }
  return {
    locale: lang,
    messages,
  };
};

export const getSurveyLocalizationData = lang => getLocalizationData(lang, true);

const getAllLocalizationData = () => {
  const ret = {};
  Object.keys(surveyLanguages.en).forEach((lang) => {
    const data = getLocalizationData(lang, false);
    ret[lang] = data;
    ret[lang].messages = flattenMessages(ret[lang].messages);
  });
  return ret;
};

// this object will have language-specific data added to it which will be placed in the state when that language is active
// if localization data get to big, stop importing in all languages and switch to using API requests to load upon switching languages
export const localizationData = getAllLocalizationData();
