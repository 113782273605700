import PropTypes from 'prop-types';
import React from 'react';

import {
  addClass,
  ariaExpanded,
  bootstrapCustomEvent,
  clickEvent,
  collapsing,
  dataTarget,
  dataToggle,
  emulateTransitionEnd,
  getAttribute,
  getClosest,
  getElementsByClassName,
  getMaxHeight,
  hasClass,
  height,
  hiddenEvent,
  hideEvent,
  length,
  on,
  removeClass,
  setAttribute,
  showClass,
  showEvent,
  shownEvent,
  stringCollapse,
  style,
} from '../Utils/Utils';

class Collapse extends React.Component {

  componentDidMount() {
    const queryElement = (selector, parent) => {
      const lookUp = parent || document;
      return typeof selector === 'object' ? selector : lookUp.querySelector(selector);
    };

    // initialization element
    const element = this.toggleElement;

    // set options
    const options = this.props.options;


    // event targets and constants
    this.accordion = null;
    this.collapse = null;
    this.isAnimating = false; // when true it will prevent click handlers
    this.accordionData = element[getAttribute]('data-parent');

    // component strings
    this.component = 'collapse';
    this.collapsed = 'collapsed';


    const getTarget = () => {
      const href = element.href && element[getAttribute]('href');
      const parent = element[getAttribute](dataTarget);
      const id = href || parent;
      return id && queryElement(id);
    };
    // init
    if (!(stringCollapse in element)) { // prevent adding event handlers twice
      on(element, clickEvent, this.toggle.bind(this));
    }
    this.collapse = getTarget();
    this.accordion = queryElement(options.parent) || (this.accordionData && getClosest(element, this.accordionData));
    element[stringCollapse] = this;
  }

  // private methods
  openAction(collapseElementParam) {
    const collapseElement = collapseElementParam;
    bootstrapCustomEvent(collapseElement, showEvent, this.component);
    this.isAnimating = true;
    addClass(collapseElement, collapsing);
    addClass(collapseElement, showClass);
    setTimeout(() => {
      collapseElement[style][height] = `${getMaxHeight(collapseElement)}px`;

      emulateTransitionEnd(collapseElement, () => {
        this.isAnimating = false;
        collapseElement[setAttribute](ariaExpanded, 'true');
        removeClass(collapseElement, collapsing);
        collapseElement[style][height] = '';
        bootstrapCustomEvent(collapseElement, shownEvent, this.component);
      });
    }, 20);
  }

  closeAction(collapseElementParam) {
    const collapseElement = collapseElementParam;
    bootstrapCustomEvent(collapseElement, hideEvent, this.component);
    this.isAnimating = true;
    collapseElement[style][height] = `${getMaxHeight(collapseElement)}px`;
    setTimeout(() => {
      addClass(collapseElement, collapsing);
      collapseElement[style][height] = '0px';

      emulateTransitionEnd(collapseElement, () => {
        this.isAnimating = false;
        collapseElement[setAttribute](ariaExpanded, 'false');
        removeClass(collapseElement, collapsing);
        removeClass(collapseElement, showClass);
        collapseElement[style][height] = '';
        bootstrapCustomEvent(collapseElement, hiddenEvent, this.component);
      });
    }, 20);
  }

  // public methods
  toggle(e) {
    e.preventDefault();
    if (this.isAnimating) return;
    if (!hasClass(this.collapse, showClass)) {
      this.show();
    } else {
      this.hide();
    }
  }

  hide() {
    this.closeAction(this.collapse);
    addClass(this.toggleElement, this.collapsed);
  }

  show() {
    this.openAction(this.collapse);
    removeClass(this.toggleElement, this.collapsed);

    if (this.accordion !== null) {
      const activeCollapses = getElementsByClassName(this.accordion, `${this.component} ${showClass}`);
      const allToggles = this.accordion.querySelectorAll(`[${dataToggle}="${this.component}"]`);
      let correspondingCollapse;
      for (let i = 0, al = activeCollapses[length]; i < al; i += 1) {
        if (activeCollapses[i] !== this.collapse) {
          this.closeAction(activeCollapses[i]);
        }
      }
      for (let u = 0, atl = allToggles[length]; u < atl; u += 1) {
        correspondingCollapse = allToggles[u][getAttribute](dataTarget) || allToggles[u].href;
        if (correspondingCollapse.split('#')[1] !== this.collapse.id) {
          addClass(allToggles[u], this.collapsed);
        } else {
          removeClass(allToggles[u], this.collapsed);
        }
      }
    }
  }

  render() {
    const child = React.cloneElement(this.props.children, {
      ref: (el) => {
        this.toggleElement = el;
      },
    });
    return React.Children.only(child);
  }
}

Collapse.defaultProps = {
  options: {},
};

Collapse.propTypes = {
  options: PropTypes.shape({
    parent: PropTypes.element,
  }),
  children: PropTypes.element.isRequired,
};

export default Collapse;
