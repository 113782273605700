import React from 'react';
import Icon from 'react-icon-base';

const Email = props => (
  <Icon viewBox="0 0 455 455" {...props}>
    <g>
      <path d="M390.2 119c-3-2.9-7.7-2.7-10.6.3L235.9 270.1c-2.2 2.4-5.2 3.6-8.4 3.6s-6.1-1.3-8.4-3.6L75.4 119.3c-2.9-3-7.6-3.1-10.6-.3-3 2.9-3.1 7.6-.3 10.6l93.1 97.7-93.1 98.1c-2.9 3-2.7 7.8.3 10.6 1.5 1.4 3.3 2.1 5.2 2.1 2 0 4-.8 5.4-2.3l92.6-97.6 40.3 42.3c5.1 5.4 11.9 8.3 19.2 8.3s14.1-2.9 19.2-8.3l40.3-42.3 92.6 97.6c1.5 1.6 3.5 2.3 5.4 2.3 1.9 0 3.7-.7 5.2-2.1 3-2.9 3.1-7.6.3-10.6l-93.1-98.1 93.1-97.7c2.8-3 2.7-7.7-.3-10.6z"/><path d="M394.2 101.9c7.4 0 13.3 6 13.3 13.3v224.5c0 7.4-6 13.3-13.3 13.3H60.8c-7.4 0-13.3-6-13.3-13.3V115.3c0-7.4 6-13.3 13.3-13.3h333.4m0-15.1H60.8c-15.6 0-28.3 12.8-28.3 28.3v224.5c0 15.6 12.8 28.3 28.3 28.3h333.3c15.6 0 28.3-12.8 28.3-28.3V115.3c.1-15.6-12.7-28.4-28.2-28.4z"/>
    </g>
  </Icon>
);

export default Email;
