import { MenuItem, MenuList, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';

const messages = defineMessages({
  active: {
    id: 'People.filters.active',
    defaultMessage: 'Active',
  },
  blocked: {
    id: 'People.filters.blocked',
    defaultMessage: 'Unsubscribed',
  },
  unsubscribed: {
    id: 'People.filters.unsubscribed',
    defaultMessage: 'Bounced',
  },
});

const Filter = ({ name }) => {
  const { formatMessage } = useIntl();
  const { id, status } = useParams();
  return (
    <MenuItem
      component={Link}
      to={`/surveys/${id}/people/?status=${name}`}
      selected={name === status}
    >
      <Typography>{formatMessage(messages[name])}</Typography>
    </MenuItem>
  );
};

Filter.propTypes = {
  name: PropTypes.string.isRequired,
};

const Filters = () => (
  <MenuList sx={{
    py: 0,
    '& a': {
      borderRadius: 249, mb: 1, color: 'inherit', '&:hover, &:active, &:visited': { color: 'inherit' },
    },
  }}
  >
    <Filter name="active" />
    <Filter name="unsubscribed" />
    <Filter name="blocked" />
  </MenuList>
);

export default Filters;
