import PropTypes from 'prop-types';
import React from 'react';
import ThemeContext from './ThemeContext';

/**
 * This component takes a `theme` property.
 * It makes the `theme` available down the React tree thanks to React context.
 * This component should preferably be used at **the root of your component tree**.
 */
function ThemeProvider(props) {
  const { children, theme } = props;
  return (<ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>);
}

ThemeProvider.propTypes = {
  /**
   * You can wrap a node.
   */
  children: PropTypes.node.isRequired,
  /**
   * A theme object.
   */
  theme: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ThemeProvider;
