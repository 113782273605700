import PropTypes from 'prop-types';
import React from 'react';
import styles from './MobileMockup.css';

const MobileMockup = props => (
  <div className={styles.wrapper}>
    <div className={styles.phone}>
      <div className={styles.screen}>
        {props.children}
      </div>
      <div className={styles.homeBtn} />
    </div>
  </div>
);

MobileMockup.defaultProps = {
  children: null,
};

MobileMockup.propTypes = {
  children: PropTypes.any,
};

export default MobileMockup;
