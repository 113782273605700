import { addFromLink } from 'client/modules/Response/ResponseActions';
import { useReadLinkQuery } from 'client/modules/Surveys/linkApiSlice';
import { Loading } from 'ra-ui-materialui';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import NotFound from '../../../App/components/NotFound/NotFound';

const AddResponseLinkPage = () => {
  const [q] = useSearchParams();
  const dispatch = useDispatch();
  const { link, score } = useParams();
  const { data, isSuccess, isLoading } = useReadLinkQuery({ link });
  const linkProps = data?.properties.reduce((acc, prop) => ({
    ...acc,
    [prop.name]: prop.value,
  }), {});
  const allProperties = { ...linkProps, ...(Object.fromEntries(q)) };
  const {
    name, email, phoneNumber, ...properties
  } = allProperties;
  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(addFromLink(link, { name, email }, properties)).then((res) => {
        window?.location.replace(`/r/${res._id}${typeof score !== 'undefined' ? `/${score}` : ''}`);
      });
    }
  }, [isLoading, isSuccess]);
  if (isLoading) return <Loading />;
  if (!isSuccess) return <NotFound />;
  return null;
};

export default AddResponseLinkPage;
