/* eslint-disable react/prop-types */
import {
  Box, InputBase, Typography, Input,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styles from './InputAutoWidth.css';

const MyInput = React.forwardRef(
  (props, ref) => {
    const [content, setContent] = useState(props.value || props.defaultValue || '-');
    const span = useRef();
    useEffect(() => {
      setContent(props.value || props.defaultValue || '-');
    }, [props.value, props.defaultValue]);
    const changeHandler = (evt) => {
      setContent(evt.target.value || '-');
      if (props.onChange) {
        props.onChange(evt);
      }
    };
    return (
      <Box>
        <Typography
          ref={span}
          variant="body1"
          className={styles.hidden}
        >
          {content}
        </Typography>
        <Box
          ref={ref}
          component={props.multiline ? 'textarea' : 'input'}
          {...props}
          onChange={changeHandler}
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            minWidth: '64px',
            height: '100% !important',
            resize: 'none',
          }}
        />
      </Box>
    );
  },
);

const InputAutoWidth = (props) => {
  return (
    <InputBase
      className={styles.root}
      inputComponent={MyInput}
      {...props}
      inputProps={{ multiline: props.multiline }}
    />
  );
};
export default InputAutoWidth;
