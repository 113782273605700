import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DeleteTagDialog = ({
  isOpen, close, onConfirm, tag: { name } = {},
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    onConfirm();
  };
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <FormattedMessage
          id="ManageTags.areYouSure"
          defaultMessage="Are you sure you want to delete the tag {name}?"
          values={{ name }}
        />
      </DialogTitle>
      <DialogContent>
        <FormattedMessage
          id="ManageTags.deleteTagWarning"
          defaultMessage="This action cannot be undone."
        />
      </DialogContent>
      <DialogActions>
        <form onSubmit={handleSubmit}>
          <Button onClick={close} color="primary">
            <FormattedMessage id="ManageTags.cancel" defaultMessage="Cancel" />
          </Button>
          <Button type="submit" color="error" autoFocus>
            <FormattedMessage id="ManageTags.delete" defaultMessage="Delete" />
          </Button>
        </form>
      </DialogActions>
    </Dialog>
  );
};

DeleteTagDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  tag: PropTypes.object.isRequired,
};
export default DeleteTagDialog;
