import moment from 'moment';
import { defineMessages } from 'react-intl';

const duration = /(-?\d*\.?\d+(?:e[-+]?\d+)?)\s*([a-z]*)/ig;


const messages = defineMessages({
  ms: {
    id: 'duration.milliseconds',
    defaultMessage: '{n, plural, one {# millisecond} other {# milliseconds}}',
  },
  s: {
    id: 'duration.seconds',
    defaultMessage: '{n, plural, one {# second} other {# seconds}}',
  },
  m: {
    id: 'duration.minutes',
    defaultMessage: '{n, plural, one {# minute} other {# minutes}}',
  },
  h: {
    id: 'duration.hours',
    defaultMessage: '{n, plural, one {# hour} other {# hours}}',
  },
  d: {
    id: 'duration.days',
    defaultMessage: '{n, plural, one {# day} other {# days}}',
  },
  w: {
    id: 'duration.weeks',
    defaultMessage: '{n, plural, one {# week} other {# weeks}}',
  },
  M: {
    id: 'duration.months',
    defaultMessage: '{n, plural, one {# month} other {# months}}',
  },
  y: {
    id: 'duration.years',
    defaultMessage: '{n, plural, one {# year} other {# years}}',
  },
});

const internalParseDuration = (strIn, cb) => {
  let str = strIn;
  str = str.replace(/^now-/, '');
  // ignore commas
  str = str.replace(/(\d),(\d)/g, '$1$2');
  str.replace(duration, (_, n, units) => {
    const parsedUnits = units || 'milliseconds';
    cb(parseFloat(n, 10), parsedUnits);
  });
};

/**
 * convert `str` to moment duration
 *
 * @param {String} str
 * @return {Number}
 */
export const parseDuration = (str) => {
  const ret = moment.duration(0);
  internalParseDuration(str, (n, unit) => {
    ret.add(n, unit);
  });
  return ret;
};

export const humanizeDuration = (str, formatMessage) => {
  const ret = [];
  internalParseDuration(str, (n, unit) => {
    ret.push(formatMessage(messages[unit], { n }));
  });
  return ret.join(' ');
};
