import { Box, Typography } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Filters from 'client/modules/People/pages/List/Filters';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  Link, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import Layout from '../../../App/components/Layout/Layout';
import { useGetPeopleQuery } from '../../peopleApiSlice';

const messages = defineMessages({
  title: {
    id: 'People.title',
    defaultMessage: 'People',
  },
  subtitle: {
    id: 'People.subtitle',
    defaultMessage: 'This is a list of all people that you have added to Promoter Ninja.',
  },
  unsubscribed: {
    id: 'People.status.unsubscribed',
    defaultMessage: 'Unsubscribed',
  },
  blocked: {
    id: 'People.status.blocked',
    defaultMessage: 'Bounced',
  },
  sent: {
    id: 'People.status.sent',
    defaultMessage: 'Sent',
  },
  scheduled: {
    id: 'People.status.scheduled',
    defaultMessage: 'Scheduled',
  },
  skipped: {
    id: 'People.status.skipped',
    defaultMessage: 'Skipped',
  },
  blocklisted: {
    id: 'People.status.blocklisted',
    defaultMessage: 'Blocked',
  },
  created: {
    id: 'People.status.created',
    defaultMessage: 'Created',
  },
  people: {
    id: 'People.people',
    defaultMessage: '{count} people',
  },
  email: {
    id: 'People.table.email',
    defaultMessage: 'Email,',
  },
  name: {
    id: 'People.table.name',
    defaultMessage: 'Name,',
  },
  status: {
    id: 'People.table.status',
    defaultMessage: 'Status,',
  },
  backToPeople: {
    id: 'Person.Back',
    defaultMessage: 'Back to people',
  },
  searchEmail: {
    id: 'Search.Email',
    defaultMessage: 'Email starts with...',
  },
});

const CustomToolbar = () => {
  const { formatMessage } = useIntl();
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      p: 0.5,
    }}
    >
      <GridToolbarQuickFilter placeholder={formatMessage(messages.searchEmail)} />
    </Box>
  );
};


const renderLink = (survey, status, formatMessage) => params => (params.value == null ? '' : (
  <Link
    to={`/surveys/${survey}/people/${params.row.id}`}
    tabIndex={params.tabIndex}
    state={{
      returnLinkTo: 'back',
      returnLinkText: formatMessage(messages.backToPeople),
    }}
  >
    {params.value}
  </Link>
));

const PeopleList = () => {
  const navigate = useNavigate();
  const [limit, setLimit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [searchParams] = useSearchParams();
  const status = searchParams.get('status') || 'active';
  const quickFilterValues = searchParams.get('q')?.split('+').filter(v => v) || [];
  const filter = { status };
  if (!isEmpty(quickFilterValues)) {
    filter.query = quickFilterValues.join(' ');
  }
  const [sortModel, setSortModel] = React.useState([
    {
      field: 'email',
      sort: 'asc',
    },
  ]);
  const { id: survey } = useParams();
  const { formatMessage, locale } = useIntl();
  const { data, isFetching } = useGetPeopleQuery({
    survey,
    skip,
    limit,
    sort: sortModel.reduce((acc, curr) => acc
      + (acc ? ',' : '')
      + (curr.sort === 'desc' ? '-' : '')
      + curr.field, ''),
    filter: JSON.stringify(filter),
  });

  const onFilterChange = (newFilter) => {
    navigate({ search: `q=${newFilter.quickFilterValues.join('+')}` });
  };

  const statusFormatter = (p) => {
    return (p.value.at
      ? `${formatMessage(messages[p.value.status])} ${moment.unix(p.value.at).locale(locale).fromNow()}`
      : '');
  };
  const columns = [
    {
      field: 'email',
      headerName: formatMessage(messages.email),
      minWidth: 250,
      flex: 1.5,
      editable: false,
      renderCell: renderLink(survey, status, formatMessage),
    },
    {
      field: 'name',
      headerName: formatMessage(messages.name),
      minWidth: 200,
      flex: 1,
      editable: false,
      renderCell: renderLink(survey, status, formatMessage),
    },
    {
      field: 'status',
      headerName: formatMessage(messages.status),
      minWidth: 200,
      flex: 1,
      editable: false,
      sortable: false,
      valueFormatter: statusFormatter,
      valueGetter: ({ row }) => {
        if (row.blocklisted_at) {
          return {
            status: 'blocklisted',
            at: row.blocklisted_at,
          };
        }
        if (row.unsubscribed_at) {
          return {
            status: 'unsubscribed',
            at: row.unsubscribed_at,
          };
        }
        if (row.blocked_at) {
          return {
            status: 'blocked',
            at: row.blocked_at,
          };
        }
        if (row.last_sent_at) {
          return {
            status: 'sent',
            at: row.last_sent_at,
          };
        }
        if (row.next_survey_scheduled_at) {
          return {
            status: 'scheduled',
            at: row.next_survey_scheduled_at,
          };
        }
        if (row.skipped?.at) {
          return {
            status: 'skipped',
            at: row.skipped.at,
          };
        }
        return {
          status: 'created',
          at: row.created_at,
        };
      },
    },
  ];

  return (
    <Layout secondaryNav={<SecondaryNavTabs survey={survey} />}>
      <Box sx={{
        display: 'flex', maxWidth: 1280, width: '100%', alignSelf: 'center', mt: 3, mb: 2,
      }}
      >
        <Box>
          <Typography variant="h1">{formatMessage(messages.title)}</Typography>
          <Typography>{formatMessage(messages.subtitle)}</Typography>
        </Box>
      </Box>
      <Box sx={{
        display: 'flex', maxWidth: 1280, width: '100%', alignSelf: 'center', mb: 4, gap: 2,
      }}
      >
        <Box sx={{ flexGrow: 1, flexBasis: '25%' }}>
          <Filters />
        </Box>
        <Box sx={{
          flexGrow: 4, flexBasis: '75%', minHeight: '65vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <Typography variant="h2" color="muted.main" sx={{ fontWeight: 400 }}>{formatMessage(messages.people, { count: data?.total || 0 })}</Typography>
          <DataGrid
            sx={{
              '& a, & a:hover': {
                color: 'inherit',
              },
            }}
            disableRowSelectionOnClick
            slots={{ toolbar: CustomToolbar }}
            density="compact"
            disableColumnMenu
            autoPageSize
            paginationMode="server"
            filterMode="server"
            filterModel={{
              items: [],
              logicOperator: 'and',
              quickFilterLogicOperator: 'and',
              quickFilterValues,
            }}
            onFilterModelChange={onFilterChange}
            sortingMode="server"
            rowCount={data?.total || 0}
            loading={isFetching}
            paginationModel={{ page: skip / limit, pageSize: limit }}
            onPaginationModelChange={({ page: newPage, pageSize: newSize }) => {
              setSkip(newPage * newSize);
              setLimit(newSize);
            }}
            rows={data?.docs || []}
            columns={columns}
            sortModel={sortModel}
            onSortModelChange={newSortModel => setSortModel(newSortModel)}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 500 },
              },
            }}
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default PeopleList;
