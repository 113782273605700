import { Check } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import CardActions from '@mui/material/CardActions';
import Paper from '@mui/material/Paper';
import { getIntegrations } from 'client/modules/Settings/SettingsReducer';
import capitalizeFirstLetter from 'client/util/capitalizeFirstLetter';
import React from 'react';
import Helmet from 'react-helmet';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import gsheets from './assets/gsheets.svg';
import hubspot from './assets/hubspot.svg';
import salesforce from './assets/salesforce.svg';
import slack from './assets/slack.svg';
import webhooks from './assets/webhooks.svg';
import zendesk from './assets/zendesk.svg';
import styles from './IntegrationsListPage.css';

const logos = {
  gsheets,
  hubspot,
  salesforce,
  slack,
  webhooks,
  zendesk,
};

const messages = defineMessages({
  title: {
    id: 'IntegrationsPage.Title',
    defaultMessage: 'Integrations settings',
  },
  save: {
    id: 'IntegrationsPage.Save',
    defaultMessage: 'Save',
  },
  gsheets: {
    id: 'IntegrationsPage.GoogleSheets',
    defaultMessage: 'Send survey responses to Google Sheets',
  },
  hubspot: {
    id: 'IntegrationsPage.Hubspot',
    defaultMessage: 'Get people properties and sync responses to contacts',
  },
  salesforce: {
    id: 'IntegrationsPage.Salesforce',
    defaultMessage: 'Trigger surveys and sync responses to contacts',
  },
  slack: {
    id: 'IntegrationsPage.Slack',
    defaultMessage: 'Send responses to Slack channels',
  },
  webhooks: {
    id: 'IntegrationsPage.Webhooks',
    defaultMessage: 'Send responses and unsubscribe events to your own server',
  },
  zendesk: {
    id: 'IntegrationsPage.Zendesk',
    defaultMessage: 'Trigger surveys on ticket events',
  },
  settings: {
    id: 'IntegrationsPage.Settings',
    defaultMessage: 'Settings',
  },
  setup: {
    id: 'IntegrationsPage.Setup',
    defaultMessage: 'Set up',
  },
});

const IntegrationsListPage = () => {
  const { formatMessage } = useIntl();
  const integrations = useSelector(getIntegrations);
  return (
    <Paper className={styles.form} style={{ padding: '2em' }}>
      <div className={styles.widgets}>
        <Helmet title={formatMessage(messages.title)} />
        {Object.entries(integrations).map(([name, integration]) => (
          <Card key={name} variant="outlined" sx={{ position: 'relative' }}>
            <CardActionArea component={Link} to={`/settings/integrations/${name}`}>
              <CardHeader
                avatar={
                  <SVGInline height={30} width="auto" svg={logos[name] || logos.salesforce} />
                }
                title={
                  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 500 }}>{capitalizeFirstLetter(name)}</Typography>
                    {!integration || (
                      <div className={styles.activeBadge}>
                        <Check fontSize="10" />
                      </div>
                    )}
                  </Box>
                }
              />
              <CardContent sx={{ height: 100 }}>
                <Typography>{formatMessage(messages[name])}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  color="primary"
                  component={Link}
                  to={`/settings/integrations/${name}`}
                  size="small"
                >
                  {formatMessage(messages[integration ? 'settings' : 'setup'])}
                </Button>
              </CardActions>
            </CardActionArea>
          </Card>
        ))}
      </div>
    </Paper>
  );
};

export default IntegrationsListPage;
