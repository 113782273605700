import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Accordion from './Accordion';

const AccordionContainer = ({ startPosition, children }) => {
  const [openPosition, setOpenPosition] = useState(startPosition);

  const onOpenedChange = (position) => {
    if (openPosition !== position) {
      setOpenPosition(position);
    } else {
      setOpenPosition(null);
    }
  };

  return (
    <Accordion
      openPosition={openPosition}
      onOpenedChange={onOpenedChange}
    >
      {children}
    </Accordion>
  );
};

AccordionContainer.defaultProps = {
  startPosition: null,
};

AccordionContainer.propTypes = {
  startPosition: PropTypes.number,
  children: PropTypes.any.isRequired,
};

export default AccordionContainer;
