import { Box } from '@mui/material';
import Spinner from 'components/Spinner/Spinner';
import PropTypes from 'prop-types';
/**
 * Root Component
 */
import React from 'react';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from 'react-router-dom';
import { getI18n } from './AppReducer';
import { isLoggedIn } from './modules/Auth/AuthReducer';

// Import Routes
import routes from './routes';

// Base stylesheet
require('./main.css');
require('slick-carousel/slick/slick.css');
require('slick-carousel/slick/slick-theme.css');
require('searchkit/theming/theme.scss');
require('./searchkit.css');
require('react-flexbox-grid/dist/react-flexbox-grid.css');

const App = (props) => {
  return (
    <Provider store={props.store}>
      <IntlProvider
        locale={props.locale}
        messages={props.messages}
        key={`${props.locale}-${props.loggedIn}`}
      >
        <RouterProvider router={createBrowserRouter(createRoutesFromElements(routes(props.store)))} fallbackElement={<Box sx={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}><Spinner /></Box>}/>
      </IntlProvider>
    </Provider>
  );
};

// Retrieve data from store as props
function mapStateToProps(state) {
  return {
    ...getI18n(state),
    loggedIn: isLoggedIn(state),
  };
}

App.defaultProps = {
  messages: null,
};

App.propTypes = {
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  messages: PropTypes.object,
  loggedIn: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(App);
