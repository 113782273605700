import Divider from '@mui/material/Divider';
import classnames from 'classnames';
import startCase from 'lodash/startCase';
import React from 'react';
import { useHoverIntent } from 'react-use-hoverintent';
import { RefinementListFilter } from 'searchkit';
import FilterContainer from './FilterContainer';

import styles from './RefinementListFilterDivider.css';

const withProps = (WrappedComponent, props) => ({ children, ...rest }) => {
  return <WrappedComponent {...rest} {...props}>{children}</WrappedComponent>;
};

const RefinementListFilterDivider = ({ onClose, isDragging, disabled, ...props}) => {
  const Component = props.component || RefinementListFilter;
  const [over, intentRef] = useHoverIntent({
    sensitivity: 1,
    interval: 400,
  });

  const outline = over || isDragging;

  const containerComponent = withProps(FilterContainer, {
    draggable: true, outline, intentRef, onClose,
  });
  return (
    <div className={classnames(styles.container, { [styles.outline]: outline })}>
      {!outline && !disabled && <Divider />}
      <Component {...props} title={startCase(props.title)} containerComponent={containerComponent} />
    </div>
  );
}

export default RefinementListFilterDivider;
