import { Button } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React, { useEffect, useState, memo } from 'react';
import { MdClose } from 'react-icons/md';
import { FormattedMessage } from 'react-intl';

import styles from './ExportProgress.css';

const ExportProgress = ({ currentExport }) => {
  const [open, setOpen] = useState(false);
  const onClose = () => setOpen(false);
  const exportId = currentExport && currentExport.id;
  useEffect(() => {
    setOpen(!!exportId);
  }, [exportId]);
  return open && (
    <div className={styles.main}>
      {(() => {
        switch (currentExport.status) {
          case 'pending': return (
            <FormattedMessage id="ExportProgress.pending" defaultMessage="Exporting results..." />
          );
          case 'process': return (
            <div>
              <FormattedMessage
                id="ExportProgress.process"
                defaultMessage="Exporting results"
              />
              <div className={styles.progress}>
                <LinearProgress value={currentExport.progress.processed} max={currentExport.progress.total} mode="determinate" />
              </div>
            </div>
          );
          case 'done': return (
            <FormattedMessage
              id="ExportProgress.done"
              defaultMessage="Results exported correctly. {download}"
              values={{
                download: (
                  <Button variant="text" style={{ marginLeft: 12, padding: '0 12px', textTransform: 'uppercase' }}>
                    <a href={currentExport.url} download>
                      <FormattedMessage id="Download" defaultMessage="Download" />
                    </a>
                  </Button>),
              }}
            />
          );
          case 'error': return (
            <FormattedMessage
              id="ExportProgress.error"
              defaultMessage="Error exporting results"
            />
          );
          default: return '';
        }
      })()}
      <IconButton
        style={{
          color: '#fff',
          fontSize: '18px',
          position: 'absolute',
          padding: 6,
          width: 36,
          height: 36,
          top: 0,
          right: 0,
        }}
        name="close"
        onClick={onClose}
        small
        size="large"
      >
        <MdClose />
      </IconButton>
    </div>
  );
};

ExportProgress.propTypes = {
  currentExport: PropTypes.object.isRequired,
};

export default memo(ExportProgress);
