import React from 'react';
import Icon from 'react-icon-base';

const Email = props => (
  <Icon viewBox="0 0 12 12" {...props}>
    <g>
      <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".4" d="M4.4 6.1l-.5 1.2-.5-1-.5 1-.5-1.2M7 6.1l-.5 1.2-.5-1-.5 1L5 6.1M9.6 6.1l-.5 1.2-.5-1-.5 1-.5-1.2" />
      <path strokeWidth="0" d="M10.4 2.3H1.6a.7.7 0 0 0-.7.7v6a.7.7 0 0 0 .7.7h8.8a.7.7 0 0 0 .7-.7V3a.7.7 0 0 0-.7-.7zm-8.8.4h8.8a.3.3 0 0 1 .3.3v.6H1.3V3a.3.3 0 0 1 .3-.3zm8.8 6.6H1.6a.3.3 0 0 1-.3-.3V4h9.4v5a.3.3 0 0 1-.3.3z" />
      <path strokeWidth="0" d="M1.5 3.2l.5.3v-.6l-.5.3zM2.7 3.2l-.5-.3v.6l.5-.3z" />
      <path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth=".4" d="M3.2 3.2h7" />
    </g>
  </Icon>
);

export default Email;
