import { Box, Button, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import { setError as setErrorAction, setOk as setOkAction } from 'client/modules/App/AppActions';
import {
  discardAdditionalQuestionsPreview as discardAdditionalQuestionsPreviewAction,
  publishAdditionalQuestionsPreview as publishAdditionalQuestionsPreviewAction,
} from 'client/modules/Surveys/SurveyActions';
import { getId, hasAdditionalQuestions, isAdditionalQuestionsPreview } from 'client/modules/Surveys/SurveyReducer';
import useActions from 'client/util/useActions';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const DraftActions = ({ hide }) => {
  const handleOpenConfirm = useActions(openConfirm);
  const [publish, discard] = useActions([publishAdditionalQuestionsPreviewAction, discardAdditionalQuestionsPreviewAction]);
  const surveyId = useSelector(getId);
  const [setError, setOk] = useActions([setErrorAction, setOkAction]);
  const isPreview = useSelector(isAdditionalQuestionsPreview);
  const hasQuestions = useSelector(hasAdditionalQuestions);

  if (hide || !isPreview || !hasQuestions) {
    return null;
  }

  const handleDiscard = () => {
    handleOpenConfirm({
      title: (<FormattedMessage
        id="AdditionalQuestions.ConfirmDiscard"
        defaultMessage="Are you sure you want to discard all your changes?"
      />),
      text: (<FormattedMessage
        id="AdditionalQuestions.This action can not"
        defaultMessage="All your unpublished changes will be lost. This action can not be undone."
      />),
      onOk: async () => {
        await discard(surveyId);
      },
      textOk: (
        <FormattedMessage
          id="AdditionalQuestions.Discard"
          defaultMessage="Discard"
        />
      ),
      danger: true,
    });
  };
  const handlePublish = async () => {
    try {
      await publish(surveyId);
      setOk(<FormattedMessage
        id="AdditionalQuestions.Published"
        defaultMessage="Your changes are published. Now they will be visible to anyone taking the survey."
      />, 4000);
    } catch (error) {
      setError(error.message);
    }
  };
  return (
    <Paper sx={{ p: 3, mt: 3 }}>
      <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
        <FormattedMessage
          id="AdditionalQuestions.Unpublished"
          defaultMessage="You are editing a draft"
        />
      </Typography>
      <Typography sx={{ mb: 2 }}>
        <FormattedMessage
          id="AdditionalQuestions.ChangesNotVisible"
          defaultMessage="Your changes won't be shown in the survey until you publish them"
        />
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Button variant="contained" onClick={handlePublish}>
          <FormattedMessage
            id="AdditionalQuestions.Publish"
            defaultMessage="Publish"
          />
        </Button>
        <Button color="error" variant="text" onClick={handleDiscard}>
          <FormattedMessage
            id="AdditionalQuestions.Discard"
            defaultMessage="Discard"
          />
        </Button>
      </Box>
    </Paper>
  );
};

DraftActions.propTypes = {
  hide: PropTypes.bool,
};

DraftActions.defaultProps = {
  hide: false,
};
export default DraftActions;
