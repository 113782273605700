import cx from 'classnames';
import React from 'react';
import SVGInline from 'react-svg-inline';
import Archive from './icons/archive.svg';
import Forward from './icons/forward.svg';
import Junk from './icons/junk.svg';
import Printer from './icons/printer.svg';
import Reply from './icons/reply.svg';
import ReplyAll from './icons/replyAll.svg';
import Trash from './icons/trash.svg';
import style from './MailWindow.css';

const MailWindow = (props) => {
  return (
    <div className={cx([style.window, props.className])}>
      <div className={style.bar}>
        <div className={style.dots}>
          <div className={cx([style.dot, style.red])} />
          <div className={cx([style.dot, style.amber])} />
          <div className={cx([style.dot, style.green])} />
        </div>
        <div className={style.nav}>
          <SVGInline svg={Archive} />
          <SVGInline svg={Trash} />
          <SVGInline svg={Junk} />
        </div>
        <div className={style.nav}>
          <SVGInline svg={Reply} />
          <SVGInline svg={ReplyAll} />
          <SVGInline svg={Forward} />
        </div>
        <div className={style.nav}>
          <SVGInline svg={Printer} />
        </div>
      </div>
      <div className={style.frame}>
        <div className={style.content}>
          <div className={style.contentInner}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MailWindow;
