const translateAdditionalQuestions = (survey, language) => {
  const ret = { ...survey };
  ret.additionalQuestions = ret.additionalQuestions?.map((q) => {
    const newQ = { ...q };
    if (ret.language === language) {
      return newQ;
    }
    const customizations = ret.customizations
      ?.filter(c => c.language === language && c.name.includes(q._id.toString()));
    if (customizations?.find(c => c.name.endsWith('-text'))) {
      customizations.forEach((c) => {
        const key = c.name.replace(/^.*-/, '');
        const idx = newQ.optionsAttributes?.findIndex(o => o._id.toString() === key);
        if (idx > -1) {
          newQ.optionsAttributes[idx].text = c.value;
        } else {
          newQ[key] = c.value;
        }
      });
      return newQ;
    }
    return null;
  }).filter(q => q);
  return ret;
};

export default translateAdditionalQuestions;
