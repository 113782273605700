import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { getToken } from 'client/modules/Auth/AuthReducer';
import AutoWidth from 'client/modules/Surveys/components/AutoWidth/AutoWidth';
import LineTooltip from 'client/modules/Surveys/components/LineTooltip/LineTooltip';
import ContentTooltip from 'client/modules/Surveys/pages/DashboardPage/ContentTooltip';
import styles from 'client/modules/Surveys/pages/DashboardPage/DashboardPage.css';
import SearchKitContext from 'client/modules/Surveys/pages/DashboardPage/SearchKitContext';
import { fetchTimeSeries, setChartPeriod, toggleShowEngagement } from 'client/modules/Surveys/SurveyActions';
import {
  getChartPeriod, getShowEngagement, getTimeSeries, isChartVisible,
} from 'client/modules/Surveys/SurveyReducer';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as Config from '../../../../../server/config';

const messages = defineMessages({
  daily: {
    id: 'Dashboard.daily',
    defaultMessage: 'Daily',
  },
  weekly: {
    id: 'Dashboard.weekly',
    defaultMessage: 'Weekly',
  },
  monthly: {
    id: 'Dashboard.monthly',
    defaultMessage: 'Monthly',
  },
  showEngagement: {
    id: 'Dashboard.showEngagement',
    defaultMessage: 'Engagement',
  },
});

const DashboardChart = ({ survey }) => {
  const { id: surveyId } = useParams();
  const token = useSelector(getToken);
  const chartPeriod = useSelector(getChartPeriod);
  const showEngagement = !!useSelector(getShowEngagement);
  const dispatch = useDispatch();
  const timeSeries = useSelector(getTimeSeries);
  const chartVisible = useSelector(isChartVisible);
  const { searchkit } = useContext(SearchKitContext);
  const { query: { query: { post_filter: filter } } } = searchkit;

  useEffect(() => {
    if (chartVisible) {
      const upperBound = (surveyId === 'demo' || !survey?.hasResponses) ? new Date(parseInt(Config.demoTime, 10)) : survey.lastResponseAt;
      dispatch(fetchTimeSeries(!survey?.hasResponses ? 'demo' : surveyId, upperBound, filter, token));
    }
  }, [chartVisible, surveyId, survey.lastResponseAt, filter, token]);

  const handleToggle = (event) => {
    dispatch(toggleShowEngagement(event.target.checked));
  };

  const handlePeriodChange = (event, period) => {
    dispatch(setChartPeriod(period));
  };

  const { formatMessage } = useIntl();
  return (
    <Row
      className={`${styles.metrics} ${styles.chartContainer} ${chartVisible ? styles.visible : ''}`}
    >
      <Col xs={12}>
        <div className={styles.chartTitle}>
          <h2>
            <FormattedMessage
              id="Dashboard.HistoricValues"
              defaultMessage="Historic values"
            />
          </h2>
          <FormControlLabel
            control={(
              <Switch
                color="primary"
                style={{ width: 'auto' }}
                labelStyle={{ fontWeight: 'normal' }}
                checked={showEngagement}
                onChange={handleToggle}
              />
            )}
            label={formatMessage(messages.showEngagement)}
            labelPlacement="start"
          />

          <Tabs
            indicatorColor="primary"
            className={styles.tabs}
            value={chartPeriod}
            onChange={handlePeriodChange}
            inkBarStyle={{ backgroundColor: 'rgb(33, 150, 243)' }}
          >
            <Tab label={formatMessage(messages.daily)} value="day" />
            <Tab label={formatMessage(messages.weekly)} value="week" />
            <Tab label={formatMessage(messages.monthly)} value="month" />
          </Tabs>
        </div>
        <AutoWidth>
          <LineTooltip
            chartPeriod={chartPeriod}
            surveyType={survey.surveyType}
            svgClassName={styles.chart}
            data={timeSeries}
            height={330}
            width={800}
            margins={{
              left: 50, right: 0, top: 10, bottom: 30,
            }}
            chartSeries={[{
              field: survey.surveyType === 'star_five' ? 'average' : 'nps',
              symbol: 'circle',
              symbolSize: 6,
              color: '#474645',
            }]}
            x={d => (d ? new Date(d.key) : null)}
            bar={showEngagement ? (d => [{ sent: d.sent }, { opened: d.opened }, { responded: d.responded }, { commented: d.commented }]) : null}
            point={d => d}
            xScale="time"
          >
            <ContentTooltip surveyType={survey.surveyType} />
          </LineTooltip>
        </AutoWidth>
      </Col>
    </Row>
  );
};

DashboardChart.propTypes = {
  survey: PropTypes.object.isRequired,
};
export default DashboardChart;
