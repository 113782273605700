import moment from 'moment';
import { handleActions } from 'redux-actions';
import { isTokenExpired, isTokenMaxAgeExceeded } from '../../jwtHelper';

const initialState = {
  isFetching: false,
  profile: null,
  errorMessage: null,
  nextPath: null,
  changingPasswordFor: null,
};

const AuthReducer = handleActions({
  REQUEST_LOGIN: state => ({
    ...state,
    isFetching: true,
  }),
  RECEIVE_LOGIN: (state, action) => ({
    ...state,
    isFetching: false,
    errorMessage: null,
    profile: action.payload,
  }),
  LOGIN_ERROR: (state, action) => {
    return {
      ...state,
      isFetching: false,
      errorMessage: action.payload,
    };
  },
  RECEIVE_LOGOUT: (state) => {
    return {
      ...state,
      isFetching: false,
      profile: null,
      nextPath: null,
    };
  },
  SAVE_NEXT_PATH: (state, action) => {
    return {
      ...state,
      nextPath: action.payload,
    };
  },
  SET_CHANGING_PASSWORD_FOR: (state, action) => {
    return {
      ...state,
      changingPasswordFor: action.payload,
    };
  },
}, initialState);

export const isFetching = state => state.auth.isFetching;
export const getErrorMessage = state => state.auth.errorMessage;
export default AuthReducer;

export const getProfile = (state) => {
  return state.auth ? state.auth.profile : null;
};

export const getToken = (state) => {
  return state.auth && state.auth.profile ? state.auth.profile.token : null;
};

export const getUserWebId = (state) => {
  return state.auth && state.auth.profile ? state.auth.profile.webId : null;
};

export const getAllowSMS = (state) => {
  return state.auth && state.auth.profile ? state.auth.profile.allowSMS : null;
};

export const isLoggedIn = (state) => {
  const token = getToken(state);
  return !!token && !isTokenExpired(token);
};

export const shouldRefreshAuth = (state) => {
  const token = getToken(state);
  return !!token && isTokenMaxAgeExceeded(token) && !isTokenExpired(token);
};

export const getNextPath = (state) => {
  return state.auth ? state.auth.nextPath : null;
};

export const getChangingPasswordFor = state => state.auth && state.auth.changingPasswordFor;

export const getRemainingTrialDays = (state) => {
  const profile = getProfile(state);
  if (!profile) {
    return null;
  }
  const remaining = profile.trialEndsAt ? Math.max(moment(profile.trialEndsAt).endOf('day').diff(moment().startOf('day'), 'days'), 0) : null;
  return remaining;
};


export const visitorType = (state) => {
  if (isLoggedIn(state)) {
    if (getRemainingTrialDays(state) === null) {
      return 'customer';
    }
    return 'user';
  }
  return 'anonymous';
};
