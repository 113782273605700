import PropTypes from 'prop-types';

const surveyShape = {
  id: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  subject: PropTypes.string,
  topColor: PropTypes.string.isRequired,
  buttonColor: PropTypes.string.isRequired,
  lastResponseAt: PropTypes.string,
  updated_at: PropTypes.string,
};

export default surveyShape;
