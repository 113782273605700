import { NO_CONTENT } from 'http-status-codes';
import fetch from 'isomorphic-fetch';
import Config from '../../server/config';
import AppError from '../../server/util/AppError';
import { logoutUser, receiveLogin } from '../modules/Auth/AuthActions';

const DETAIL_MESSAGES_FORCING_REFRESH = ['maxAge exceeded', 'jwt expired'];
const shouldRefresh = json => json && (DETAIL_MESSAGES_FORCING_REFRESH.indexOf(json.details) >= 0);

export default async function callApi(dispatch, endpoint, token, method = 'get', data = undefined, file = undefined, refreshed = false) {
  try {
    const headers = {};
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
    let body;
    if (file) {
      body = new FormData();
      body.append('file', file);
      if (data) {
        Object.keys(data)
          .forEach((key) => {
            if (typeof data[key] === 'string') {
              body.append(key, data[key]);
            } else {
              body.append(key, JSON.stringify(data[key]));
            }
          });
      }
    } else {
      headers['content-type'] = 'application/json';
      body = JSON.stringify(data);
    }
    const publicDir = token ? '' : '/public';
    const resp = await fetch(`${Config.apiUrl}${publicDir}/${endpoint}`, {
      headers,
      method,
      body,
    });
    let json = null;
    let response;
    let error;
    if (resp.status === NO_CONTENT) {
      response = resp;
    } else {
      try {
        json = await resp.json();
        response = resp;
      } catch (err) {
        error = err;
        response = {
          ...resp,
          ok: false
        };
        json = null;
      }
    }

    if (!response.ok) {
      if (shouldRefresh(json) && !refreshed) {
        console.log('Refreshing token');
        const res = await callApi(dispatch, 'auth/refresh', token, 'get', undefined, undefined, true);
        console.log('Token refreshed');
        dispatch(receiveLogin(res.user));
        return callApi(dispatch, endpoint, res.user.token, method, data, file, true);
      }

      const err = error || new AppError({
        status: response.status,
        message: json?.message || 'Error fetching API response',
        errors: json?.errors || [],
      });
      err.extra = {
        json,
        endpoint,
        method,
        body: data,
      };
      err.response = response;
      err.bodyJson = json;
      throw err;
    }
    return json;
  } catch (err) {
    console.log('Error calling API', err);
    if (err.status === 401 && endpoint !== 'auth') {
      dispatch(logoutUser());
    } else {
      throw err;
    }
  }
  return null;
}
