import { useFindBySurveyQuery } from 'client/modules/Surveys/savedSearchApiSlice';
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

export const defaultValue = {
  filters: ['all'],
  groupBy: 'month',
  dateRange: ['now/y', 'now'],
  field: 'score',
};

const AnalyzeEvolutionContext = createContext(defaultValue);
export default AnalyzeEvolutionContext;
export const AnalyzeEvolutionProvider = ({ children, survey }) => {
  const [dateRange, setDateRange] = useState(defaultValue.dateRange);
  const [groupBy, setGroupBy] = useState(defaultValue.groupBy);
  const [filters, setFilters] = useState(defaultValue.filters);
  const [field, setField] = useState(defaultValue.field);
  const { data: searches, isLoading: isLoadingSearches } = useFindBySurveyQuery(survey.id, { skip: !survey });
  return (
    <AnalyzeEvolutionContext.Provider value={{
      dateRange, setDateRange, groupBy, setGroupBy, filters, setFilters, survey, searches, isLoadingSearches, field, setField,
    }}
    >
      {children}
    </AnalyzeEvolutionContext.Provider>
  );
};
AnalyzeEvolutionProvider.propTypes = {
  children: PropTypes.node.isRequired,
  survey: PropTypes.object.isRequired,
};
export const useAnalyzeEvolution = () => useContext(AnalyzeEvolutionContext);
