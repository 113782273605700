/* Native Javascript for Bootstrap 4 | Internal Utility Functions
 ----------------------------------------------------------------*/

export const globalObject = typeof global !== 'undefined' ? global : this || window;
export const doc = typeof document === 'object' ? document.documentElement : {};
export const body = typeof document === 'object' ? document.body : {};

// function toggle attributes
export const dataToggle = 'data-toggle';
export const dataDismiss = 'data-dismiss';
export const dataSpy = 'data-spy';
export const dataRide = 'data-ride';

// components
export const stringAlert = 'Alert';
export const stringButton = 'Button';
export const stringCarousel = 'Carousel';
export const stringCollapse = 'Collapse';
export const stringDropdown = 'Dropdown';
export const stringModal = 'Modal';
export const stringPopover = 'Popover';
export const stringScrollSpy = 'ScrollSpy';
export const stringTab = 'Tab';
export const stringTooltip = 'Tooltip';

// options DATA API
export const databackdrop = 'data-backdrop';
export const dataKeyboard = 'data-keyboard';
export const dataTarget = 'data-target';
export const dataInterval = 'data-interval';
export const dataHeight = 'data-height';
export const dataPause = 'data-pause';
export const dataOriginalTitle = 'data-original-title';
export const dataOriginalText = 'data-original-text';
export const dataDismissible = 'data-dismissible';
export const dataTrigger = 'data-trigger';
export const dataAnimation = 'data-animation';
export const dataContainer = 'data-container';
export const dataPlacement = 'data-placement';
export const dataDelay = 'data-delay';
export const dataOffsetTop = 'data-offset-top';
export const dataOffsetBottom = 'data-offset-bottom';

// option keys
export const backdrop = 'backdrop';
export const keyboard = 'keyboard';
export const delay = 'delay';
export const content = 'content';
export const target = 'target';
export const interval = 'interval';
export const pause = 'pause';
export const animation = 'animation';
export const placement = 'placement';
export const container = 'container';

// box model
export const offsetTop = 'offsetTop';
export const offsetBottom = 'offsetBottom';
export const offsetLeft = 'offsetLeft';
export const scrollTop = 'scrollTop';
export const scrollLeft = 'scrollLeft';
export const clientWidth = 'clientWidth';
export const clientHeight = 'clientHeight';
export const offsetWidth = 'offsetWidth';
export const offsetHeight = 'offsetHeight';
export const innerWidth = 'innerWidth';
export const innerHeight = 'innerHeight';
export const scrollHeight = 'scrollHeight';
export const height = 'height';

// aria
export const ariaExpanded = 'aria-expanded';
export const ariaHidden = 'aria-hidden';

// event names
export const clickEvent = 'click';
export const hoverEvent = 'hover';
export const keydownEvent = 'keydown';
export const resizeEvent = 'resize';
export const scrollEvent = 'scroll';
// originalEvents
export const showEvent = 'show';
export const shownEvent = 'shown';
export const hideEvent = 'hide';
export const hiddenEvent = 'hidden';
export const closeEvent = 'close';
export const closedEvent = 'closed';
export const slidEvent = 'slid';
export const slideEvent = 'slide';
export const changeEvent = 'change';

// other
export const getAttribute = 'getAttribute';
export const setAttribute = 'setAttribute';
export const hasAttribute = 'hasAttribute';
export const getElementsByTagName = 'getElementsByTagName';
export const getBoundingClientRect = 'getBoundingClientRect';
export const getElementsByCLASSNAME = 'getElementsByClassName';

export const indexOf = 'indexOf';
export const parentNode = 'parentNode';
export const length = 'length';
export const toLowerCase = 'toLowerCase';
export const Transition = 'Transition';
export const Webkit = 'Webkit';
export const style = 'style';

export const active = 'active';
export const showClass = 'show';
export const collapsing = 'collapsing';
export const disabled = 'disabled';
export const loading = 'loading';
export const left = 'left';
export const right = 'right';
export const top = 'top';
export const bottom = 'bottom';

// tooltip / popover
export const fixedTop = 'fixed-top';
export const fixedBottom = 'fixed-bottom';
export const mouseHover = typeof document === 'object' && ('onmouseleave' in document) ? ['mouseenter', 'mouseleave'] : ['mouseover', 'mouseout'];
export const tipPositions = /\b(top|bottom|left|top)+/;

// transitionEnd since 2.0.4
export const supportTransitions = typeof document === 'object' && (Webkit + Transition
  in doc[style] || Transition[toLowerCase]() in doc[style]);
export const transitionEndEvent = typeof document === 'object' && (Webkit + Transition
in doc[style] ? `${Webkit[toLowerCase]() + Transition}End` : `${Transition[toLowerCase]()}end`);

// set new focus element since 2.0.3
export const setFocus = (element) => {
  return element.focus ? element.focus() : element.setActive();
};

// class manipulation, since 2.0.0 requires polyfill.js
export const addClass = (element, classNAME) => {
  element.classList.add(classNAME);
};
export const removeClass = (element, classNAME) => {
  element.classList.remove(classNAME);
};
export const hasClass = (element, classNAME) => { // since 2.0.0
  return element.classList.contains(classNAME);
};

// selection methods
export const getElementsByClassName = (element, classNAME) => { // returns Array
  return [].slice.call(element[getElementsByCLASSNAME](classNAME));
};
export const queryElement = (selector, parent) => {
  const lookUp = parent || document;
  return typeof selector === 'object' ? selector : lookUp.querySelector(selector);
};
export const getClosest = (elementParam, selector) => { // element is the element and selector is for the closest parent element to find
  // source http://gomakethings.com/climbing-up-and-down-the-dom-tree-with-vanilla-javascript/
  const firstChar = selector.charAt(0);
  let element = elementParam;
  for (; element && element !== document; element = element[parentNode]) { // Get closest match
    if (firstChar === '.') { // If selector is a class
      if (queryElement(selector, element[parentNode]) !== null && hasClass(element, selector.replace('.', ''))) {
        return element;
      }
    } else if (firstChar === '#') { // If selector is an ID
      if (element.id === selector.substr(1)) {
        return element;
      }
    }
  }
  return false;
};

// event attach jQuery style / trigger  since 1.2.0
export const on = (element, event, handler) => {
  element.addEventListener(event, handler, false);
};
export const off = (element, event, handler) => {
  element.removeEventListener(event, handler, false);
};
export const one = (element, event, handler) => { // one since 2.0.4
  on(element, event, function handlerWrapper(e) {
    handler(e);
    off(element, event, handlerWrapper);
  });
};
export const emulateTransitionEnd = (element, handler) => { // emulateTransitionEnd since 2.0.4
  if (false && supportTransitions) {
    one(element, transitionEndEvent, handler);
  } else {
    handler();
  }
};
export const bootstrapCustomEvent = (element, eventName, componentName, related) => {
  const OriginalCustomEvent = new CustomEvent(`${eventName}.bs.${componentName}`);
  OriginalCustomEvent.relatedTarget = related;
  element.dispatchEvent(OriginalCustomEvent);
};

// reference a live collection of the DOM
export const AllDOMElements = typeof document === 'object' && document[getElementsByTagName]('*');

// Init DATA API
export const initializeDataAPI = (component, constructor, dataAttribute, collection) => {
  const lookUp = collection && collection[length] ? collection : AllDOMElements;
  for (let i = 0; i < lookUp[length]; i += 1) {
    const attrValue = lookUp[i][getAttribute](dataAttribute);
    const expectedAttrValue = component.replace(/spy/i, '')[toLowerCase]();
    if ((attrValue && component === stringButton && (attrValue[indexOf](expectedAttrValue) > -1)) // data-toggle="buttons"
      || attrValue === expectedAttrValue) { // all other components
      new constructor(lookUp[i]);
    }
  }
};

// tab / collapse stuff
export const targetsReg = /^#(.)+$/;
export const getOuterHeight = (child) => {
  const childStyle = child && globalObject.getComputedStyle(child);
  const btp = /px/.test(childStyle.borderTopWidth) ? Math.round(childStyle.borderTopWidth.replace('px', '')) : 0;
  const btb = /px/.test(childStyle.borderBottomWidth) ? Math.round(childStyle.borderBottomWidth.replace('px', '')) : 0;
  const mtp = /px/.test(childStyle.marginTop) ? Math.round(childStyle.marginTop.replace('px', '')) : 0;
  const mbp = /px/.test(childStyle.marginBottom) ? Math.round(childStyle.marginBottom.replace('px', '')) : 0;
  return child[clientHeight] + parseInt(btp, 10) + parseInt(btb, 10) + parseInt(mtp, 10) + parseInt(mbp, 10);
};
export const getMaxHeight = (parent) => { // get collapse trueHeight and border
  let parentHeight = 0;
  for (let k = 0, ll = parent.children[length]; k < ll; k += 1) {
    parentHeight += getOuterHeight(parent.children[k]);
  }
  return parentHeight;
};

// tooltip / popover stuff
export const isElementInViewport = (element) => { // check if this.tooltip is in viewport
  const rect = element[getBoundingClientRect]();
  return (rect[top] >= 0 && rect[left] >= 0 &&
    rect[bottom] <= (globalObject[innerHeight] || doc[clientHeight]) &&
    rect[right] <= (globalObject[innerWidth] || doc[clientWidth]));
};
export const getScroll = () => ({ // also Affix and ScrollSpy uses it
  y: globalObject.pageYOffset || doc[scrollTop],
  x: globalObject.pageXOffset || doc[scrollLeft],
});

export const styleTip = (link, elementParam, position, parent) => { // both popovers and tooltips
  const element = elementParam;
  const rect = link[getBoundingClientRect]();
  const scroll = parent === body ? getScroll() : {
    x: parent[offsetLeft] + parent[scrollLeft],
    y: parent[offsetTop] + parent[scrollTop],
  };
  const linkDimensions = { w: rect[right] - rect[left], h: rect[bottom] - rect[top] };
  const elementDimensions = { w: element[offsetWidth], h: element[offsetHeight] };

  // apply styling to tooltip or popover
  if (position === top) { // TOP
    element[style][top] = `${rect[top] + scroll.y - elementDimensions.h}px`;
    element[style][left] = `${rect[left] + scroll.x - elementDimensions.w / 2 + linkDimensions.w / 2}px`;
  } else if (position === bottom) { // BOTTOM
    element[style][top] = `${rect[top] + scroll.y + linkDimensions.h}px`;
    element[style][left] = `${rect[left] + scroll.x - elementDimensions.w / 2 + linkDimensions.w / 2}px`;
  } else if (position === left) { // LEFT
    element[style][top] = `${rect[top] + scroll.y - elementDimensions.h / 2 + linkDimensions.h / 2}px`;
    element[style][left] = `${rect[left] + scroll.x - elementDimensions.w}px`;
  } else if (position === right) { // RIGHT
    element[style][top] = `${rect[top] + scroll.y - elementDimensions.h / 2 + linkDimensions.h / 2}px`;
    element[style][left] = `${rect[left] + scroll.x + linkDimensions.w}px`;
  }
  return element.className[indexOf](position) === -1 && (element.className = element.className.replace(tipPositions, position));
};
export const updatePlacement = (position) => {
  switch (position) {
    case top:
      return bottom;
    case bottom:
      return top;
    case left:
      return right;
    case right:
      return left;
    default:
      return position;
  }
};
