import {
  Add, Archive, ArrowDropDown, CheckBox, Close, Delete, DriveFileMove, Edit, IndeterminateCheckBox, MoreHoriz, Share, Unarchive,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CardActionArea,
  Checkbox,
  DialogActions,
  DialogTitle,
  FormControl,
  InputLabel,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  Typography,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardText from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import cx from 'classnames';
import { getI18n } from 'client/AppReducer';
import { getToken } from 'client/modules/Auth/AuthReducer';
import {
  useCreateFolderMutation, useFindFoldersQuery, useRemoveFolderMutation, useUpdateFolderMutation,
} from 'client/modules/Surveys/folderApiSlice';
import SearchBox from 'client/modules/Surveys/pages/SurveysPage/SearchBox';
import { deleteSurvey, editSurvey, fetchSurveys } from 'client/modules/Surveys/SurveyActions';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import InputAutoWidth from 'client/util/InputAutoWidth';
import useOpenClose from 'client/util/useOpenClose';
import React, { useEffect, useRef, useState } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, NavLink, useNavigate, useParams,
} from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import TimeAgo from 'react-timeago';
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter';
import enStrings from 'react-timeago/lib/language-strings/en';
import esStrings from 'react-timeago/lib/language-strings/es';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Layout from '../../../App/components/Layout/LayoutContainer';
import survey from './survey.svg';
import emptyFolder from './folder.svg';
import empty from './empty.svg';

import styles from './SurveysPage.css';

const messages = defineMessages({
  surveyList: {
    id: 'Surveys.SurveyList',
    defaultMessage: 'Active Surveys',
  },
  results: {
    id: 'Surveys.Results',
    defaultMessage: 'See results',
  },
  delete: {
    id: 'Surveys.Delete',
    defaultMessage: 'Delete',
  },
  cancel: {
    id: 'Surveys.Cancel',
    defaultMessage: 'Cancel',
  },
  enabled: {
    id: 'Surveys.Enabled',
    defaultMessage: 'Enabled',
  },
  disabled: {
    id: 'Surveys.Disabled',
    defaultMessage: 'Disabled',
  },
  active: {
    id: 'Surveys.Active',
    defaultMessage: 'Active surveys',
  },
  archived: {
    id: 'Surveys.Archived',
    defaultMessage: 'Archived surveys',
  },
  nps: {
    id: 'Surveys.NPS',
    defaultMessage: 'NPS',
  },
  csat: {
    id: 'Surveys.CSAT',
    defaultMessage: 'CSAT',
  },
  ces: {
    id: 'Surveys.CES',
    defaultMessage: 'CES',
  },
  star_five: {
    id: 'Surveys.StarFive',
    defaultMessage: '5 stars',
  },
  custom: {
    id: 'Surveys.Custom',
    defaultMessage: 'Custom',
  },
  allSurveyTypes: {
    id: 'Surveys.AllSurveyTypes',
    defaultMessage: 'All survey types',
  },
  name: {
    id: 'Surveys.Name',
    defaultMessage: 'Name',
  },
  selectFolder: {
    id: 'Surveys.SelectFolder',
    defaultMessage: 'Select folder',
  },
  selectAll: {
    id: 'Surveys.SelectAll',
    defaultMessage: 'Select all',
  },
  cancelSelection: {
    id: 'Surveys.CancelSelection',
    defaultMessage: 'Cancel selection',
  },
  archive: {
    id: 'Surveys.Archive',
    defaultMessage: 'Archive',
  },
  unarchive: {
    id: 'Surveys.Unarchive',
    defaultMessage: 'Unarchive',
  },
  moveToFolder: {
    id: 'Surveys.MoveToFolder',
    defaultMessage: 'Move to folder',
  },
});

const SurveysPage = () => {
  const inputRefs = useRef(new Map());
  const [renaming, setRenaming] = useState(null);
  const [isFolderDialogOpen, openFolderDialog, closeFolderDialog, openedFolder] = useOpenClose();
  const [isMoveToFolderOpen, openMoveToFolder, closeMoveToFolder, movingSurveys] = useOpenClose();
  const [moveToFolderId, setMoveToFolderId] = useState(null);
  const [newFolderName, setNewFolderName] = useState('');
  useEffect(() => {
    if (isFolderDialogOpen && openedFolder) {
      setNewFolderName(openedFolder.name);
    } else {
      setNewFolderName('');
    }
  }, [isFolderDialogOpen]);
  const [search, setSearch] = useState('');
  const [surveyType, setSurveyType] = useState(null);
  const folder = useParams().folder || 'active';
  const { data: folders = [] } = useFindFoldersQuery();
  const [createFolder] = useCreateFolderMutation();
  const [deleteFolder] = useRemoveFolderMutation();
  const [editFolder] = useUpdateFolderMutation();
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const { locale } = useSelector(getI18n);
  const allSurveys = useSelector(getSurveys) || [];
  const surveysByFolder = {
    active: allSurveys.filter(s => !s.archived && !s.folder),
    archived: allSurveys.filter(s => s.archived),
    ...(folders.reduce((acc, f) => {
      acc[f._id] = allSurveys.filter(s => s.folder === f._id && !s.archived);
      return acc;
    }, {})),
  };
  let surveys = (surveysByFolder[folder] || [])
    .sort((a, b) => new Date(b.lastResponseAt) - new Date(a.lastResponseAt));

  const surveyTypes = Object.keys(surveys.reduce((acc, s) => { acc[s.surveyType] = true; return acc; }, {}));
  surveys = surveys.filter((s) => {
    const value = (s.name || s.brand || '').toLowerCase();
    return (!surveyType || s.surveyType === surveyType)
      && (!search || search.toLowerCase().split(' ').every(c => value.includes(c)));
  });
  const [isMenuOpen, openMenu, closeMenu, menuProps] = useOpenClose();
  useEffect(() => {
    closeMenu();
  }, [isMoveToFolderOpen]);
  const navigate = useNavigate();
  const [surveyToDelete, setSurveyToDelete] = useState(null);
  const [folderToDelete, setFolderToDelete] = useState(null);
  const [selectedSurveys, setSelectedSurveys] = useState({});
  const { formatMessage } = useIntl();

  const handleSeeResults = (surveyId) => {
    navigate(`/surveys/${surveyId}`);
  };

  const handleEdit = () => {
    navigate(`/surveys/${menuProps.survey.id}/edit`);
  };

  const handleRename = () => {
    closeMenu();
    setRenaming(menuProps.survey.id);
    setTimeout(() => {
      inputRefs.current.get(menuProps.survey.id).focus();
    }, 100);
  };

  const confirmDelete = (event) => {
    setSurveyToDelete(menuProps.survey);
    event.stopPropagation();
    closeMenu();
  };

  const handleDelete = () => {
    dispatch(deleteSurvey(surveyToDelete.id, token));
    setSurveyToDelete(null);
  };

  const handleDeleteFolder = async () => {
    await deleteFolder(folderToDelete);
    dispatch(fetchSurveys(token));
    setFolderToDelete(false);
    navigate('/surveys/list/active');
  };

  const handleClose = () => setSurveyToDelete(false);
  const handleCloseDeleteFolder = () => setFolderToDelete(false);

  const handleOpenMenu = (survey, event) => {
    event.stopPropagation();
    openMenu({ anchorEl: event.currentTarget, survey });
  };

  const handleSelect = (survey, event) => {
    event.stopPropagation();
    setSelectedSurveys({ ...selectedSurveys, [survey.id]: selectedSurveys[survey.id] ? null : survey });
  };

  const toggleState = (event) => {
    event.stopPropagation();
    closeMenu();
    dispatch(editSurvey(menuProps.survey.id, { disabled: !menuProps.survey.disabled }, token));
  };

  const toggleArchived = (event) => {
    event.stopPropagation();
    closeMenu();
    dispatch(editSurvey(menuProps.survey.id, { archived: !menuProps.survey.archived }, token));
  };

  const cancelSelection = () => {
    setSelectedSurveys({});
  };

  const moveToFolder = async () => {
    await Promise.all(movingSurveys.map(s => (
      dispatch(editSurvey(
        s.id,
        { archived: false, folder: moveToFolderId === 'active' ? null : moveToFolderId },
        token,
      ))
    )));
    cancelSelection();
    closeMoveToFolder();
  };

  const handleSubmitFolder = () => {
    if (!openedFolder?._id) {
      createFolder({ name: newFolderName });
    } else {
      editFolder({ _id: openedFolder._id, name: newFolderName });
    }
    closeFolderDialog();
  };

  const handleShare = (surveyId, event) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`/surveys/${surveyId}/import/share`, { state: { create: true } });
  };

  const strings = { es: esStrings, en: enStrings };
  const formatter = buildFormatter(strings[locale]);
  const [surveyNames, setSurveyNames] = useState({});
  const isSelecting = Object.values(selectedSurveys).filter(v => v).length || 0;
  const allSelected = surveys.length === isSelecting;
  const selectAll = () => {
    setSelectedSurveys(surveys.reduce((acc, s) => {
      acc[s.id] = s;
      return acc;
    }, {}));
  };
  const archive = archived => () => {
    Object.keys(selectedSurveys).forEach((id) => {
      dispatch(editSurvey(id, { archived }, token));
    });
    setSelectedSurveys({});
  };
  return (
    <Layout>
      <div className={styles.mainContent}>
        <Grid container spacing={6} sx={{ my: 0, flex: "1 0 auto" }}>
          <Grid xs={12} md={3} item>
            <List className={styles.folderMenu}>
              {["active", "archived"].map((key) => (
                <ListItemButton
                  key={key}
                  component={NavLink}
                  to={`/surveys/list/${key}`}
                  selected={key === folder}
                >
                  <ListItemText primary={formatMessage(messages[key])} />
                  <ListItemSecondaryAction>
                    <Typography color="muted.main">
                      {surveysByFolder[key]?.length}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
              <ListSubheader
                sx={{
                  backgroundColor: "transparent",
                  display: "flex",
                  justifyContent: "space-between",
                  mt: 3,
                }}
              >
                <FormattedMessage
                  id="Surveys.Folders"
                  defaultMessage="Folders"
                />
                <Button
                  disableRipple
                  variant="text"
                  color="primary"
                  onClick={openFolderDialog}
                  sx={{
                    textTransform: "none",
                    "&:hover": { backgroundColor: "transparent" },
                  }}
                >
                  <FormattedMessage
                    id="Surveys.Create new"
                    defaultMessage="Create new"
                  />
                </Button>
              </ListSubheader>
              {folders.map((f) => (
                <ListItemButton
                  key={f.id}
                  component={NavLink}
                  to={`/surveys/list/${f._id}`}
                  selected={f.id === folder}
                  classes={{ root: styles.folderItem }}
                >
                  <ListItemText primary={f.name} />
                  <ListItemSecondaryAction>
                    <Typography color="muted.main">
                      {surveysByFolder[f._id]?.length}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        openFolderDialog(f);
                      }}
                    >
                      <Edit />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => setFolderToDelete(f)}
                    >
                      <Delete />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItemButton>
              ))}
            </List>
          </Grid>
          <Grid xs={12} md={9} item display="flex" flexDirection="column">
            {(surveys.length > 0 || folder !== "active") && (
              <>
                <Typography variant="h4" className={styles.title}>
                  {messages[folder]
                    ? formatMessage(messages[folder])
                    : folders.find((f) => f._id === folder)?.name}
                </Typography>
                <div className={styles.actionBar}>
                  <Button
                    variant="contained"
                    color="primary"
                    component={NavLink}
                    to="/surveys/new-choose-type"
                  >
                    <Add />
                    <FormattedMessage
                      id="Surveys.NewSurvey"
                      defaultMessage="Create new survey"
                    />
                  </Button>
                  <span className={styles.verticalSpacer} />
                  <SwitchTransition>
                    <CSSTransition
                      timeout={300}
                      key={isSelecting}
                      classNames={{
                        enter: styles.onEnter,
                        enterActive: styles.enterActive,
                        exit: styles.onExit,
                        exitActive: styles.exitActive,
                      }}
                    >
                      {isSelecting ? (
                        <Box className={styles.multiActions}>
                          <Typography>
                            <FormattedMessage
                              id="Surveys.SelectedSurveys"
                              defaultMessage="{count} selected"
                              values={{ count: isSelecting }}
                            />
                          </Typography>
                          <Tooltip
                            title={formatMessage(messages.selectAll)}
                            placement="top"
                            arrow
                          >
                            <IconButton onClick={selectAll}>
                              {allSelected ? (
                                <CheckBox color="primary" />
                              ) : (
                                <IndeterminateCheckBox />
                              )}
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={formatMessage(
                              folder === "archived"
                                ? messages.unarchive
                                : messages.archive,
                            )}
                            placement="top"
                            arrow
                          >
                            <IconButton
                              onClick={archive(folder !== "archived")}
                            >
                              {folder === "archived" ? (
                                <Unarchive />
                              ) : (
                                <Archive />
                              )}
                            </IconButton>
                          </Tooltip>
                          {folders?.length > 0 && (
                            <Tooltip
                              title={formatMessage(messages.moveToFolder)}
                              placement="top"
                              arrow
                            >
                              <IconButton
                                onClick={() =>
                                  openMoveToFolder(
                                    Object.values(selectedSurveys).filter(
                                      (v) => v,
                                    ),
                                  )
                                }
                              >
                                <DriveFileMove />
                              </IconButton>
                            </Tooltip>
                          )}
                          <span className={styles.verticalSpacer} />
                          <Tooltip
                            title={formatMessage(messages.cancelSelection)}
                            placement="top"
                            arrow
                          >
                            <IconButton onClick={cancelSelection}>
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      ) : (
                        <Select
                          displayEmpty
                          labelId="surveyTypeLabel"
                          className={styles.select}
                          value={surveyType}
                          sx={{
                            "& .MuiSelect-select .notranslate::after": {
                              content: `"${formatMessage(messages.allSurveyTypes)}"`,
                              opacity: 0.42,
                            },
                          }}
                          onChange={(e) => setSurveyType(e.target.value)}
                          IconComponent={(props) =>
                            surveyType ? (
                              <Close
                                {...props}
                                style={{
                                  pointerEvents: "all",
                                  cursor: "pointer",
                                }}
                                onClick={() => setSurveyType(null)}
                              />
                            ) : (
                              <ArrowDropDown {...props} />
                            )
                          }
                        >
                          {surveyTypes.map((type) => (
                            <MenuItem key={type} value={type}>
                              {formatMessage(messages[type])}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    </CSSTransition>
                  </SwitchTransition>
                  <div className={styles.spacer} />
                  <div className={styles.actionBarRight}>
                    <SearchBox value={search} setValue={setSearch} />
                  </div>
                </div>
              </>
            )}
            {surveys.length > 0 && (
              <div
                className={cx({
                  [styles.cards]: true,
                  [styles.selecting]: isSelecting,
                })}
              >
                {surveys.map((s) => (
                  <Card variant="outlined" className={styles.card} key={s.id}>
                    <CardActionArea
                      onClick={
                        renaming !== s.id
                          ? handleSeeResults.bind(null, s.id)
                          : null
                      }
                    >
                      <CardHeader
                        title={
                          <InputAutoWidth
                            readOnly={renaming !== s.id}
                            inputRef={(input) => {
                              if (input) {
                                inputRefs.current.set(s.id, input);
                              } else {
                                inputRefs.current.delete(s.id);
                              }
                            }}
                            sx={{
                              fontWeight: 500,
                              pointerEvents: renaming === s.id ? "all" : "none",
                            }}
                            multiline
                            onChange={(e) => {
                              setSurveyNames({
                                ...surveyNames,
                                [s.id]: e.target.value,
                              });
                            }}
                            value={surveyNames[s.id] || s.name || s.brand}
                            onFocus={(e) =>
                              e.currentTarget.setSelectionRange(
                                e.currentTarget.value.length,
                                e.currentTarget.value.length,
                              )
                            }
                            onTouchStart={(e) => {
                              e.stopPropagation();
                            }}
                            onMouseDown={(e) => {
                              e.stopPropagation();
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                            onKeyUp={(e) => {
                              e.stopPropagation();
                            }}
                            onKeyDown={(e) => {
                              e.stopPropagation();
                              if (e.key === "Enter") {
                                e.preventDefault();
                                e.target.blur();
                              }
                              if (e.key === "Escape") {
                                e.target.value = s.name;
                                setSurveyNames({
                                  ...surveyNames,
                                  [s.id]: e.target.value,
                                });
                                e.target.blur();
                              }
                            }}
                            onBlur={(e) => {
                              setRenaming(null);
                              if (e.target.value !== s.name) {
                                dispatch(
                                  editSurvey(
                                    s.id,
                                    { name: e.target.value },
                                    token,
                                  ),
                                );
                              }
                            }}
                          />
                        }
                        titleTypographyProps={{
                          variant: "body",
                          fontWeight: 500,
                        }}
                        subheaderTypographyProps={{ variant: "caption" }}
                        className={styles.cardHeader}
                        action={
                          <>
                            <Checkbox
                              className={styles.checkbox}
                              onClick={handleSelect.bind(null, s)}
                              onMouseDown={(e) => e.stopPropagation()}
                              onTouchStart={(e) => e.stopPropagation()}
                              checked={!!selectedSurveys[s.id]}
                            />
                            <IconButton
                              style={{ fontSize: "24px" }}
                              onClick={handleOpenMenu.bind(null, s)}
                              size="small"
                            >
                              <MoreHoriz style={{ verticalAlign: "top" }} />
                            </IconButton>
                          </>
                        }
                      />
                      <Box sx={{ my: 0, mx: 1 }} component="hr" />
                      <CardText className={styles.cardText}>
                        <Typography variant="caption" color="muted.main">
                          <FormattedMessage
                            id="Surveys.LastResponse"
                            defaultMessage="Last response {ago}"
                            values={{
                              ago: (
                                <TimeAgo
                                  itemProp="dateCreated"
                                  date={s.lastResponseAt}
                                  formatter={formatter}
                                />
                              ),
                            }}
                          />
                        </Typography>
                      </CardText>
                      <CardActions
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Chip
                          variant="outlined"
                          color={s.disabled || s.archived ? "muted" : "success"}
                          label={formatMessage(
                            s.disabled ? messages.disabled : messages.enabled,
                          )}
                          small
                          sx={{ borderRadius: 1, height: 24 }}
                        />
                        <IconButton
                          component={Link}
                          to={`/surveys/${s.id}/import/share`}
                          onClick={handleShare.bind(null, s.id)}
                        >
                          <Share />
                        </IconButton>
                      </CardActions>
                    </CardActionArea>
                  </Card>
                ))}
              </div>
            )}
            {surveys.length === 0 && folder === "active" && (
              <Box className={styles.noSurveys}>
                <SVGInline svg={survey} />
                <Typography variant="h5">
                  <FormattedMessage
                    id="Surveys.CreateASurvey"
                    defaultMessage="Create a survey"
                  />
                </Typography>
                <Typography variant="body">
                  <FormattedMessage
                    id="Surveys.NoSurveysDescription"
                    defaultMessage="Keep collecting feedback from your customers. Click on the button below to get started."
                  />
                </Typography>
                <Button
                  className={styles.createSurveyButton}
                  variant="contained"
                  color="primary"
                  component={NavLink}
                  to="/surveys/new-choose-type"
                >
                  <Add />
                  <FormattedMessage
                    id="Surveys.NewSurvey"
                    defaultMessage="Create new survey"
                  />
                </Button>
              </Box>
            )}
            {surveys.length === 0 && folder === "archived" && (
              <Box className={styles.empty}>
                <SVGInline svg={empty} />
                <Box>
                  <Typography variant="h5">
                    <FormattedMessage
                      id="Surveys.NoArchived"
                      defaultMessage="No archived surveys"
                    />
                  </Typography>
                  <Typography variant="body">
                    <FormattedMessage
                      id="Surveys.NoArchivedDescription"
                      defaultMessage="Store the surveys you no longer use here to keep your workspace clean. You can always restore them later."
                    />
                  </Typography>
                </Box>
              </Box>
            )}
            {surveys.length === 0 &&
              !["archived", "active"].includes(folder) && (
                <Box className={styles.empty}>
                  <SVGInline svg={emptyFolder} />
                  <Box>
                    <Typography variant="h5">
                      <FormattedMessage
                        id="Surveys.EmptyFolder"
                        defaultMessage="No surveys in this folder"
                      />
                    </Typography>
                    <Typography variant="body">
                      <FormattedMessage
                        id="Surveys.EmptyFolderDescription"
                        defaultMessage="Move surveys from the active surveys folder to keep your workspace organized."
                      />
                    </Typography>
                  </Box>
                </Box>
              )}
          </Grid>
        </Grid>
      </div>
      <Menu open={isMenuOpen} anchorEl={menuProps.anchorEl} onClose={closeMenu}>
        <MenuItem onClick={toggleState}>
          {menuProps.survey?.disabled ? (
            <FormattedMessage id="Surveys.Enable" defaultMessage="Enable" />
          ) : (
            <FormattedMessage id="Surveys.Disable" defaultMessage="Disable" />
          )}
        </MenuItem>
        <MenuItem onClick={handleRename}>
          <FormattedMessage id="Surveys.Rename" defaultMessage="Rename" />
        </MenuItem>
        <MenuItem onClick={handleEdit}>
          <FormattedMessage id="Surveys.Edit" defaultMessage="Edit" />
        </MenuItem>
        {folders?.length > 0 && (
          <MenuItem
            onClick={() => {
              openMoveToFolder([menuProps.survey]);
            }}
          >
            {formatMessage(messages.moveToFolder)}
          </MenuItem>
        )}
        <MenuItem onClick={toggleArchived}>
          {formatMessage(
            menuProps.survey?.archived ? messages.unarchive : messages.archive,
          )}
        </MenuItem>
        <MenuItem onClick={confirmDelete}>
          <Typography color="error">
            {formatMessage(messages.delete)}
          </Typography>
        </MenuItem>
      </Menu>
      <Dialog modal open={!!surveyToDelete} onRequestClose={handleClose}>
        <DialogTitle>
          <FormattedMessage
            id="Surveys.AreYouSure"
            defaultMessage="Are you sure you want to delete the survey {name}?"
            values={{ name: surveyToDelete?.name || surveyToDelete?.brand }}
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="Surveys.DeleteWarning"
            defaultMessage="This action cannot be undone."
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            keyboardFocused
            onClick={handleClose}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button variant="text" color="error" onClick={handleDelete}>
            {formatMessage(messages.delete)}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        modal
        open={isFolderDialogOpen}
        onRequestClose={closeFolderDialog}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitFolder();
          }}
        >
          <DialogTitle>
            {openedFolder ? (
              <FormattedMessage
                id="Surveys.EditFolder"
                defaultMessage="Edit folder"
              />
            ) : (
              <FormattedMessage
                id="Surveys.CreateFolder"
                defaultMessage="Create folder"
              />
            )}
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={formatMessage(messages.name)}
              type="text"
              fullWidth
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="inherit"
              keyboardFocused
              onClick={closeFolderDialog}
            >
              {formatMessage(messages.cancel)}
            </Button>
            <Button variant="text" color="primary" type="submit">
              {openedFolder ? (
                <FormattedMessage
                  id="Surveys.EditFolder"
                  defaultMessage="Edit folder"
                />
              ) : (
                <FormattedMessage
                  id="Surveys.CreateFolder"
                  defaultMessage="Create folder"
                />
              )}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        modal
        open={isMoveToFolderOpen}
        onRequestClose={closeMoveToFolder}
      >
        <DialogTitle>
          <FormattedMessage
            id="Surveys.MoveToFolder"
            defaultMessage="Move to folder"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <InputLabel id="selectFolderLabel">
              {formatMessage(messages.selectFolder)}
            </InputLabel>
            <Select
              label={formatMessage(messages.selectFolder)}
              labelId="selectFolderLabel"
              value={moveToFolderId}
              onChange={(e) => setMoveToFolderId(e.target.value)}
            >
              <MenuItem value="active">
                {formatMessage(messages.active)}
              </MenuItem>
              {folders.map((f) => (
                <MenuItem key={f._id} value={f._id}>
                  {f.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            keyboardFocused
            onClick={closeMoveToFolder}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button variant="text" color="primary" onClick={moveToFolder}>
            <FormattedMessage id="Surveys.Move" defaultMessage="Move" />
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        modal
        open={!!folderToDelete}
        onRequestClose={handleCloseDeleteFolder}
      >
        <DialogTitle>
          <FormattedMessage
            id="Surveys.AreYouSureFolder"
            defaultMessage="Are you sure you want to delete the folder {name}?"
            values={{ name: folderToDelete?.name || folderToDelete?.brand }}
          />
        </DialogTitle>
        <DialogContent>
          <FormattedMessage
            id="Surveys.DeleteWarning"
            defaultMessage="This action cannot be undone."
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="inherit"
            keyboardFocused
            onClick={handleCloseDeleteFolder}
          >
            {formatMessage(messages.cancel)}
          </Button>
          <Button variant="text" color="error" onClick={handleDeleteFolder}>
            {formatMessage(messages.delete)}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default SurveysPage;
