import { isLoggedIn } from 'client/modules/Auth/AuthReducer';
import PropTypes from 'prop-types';
import React, {
  createContext, useContext, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';

const ChargebeeContext = createContext();

export const ChargebeeProvider = ({ children }) => {
  const [cbInstance, setCbInstance] = useState(null);
  const loggedIn = useSelector(isLoggedIn);
  useEffect(() => {
    if (isLoggedIn && !!document) {
      import('scriptjs').then(({ default: scriptjs }) => {
        scriptjs('https://js.chargebee.com/v2/chargebee.js', () => {
          const instance = global.Chargebee.init({
            site: process.env.CHARGEBEE_SITE,
          });
          setCbInstance(instance);
        });
      });
    }
  }, [loggedIn]);
  return <ChargebeeContext.Provider value={cbInstance}>{children}</ChargebeeContext.Provider>;
};

ChargebeeProvider.propTypes = {
  children: PropTypes.node,
};

ChargebeeProvider.defaultProps = {
  children: null,
};

export const useChargebee = () => useContext(ChargebeeContext);
