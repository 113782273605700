//* * Redux toolkit slice for link API calls */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithReauth from 'client/util/baseQueryWithReauth';

export const linkApiSlice = createApi({
  reducerPath: 'linkReducer',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['link'],
  endpoints: builder => ({
    createLink: builder.mutation({
      query: ({ survey, name }) => ({
        url: `/surveys/${survey}/links`,
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: [{ type: 'link', id: 'LIST' }],
    }),
    updateLink: builder.mutation({
      query: ({
        survey, _id, name, addName, properties,
      }) => ({
        url: `/surveys/${survey}/links/${_id}`,
        method: 'PATCH',
        body: { name, addName, properties },
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'link',
          id: _id,
        }];
      },
    }),
    removeLink: builder.mutation({
      query: ({ survey, _id }) => ({
        url: `/surveys/${survey}/links/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'link',
          id: _id,
        }];
      },
    }),
    findLinks: builder.query({
      query: survey => `/surveys/${survey}/links`,
      type: 'link',
      providesTags: (links) => {
        return [
          ...(links || []).map(({ _id: id }) => ({
            type: 'link',
            id,
          })),
          {
            type: 'link',
            id: 'LIST',
          },
        ];
      },
    }),
    readLink: builder.query({
      query: ({ link }) => `/public/links/${link}`,
      type: 'link',
      providesTags: (result, error, { _id }) => {
        return [{
          type: 'link',
          id: _id,
        }];
      },
    }),
  }),
});

export const {
  useCreateLinkMutation,
  useUpdateLinkMutation,
  useRemoveLinkMutation,
  useFindLinksQuery,
  useReadLinkQuery,
  reducer: linkReducer,
} = linkApiSlice;
