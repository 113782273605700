import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './Progress.css';

const ProgressBarItem = (props) => {
  const {
    itemKey, listDocCount, count, label,
  } = props;
  const typeMap = {
    promoters: 'progress-bar-five',
    agree: 'progress-bar-five',
    satisfied: 'progress-bar-five',
    '5-stars': 'progress-bar-five',
    '4-stars': 'progress-bar-four',
    passives: 'progress-bar-three',
    neutral: 'progress-bar-three',
    '3-stars': 'progress-bar-three',
    '2-stars': 'progress-bar-two',
    detractors: 'progress-bar-one',
    disagree: 'progress-bar-one',
    dissatisfied: 'progress-bar-one',
    '1-stars': 'progress-bar-one',
  };
  const barType = typeMap[itemKey] || 'progress-bar-five';
  const itemClass = classnames([styles['progress-bar'], styles[barType]]);
  const tpc = listDocCount > 0 ? ((count || 0) / listDocCount) * 100 : 0;
  return (
    <div className={itemClass} style={{ width: `${tpc}%` }}>
      <dl>
        <dt>
          {Math.round(tpc)}
          <sup>%</sup>
        </dt>
        <dd>{label}</dd>
      </dl>
    </div>
  );
};

ProgressBarItem.propTypes = {
  itemKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  listDocCount: PropTypes.number.isRequired,
};

export default ProgressBarItem;
