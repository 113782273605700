import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import LayoutFocused from '../../../App/components/LayoutFocused/LayoutFocused';

import LoginWidget from './components/LoginWidget/LoginWidgetContainer';

const messages = defineMessages({
  title: {
    id: 'LoginPage.Title',
    defaultMessage: 'Log in',
  },
});

const LoginPage = () => {
  const { formatMessage } = useIntl();
  return (
    <LayoutFocused title={formatMessage(messages.title)}>
      <Grid fluid>
        <Row>
          <Col xs={12}>
            <LoginWidget isSignup={false} />
            <p style={{ marginTop: '3em' }}>
              <FormattedMessage
                id="LoginPage.GoToSignup"
                defaultMessage="Don't have an acount? Go to {signup}"
                values={{
                  signup: (
                    <Link to="/sign-up">
                      <FormattedMessage
                        id="LoginPage.SignUp"
                        defaultMessage="sign up"
                      />
                    </Link>
                  ),
                }}
              />
            </p>
          </Col>
        </Row>
      </Grid>
    </LayoutFocused>
  );
};

export default LoginPage;
