import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
  FaCheck, FaInfoCircle, FaExclamationTriangle, FaTimesCircle,
} from 'react-icons/fa';
import styles from './BlockMessage.css';

const BlockMessage = (props) => {
  const icon = {
    success: <FaCheck />,
    info: <FaInfoCircle />,
    warning: <FaExclamationTriangle />,
    error: <FaTimesCircle />,
  }[props.type];
  return (
    <div className={`${styles.msg} ${styles[props.type]}`}>
      <div className={`${styles.badge} ${styles[props.type]}`}>
        {icon}
      </div>
      <h1>{props.title}</h1>
      {props.message && <blockquote>{props.message}</blockquote>}
      {props.type === 'error' ? (
        <p>
          <span>
            <FormattedMessage
              id="BlockMessage.Error.Retry"
              defaultMessage="Please retry your last operation. If the problem persists, contact {contact}."
              values={{
                contact:
                  (<a href="mailto:support@promoter.ninja">
                    <FormattedMessage
                      id="BlockMessage.Error.Support"
                      defaultMessage="Promoter Ninja Support"
                    />
                  </a>),
              }}
            />
          </span>
        </p>
      ) : null}
    </div>
  );
};

BlockMessage.defaultProps = {
  message: null,
  type: 'info',
};

BlockMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  type: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
};

export default BlockMessage;
