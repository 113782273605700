import PropTypes from 'prop-types';

import {
  CheckboxItemList, Panel, renderComponent, SearchkitComponent,
} from 'searchkit';

import defaults from 'lodash/defaults';

import ExistsFieldFilterAccessor from './ExistsFieldFilterAccessor';

class ExistsFieldFilter extends SearchkitComponent {
  constructor(props) {
    super(props);
    this.accessor = null;
  }

  defineAccessor() {
    const {
      id, title, translations, labelWith, labelWithout, field,
    } = this.props;
    return new ExistsFieldFilterAccessor(id, {
      id, title, labelWith, labelWithout, translations, field,
    });
  }

  toggleState(state) {
    if (this.accessor) {
      this.accessor.state = this.accessor.state.create(this.accessor.state.getValue() === state ? '' : state);
      this.searchkit.performSearch();
    }
  }

  setFilters(keys) {
    this.accessor.state = this.accessor.state.setValue(keys.length > 0);
    this.searchkit.performSearch();
  }

  getSelectedItems() {
    if (this.accessor.state.getValue() === 'with') {
      return ['with'];
    } if (this.accessor.state.getValue() === 'without') {
      return ['without'];
    }
    return [];
  }

  render() {
    const {
      listComponent, containerComponent, showCount, title, id, labelWith, labelWithout,
    } = this.props;

    if (!this.accessor) {
      return null;
    }
    const disabled = (this.searchkit.getHitsCount() === 0) && !this.accessor.state.getValue();

    return renderComponent(
      containerComponent,
      {
        title,
        className: id ? `filter--${id}` : undefined,
        disabled,
      },
      [
        renderComponent(listComponent, {
          key: `${this.uuid}-with`,
          items: [{ key: 'with', label: labelWith, doc_count: this.accessor.getWithDocCount() }],
          selectedItems: this.getSelectedItems(),
          toggleItem: this.toggleState.bind(this, 'with'),
          setItems: this.setFilters.bind(this),
          showCount,
          disabled: this.accessor.getWithDocCount() === 0,
        }),
        renderComponent(listComponent, {
          key: `${this.uuid}-without`,
          items: [{ key: 'without', label: labelWithout, doc_count: this.accessor.getWithoutDocCount() }],
          selectedItems: this.getSelectedItems(),
          toggleItem: this.toggleState.bind(this, 'without'),
          setItems: this.setFilters.bind(this),
          showCount,
          disabled: this.accessor.getWithoutDocCount() === 0,
        }),
      ],
    );
  }
}


ExistsFieldFilter.propTypes = defaults({
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  labelWith: PropTypes.string.isRequired,
  labelWithout: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  translations: SearchkitComponent.translationsPropType(
    ExistsFieldFilterAccessor.translations,
  ),
  showCount: PropTypes.bool,
}, SearchkitComponent.propTypes);

ExistsFieldFilter.defaultProps = {
  listComponent: CheckboxItemList,
  containerComponent: Panel,
  collapsable: false,
  showCount: true,
};

export default ExistsFieldFilter;
