/* eslint-disable jsx-a11y/anchor-is-valid */
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';

import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import Helmet from 'react-helmet';
import { IoIosCheckmark } from 'react-icons/io';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import md5 from '../../../../util/md5';
import Layout from '../../../App/components/Layout/LayoutContainer';

import styles from './UserUnsubscribePage.css';

const messages = defineMessages({
  title: {
    id: 'UnsubscribePage.Title',
    defaultMessage: 'Successfully unsubscribed',
  },
  system: {
    id: 'UnsubscribePage.System',
    defaultMessage: 'You will no longer receive system messages from us.',
  },
  features: {
    id: 'UnsubscribePage.Features',
    defaultMessage: 'You will no longer receive features and services communications from us.',
  },
});

const UserUnsubscribePage = (props) => {
  const { formatMessage } = props.intl;

  return (
    <Layout>
      <Grid className={styles['main-grid']} fluid>
        <Row className={styles['main-content']}>
          <Col xs={12} sm={12} md={8} lg={6}>
            <Helmet title={formatMessage(messages.title)} />
            <div className={styles.avatar}>
              <img src={`https://www.gravatar.com/avatar/${md5(props.email || '')}?d=mm`} alt={props.email} />
            </div>
            <h2 className={styles.email}>{props.email}</h2>
            <Paper className={styles.paper}>
              <div className={styles.message}>
                <IoIosCheckmark className={styles.check} />
                <span>{messages[props.group] ? formatMessage(messages[props.group]) : null}</span>
                <div className={styles.button}>
                  <Link to={props.preferencesPath}>
                    <FormattedMessage
                      id="UnsubscribePage.SetEmailPreferences"
                      defaultMessage="Set Email Preferences"
                    />
                  </Link>
                </div>
              </div>
            </Paper>
          </Col>
        </Row>
      </Grid>
    </Layout>
  );
};


UserUnsubscribePage.propTypes = {
  intl: PropTypes.object.isRequired,
  email: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
  preferencesPath: PropTypes.string.isRequired,
};

export default injectIntl(UserUnsubscribePage);

