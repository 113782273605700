import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withLocation from '../../../util/withLocation';
import withParams from '../../../util/withParams';
import Spinner from '../../App/components/Spinner/Spinner';
import { getToken } from '../../Auth/AuthReducer';
import BlockMessage from '../components/BlockMessage';

import { addIntegration as addIntegrationAction } from '../IntegrationsActions';
import {
  getDone, getMessage, getIntegrationProps, getType,
} from '../IntegrationsReducer';

const messages = defineMessages({
  error: {
    id: 'Integrations.Error',
    defaultMessage: 'Authorization error',
  },
  success: {
    id: 'Integrations.Success',
    defaultMessage: 'Access granted successfully',
  },
  successMessage: {
    id: 'Integrations.SuccessMessage',
    defaultMessage: 'You will be redirected to your dashboard in 5 seconds.',
  },
});

const IntegrationsPage = ({
  token, code, state, urlType, type, message, done, addIntegration, integrationProps,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!done) {
      addIntegration(token, code, state, urlType);
    }
  }, []);

  useEffect(() => {
    if (done) {
      const packageUrlParam = integrationProps.packageUrl ? `?installPackage=${encodeURIComponent(integrationProps.packageUrl)}` : '';
      setTimeout(() => navigate(`/settings/integrations/${urlType}${packageUrlParam}`), 5000);
    }
  }, [done]);

  const title = type === 'error' ? formatMessage(messages.error) : formatMessage(messages.success);
  const fmtMessage = type === 'error' ? message : formatMessage(messages.successMessage);
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      {done ? <BlockMessage title={title} message={fmtMessage} type={type} /> : <Spinner />}
    </div>
  );
};

IntegrationsPage.defaultProps = {
  message: null,
  type: null,
  code: null,
  state: null,
  urlType: null,
  integrationProps: {},
};

IntegrationsPage.propTypes = {
  token: PropTypes.string.isRequired,
  message: PropTypes.string,
  type: PropTypes.string,
  done: PropTypes.bool.isRequired,
  integrationProps: PropTypes.object,
  code: PropTypes.string,
  state: PropTypes.string,
  urlType: PropTypes.string,
  addIntegration: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  token: getToken(state),
  message: getMessage(state),
  type: getType(state),
  done: getDone(state),
  integrationProps: getIntegrationProps(state),
  code: ownProps.location.query.code,
  state: ownProps.location.query.state,
  urlType: ownProps.params.type,
});

const mapDispatchToProps = dispatch => ({
  addIntegration(token, code, state, type) {
    dispatch(addIntegrationAction(token, code, state, type));
  },
});

export default withParams(withLocation(connect(mapStateToProps, mapDispatchToProps)(IntegrationsPage)));
