import { BarChart, InsertChart } from '@mui/icons-material';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { AbstractItemList } from 'searchkit';
import styles from './NPS.css';

class NPS extends AbstractItemList {
  render() {
    const {
      items, docCount, toggleChart, surveyType = '', intl, filtered, showToggle,
    } = this.props;

    const isFiveStar = surveyType === 'star_five';

    let totalScore = 0;
    let total = 0;
    let count = 0;

    if (items) {
      items.forEach((option) => {
        if (['promoters', 'agree', 'satisfied'].includes(option.key) && !option.missing) {
          totalScore += docCount > 0 ? option.doc_count / docCount : 0;
        }
        if (option.key === 'detractors' && !option.missing) {
          totalScore -= docCount > 0 ? option.doc_count / docCount : 0;
        }
        if (option.key.indexOf('-') > 0) {
          const [score] = option.key.split('-');
          total += option.doc_count * (+score);
          count += option.doc_count;
        }
      });
    }

    totalScore = Math.round(totalScore * 100);
    const avg = total > 0 ? total / count : 0;

    const typeString = surveyType.toUpperCase();
    const style = {
      style: 'decimal',
      maximumFractionDigits: 1,
    };
    const day30 = filtered ? <FormattedMessage id="Dashboard.day30" defaultMessage="30-day" /> : '';
    return (
      <div className={classNames({ [styles.nps]: true, [styles.wide]: showToggle })}>
        <div className={styles.npsNumber}>{isFiveStar ? intl.formatNumber(avg, style) : totalScore}</div>
        <div className={styles.npsLabelWrapper}>
          <div className={styles.npsLabel}>
            <FormattedMessage
              id="Dashboard.GlobalScoreFilter"
              defaultMessage="{day30} {surveyType}"
              values={{
                surveyType: isFiveStar
                  ? <FormattedMessage id="Dashboard.Average" defaultMessage="average" />
                  : typeString,
                day30,
              }}
            />
          </div>
          {showToggle && (
            <div className={styles.npsGraph}>
              <button type="button" className={styles.button} onClick={toggleChart}>
                <span className={styles.showGraphLong}>
                  <FormattedMessage
                    id="Dashboard.ShowChart"
                    defaultMessage="Show graph"
                  />
                </span>
                <span className={styles.showGraphShort}>
                  <FormattedMessage
                    id="Dashboard.ShowChartShort"
                    defaultMessage="Graph"
                  />
                </span>
                <BarChart />
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

NPS.propTypes = {
  intl: PropTypes.object.isRequired,
  toggleChart: PropTypes.func.isRequired,
  chartVisible: PropTypes.bool.isRequired,
  showToggle: PropTypes.bool,
};

NPS.defaultProps = {
  showToggle: true,
};

export default NPS;
