import RaisedButton from 'components/RaisedButton/RaisedButton';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import tinycolor from 'tinycolor2';
import Spinner from '../Spinner/Spinner';
import ColorThief from './async-load/color-thief';
import styles from './UploadS3.css';

const generateFileName = (len = 32) => {
  const randomChars = '0123456789abcdefghijklmnopqrstuvwxyz';
  let rnd = '';
  for (let i = 1; i <= len; i += 1) {
    const rn = Math.floor(Math.random() * randomChars.length);
    rnd += randomChars.substring(rn, rn + 1);
  }
  return rnd;
};

export default class Upload extends Component {
  constructor(props) {
    super(props);
    if (typeof document !== 'undefined') {
      // eslint-disable-next-line global-require
      this.$script = require('scriptjs');
      this.$script([ColorThief], 'ColorThief');
    }
    const { label } = props;
    this.state = {
      label,
    };
  }

  onProgress(percent) {
    this.setState({
      label: (
        <span>
          {`${percent}%`}
          <Spinner className={styles.spinner} />
        </span>
      ),
    });
  }

  onFinish(e) {
    const { label, onFileLoad } = this.props;
    try {
      if (typeof document !== 'undefined') {
        let src = `${e.url}?r=${new Date().getTime()}`;
        let it = 0;
        setTimeout(() => {
          this.$script.ready('ColorThief', () => {
            const img = new Image();
            img.crossOrigin = 'Anonymous';
            img.onerror = () => {
              if (it < 10) {
                it += 1;
                setTimeout(() => {
                  src = `${e.url}?r=${new Date().getTime()}`;
                  img.src = `${src}b`;
                }, 500);
              }
            };
            img.onload = () => {
              const palette = (new window.ColorThief()).getPalette(img, 8, 1);
              palette.forEach(([r, g, b]) => {
                const c = tinycolor({ r, g, b });
                const bright = c.getBrightness();
                console.log(`%c rgb(${r}, ${g}, ${b}) brightness: ${bright} dark: ${c.isDark()}`, `color: ${c.isDark() ? '#ffffff' : '#000000'}; background: rgb(${r},${g},${b})`);
              });
              let dark;
              for (let i = 0; i < palette.length && typeof dark === 'undefined'; i += 1) {
                const [r, g, b] = palette[i];
                const c = tinycolor({ r, g, b });
                if (c.isDark()) {
                  dark = c;
                }
              }
              onFileLoad(e.url, dark ? dark.toHexString() : undefined);
              this.setState({ label });
            };
            img.src = `${src}b`;
          });
        }, 1500);
      }
    } catch (err) {
      onFileLoad(e.url);
      this.setState({ label });
    }
    const xhr = new XMLHttpRequest();
    xhr.open('DELETE', e.deleteUrl, true);
    xhr.send();
  }

  render() {
    const {
      onFileLoad, buttonControl, token, fileName = generateFileName(), ...rest
    } = this.props;
    const { label } = this.state;
    return (
      <div className={styles.Container}>
        {
          fileName && fileName !== 'null' ? React.createElement(
            buttonControl,
            {
              variant: 'outlined',
              containerElement: 'label',
              ...rest,
              label,
            },
            (
              <ReactS3Uploader
                onProgress={this.onProgress.bind(this)}
                onFinish={this.onFinish.bind(this)}
                className={styles.FileInput}
                signingUrl="/api/sign-s3"
                accept="image/*"
                s3path=""
                signingUrlWithCredentials
                uploadRequestHeaders={{}}
                scrubFilename={() => `${fileName}.png`}
                signingUrlHeaders={{ Authorization: `Bearer ${token}` }}
              />
            )
          ) : null
        }
      </div>
    );
  }
}

Upload.defaultProps = {
  onFileLoad: () => undefined,
  buttonControl: RaisedButton,
  fileName: generateFileName(),
};

Upload.propTypes = {
  label: PropTypes.string.isRequired,
  onFileLoad: PropTypes.func,
  buttonControl: PropTypes.func,
  token: PropTypes.string.isRequired,
  fileName: PropTypes.string,
};
