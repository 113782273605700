/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { scale, xDomainCount, yDomainCount } from 'mhs-d3-core';

import CommonProps from '../CommonProps';

export const expandDomain = (dom, tpc = 0.125) => {
  const expandNumberDomain = (nd) => {
    const delta = (nd[1] - nd[0]) * tpc;
    return [nd[0] - delta, nd[1] + delta];
  };
  let ret = dom;
  if (typeof ret[0] !== 'undefined') {
    if (ret[0] instanceof Date) {
      ret = ret.map(v => v.getTime());
      ret = expandNumberDomain(ret);
      ret = ret.map(v => new Date(v));
    } else {
      ret = expandNumberDomain(ret);
    }
  }
  return ret;
};

export default class ChartSvg extends Component {
  render() {
    const {
      horizontal,
      height,
      width,
      margins,
      xTicks,
      yTicks,
      xTickFormat,
      yTickFormat,
      xBandPaddingInner,
      xBandPaddingOuter,
      yBandPaddingInner,
      yBandPaddingOuter,
      xLabel,
      yLabel,
      stack,
      data,
      svgClassName,
      id,
      x,
      y,
      svgRef,
    } = this.props;
    let {
      xScale,
      yScale,
      xRange,
      yRange,
      xDomain,
      yDomain,
    } = this.props;

    xRange = xRange || [0, width - margins.left - margins.right];
    yRange = yRange || [height - margins.top - margins.bottom, 0];
    xDomain = xDomain || xDomainCount(this.props, stack, horizontal);
    yDomain = yDomain || yDomainCount(this.props, stack, horizontal);

    if (xScale === 'ordinal') {
      xScale = 'band';
    }

    if (yScale === 'ordinal') {
      yScale = 'band';
    }

    const newXScale = {
      scale: xScale,
      range: xRange,
      domain: xDomain,
      bandPaddingInner: xBandPaddingInner,
      bandPaddingOuter: xBandPaddingOuter,
    };

    const xScaleSet = scale(newXScale);

    const newYScale = {
      scale: yScale,
      range: yRange,
      domain: yDomain,
      bandPaddingInner: yBandPaddingInner,
      bandPaddingOuter: yBandPaddingOuter,
    };

    const yScaleSet = scale(newYScale);

    const totalMax = data.reduce((max, d) => (d.total > max ? d.total : max), 0);

    const newY100Scale = {
      scale: yScale,
      range: yRange,
      domain: expandDomain([0, totalMax]),
      bandPaddingInner: yBandPaddingInner,
      bandPaddingOuter: yBandPaddingOuter,
    };
    const y100ScaleSet = scale(newY100Scale);
    const bottom = y100ScaleSet(totalMax);
    const bars = this.props.bar ? data.map((d) => {
      let acc = totalMax;
      return this.props.bar(d).map((b) => {
        const key = Object.keys(b)[0];
        acc -= b[key];
        return {
          name: 'nps',
          key,
          x: x(d),
          y: y100ScaleSet(totalMax - acc),
          height: y100ScaleSet(totalMax - b[key]) - bottom,
        };
      });
    }) : null;

    const children = React.Children.map(this.props.children, (el) => {
      if (el) {
        return React.cloneElement(el, {
          height,
          width,
          margins,
          xScaleSet,
          yScaleSet,
          y100ScaleSet,
          xDomain,
          yDomain,
          xRange,
          yRange,
          xBandPaddingInner,
          xBandPaddingOuter,
          yBandPaddingInner,
          yBandPaddingOuter,
          xScale,
          yScale,
          xTickFormat,
          yTickFormat,
          xTicks,
          yTicks,
          xLabel,
          yLabel,
          data,
          x,
          y,
          bars,
        });
      }
      return null;
    });

    const t = `translate(${margins.left}, ${margins.top})`;

    return (
      <svg
        ref={svgRef}
        height={height}
        width={width}
        className={svgClassName}
        id={id}
      >
        <g
          transform={t}
        >
          {children}
        </g>
      </svg>
    );
  }
}

ChartSvg.defaultProps = {
  svgClassName: 'react-d3-core__container_svg',
  svgRef: () => {},
  ...CommonProps,
};

ChartSvg.propTypes = {
  id: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  margins: PropTypes.object.isRequired,
  svgClassName: PropTypes.string.isRequired,
  svgRef: PropTypes.func,
};
