const uploadSignedS3 = (signedUrl, file, onProgress) => new Promise((resolve, reject) => {
  const successResponses = [200, 201];
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedUrl, true);
  xhr.onload = () => {
    if (successResponses.indexOf(xhr.status) >= 0) {
      onProgress(null, 'Upload completed');
      resolve({ status: xhr.status });
    } else {
      reject(new Error(`Upload error: ${xhr.status}`));
    }
  };
  xhr.onerror = () => reject(new Error('XHR error'));
  xhr.upload.onprogress = (e) => {
    let percentLoaded;
    if (e.lengthComputable) {
      percentLoaded = Math.round((e.loaded / e.total) * 100);
      onProgress(percentLoaded, percentLoaded === 100 ? 'Finalizing' : 'Uploading');
    }
  };
  xhr.setRequestHeader('Content-Type', file.type);
  xhr.send(file);
});

export default uploadSignedS3;
