import React from 'react';
import { CheckboxItemComponent } from 'searchkit';
import startCase from 'lodash/startCase';

class StartCaseCheckboxItemComponent extends React.Component {
  componentDidMount() {
    const label = this.el.querySelector('.sk-item-list-option__text');
    if (label.offsetWidth < label.scrollWidth && !label.getAttribute('title')) {
      label.setAttribute('title', label.innerText);
    }
  }

  render() {
    return (
      <div ref={(el) => {
        this.el = el;
      }}
      >
        <CheckboxItemComponent
          {...this.props}
          label={startCase(this.props.label)}
        />
      </div>
    );
  }
}

export default StartCaseCheckboxItemComponent;

