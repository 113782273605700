import { googleLogout } from '@react-oauth/google';
import { UNAUTHORIZED } from 'http-status-codes';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { createActions } from 'redux-actions';
import { getI18n } from '../../AppReducer';
import callApi from '../../util/apiCaller';
import { setError } from '../App/AppActions';
import { getNextPath, getProfile, getToken } from './AuthReducer';

const messages = defineMessages({
  email: {
    id: 'AuthError.email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'AuthError.password',
    defaultMessage: 'Password',
  },
  unexpectedError: {
    id: 'ForgotPassword.UnexpectedError',
    defaultMessage: 'Sorry! Some unexpected error has occurred, please try again.',
  },
  invalidToken: {
    id: 'ForgotPassword.InvalidToken',
    defaultMessage: 'The link you used is invalid or has already expired.',
  },
  error401: {
    id: 'AuthError.Unauthorized',
    defaultMessage: 'Invalid username or password.',
  },
  error401Signup: {
    id: 'AuthError.UnauthorizedSignup',
    defaultMessage: 'This email address is already in use.',
  },
});

const formatMessage = id => <FormattedMessage id={id} />;

// There are three possible states for our login
// process and we need actions for each of them
export const {
  requestLogin, receiveLogin, loginError, requestLogout, receiveLogout, saveNextPath, setChangingPasswordFor, userCreated, userVerifiedEmail,
} = createActions(
  {}, 'REQUEST_LOGIN', 'RECEIVE_LOGIN', 'LOGIN_ERROR', 'REQUEST_LOGOUT', 'RECEIVE_LOGOUT', 'SAVE_NEXT_PATH',
  'SET_CHANGING_PASSWORD_FOR', 'USER_CREATED', 'USER_VERIFIED_EMAIL',
);

const sendEmailConfirmation = (token, locale, dispatch) => {
  callApi(dispatch, `nv/users/current/send-email-confirmation?locale=${locale}`, token, 'get');
};

export const resendEmailConfirmation = (token) => {
  return (dispatch, getState) => {
    const { locale } = getI18n(getState());
    sendEmailConfirmation(token, locale, dispatch);
  };
};

function trackNewUser(emailVerified, email, dispatch) {
  if (emailVerified) {
    const vkey = 'c1a651811f07578aea4e831c401dd361';
    const vid = '2115900';
    const prefix = ((document.location.protocol === 'https:') ? 'https://ct.capterra.com' : 'http://ct.capterra.com');

    ((() => {
      const ct = document.createElement('script');
      ct.type = 'text/javascript';
      ct.async = true;
      ct.src = `${prefix}/capterra_tracker.js?vid=${vid}&vkey=${vkey}`;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ct, s);
    })());
  }
  dispatch(userCreated(email));
  // Google users may signup with pre-verified email
  if (emailVerified) {
    dispatch(userVerifiedEmail(email));
  }
}

const loginSuccess = (dispatch, isSignup, locale, user, nextPath, replace) => {
  dispatch(receiveLogin(user));
  if (user.email_verified) {
    let url = '/';
    if (nextPath) {
      url = nextPath;
      dispatch(saveNextPath(null));
    }
    replace(url);
  } else if (isSignup) {
    sendEmailConfirmation(user.token, locale, dispatch);
    replace('/verify-email', { state: { token: user.token } });
  } else {
    replace('/verify-email-resend');
  }
  if (user.wasNew) {
    trackNewUser(user.email_verified, user.email, dispatch);
  }
};

const loginFailure = (dispatch, err, isSignup) => {
  // If there was a problem, we want to
  // dispatch the error condition
  let errorMessage;
  if (err.response && err.response.message === 'Validation error') {
    const keys = Object.keys(err.response.errors);
    if (keys.length > 0) {
      const field = keys[0];
      errorMessage = (
        <FormattedMessage
          id="AuthError.ValidationError"
          defaultMessage="{field} is not valid"
          values={{
            field: <FormattedMessage id={`AuthError.${field}`} />,
          }}
        />
      );
    }
  }
  if (!errorMessage) {
    const sufix = isSignup ? 'Signup' : '';
    const errorMessageDesc = messages[`error${err.status}${sufix}`] ? messages[`error${err.status}${sufix}`] : messages.unexpectedError;
    errorMessage = formatMessage(errorMessageDesc.id);
  }
  dispatch(loginError(errorMessage));
};

export function signInAs(email, replace) {
  return (dispatch, getState) => {
    const state = getState();
    const { locale } = getI18n(state);
    const token = getToken(state);
    return callApi(dispatch, 'sign-in-as', token, 'post', { email }).then((res) => {
      loginSuccess(dispatch, false, locale, res.user, null, replace);
    }).catch((err) => {
      loginFailure(dispatch, err, false);
    });
  };
}


export function updateToken() {
  return (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, 'auth', token, 'get').then((res) => {
      dispatch(receiveLogin(res.user));
    }).catch((err) => {
      loginFailure(dispatch, err, false);
    });
  };
}

// Calls the API to get a token and dispatches actions along the way
export function loginUser(creds, isSignup, replace) {
  return (dispatch, getState) => {
    const state = getState();
    const { locale } = getI18n(state);
    const nextPath = getNextPath(state);
    // We dispatch requestLogin to kickoff the call to the API
    dispatch(requestLogin(creds));
    callApi(dispatch, 'auth', null, 'post', { ...creds, isSignup }).then((res) => {
      loginSuccess(dispatch, isSignup, locale, res.user, nextPath, replace);
    }).catch((err) => {
      loginFailure(dispatch, err, isSignup);
    });
  };
}

// Calls the API to get a token and dispatches actions along the way
export function confirmEmail(token, replace) {
  return (dispatch, getState) => {
    const { locale } = getI18n(getState());
    const nextPath = getNextPath(getState());
    return callApi(dispatch, `confirm-email/${token}`, null, 'get')
      .then((res) => {
        loginSuccess(dispatch, false, locale, res.user, nextPath, replace);
        dispatch(userVerifiedEmail(res.user.email));
      })
      .catch(({ response }) => {
        const status = response ? response.status : '500';
        replace(`/sign-up?confirmEmailError=${status}`);
      });
  };
}

// Logs the user out
export function logoutUser() {
  return (dispatch) => {
    googleLogout();
    dispatch(requestLogout());
    dispatch(receiveLogout());
  };
}

export function sendPasswordReminder(email, locale) {
  return (dispatch) => {
    callApi(dispatch, 'password-reminder', null, 'post', { email, locale });
  };
}

export function passwordReminderCheck(id, token) {
  return (dispatch) => {
    callApi(dispatch, `password-reminder/${id}/${token}`, null, 'get')
      .then(({ response }) => {
        dispatch(setChangingPasswordFor(response.email));
      })
      .catch(({ response }) => {
        const error = response && response.status && response.status === UNAUTHORIZED
          ? formatMessage('ForgotPassword.InvalidToken')
          : formatMessage('ForgotPassword.UnexpectedError');
        dispatch(setError(error));
      });
  };
}

export function passwordReminderChange(id, token, password, replace) {
  return (dispatch, getState) => {
    callApi(dispatch, `password-reminder/${id}/${token}`, null, 'post', { password })
      .then((res) => {
        const { locale } = getI18n(getState());
        const nextPath = getNextPath(getState());
        replace('/');
        loginSuccess(dispatch, false, locale, res.user, nextPath, replace);
      })
      .catch(({ response }) => {
        const error = response && response.status && response.status === UNAUTHORIZED
          ? formatMessage('ForgotPassword.InvalidToken')
          : formatMessage('ForgotPassword.UnexpectedError');
        dispatch(setError(error));
      });
  };
}

export function refresh(token) {
  return dispatch => callApi(dispatch, 'auth/refresh', token).then((res) => {
    dispatch(receiveLogin(res.user));
    return res.user;
  });
}


export function updatePassword(currentPassword, newPassword) {
  return (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, 'auth/password', token, 'put', { currentPassword, newPassword });
  };
}

export function updateEmailAction(newEmail) {
  return (dispatch, getState) => {
    const token = getToken(getState());
    const { locale } = getI18n(getState());
    return callApi(dispatch, 'user/email', token, 'put', { newEmail, locale });
  };
}


export function deleteMeAction() {
  return (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, 'user/me', token, 'delete');
  };
}
