import useActions from 'client/util/useActions';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FastClick } from 'searchkit';
import { isTokenExpired } from '../../../../jwtHelper';
import { refresh as refreshAction } from '../../../Auth/AuthActions';
import { getToken } from '../../../Auth/AuthReducer';
import SearchKitContext from '../../pages/DashboardPage/SearchKitContext';

const SearchError = ({
  errorLabel, bemBlocks, tryAgainLabel,
}) => {
  const token = useSelector(getToken);
  const refresh = useActions(refreshAction);
  const [retried, setRetried] = useState(false);
  const { searchkit } = useContext(SearchKitContext);
  const expired = isTokenExpired(token);
  useEffect(() => {
    if (expired) {
      refresh(token).then(() => {
        searchkit.reloadSearch();
      });
    } else if (!retried) {
      searchkit.reloadSearch();
      setRetried(true);
    }
  }, [expired, searchkit, setRetried, refresh]);
  return (
    <div data-qa="no-hits" className={bemBlocks.container()}>
      <div className={bemBlocks.container('info')}>
        {errorLabel}
      </div>
      <div className={bemBlocks.container('steps')}>
        <FastClick handler={() => window.location.reload()}>
          <div className={bemBlocks.container('step-action')}>
            {tryAgainLabel}
          </div>
        </FastClick>
      </div>
    </div>
  );
};

SearchError.propTypes = {
  errorLabel: PropTypes.string.isRequired,
  bemBlocks: PropTypes.object.isRequired,
  tryAgainLabel: PropTypes.string.isRequired,
};

export default SearchError;
