import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber, injectIntl } from 'react-intl';


import bsStyle from '../Bootstrap/Bootstrap.css';
import style from './PricingPlan.css';

const bs = classNames.bind(bsStyle);

const PricingPlan = (props) => {
  return (
    <div className={`${bs('col-sm-4')} ${style.priceStyles}`}>
      {props.popular ? <div className={`${style.popular} ${bs('mbr-title-font')}`}>
        <FormattedMessage id="PricingPlan.MostPopular" defaultMessage="Most Popular" />
      </div> : ''}
      <div className={`${style.item} ${props.popular ? style.itemPopular : ''}`} data-hover="">
        <h3 className={`${style.title} ${bs('mbr-title-font', 'mbr-primary-color')}`}>{props.name}</h3>
        <p className={style.sentence}>
          <FormattedMessage
            id="PricingPlan.Sentence"
            defaultMessage="Send NPS surveys to {number} people monthly."
            values={{ number: props.intl.formatNumber(props.people) }}
          />
        </p>
        <div className={`${style.price} ${bs('mbr-title-font')}`}>
          <span className={style.currency}>€</span>
          <span><FormattedNumber value={props.price} /></span>
          <span className={style.period}>
            {props.unit === 'month' ?
              <FormattedMessage
                id="PricingPlan.Month"
                defaultMessage="/ {period, plural, one {month} other {# months}}"
                values={{
                  period: props.period,
                }}
              /> : <FormattedMessage
                id="PricingPlan.Year"
                defaultMessage="/ {period, plural, one {year} other {# years}}"
                values={{
                  period: props.period,
                }}
              />
            }
            {' '}
            {props.freeMonths ?
              <FormattedMessage
                id="PricingPlan.FreeMonths"
                defaultMessage="{freeMonths, plural, one {(get 1 for free)} other {(get # for free)}}"
                values={{
                  freeMonths: props.freeMonths,
                }}
              >{msg => <span className={style.freeMonths}>{msg}</span>}</FormattedMessage> : null}
          </span>
        </div>
        <div>
          <ul className={`${style.featureList} ${bs('mbr-text-font')}`}>
            <li>
              <FormattedMessage
                id="Pricing.UnlimitedProperties"
                defaultMessage="Unlimited segmentation properties"
              />
            </li>
            <li><FormattedMessage id="Pricing.UnlimitedSurveys" defaultMessage="Unlimited surveys" /></li>
            <li><FormattedMessage id="Pricing.SurveyCustomization" defaultMessage="Survey customization" /></li>
            <li><FormattedMessage id="Pricing.API" defaultMessage="Developer's API" /></li>
          </ul>
        </div>
        <div>
          <button onClick={props.handleSelect} className={`${style.btn} ${bs('btn', 'mbr-title-font', 'btn-primary')}`}>
            <FormattedMessage id="Pricing.Select" defaultMessage="Select Plan" />
          </button>
        </div>
      </div>
    </div>
  );
};

PricingPlan.defaultProps = {
  popular: false,
  intl: null,
  freeMonths: 0,
  period: 1,
  unit: 'month',
};

PricingPlan.propTypes = {
  intl: PropTypes.shape({
    formatNumber: PropTypes.func,
  }),
  popular: PropTypes.bool,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  people: PropTypes.number.isRequired,
  handleSelect: PropTypes.func.isRequired,
  period: PropTypes.number,
  unit: PropTypes.string,
  freeMonths: PropTypes.number,
};

export default injectIntl(PricingPlan);
