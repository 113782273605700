import React from 'react';

import style from './BrowserWindow.css';

const BrowserWindow = (props) => {
  return (
    <div className={`${style.window} ${props.className || ''}`}>
      <div className={style.bar}>
        <div className={style.nav} />
        <div className={style.navigation} />
      </div>
      <div className={style.frame}>
        <div className={style.content}>
          <div className={style.contentInner}>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrowserWindow;
