import { createAction } from '@reduxjs/toolkit';

export const closeConfirm = createAction('confirm/close');

export const openConfirm = createAction('confirm/open', ({
  title, text, onOk, textOk, textCancel, danger,
}) => ({
  payload: {
    open: true, title, text, onOk, textOk, textCancel, danger,
  },
}));
