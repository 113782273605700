import d3 from 'd3';
import { extent } from 'd3-array';
import { scaleTime } from 'd3-scale';
import { timeDay, timeHour, timeMinute, timeMonth, timeSecond, timeWeek, timeYear } from 'd3-time';
import { timeFormatEnUs, timeFormatEsEs } from 'd3-time-format';
import { scale, xDomainCount } from 'mhs-d3-core';
import moment from 'moment';
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getI18n } from '../../../../AppReducer';
import Chart, { expandDomain } from './charts/Chart';
import LineChart from './charts/LineChart';
import CommonProps from './CommonProps';

import styles from './LineTooltip.css';
import Tooltip from './utils/Tooltip';

const defYDomains = {
  nps: [-100, 100],
  ces: [0, 100],
  csat: [0, 100],
  star_five: [1, 5],
};

class LineTooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (this.rootRef) {
      d3.select(this.rootRef)
        .on('mousemove', this.onMouseMove.bind(this));
    }
  }

  onMouseMove() {
    const {
      data, x, y, chartSeries, margins,
    } = this.props;
    const { xScaleSet, yScaleSet } = this;
    const svg = this.rootRef.closest('svg');
    const mouseX = d3.mouse(svg)[0];
    const closest = data.reduce((best, value, i) => {
      const pointX = xScaleSet(x(value)) + margins.left;
      const absx = Math.abs(pointX - mouseX);
      if (absx < best.value) {
        return { index: i, value: absx };
      }

      return best;
    }, { index: -1, value: Number.MAX_SAFE_INTEGER });

    if (this.state.lastIndex !== closest.index) {
      const xTooltip = xScaleSet(x(data[closest.index])) + margins.left;
      const yTooltip = yScaleSet(y(data[closest.index][chartSeries[0].field]));
      this.setState({
        xTooltip,
        yTooltip,
        contentTooltip: {
          point: data[closest.index],
        },
      });
      this.setState({ lastIndex: closest.index });
    }
  }

  onMouseLeave() {
    this.setState({
      xTooltip: 0, yTooltip: 0, contentTooltip: null, lastIndex: -1,
    });
  }

  render() {
    if (this.props.data !== null) {
      const { format } = (this.props.locale === 'es' ? timeFormatEsEs : timeFormatEnUs);
      const formatMillisecond = format('.%L');
      const formatSecond = format(':%S');
      const formatMinute = format('%I:%M');
      const formatHour = format('%I %p');
      const formatDay = format('%a %d');
      const formatWeek = format('%b %d');
      const formatMonth = format('%b');
      const formatYear = format('%Y');

      const tickFormat = (date) => {
        return (
          timeSecond(date) < date ? formatMillisecond
            : timeMinute(date) < date ? formatSecond
              : timeHour(date) < date ? formatMinute
                : timeDay(date) < date ? formatHour
                  : timeMonth(date) < date ? (timeWeek(date) < date ? formatDay : formatWeek)
                    : timeYear(date) < date ? formatMonth
                      : formatYear
        )(date);
      };

      let xDomain = xDomainCount(this.props, this.props.stack, this.props.horizontal);

      const nTicks = Math.round(this.props.width / 125);
      const xTickValues = scaleTime().domain(xDomain).ticks(nTicks);
      xDomain = expandDomain(xDomain, (1000 / this.props.width) * 0.03);

      const yDom = (data, chartSeries, y) => {
        const domainArr = chartSeries.map((d) => {
          const { field } = d;
          const ext = extent(data, (dt) => {
            return y(dt[field]);
          });
          return ext;
        });
        const extentArr = extent([].concat(...domainArr));
        return extentArr;
      };

      let yDomain = yDom(this.props.data, this.props.chartSeries, this.props.y);

      if (yDomain[0] === yDomain[1] && defYDomains[this.props.surveyType]) {
        yDomain = defYDomains[this.props.surveyType];
      }

      yDomain = expandDomain(yDomain);

      if (xDomain[0] && xDomain[0].getTime && xDomain[0].getTime() === xDomain[1].getTime()) {
        xDomain[0] = moment(xDomain[0]).subtract(15, 'days').toDate();
        xDomain[1] = moment(xDomain[1]).add(15, 'days').toDate();
      }

      const newXScale = {
        scale: this.props.xScale,
        range: this.props.xRange || [0, this.props.width - this.props.margins.left - this.props.margins.right],
        domain: xDomain,
        bandPaddingInner: this.props.xBandPaddingInner,
        bandPaddingOuter: this.props.xBandPaddingOuter,
      };
      this.xScaleSet = scale(newXScale);

      const newYScale = {
        scale: this.props.yScale,
        range: this.props.yRange || [this.props.height - this.props.margins.top - this.props.margins.bottom, 0],
        domain: yDomain,
        bandPaddingInner: this.props.yBandPaddingInner,
        bandPaddingOuter: this.props.yBandPaddingOuter,
      };

      this.yScaleSet = scale(newYScale);

      return (
        <div className={styles.root} onMouseLeave={this.onMouseLeave.bind(this)}>
          <Tooltip {...this.props} {...this.state}>
            {this.props.children}
          </Tooltip>
          <Chart
            {...this.props}
            {...this.state}
            yTicks={[5]}
            xTickFormat={tickFormat}
            yDomain={yDomain}
            xDomain={xDomain}
            svgRef={(ref) => {
              this.rootRef = ref;
            }}
          >
            <LineChart
              {...this.props}
              {...this.state}
              xAxisClassName={styles.axis}
              yAxisClassName={styles.axis}
              gridAxisClassName={styles.grid}
              xGridStyleClassName={styles.gridLine}
              yGridStyleClassName={styles.gridLine}
              gridStyleClassName={styles.gridLine}
              xTickPadding={15}
              xTickValues={xTickValues}
              yTickPadding={15}
              yGridCount={[5]}
              xAxisStyling={{
                ticksClassName: styles.axisTicks,
                pathClassName: styles.axisPath,
                textClassName: styles.axisText,
              }}
              yAxisStyling={{
                ticksClassName: styles.axisTicks,
                pathClassName: styles.axisPath,
                textClassName: styles.axisText,
              }}
              legendClassName={styles.legend}
              dotClassName={styles.dot}
              lineClassName={styles.line}
            />
          </Chart>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = state => ({
  locale: getI18n(state).locale,
});

LineTooltip.defaultProps = { ...CommonProps };

export default connect(mapStateToProps)(LineTooltip);
