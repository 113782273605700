import { Button, Card, CardContent, CardHeader, Typography } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import { getToken } from 'client/modules/Auth/AuthReducer';
import {
  addIntegration,
  getIntegration,
  removeIntegration,
} from 'client/modules/Integrations/IntegrationsActions';
import gsheets from 'client/modules/Settings/pages/IntegrationsPage/assets/gsheets.svg';
import hubspot from 'client/modules/Settings/pages/IntegrationsPage/assets/hubspot.svg';
import salesforce from 'client/modules/Settings/pages/IntegrationsPage/assets/salesforce.svg';
import slack from 'client/modules/Settings/pages/IntegrationsPage/assets/slack.svg';
import webhooks from 'client/modules/Settings/pages/IntegrationsPage/assets/webhooks.svg';
import zendesk from 'client/modules/Settings/pages/IntegrationsPage/assets/zendesk.svg';
import IntegrationPreRequiredFields from 'client/modules/Settings/pages/IntegrationsPage/components/IntegrationPreRequiredFields';
import RemoveButton from 'client/modules/Settings/pages/IntegrationsPage/components/RemoveButton';
import SaveButton from 'client/modules/Settings/pages/IntegrationsPage/components/SaveButton';
import {
  fetchSettings,
  integrationsPropertiesChange,
  setIntegrationProperties,
} from 'client/modules/Settings/SettingsActions';
import { getIntegrations } from 'client/modules/Settings/SettingsReducer';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useMatch } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import messages from './integrationMessages';

const logos = {
  gsheets,
  hubspot,
  salesforce,
  slack,
  webhooks,
  zendesk,
};
const IntegrationPage = () => {
  const [preRequiredFields, setPreRequiredFields] = useState({});
  const [saving, setSaving] = useState(false);
  const match = useMatch('/settings/integrations/:name');
  const { name } = match?.params || {};
  const integration = useSelector(getIntegrations)?.[name];
  const form = useForm({
    defaultValues: integration,
  });
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const surveys = useSelector(getSurveys);
  if (!surveys) {
    return null;
  }
  const onIntegrationConnect = (intName, fields) => {
    dispatch(getIntegration(token, intName, fields)).then(res => {
      if (res.url) {
        window.location.href = res.url;
      } else {
        dispatch(addIntegration(token, null, fields, intName));
      }
    });
  };
  const onSubmit = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.target.reportValidity()) {
      setSaving(true);
      form.handleSubmit(() => {
        dispatch(setIntegrationProperties(token, name, integration)).finally(() =>
          setSaving(false),
        );
      })(event);
    }
  };

  const onIntegrationRemove = async () => {
    await dispatch(removeIntegration(token, name));
    await dispatch(fetchSettings(token));
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={onSubmit}>
        <Card sx={{ mb: 4 }}>
          <CardHeader
            sx={{ alignItems: 'flex-start' }}
            title={
              <Typography variant="h2" sx={{ display: 'flex', alignItems: 'center' }}>
                <SVGInline
                  height={30}
                  width="auto"
                  style={{ marginRight: 10, display: 'flex', alignItems: 'center' }}
                  svg={logos[name]}
                />
                {formatMessage(messages[name])}
              </Typography>
            }
            subheader={
              <Typography variant="body2">
                {formatMessage(messages[`${name}Description`])}
              </Typography>
            }
            disableTypography
          />
          <CardContent>
            {!surveys && (
              <FormattedMessage
                id="IntegrationsSettings.NoSurveys"
                defaultMessage="Please create a survey first."
              />
            )}
            {!surveys || !integration || (
              <Outlet
                context={{
                  onIntegrationsPropertiesChange: (event, type, props) =>
                    dispatch(integrationsPropertiesChange({ type, props })),
                  integration,
                  name,
                  surveys,
                  onIntegrationConnect,
                }}
              />
            )}
            {surveys && !integration && (
              <IntegrationPreRequiredFields
                name={name}
                preRequiredFields={preRequiredFields}
                onChange={setPreRequiredFields}
              />
            )}
          </CardContent>
          <CardActions
            sx={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}
          >
            {integration ? (
              <>
                <SaveButton saving={saving} type="submit" />
                <RemoveButton onIntegrationRemove={onIntegrationRemove} />
              </>
            ) : (
              <Button
                type="submit"
                variant="outlined"
                onClick={() => onIntegrationConnect(name, preRequiredFields)}
              >
                <FormattedMessage
                  id="IntegrationsSettings.ConnectTo"
                  defaultMessage="Connect to {name}"
                  values={{
                    name,
                  }}
                />
              </Button>
            )}
          </CardActions>
        </Card>
      </form>
    </FormProvider>
  );
};
export default IntegrationPage;
