import { Box, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import useRegisterSelect from 'client/util/useRegisterSelect';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

const ownMessages = defineMessages({
  minutes: {
    id: 'Unit.Minutes',
    defaultMessage: 'Minutes',
  },
  hours: {
    id: 'Unit.Hours',
    defaultMessage: 'Hours',
  },
  days: {
    id: 'Unit.Days',
    defaultMessage: 'Days',
  },
  weeks: {
    id: 'Unit.Weeks',
    defaultMessage: 'Weeks',
  },
  months: {
    id: 'Unit.Months',
    defaultMessage: 'Months',
  },
  years: {
    id: 'Unit.Years',
    defaultMessage: 'Years',
  },
});

const FieldWithUnit = ({
  name, index, number = index, actions, messages,
}) => {
  const { register } = useFormContext();
  const registerSelect = useRegisterSelect();
  const { formatMessage } = useIntl();
  const fieldName = index !== null ? `${name}.${index}` : name;
  return (
    <>
      <Box>
        <InputLabel
          id={`${fieldName}.value-label`}
          htmlFor={`${fieldName}.value`}
          sx={{ whiteSpace: 'wrap' }}
        >
          {formatMessage(messages[name], { number, b: (...chunks) => <strong>{chunks}</strong> })}
        </InputLabel>
      </Box>
      <Box>
        <OutlinedInput
          {...register(`${fieldName}.value`, { valueAsNumber: true })}
          id={`${fieldName}.value`}
          size="small"
          variant="outlined"
          type="number"
          inputProps={{
            min: 0,
            max: 10000,
          }}
          sx={{ minWidth: 60, '& input': { textAlign: 'right', pr: 0 } }}
        />
      </Box>
      <Box>
        <Select
          {...registerSelect(`${fieldName}.unit`)}
          id={`${fieldName}.unit`}
          aria-labelledby={`${fieldName}.unit-label`}
          variant="standard"
          size="small"
          disableUnderline
          autoWidth
          sx={{ '& .MuiSelect-icon': { top: 'calc(50% - 0.6em)' } }}
        >
          {['minutes', 'hours', 'days', 'weeks', 'months', 'years'].map(unit => (
            <MenuItem
              key={unit}
              value={unit}
            >
              {formatMessage(ownMessages[unit])}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        {actions}
      </Box>
    </>
  );
};

FieldWithUnit.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number,
  number: PropTypes.number,
  actions: PropTypes.node,
  messages: PropTypes.object.isRequired,
};

FieldWithUnit.defaultProps = {
  index: null,
  actions: null,
  number: undefined,
};

export default FieldWithUnit;
