import { Layers } from '@mui/icons-material';
import {
  ListItemButton, ListItemIcon, ListItemText, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import { getI18n } from 'client/AppReducer';
import { helpSlice, useListCategoriesQuery, useListContentsQuery } from 'client/modules/Help/helpSlice';
import icons from 'client/modules/Help/icons';
import Spinner from 'components/Spinner/Spinner';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styles from './Help.css';

const HelpCategory = () => {
  const { category } = useParams();
  const { locale } = useIntl();
  const { data: categories, isLoading } = useListCategoriesQuery(locale);
  const { data } = useListContentsQuery({ category, locale });
  const categoryObj = categories?.find(c => c.slug === category);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={2}>
        <List>
          {isLoading ? (
            <Spinner />
          ) : (categories?.map(({
            slug, name, icon,
          }) => {
            const Icon = icons[icon || Layers];
            return (
              <ListItemButton
                key={slug}
                selected={category === slug}
                component={RouterLink}
                to={`/help/${slug}`}
              >
                <ListItemIcon sx={{ minWidth: 32, justifyContent: 'flex-start' }}>
                  <Icon className={styles.icon} sx={{ fontSize: 20, color: 'primary.main' }} />
                </ListItemIcon>
                <ListItemText primary={name} />
              </ListItemButton>
            );
          }))}
        </List>
      </Grid>
      <Grid item xs={12} sm={10}>
        <Paper className={styles.paper}>
          <Typography variant="h4" sx={{ mb: 1 }}>
            {categoryObj?.name}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {categoryObj?.description}
          </Typography>
          <Divider sx={{ mt: 2, mb: 4 }} />
          {data?.map(({
            slug, title,
          } = {}) => (
            <Link component={RouterLink} to={slug} sx={{ display: 'block', my: 2 }}>
              <Typography sx={{ mb: 1 }}>
                {title}
              </Typography>
            </Link>
          ))}
        </Paper>
      </Grid>
    </Grid>
  );
};

HelpCategory.loader = ({ dispatch, getState }) => ({ params: { category } }) => {
  const { locale } = getI18n(getState());
  dispatch(helpSlice.endpoints.listContents.initiate({ category, locale }));
  return Promise.all(dispatch(helpSlice.util.getRunningQueriesThunk()));
};

export default HelpCategory;
