import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { validSurveyLanguages } from '../../../../../Intl/setup';
import AppError from '../../../../../server/util/AppError';
import withParams from '../../../../util/withParams';
import App from '../../../App/App';
import NotFound from '../../../App/components/NotFound/NotFound';
import { addPairwiseAnswer, getPairwiseQuestion, setPairwiseError, setResponseEnabled } from '../../PairwiseActions';
import { getError, getQuestion, getResponseEnabled } from '../../PairwiseReducer';
import AddPairwiseResponsePage from './AddPairwiseResponsePage';

const locales = validSurveyLanguages;

class AddPairwiseResponsePageContainer extends React.Component {

  componentWillMount() {
    const { pairwiseSurveyId, locale, user } = this.props;
    if (locales.indexOf(locale) < 0) {
      this.props.setPairwiseError(new AppError({ message: 'Missing locale' }));
    } else if (!user) {
      this.props.setPairwiseError(new AppError({ message: 'Missing user' }));
    } else {
      this.props.getPairwiseQuestion(pairwiseSurveyId, locale);
    }
  }

  render() {
    if (this.props.error) {
      return <App><NotFound /></App>;
    }

    return <AddPairwiseResponsePage {...this.props} />;
  }

}

const mapStateToProps = (state, ownProps) => {
  const countriesToLocale = {
    Spain: 'es',
    Poland: 'pl',
    Turkey: 'tr',
    Brazil: 'pt',
    Mexico: 'es',
    Italy: 'it',
  };
  const properties = ownProps.location.query;
  let locale = ownProps.params.locale;
  if (locale.length > 2) {
    properties.country = properties.country || locale;
    locale = countriesToLocale[locale];
  }
  return {
    properties,
    pairwiseSurveyId: ownProps.params.survey,
    locale,
    user: ownProps.params.user || 'empty-user',
    question: getQuestion(state),
    error: getError(state),
    responseEnabled: getResponseEnabled(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPairwiseQuestion: (pairwiseSurveyId, locale) => {
      dispatch(getPairwiseQuestion(pairwiseSurveyId, locale));
    },
    addPairwiseAnswer: (surveyId, combinationId, locale, user, answer, properties, reverse) => {
      dispatch(addPairwiseAnswer(surveyId, combinationId, locale, user, answer, properties, reverse));
    },
    setPairwiseError: (error) => {
      dispatch(setPairwiseError(error));
    },
    setResponseEnabled: (enabled) => {
      dispatch(setResponseEnabled(enabled));
    },
  };
};

AddPairwiseResponsePageContainer.defaultProps = {
  error: null,
};

AddPairwiseResponsePageContainer.propTypes = {
  error: PropTypes.object,
  getPairwiseQuestion: PropTypes.func.isRequired,
  setPairwiseError: PropTypes.func.isRequired,
  setResponseEnabled: PropTypes.func.isRequired,
  pairwiseSurveyId: PropTypes.string.isRequired,
  responseEnabled: PropTypes.bool.isRequired,
  locale: PropTypes.string.isRequired,
  user: PropTypes.string.isRequired,
};

export default withParams(connect(mapStateToProps, mapDispatchToProps)(AddPairwiseResponsePageContainer));
