import { Add, Close } from '@mui/icons-material';
import {
  Box, Button, CardContent, Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import FieldWithUnit from 'client/modules/Surveys/pages/CustomizeSurvey/FieldWithUnit';
import * as PropTypes from 'prop-types';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  title: {
    id: 'TargetingScheduling.Title',
    defaultMessage: 'Scheduling',
  },
  subtitle: {
    id: 'TargetingScheduling.Subtitle',
    defaultMessage: 'Control the timing and frequency of surveys shown to your customers.',
  },
  firstSurvey: {
    id: 'TargetingScheduling.FirstSurvey',
    defaultMessage: 'First survey after',
  },
  unit: {
    id: 'TargetingScheduling.Unit',
    defaultMessage: 'Unit',
  },
  nextSurveys: {
    id: 'TargetingScheduling.FollowingSurvey',
    defaultMessage: '{number, plural, =0 {Second} =1 {Third} =2 {Fourth} other {Next}} survey',
  },
  resurveyThrottle: {
    id: 'TargetingScheduling.ResurveyThrottle',
    defaultMessage: 'Resurvey every',
  },
  webSurveys: {
    id: 'TargetingScheduling.WebSurveys',
    defaultMessage: 'Web surveys',
  },
  addFollowingSurvey: {
    id: 'TargetingScheduling.AddFollowingSurvey',
    defaultMessage: 'Add {number, plural, =0 {Second} =1 {Third} =2 {Fourth} other {Next}} survey',
  },
});

const AddNextButton = ({ append, number }) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      type="button"
      onClick={() => append({
        value: 3,
        unit: 'months',
      })}
      sx={{ width: 'max-content', textTransform: 'none' }}
    >
      <Add sx={{ fontSize: '1.2em' }} />
      {formatMessage(messages.addFollowingSurvey, { number })}
    </Button>
  );
};
AddNextButton.propTypes = {
  append: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
};

const TargetingScheduling = () => {
  const nextSurveys = useFieldArray({
    name: 'nextSurveys',
  });
  const { formatMessage } = useIntl();
  return (
    <CardContent sx={{
      borderWidth: 0,
      borderColor: 'muted.lighter',
      borderStyle: 'solid',
      borderTopWidth: 1,
    }}
    >
      <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
        {formatMessage(messages.subtitle)}
      </Typography>
      <Box sx={{
        display: 'grid',
        gridTemplateColumns: 'max-content repeat(3, min-content)',
        gap: 2,
        alignItems: 'baseline',
      }}
      >
        <FieldWithUnit
          name="firstSurvey"
          actions={(nextSurveys.fields.length <= 2) && (
            <AddNextButton
              append={nextSurveys.append}
              number={nextSurveys.fields.length}
            />
          )}
          messages={messages}
        />
        {nextSurveys.fields.map((field, index) => (
          <FieldWithUnit
            key={field.id}
            name="nextSurveys"
            index={index}
            actions={(
              <IconButton
                type="button"
                onClick={() => nextSurveys.remove(index)}
                sx={{ ml: -2, alignSelf: 'center' }}
                size="small"
              >
                <Close />
              </IconButton>
            )}
            messages={messages}
          />
        ))}
        <FieldWithUnit name="resurveyThrottle" number={nextSurveys.fields.length} messages={messages} />
      </Box>
    </CardContent>
  );
};
export default TargetingScheduling;
