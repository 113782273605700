import { createAction } from 'redux-actions';

import callApi from '../../../util/apiCaller';

export const fetchPlansCb = createAction('FETCH_PLANS_CB');

export function fetchPlans(period) {
  return (dispatch) => {
    return callApi(dispatch, `pricing/plans/${period}`, null).then((res) => {
      dispatch(fetchPlansCb({ plans: res, period }));
    });
  };
}
