import { getToken } from 'client/modules/Auth/AuthReducer';
import { OK } from 'http-status-codes';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { createAction } from 'redux-actions';
import AppError from '../../../server/util/AppError';

import callApi from '../../util/apiCaller';

import { setError, setOk } from '../App/AppActions';
import { updateToken } from '../Auth/AuthActions';

export const getCheckoutPageCb = createAction('GET_CHECKOUT_PAGE_CB');
export const addSubscriptionCb = createAction('ADD_SUBSCRIPTION_CB');
export const checkSubscriptionCb = createAction('CHECK_SUBSCRIPTION_CB');

export const redirectToCustomerPortal = (token, replace) => dispatch => (
  callApi(dispatch, 'users/subscriptions', token, 'GET').then((result) => {
    window.location.href = result.redirectTo;
  }).catch(() => {
    replace('/');
    setTimeout(() => {
      dispatch(setError(
        <FormattedMessage
          id="Subscription.ErrorCreatingSession"
          defaultMessage="Error retrieving subscription settings. If the error persists please contact {support}."
          values={{
            support: <a href="mailto:support@promoter.ninja">support@promoter.ninja</a>,
          }}
        />,
      ));
    }, 1000);
  })
);

export function getCheckoutPage(token, planId, locale, navigate) {
  return (dispatch) => {
    return callApi(dispatch, 'users/checkout-pages', token, 'POST', { planId, locale }).then((res) => {
      if (res.status === OK) {
        const {
          id, siteName, state, url,
        } = res.hosted_page;
        dispatch(getCheckoutPageCb({
          id, siteName, state, url, planId,
        }));
        navigate('/subscriptions/checkout');
      }
    }).catch((response) => {
      if (response.errors && response.errors.user && response.errors.user === 'had_subscription') {
        dispatch(setError(
          <FormattedMessage
            id="Subscription.HadSubscription"
            defaultMessage="You already had an active subscription. Go to {subscriptionSettings}."
            values={{
              subscriptionSettings: (
                <Link to="/subscriptions">
                  <FormattedMessage
                    id="Subscription.SubscriptionSettings"
                    defaultMessage="subscription settings"
                  />
                </Link>
              ),
            }}
          />,
        ));
      } else {
        dispatch(setError(
          <FormattedMessage
            id="Subscription.CreationError"
            defaultMessage="There was an error creating subscription, please try again."
          />,
        ));
      }
    });
  };
}

export function addSubscription(token, hostedPageId, replace) {
  return (dispatch) => {
    return callApi(dispatch, 'users/subscriptions', token, 'POST', { hostedPageId })
      .then((res) => {
        if (res.status === OK) {
          replace('/');
          return dispatch(updateToken()).then(() => {
            dispatch(addSubscriptionCb(res.subscription));
            setTimeout(() => dispatch(setOk(
              <FormattedMessage id="Subscription.Created" defaultMessage="Subscription created successfully." />,
            )), 500);
          });
        }
        throw new AppError({ message: 'Error creating subscription' });
      })
      .catch(() => {
        replace('/pricing');
        setTimeout(() => dispatch(setError(
          <FormattedMessage
            id="Subscription.CreationError"
            defaultMessage="There was an error creating subscription, please try again."
          />,
        )), 500);
      });
  };
}

export function checkSubscriptionAction() {
  return (dispatch, getState) => {
    const token = getToken(getState());
    return callApi(dispatch, 'users/subscriptions/check', token, 'GET').then((res) => {
      dispatch(checkSubscriptionCb(res.subscription));
    });
  };
}
