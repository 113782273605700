import classNames from 'classnames/bind';
import React from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import bsStyle from '../../../../../App/components/Bootstrap/Bootstrap.css';

import style from './Features.css';

import Channels from './images/channels.png';
import Channels2x from './images/channels@2x.png';

import MultiLanguage from './images/multi-language.png';
import MultiLanguage2x from './images/multi-language@2x.png';

import NoCode from './images/no-code.png';
import NoCode2x from './images/no-code@2x.png';


import RealTime from './images/real-time.png';
import RealTime2x from './images/real-time@2x.png';

import UploadCSV from './images/upload-csv.png';
import UploadCSV2x from './images/upload-csv@2x.png';

const bs = classNames.bind(bsStyle);

const messages = defineMessages({
  multiLanguageAlt: { id: 'Features.MultiLanguageAlt', defaultMessage: 'Multi Language' },
  noCodeAlt: { id: 'Features.NoCodeAlt', defaultMessage: 'Without coding' },
  uploadAlt: { id: 'Features.UploadAlt', defaultMessage: 'Upload CSV' },
  realTimeAlt: { id: 'Features.RealTimeAlt', defaultMessage: 'Real time analytics' },
  channelsAlt: { id: 'Features.Channels', defaultMessage: 'Multi-channel' },
});

const Features = (props) => {

  const formatMessage = props.intl.formatMessage;

  return (
    <section
      className={`${style.services} ${bs('mbr-section--bg-adapted', 'mbr-section--relative')}`}
      id="features1-5"
    >
      <div className={bs('container')}>
        <Grid className={bs('container')} fluid>
          <Row className={style.centeredRow}>
            <Col sm={5} xs={12}>
              <h3 className={bs('mbr-title-font')}>
                <FormattedMessage
                  id="Features.MultiLanguageTitle"
                  defaultMessage="Send NPS surveys in multiple languages."
                />
              </h3>
              <p>
                <FormattedMessage
                  id="Features.MultiLanguageDescription"
                  defaultMessage="Your customers will answer the NPS question in their native language. Add their prefered language to your CSV and Promoter Ninja will do the rest."
                />
              </p>
            </Col>
            <Col sm={7} xs={12} className={style.padded}>
              <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
                <img
                  src={MultiLanguage}
                  srcSet={`${MultiLanguage} 400w, ${MultiLanguage2x} 800w`}
                  alt={formatMessage(messages.multiLanguageAlt)}
                  className={bs('mbr-figure__img', 'img-responsive', 'center-block')}
                />
              </figure>
            </Col>
          </Row>
          <Row className={`${style.centeredRow} ${style.reverse}`}>
            <Col sm={5} xs={12}>
              <h3 className={bs('mbr-title-font')}>
                <FormattedMessage id="Features.FullyCodeFreeTitle" defaultMessage="Fully Code-Free Customized" />
              </h3>
              <p>
                <FormattedMessage
                  id="Features.FullyCodeFreeDescription"
                  defaultMessage="Add your logo and pick your colours to customize your NPS survey e-mail within the Promoter Ninja app, no coding knowledge required."
                />
              </p>
            </Col>
            <Col sm={7} xs={12} className={style.padded}>
              <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
                <img
                  src={NoCode}
                  srcSet={`${NoCode} 400w, ${NoCode2x} 800w`}
                  alt={formatMessage(messages.noCodeAlt)}
                  className={bs('mbr-figure__img', 'img-responsive', 'center-block')}
                />
              </figure>
            </Col>
          </Row>
          <Row className={style.centeredRow}>
            <Col sm={5} xs={12}>
              <h3 className={bs('mbr-title-font')}>
                <FormattedMessage id="Features.EasyImportTitle" defaultMessage="Import your data easily" />
              </h3>
              <p>
                <FormattedMessage
                  id="Features.EasyImportDescription"
                  defaultMessage="Upload your contacts with a simple CSV. Add as much columns as you need to further filter the results based on your own segmentation criteria."
                />
              </p>
            </Col>
            <Col sm={7} xs={12} className={style.padded}>
              <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
                <img
                  src={UploadCSV}
                  srcSet={`${UploadCSV} 400w, ${UploadCSV2x} 800w`}
                  alt={formatMessage(messages.uploadAlt)}
                  className={bs('mbr-figure__img', 'img-responsive', 'center-block')}
                />
              </figure>
            </Col>
          </Row>
          <Row className={`${style.centeredRow} ${style.reverse}`}>
            <Col sm={5} xs={12}>
              <h3 className={bs('mbr-title-font')}>
                <FormattedMessage
                  id="Features.RealTimeAnalyticsTitle"
                  defaultMessage="Analyze responses in real time"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="Features.RealTimeAnalyticsDescription"
                  defaultMessage="Get immediate feedback from your customers. Gather your NPS score plus the answers to your open-ended questions and filter them in real time."
                />
              </p>
            </Col>
            <Col sm={7} xs={12} className={style.padded}>
              <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
                <img
                  src={RealTime}
                  srcSet={`${RealTime} 400w, ${RealTime2x} 800w`}
                  alt={formatMessage(messages.realTimeAlt)}
                  className={bs('mbr-figure__img', 'img-responsive', 'center-block')}
                />
              </figure>
            </Col>
          </Row>
          <Row className={style.centeredRow}>
            <Col sm={5} xs={12}>
              <h3 className={bs('mbr-title-font')}>
                <FormattedMessage
                  id="Features.MultiChannelTitle"
                  defaultMessage="Multi-channel surveys"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="Features.MultichannelDescription"
                  defaultMessage="Survey your customers directly on your website or send surveys via email or SMS."
                />
              </p>
            </Col>
            <Col sm={7} xs={12} className={style.padded}>
              <figure className={bs('mbr-figure', 'mbr-figure--adapted', 'mbr-figure--full-width')}>
                <img
                  src={Channels}
                  srcSet={`${Channels} 400w, ${Channels2x} 800w`}
                  alt={formatMessage(messages.channelsAlt)}
                  className={bs('mbr-figure__img', 'img-responsive', 'center-block')}
                />
              </figure>
            </Col>
          </Row>
        </Grid>
      </div>
    </section>
  );
};

export default injectIntl(Features);
