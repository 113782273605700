import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import styles from './RemoveButton.css';

const messages = defineMessages({
  remove: {
    id: 'IntegrationsSettings.RemoveIntegration',
    defaultMessage: 'Remove integration',
  },
});

const RemoveButton = ({ onIntegrationRemove, intl: { formatMessage } }) => (
  <Button
    variant="outlined"
    color="error"
    onClick={onIntegrationRemove}
  >
    {formatMessage(messages.remove)}
  </Button>
);

RemoveButton.propTypes = {
  onIntegrationRemove: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(RemoveButton);
