import { Add, Close } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const toArrayWithDefault = (personProperties) => {
  const ret = Object.entries(personProperties)
    .map(
      ([name, value]) => ({
        name,
        value,
      }),
    );
  if (ret.length === 0) {
    ret.push({ name: '', value: '' });
  }
  return ret;
};

const CustomizePreviewModal = ({
  personProperties, setPersonProperties, score, setScore, isOpen, onClose,
}) => {
  const defaultValues = useMemo(() => ({
    score,
    personProperties: toArrayWithDefault(personProperties),
  }), [personProperties, score]);
  const {
    register,
    control,
    handleSubmit,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  // Person properties field array
  const {
    fields: personPropertiesFields,
    append: appendPersonProperties,
    remove: removePersonProperties,
  } = useFieldArray({
    control,
    name: 'personProperties',
  });

  const onSubmit = useCallback((data) => {
    setScore(data.score);
    const newProperties = data.personProperties.filter(({ name, value }) => name && value).reduce(
      (ret, { name, value }) => ({ ...ret, [name]: value }),
      {},
    );
    setPersonProperties(newProperties);
    onClose();
  }, [onClose, setPersonProperties, setScore]);

  const addRow = useCallback(() => {
    appendPersonProperties({ id: Math.random(), name: '', value: '' });
  }, [appendPersonProperties]);
  const removeRow = useCallback(
    index => () => removePersonProperties(index),
    [removePersonProperties],
  );
  const handleFocus = useCallback((el) => {
    el?.focus();
  }, []);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="customize-preview-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="customize-preview-title" sx={{ display: 'flex', alignItems: 'flex-start' }}>
          <Typography variant="h6">
            <FormattedMessage
              id="CustomizePreviewModal.Title"
              defaultMessage="Customize Preview"
            />
          </Typography>
        </DialogTitle>
        <DialogContent sx={{
          backgroundColor: 'background.body', borderWidth: 0, borderColor: 'muted.lighter', borderStyle: 'solid', borderTopWidth: 1, borderBlockWidth: 1,
        }}
        >
          <Typography variant="body2" color="muted.main" sx={{ mt: 4 }}>
            <FormattedMessage
              id="CustomizePreviewModal.Description"
              defaultMessage="Configure the score and the person properties."
            />
          </Typography>
          <Typography sx={{ mt: 3, fontWeight: 'bold' }}>
            <FormattedMessage id="CustomizePreviewModal.Properties" defaultMessage="Properties" />
          </Typography>
          {personPropertiesFields.map((field, index) => (
            <Box
              key={field.id}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr min-content',
                columnGap: 2,
                alignItems: 'center',
              }}
            >
              <TextField
                inputRef={index === personPropertiesFields.length - 1 ? handleFocus : null}
                size="small"
                sx={{ backgroundColor: 'background.paper' }}
                {...register(`personProperties.${index}.name`)}
                label={<FormattedMessage id="CustomizePreviewModal.Name" defaultMessage="Name" />}
                type="text"
                fullWidth
                margin="normal"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    document.querySelector(`input[name="${e.target.name?.replace('.name', '.value')}"]`).focus();
                  }
                }}
              />
              <TextField
                size="small"
                sx={{ backgroundColor: 'background.paper' }}
                {...register(`personProperties.${index}.value`)}
                label={<FormattedMessage id="CustomizePreviewModal.Value" defaultMessage="Value" />}
                type="text"
                fullWidth
                margin="normal"
                variant="outlined"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    addRow();
                  }
                }}
              />
              <IconButton
                sx={{ mt: 0.7 }}
                edge="start"
                aria-label="delete"
                onClick={removeRow(index)}
              >
                <Close />
              </IconButton>
            </Box>
          ))}
          <Button
            sx={{ textTransform: 'none' }}
            variant="text"
            color="primary"
            onClick={addRow}
            startIcon={<Add />}
          >
            <FormattedMessage id="CustomizePreviewModal.AddProperty" defaultMessage="Add property" />
          </Button>
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="inherit" onClick={onClose}>
            <FormattedMessage id="Confirm.Cancel" defaultMessage="Canel" />
          </Button>
          <Button variant="text" type="submit" color="primary">
            <FormattedMessage id="Confirm.Save" defaultMessage="Save" />
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

CustomizePreviewModal.propTypes = {
  personProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
  setPersonProperties: PropTypes.func.isRequired,
  score: PropTypes.number.isRequired,
  setScore: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CustomizePreviewModal;
