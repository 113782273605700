export const ExtendableBuiltin = (cls) => {
  function ExtendableBuiltinInner(...rest) {
    cls.apply(this, rest);
  }

  ExtendableBuiltinInner.prototype = Object.create(cls.prototype);
  Object.setPrototypeOf(ExtendableBuiltinInner, cls);

  return ExtendableBuiltinInner;
};

export const ExtendableError = ExtendableBuiltin(Error);
