import { setError } from 'client/modules/App/AppActions';
import { connect } from 'react-redux';
import { getToken } from '../../../Auth/AuthReducer';
import {
  customMailServiceApikeyChange,
  customMailServiceDomainChange, customMailServiceFromEmailChange,
  customMailServiceTypeChange,
  setSettings,
  useCustomMailServiceChange,
} from '../../SettingsActions';
import { getCustomMailService, isUseCustomMailService, } from '../../SettingsReducer';

import MailProviderPage from './MailProviderPage';

const mapStateToProps = (state) => {
  return {
    customMailService: getCustomMailService(state),
    useCustomMailService: isUseCustomMailService(state),
    token: getToken(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetSettings: (token, settings, getErrorMessage) => {
      dispatch(setSettings(settings, getErrorMessage));
    },
    onMailServiceTypeInputChange: (event) => {
      dispatch(customMailServiceTypeChange(event.target.value));
    },
    onMailServiceApiKeyInputChange: (event) => {
      dispatch(customMailServiceApikeyChange(event.target.value));
    },
    onMailServiceDomainInputChange: (event) => {
      dispatch(customMailServiceDomainChange(event.target.value));
    },
    onMailServiceFromEmailInputChange: (event) => {
      dispatch(customMailServiceFromEmailChange(event.target.value));
    },
    onHandleToggleCustomMail: (newValue) => {
      dispatch(useCustomMailServiceChange(newValue));
    },
    setError: (error) => {
      dispatch(setError(error));
    }
  };
};

const MailProviderPageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MailProviderPage);

export default MailProviderPageContainer;
