const onMailPreviewClick = setScore => (e) => {
  e.preventDefault();
  e.stopPropagation();
  const inA = e.target.closest('a');
  if (inA) {
    const value = +(e.target.innerText || e.target.getAttribute('alt'));
    const hasValue = !Number.isNaN(value);
    if (hasValue) {
      setScore(value);
    }
  }
};

export default onMailPreviewClick;
