import {
  Add, Check, Delete, DriveFileRenameOutline, Merge,
} from '@mui/icons-material';
import {
  Box, Button, TableContainer, TableHead, Typography,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2';
import { setError, setOk } from 'client/modules/App/AppActions';
import { getToken } from 'client/modules/Auth/AuthReducer';
import {
  addTagsAction, deleteTagAction, fetchTags, replaceTagAction,
} from 'client/modules/Response/ResponseActions';
import { getAllTags } from 'client/modules/Response/ResponseReducer';
import { fetchSettings, setSettings } from 'client/modules/Settings/SettingsActions';
import { getAutoTagging, isSettingsLoaded } from 'client/modules/Settings/SettingsReducer';
import DeleteTagDialog from 'client/modules/Surveys/pages/ManageTagsPage/DeleteTagDialog';
import ReplaceTagDialog from 'client/modules/Surveys/pages/ManageTagsPage/ReplaceTagDialog';
import { fetchSurveys } from 'client/modules/Surveys/SurveyActions';
import { getSurveys } from 'client/modules/Surveys/SurveyReducer';
import surveyGroupMessages from 'client/surveyGroupMessages';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import Layout from 'components/Layout/LayoutContainer';
import Spinner from 'components/Spinner/Spinner';
import HttpStatusCodes from 'http-status-codes';
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import autotag from './assets/autotag.svg';
import styles from './ManageTagsPage.css';

const messages = defineMessages({
  title: {
    id: 'ManageTags.title',
    defaultMessage: 'Tags',
  },
  total: {
    id: 'ManageTags.total',
    defaultMessage: 'Total',
  },
  rename: {
    id: 'ManageTags.rename',
    defaultMessage: 'Rename',
  },
  merge: {
    id: 'ManageTags.merge',
    defaultMessage: 'Merge',
  },
  delete: {
    id: 'ManageTags.delete',
    defaultMessage: 'Delete',
  },
  deleteSuccess: {
    id: 'ManageTags.deleteSuccess',
    defaultMessage: 'Tag deleted successfully',
  },
  renameSuccess: {
    id: 'ManageTags.renameSuccess',
    defaultMessage: 'Tag renamed successfully',
  },
  mergeSuccess: {
    id: 'ManageTags.mergeSuccess',
    defaultMessage: 'Tag merged successfully',
  },
  addSuccess: {
    id: 'ManageTags.addSuccess',
    defaultMessage: 'Tag added successfully',
  },
  autoTagging: {
    id: 'ManageTags.aiAutoTagging',
    defaultMessage: 'AI Auto tagging (beta)',
  },
  enableAutoTagging: {
    id: 'ManageTags.enableAutoTagging',
    defaultMessage: 'Enable AI Auto tagging',
  },
  autoTaggingInstructions: {
    id: 'ManageTags.autoTaggingInstructions',
    defaultMessage: 'Instructions for AI Auto tagging',
  },
  returnToSurvey: {
    id: 'ManageTags.returnToSurvey',
    defaultMessage: 'Return to survey',
  },
  unexpectedError: {
    id: 'ManageTags.unexpectedError',
    defaultMessage: 'An unexpected error occurred',
  },
  tagAlreadyExists: {
    id: 'ManageTags.tagAlreadyExists',
    defaultMessage: 'A tag with that name already exists',
  },
});

const ManageTagsPage = () => {
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const autoTagging = useSelector(getAutoTagging);
  const [instructions, setInstructions] = useState(autoTagging?.instructions);
  const instructionsRef = React.useRef();
  useEffect(() => {
    setInstructions(autoTagging?.instructions);
  }, [autoTagging]);
  const saveSettings = useActions(setSettings);
  const [addTags, deleteTag, replaceTag] = useActions([addTagsAction, deleteTagAction, replaceTagAction]);
  const [success, failure] = useActions([setOk, setError]);
  const [isDeleteOpen, openDelete, closeDelete, deleteParams] = useOpenClose();
  const [isReplaceOpen, openReplace, closeReplace, replaceParams] = useOpenClose();
  const { id } = useParams();
  const tags = useSelector(getAllTags);
  const groups = tags?.[0]?.groups ? Object.keys(tags[0].groups) : [];
  const { formatMessage } = useIntl();
  const handleDelete = async () => {
    try {
      await deleteTag(id, deleteParams.tag.name);
      closeDelete();
      success(formatMessage(messages.deleteSuccess), 3000);
    } catch (error) {
      failure(formatMessage(messages.unexpectedError), 3000);
    }
  };
  const handleConfirm = async (newValue) => {
    try {
      if (replaceParams.op === 'add') {
        await addTags(id, newValue);
      } else {
        await replaceTag(id, replaceParams.tag.name, newValue.trim());
      }
      success(formatMessage(messages[`${replaceParams.op}Success`]), 3000);
    } catch (error) {
      if (error.status === HttpStatusCodes.CONFLICT) {
        failure(formatMessage(messages.tagAlreadyExists), 3000);
      } else {
        failure(formatMessage(messages.unexpectedError), 3000);
      }
    } finally {
      closeReplace();
    }
  };
  const handleAddTag = () => {
    openReplace({ op: 'add' });
  };
  return (
    <Layout
      returnLinkTo={`/surveys/${id}`}
      returnLinkText={formatMessage(messages.returnToSurvey)}
    >
      <Box sx={{ minHeight: 'calc(100vh - 250px)' }}>
        <Grid
          container
          spacing={4}
          sx={{
            width: '100%',
            maxWidth: 1280,
            alignSelf: 'center',
            my: 2,
            mx: 'auto',
          }}
        >
          <Helmet title={formatMessage(messages.title)} />
          <Grid xs={12}>
            <Typography variant="h1">
              {formatMessage(messages.title)}
            </Typography>
            <Typography>
              <FormattedMessage
                id="ManageTags.subtitle"
                defaultMessage="List of every tag used on some answer."
              />
            </Typography>
          </Grid>
          <Grid xs={12} md={8}>
            <TableContainer component={Paper} variant="outlined">
              <Table size="small">
                <TableHead sx={{ backgroundColor: 'background.body' }}>
                  <TableRow>
                    <TableCell>
                      <FormattedMessage
                        id="ManageTags.Tag"
                        defaultMessage="Tag"
                      />
                    </TableCell>
                    {groups.map(group => (
                      <TableCell key={group} sx={{ textAlign: 'center' }}>
                        {formatMessage(surveyGroupMessages[group])}
                      </TableCell>
                    ))}
                    <TableCell sx={{ textAlign: 'center' }}>
                      {formatMessage(messages.total)}
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="text"
                        color="primary"
                        onClick={handleAddTag}
                        sx={{ alignSelf: 'flex-end' }}
                        startIcon={<Add />}
                      >
                        <FormattedMessage
                          id="ManageTags.addTags"
                          defaultMessage="Add tags"
                        />
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!tags && (
                    <Box>
                      <Spinner />
                    </Box>
                  )}
                  {tags?.map(tag => (
                    <TableRow>
                      <TableCell>
                        <Link to={`/surveys/${id}/?tags[0]=${tag.name}`}>{tag.name}</Link>
                      </TableCell>
                      {groups.map(group => (
                        <TableCell key={group} sx={{ textAlign: 'center' }}>
                          {tag.groups[group] || 0}
                        </TableCell>
                      ))}
                      <TableCell sx={{ textAlign: 'center' }}>
                        {Object.values(tag.groups).reduce((a, b) => a + b, 0)}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          color="muted"
                          title={formatMessage(messages.rename)}
                          onClick={() => {
                            openReplace({ tag, op: 'rename' });
                          }}
                        >
                          <DriveFileRenameOutline />
                        </IconButton>
                        <IconButton
                          disabled={tag.additional}
                          color="muted"
                          title={formatMessage(messages.merge)}
                          onClick={() => {
                            openReplace({ tag, op: 'merge' });
                          }}
                        >
                          <Merge />
                        </IconButton>
                        <IconButton
                          color="muted"
                          title={formatMessage(messages.delete)}
                          onClick={() => {
                            openDelete({ tag });
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid xs={12} md={4}>
            {autoTagging && (
              <>
                <Typography variant="h2">
                  <SVGInline svg={autotag} width={32} height={32} style={{ verticalAlign: 'middle', marginRight: 8 }} />
                  {formatMessage(messages.autoTagging)}
                </Typography>
                <Typography>
                  <FormattedMessage
                    id="ManageTags.autoTaggingDescription"
                    defaultMessage="AI Auto tagging will automatically tag your responses based on the content of the response using the existing tags. This is a beta feature and may not work as expected."
                  />
                </Typography>
                {!autoTagging.enabled ? (
                  <Button
                    variant="text"
                    color="primary"
                    onClick={async () => {
                      await saveSettings({ autoTagging: { enabled: true } });
                      setTimeout(() => {
                        instructionsRef.current.focus();
                      }, 100);
                    }}
                    sx={{ my: 2 }}
                  >
                    {formatMessage(messages.enableAutoTagging)}
                  </Button>
                ) : (
                  <Box sx={{ my: 2 }}>
                    <TextField
                      inputRef={instructionsRef}
                      fullWidth
                      multiline
                      minRows={4}
                      variant="outlined"
                      value={instructions}
                      onChange={(event) => {
                        if (saved) {
                          setSaved(false);
                        }
                        setInstructions(event.target.value);
                      }}
                      label={formatMessage(messages.autoTaggingInstructions)}
                      sx={{ backgroundColor: 'background.paper' }}
                    />
                    <Box sx={{
                      display: 'flex', justifyContent: 'flex-end', alignItems: 'baseline', gap: 2,
                    }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        disabled={saving || saved}
                        onClick={async () => {
                          setSaving(true);
                          await saveSettings({ autoTagging: { instructions } });
                          setSaving(false);
                          setSaved(true);
                        }}
                        sx={{ my: 2 }}
                      >
                        <SwitchTransition>
                          <CSSTransition
                            timeout={200}
                            key={`${saved}-${saving}`}
                            classNames={{
                              enter: styles.copyButtonEnter,
                              enterActive: styles.copyButtonEnterActive,
                              exit: styles.copyButtonExit,
                              exitActive: styles.copyButtonExitActive,
                            }}
                          >
                            <Box>
                              {saving && (<FormattedMessage id="ManageTags.saving" defaultMessage="Saving..." />)}
                              {saved && (
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                  <FormattedMessage id="ManageTags.saved" defaultMessage="Saved" />
                                  <Check color="success" sx={{ fontSize: 18, mt: '2px' }} />
                                </Box>
                              )}
                              {!saving && !saved && (<FormattedMessage id="ManageTags.save" defaultMessage="Save" />)}
                            </Box>
                          </CSSTransition>
                        </SwitchTransition>
                      </Button>
                    </Box>
                    <Divider sx={{ mt: 6, mb: 2 }} />
                    <Button
                      variant="text"
                      color="error"
                      onClick={() => {
                        saveSettings({ autoTagging: { enabled: false } });
                      }}
                    >
                      <FormattedMessage id="ManageTags.disableAutoTagging" defaultMessage="Disable AI Auto tagging" />
                    </Button>
                  </Box>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <DeleteTagDialog isOpen={isDeleteOpen} onConfirm={handleDelete} close={closeDelete} tag={deleteParams.tag} />
      <ReplaceTagDialog isOpen={isReplaceOpen} onConfirm={handleConfirm} close={closeReplace} tag={replaceParams.tag} op={replaceParams.op} />
    </Layout>
  );
};

ManageTagsPage.loader = ({ getState, dispatch }) => async ({ params: { id } }) => {
  const state = getState();
  const token = getToken(state);
  const stateSurveys = getSurveys(state);
  const promises = [dispatch(fetchTags(token, id))];
  if (!stateSurveys?.length) {
    promises.push(dispatch(fetchSurveys(token)));
  }
  if (!isSettingsLoaded(state)) {
    promises.push(dispatch(fetchSettings(token)));
  }
  await Promise.all(promises);
  return null;
};
export default ManageTagsPage;
