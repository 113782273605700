import { ListAlt } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardText from '@mui/material/CardContent';
import CardTitle from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import surveyTypeMessages from 'client/modules/Surveys/surveyTypeMessages';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import withProps from '../../../../util/withProps';
import Layout from '../../../App/components/Layout/Layout';
import { inputChange, newSurvey } from '../../SurveyActions';
import { surveyMessages } from '../../SurveyReducer';

import styles from './ChooseTypePage.css';
import Ces from './Icons/Ces';
import Csat from './Icons/Csat';
import Nps from './Icons/Nps';
import Stars from './Icons/Stars';

const ChooseTypePage = (props) => {
  const { id } = props;
  const { formatMessage } = useIntl();
  const navigatge = useNavigate();

  useEffect(() => {
    props.newSurvey();
  }, [id]);
  const handleTypeSelect = (event, type) => {
    event.preventDefault();
    navigatge(`/surveys/new?type=${type}`);
  };

  let returnLinkTo = null;
  let returnLinkText = null;
  if (id) {
    returnLinkTo = `/surveys/${id}`;
    returnLinkText = formatMessage(surveyMessages.returnToSurvey);
  }

  return (
    <Layout returnLinkTo={returnLinkTo} returnLinkText={returnLinkText}>
      <div className={styles.main}>
        <h1 className={styles.mainTitle}>
          <FormattedMessage id="ChooseType.title" defaultMessage="What kind of survey would you like to create?" />
        </h1>
        <div className={styles.cards}>
          <Card className={styles.card} onClick={event => handleTypeSelect(event, 'nps')}>
            <CardMedia>
              <Nps className={`${styles.icon} ${styles.nps}`} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyTypeMessages.nps)} />
            <CardText>
              <FormattedMessage
                id="ChooseType.nps.Text"
                defaultMessage="Understand how loyal your customers are with the standard NPS© metric."
              />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyTypeMessages.choose)}
                primary
                onClick={event => handleTypeSelect(event, 'nps')}
              />
            </CardActions>
          </Card>
          <Card className={styles.card} onClick={event => handleTypeSelect(event, 'star_five')}>
            <CardMedia>
              <Stars className={`${styles.icon} ${styles.stars}`} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyTypeMessages.star_five)} />
            <CardText>
              <FormattedMessage
                id="ChooseType.star_five.Text"
                defaultMessage="Let your customers rate any part of your product or service with 1 to 5 stars."
              />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyTypeMessages.choose)}
                primary
                onClick={event => handleTypeSelect(event, 'star_five')}
              />
            </CardActions>
          </Card>
          <Card className={styles.card} onClick={event => handleTypeSelect(event, 'ces')}>
            <CardMedia>
              <Ces className={`${styles.icon} ${styles.ces}`} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyTypeMessages.ces)} />
            <CardText>
              <FormattedMessage
                id="ChooseType.ces.Text"
                defaultMessage="Gauge the effort your clients have to make to use your products or services."
              />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyTypeMessages.choose)}
                primary
                onClick={event => handleTypeSelect(event, 'ces')}
              />
            </CardActions>
          </Card>
          <Card className={styles.card} onClick={event => handleTypeSelect(event, 'csat')}>
            <CardMedia>
              <Csat className={`${styles.icon} ${styles.csat}`} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyTypeMessages.csat)} />
            <CardText>
              <FormattedMessage
                id="ChooseType.csat.Text"
                defaultMessage="Discover how satisfied your customers are with your products or services."
              />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyTypeMessages.choose)}
                primary
                onClick={event => handleTypeSelect(event, 'csat')}
              />
            </CardActions>
          </Card>
          <Card className={styles.card} onClick={event => handleTypeSelect(event, 'custom')}>
            <CardMedia>
              <ListAlt className={`${styles.icon} ${styles.custom}`} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyTypeMessages.custom)} />
            <CardText>
              <FormattedMessage
                id="ChooseType.custom.Text"
                defaultMessage="Create a survey with your own questions and answers."
              />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyTypeMessages.choose)}
                primary
                onClick={event => handleTypeSelect(event, 'custom')}
              />
            </CardActions>
          </Card>
        </div>
      </div>
    </Layout>
  );
};

ChooseTypePage.defaultProps = {
  id: null,
};

ChooseTypePage.propTypes = {
  id: PropTypes.string,
  newSurvey: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return ({
    id: ownProps.id || (ownProps.params && ownProps.params.id),
  });
};

const mapDispatchToProps = dispatch => ({
  inputChange(id, value) {
    dispatch(inputChange(id, value));
  },
  newSurvey() {
    dispatch(newSurvey());
  },
});

export default withProps(connect(mapStateToProps, mapDispatchToProps)(ChooseTypePage));
