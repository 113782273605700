import classNames from 'classnames/bind';
import HashLink from 'components/HashLink';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import Scrollspy from 'react-scrollspy';
import SVGInline from 'react-svg-inline';

import Collapse from '../../../../../App/components/Collapse/Collapse';

import logo from '../../../../../App/components/Header/promoter-ninja.svg';
import logoReverse from '../../images/promoter-ninja-reverse.svg';

import styles from './Nav.css';

const cx = classNames.bind(styles);

const Nav = ({ surveyType, handleSignup }) => {
  const collapse = useRef();
  const location = useLocation();

  useEffect(() => {
    // Location changed
    if (collapse.current) {
      collapse.current.hide();
    }
  }, [location]);

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.brand}>
          <Link to="/">
            <SVGInline svg={logoReverse} className={cx('logo', 'reverse')} />
            <SVGInline svg={logo} className={styles.logo} />
          </Link>
        </div>
        <Collapse ref={collapse}>
          <button
            type="button"
            className={`${styles.navbarToggle} collapsed`}
            data-target={`.${styles.nav}`}
            aria-expanded="false"
          >
            <span className={styles.iconBar} />
            <span className={styles.iconBar} />
            <span className={styles.iconBar} />
          </button>
        </Collapse>
        <Scrollspy
          className={cx('nav', 'collapsed')}
          items={['sec-home', 'sec-features', 'sec-product', 'sec-pricing']}
          currentClassName="is-current"
          offset={-105}
        >
          <li>
            <HashLink smooth to={{ hash: 'sec-home-a' }}>
              <FormattedMessage id="Nav.Home" defaultMessage="Home" />
            </HashLink>
          </li>
          <li>
            <HashLink smooth to={{ hash: 'sec-features-a' }}>
              <FormattedMessage id="Nav.Features" defaultMessage="Features" />
            </HashLink>
          </li>
          <li>
            <HashLink smooth to={{ hash: 'sec-product-a' }}>
              <FormattedMessage id="Nav.Product" defaultMessage="Product" />
            </HashLink>
          </li>
          <li>
            <HashLink smooth to={{ hash: 'sec-pricing-a' }}>
              <FormattedMessage id="Nav.Pricing" defaultMessage="Pricing" />
            </HashLink>
          </li>
          <li><Link to="/login"><FormattedMessage id="Nav.Login" defaultMessage="Login" /></Link></li>
          <li>
            <button type="button" onClick={handleSignup}>
              <FormattedMessage
                id="Nav.GetStarted"
                defaultMessage="Get started"
              />
            </button>
          </li>
        </Scrollspy>
      </div>
    </nav>
  );
};

Nav.defaultProps = {
  surveyType: '',
};

Nav.propTypes = {
  handleSignup: PropTypes.func.isRequired,
  surveyType: PropTypes.string,
};

export default Nav;
