import { defineMessages } from 'react-intl';

const surveyGroupMessages = defineMessages({
  promoters: {
    id: 'Group.Promoters',
    defaultMessage: 'Promoters',
  },
  passives: {
    id: 'Group.Passives',
    defaultMessage: 'Passives',
  },
  detractors: {
    id: 'Group.Detractors',
    defaultMessage: 'Detractors',
  },
  agree: {
    id: 'Group.Agree',
    defaultMessage: 'Agree',
  },
  neutral: {
    id: 'Group.Neutral',
    defaultMessage: 'Neutral',
  },
  disagree: {
    id: 'Group.Disagree',
    defaultMessage: 'Disagree',
  },
  satisfied: {
    id: 'Group.Satisfied',
    defaultMessage: 'Satisfied',
  },
  dissatisfied: {
    id: 'Group.Dissatisfied',
    defaultMessage: 'Dissatisfied',
  },
});
export default surveyGroupMessages;
