import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect, useDispatch, useSelector } from 'react-redux';

import { getI18n } from '../../../../AppReducer';
import withParams from '../../../../util/withParams';
import Spinner from '../../../App/components/Spinner/Spinner';
import { getToken } from '../../../Auth/AuthReducer';

import {
  deleteResponse, fetchResponse, fetchTags, setTags,
} from '../../ResponseActions';
import {
  getAllTags, getFetchError, getResponse, isResponseLoaded,
} from '../../ResponseReducer';

import ResponsePage from './ResponsePage';

const ResponsePageContainer = (props) => {
  const { params: { surveyId, responseId } } = props;
  const dispatch = useDispatch();
  const token = useSelector(getToken);
  const allTags = useSelector(getAllTags);
  const loaded = useSelector(isResponseLoaded(responseId));

  useEffect(() => {
    if (token && !allTags) {
      dispatch(fetchTags(token, surveyId));
    }
    if (token && !loaded) {
      dispatch(fetchResponse(token, surveyId, responseId));
    }
  }, [token, surveyId, responseId]);

  if (props.response) {
    return <ResponsePage {...props} />;
  }
  return <Spinner />;
};

ResponsePageContainer.propTypes = {
  params: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  let allTags = getAllTags(state);
  allTags = allTags?.map(tag => (Object.prototype.hasOwnProperty.call(tag, 'name') ? tag.name : tag));
  return {
    allTags,
    response: getResponse(ownProps.params.responseId)(state),
    tags: getResponse(ownProps.params.responseId)(state) ? getResponse(ownProps.params.responseId)(state).tags || [] : [],
    locale: getI18n(state).locale,
    token: getToken(state),
    fetchError: getFetchError(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteResponse: (token, surveyId, responseId, cb) => {
      dispatch(deleteResponse(token, surveyId, responseId, cb));
    },
    setTags: (token, surveyId, responseId, tags) => {
      dispatch(setTags(token, surveyId, responseId, tags));
    },
  };
};

export default withParams(connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(ResponsePageContainer)));
