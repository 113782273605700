//* * Redux toolkit slice for folder API calls */
import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithReauth from 'client/util/baseQueryWithReauth';

export const folderApiSlice = createApi({
  reducerPath: 'folderReducer',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Folder'],
  endpoints: builder => ({
    createFolder: builder.mutation({
      query: ({ name }) => ({
        url: '/folders',
        method: 'POST',
        body: { name },
      }),
      invalidatesTags: [{ type: 'Folder', id: 'LIST' }],
    }),
    updateFolder: builder.mutation({
      query: ({ _id, name }) => ({
        url: `/folders/${_id}`,
        method: 'PATCH',
        body: { name },
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'Folder',
          id: _id,
        }];
      },
    }),
    removeFolder: builder.mutation({
      query: ({ _id }) => ({
        url: `/folders/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'Folder',
          id: _id,
        }];
      },
    }),
    findFolders: builder.query({
      query: () => '/folders',
      type: 'Folder',
      providesTags: (folders) => {
        return [
          ...(folders || []).map(({ _id: id }) => ({
            type: 'Folder',
            id,
          })),
          {
            type: 'Folder',
            id: 'LIST',
          },
        ];
      },
    }),
  }),
});

export const {
  useCreateFolderMutation,
  useUpdateFolderMutation,
  useRemoveFolderMutation,
  useFindFoldersQuery,
  reducer: folderReducer,
} = folderApiSlice;
