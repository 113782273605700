import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import NPS from './NPS';

import { isChartVisible } from '../../../SurveyReducer';
import { toggleChart } from '../../../SurveyActions';

const mapStateToProps = (state) => {
  return {
    chartVisible: isChartVisible(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleChart: () => {
      dispatch(toggleChart());
    },
  };
};

const NPSContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(NPS));

export default NPSContainer;
