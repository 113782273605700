import { defineMessages } from 'react-intl';

const messages = defineMessages({
  back: {
    id: 'Person.Back',
    defaultMessage: 'Back to people',
  },
  status_created: {
    id: 'Person.status.created',
    defaultMessage: 'Survey created',
  },
  status_scheduled: {
    id: 'Person.status.scheduled',
    defaultMessage: 'Survey scheduled',
  },
  status_sent: {
    id: 'Person.status.sent',
    defaultMessage: 'Survey sent',
  },
  status_opened: {
    id: 'Person.status.opened',
    defaultMessage: 'Survey opened',
  },
  status_responded: {
    id: 'Person.status.responded',
    defaultMessage: 'Survey responded',
  },
  status_commented: {
    id: 'Person.status.commented',
    defaultMessage: 'Survey commented',
  },
  status_shown: {
    id: 'Person.status.shown',
    defaultMessage: 'Survey shown',
  },
  status_throttled: {
    id: 'Person.status.throttled',
    defaultMessage: 'Survey throttled',
  },
  status_bounced: {
    id: 'Person.status.bounced',
    defaultMessage: 'Email bounced',
  },
  status_blocked: {
    id: 'Person.status.blocked',
    defaultMessage: 'Email blocked',
  },
  status_unsubscribed: {
    id: 'Person.status.unsubscribed',
    defaultMessage: 'Email unsubscribed',
  },
  noActivities: {
    id: 'Person.noActivities',
    defaultMessage: 'No activities found',
  },
  disclaimer: {
    id: 'Person.disclaimer',
    defaultMessage: 'Activities are only tracked from May 29, 2023 onwards',
  },
  status: {
    id: 'Person.status',
    defaultMessage: 'Status',
  },
  statusHint: {
    id: 'Person.statusHint',
    defaultMessage: 'People are active if they don\'t have email bounces or have unsubscribed. '
      + 'If either of these events occurs, they are marked as inactive and cannot be surveyed again.',
  },
  personStatusActive: {
    id: 'Person.personStatusActive',
    defaultMessage: 'Active',
  },
  personStatusBlocked: {
    id: 'Person.personStatusBlocked',
    defaultMessage: 'Inactive (Bounced)',
  },
  personStatusUnsubscribed: {
    id: 'Person.personStatusUnsubscribed',
    defaultMessage: 'Inactive (Unsubscribed)',
  },
  personStatusBlocklisted: {
    id: 'Person.personStatusBlocklisted',
    defaultMessage: 'Inactive (Blocklisted)',
  },
  timesSurveyed: {
    id: 'Person.timesSurveyed',
    defaultMessage: 'Number of times surveyed',
  },
  lastSurveyed: {
    id: 'Person.lastSurveyed',
    defaultMessage: 'Last surveyed',
  },
});

export default messages;
