import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const TabPanel = (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    id, labelledby, children, value, index, selected, ...other
  } = props;

  return (
    <Box
      role="tabpanel"
      hidden={!selected}
      id={id}
      aria-labelledby={labelledby}
      sx={{ display: 'flex', flexDirection: 'column' }}
      {...other}
    >
      {selected && children}
    </Box>
  );
};

TabPanel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default TabPanel;
