import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardText from '@mui/material/CardContent';
import CardTitle from '@mui/material/CardHeader';
import { useCreateLinkMutation, useFindLinksQuery } from 'client/modules/Surveys/linkApiSlice';
import SecondaryNavTabs from 'client/modules/Surveys/pages/DashboardPage/SecondaryNavTabs';
import RaisedButton from 'components/RaisedButton/RaisedButton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Email from '../../../App/components/Icons/Email';
import SMS from '../../../App/components/Icons/SMS';
import Web from '../../../App/components/Icons/Web';
import LinkIcon from '../../../App/components/Icons/Link';
import Layout from '../../../App/components/Layout/Layout';
import { getAllowSMS } from '../../../Auth/AuthReducer';
import { inputChange } from '../../SurveyActions';
import { getSurvey, surveyMessages } from '../../SurveyReducer';

import styles from './ChooseChannel.css';

const ChooseChannel = (props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { allowSMS, id: propId } = props;
  const { id: paramId } = useParams();
  const id = propId || paramId;
  const [createLink] = useCreateLinkMutation();
  const { data, isSuccess } = useFindLinksQuery(id);
  const survey = useSelector(state => getSurvey(state, id));

  const handleImport = (event, type) => {
    event.preventDefault();
    if (type === 'share' && isSuccess && !data?.length) {
      createLink({
        survey: id,
        name: survey.name,
        properties: [],
      });
    }
    navigate(`/surveys/${id}/import/${type}`);
  };

  return (
    <Layout secondaryNav={<SecondaryNavTabs survey={id} />}>
      <div className={styles.main}>
        <h1 className={styles.mainTitle}>
          <FormattedMessage id="Channel.title" defaultMessage="Pick a channel where you want to survey people" />
        </h1>
        <div className={styles.cards}>
          <Card className={styles.card} onClick={event => handleImport(event, 'email')}>
            <CardMedia>
              <Email className={styles.icon} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyMessages.channelEmailTitle)} />
            <CardText className={styles.text}>
              <FormattedMessage id="Channel.Email.Text" defaultMessage="Send surveys to your customers by email." />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyMessages.channelChoose)}
                primary
                onClick={event => handleImport(event, 'email')}
              />
            </CardActions>
          </Card>
          <Card className={styles.card} onClick={event => handleImport(event, 'web')}>
            <CardMedia>
              <Web className={styles.icon} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyMessages.channelWebTitle)} />
            <CardText className={styles.text}>
              <FormattedMessage id="Channel.Web.Text" defaultMessage="Survey your customers directly on your website." />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyMessages.channelChoose)}
                primary
                onClick={event => handleImport(event, 'web')}
              />
            </CardActions>
          </Card>
          <Card className={styles.card} onClick={event => handleImport(event, 'share')}>
            <CardMedia>
              <LinkIcon className={styles.icon} />
            </CardMedia>
            <CardTitle title={formatMessage(surveyMessages.channelLinkTitle)} />
            <CardText className={styles.text}>
              <FormattedMessage
                id="Channel.Link.Text"
                defaultMessage="Share a link to your survey in your email, website, or anywhere else"
              />
            </CardText>
            <CardActions className={styles.actions}>
              <RaisedButton
                label={formatMessage(surveyMessages.channelChoose)}
                primary
                onClick={event => handleImport(event, 'share')}
              />
            </CardActions>
          </Card>
          {allowSMS
            ? (
              <Card className={styles.card} onClick={event => handleImport(event, 'sms')}>
                <CardMedia>
                  <SMS className={styles.icon} />
                </CardMedia>
                <CardTitle title={formatMessage(surveyMessages.channelSMSTitle)} />
                <CardText className={styles.text}>
                  <FormattedMessage id="Channel.SMS.Text" defaultMessage="Send surveys to your customers by SMS." />
                </CardText>
                <CardActions className={styles.actions}>
                  <RaisedButton
                    label={formatMessage(surveyMessages.channelChoose)}
                    primary
                    onClick={event => handleImport(event, 'sms')}
                  />
                </CardActions>
              </Card>
            )
            : null
          }
        </div>
      </div>
    </Layout>
  );
};

ChooseChannel.defaultProps = {
  id: null,
  allowSMS: false,
};

ChooseChannel.propTypes = {
  id: PropTypes.string,
  allowSMS: PropTypes.bool,
};

const mapStateToProps = state => ({
  allowSMS: getAllowSMS(state),
});

const mapDispatchToProps = dispatch => ({
  inputChange(id, value) {
    dispatch(inputChange(id, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ChooseChannel);
