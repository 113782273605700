import { Check } from '@mui/icons-material';
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Grid, Row } from 'react-flexbox-grid/dist/react-flexbox-grid';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';

import styles from './SignupPage.css';
import LoginWidget from './components/LoginWidget/LoginWidgetContainer';
import LayoutFocused from '../../../App/components/Layout/LayoutContainer';
import { loginError } from '../../AuthActions';

const messages = defineMessages({
  title: {
    id: 'Signup.Title',
    defaultMessage: 'Sign up',
  },
  error404: {
    id: 'tokenNotFound',
    defaultMessage: 'Token not found, please sign up again.',
  },
  error400: {
    id: 'tokenExpired',
    defaultMessage: 'Token expired, please sign up again.',
  },
  error500: {
    id: 'unexpectedError',
    defaultMessage: 'Unexpected error, please sign up again.',
  },
});

const SignupPage = (props) => {
  const { formatMessage } = props.intl;
  return (
    <LayoutFocused title={formatMessage(messages.title)} maxWidth="100%">
      <Grid className={styles.main} fluid>
        <Row className={styles.row}>
          <Col className={styles.left} xs={12} sm={6} md={6} lg={5}>
            <LoginWidget isSignup />
          </Col>
          <Col className={styles.right} xs={0} sm={6} md={6} lg={7}>
            <ol className={styles.bullets}>
              <li className={styles.bullet}>
                <Check className={styles.icon} />
                <div className={styles.text}>
                  <h2>
                    <FormattedMessage id="Signup.Bullet1Title" defaultMessage="The first 500 surveys are on us" />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="Signup.Bullet1Desc"
                      defaultMessage="No hidden costs. No credit card needed."
                    />
                  </p>
                </div>
              </li>
              <li className={styles.bullet}>
                <Check className={styles.icon} />
                <div className={styles.text}>
                  <h2>
                    <FormattedMessage id="Signup.Bullet2Title" defaultMessage="Start collecting feedback today" />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="Signup.Bullet2Desc"
                      defaultMessage="With only a few clicks, no IT skills required."
                    />
                  </p>
                </div>
              </li>
              <li className={styles.bullet}>
                <Check className={styles.icon} />
                <div className={styles.text}>
                  <h2><FormattedMessage id="Signup.Bullet3Title" defaultMessage="Get the most for your money" /></h2>
                  <p>
                    <FormattedMessage
                      id="Signup.Bullet3Desc"
                      defaultMessage="We cut our costs to the limit to provide the cheapest NPS tool."
                    />
                  </p>
                </div>
              </li>
            </ol>
          </Col>
        </Row>
      </Grid>
    </LayoutFocused>
  );
};

SignupPage.propTypes = {
  intl: PropTypes.any.isRequired,
};

const formatMessage = id => <FormattedMessage id={id} />;

export const showError = (store, nextState, replace, callback) => {
  const error = nextState.location.query.confirmEmailError;
  if (['404', '400', '500'].indexOf(error) > -1) {
    const errorMessage = formatMessage(messages[`error${error}`].id);
    store.dispatch(loginError(errorMessage));
  }
  callback();
};

export default injectIntl(SignupPage);
