import classNames from 'classnames/bind';
import homeMessages from 'client/modules/Public/pages/HomePage/homeMessages';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { CookieBannerUniversal, Cookies, CookiesProvider } from 'react-cookie-banner';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import Typed from 'typed.js';
import { useGoogleOneTapLogin } from '@react-oauth/google';
import bsStyle from '../../../../../App/components/Bootstrap/Bootstrap.css';
import BrowserWindow from '../../../../../App/components/BrowserWindow/BrowserWindow';
import Message from '../../../../../App/components/Message/Message';
import ResponsiveImage from '../../../../../App/components/ResponsiveImage/ResponsiveImage';
import { loginError, loginUser } from '../../../../../Auth/AuthActions';
import { getErrorMessage } from '../../../../../Auth/AuthReducer';
import { loginMessages } from '../../../../../Auth/pages/Login/components/LoginWidget/LoginWidget';
import styles from '../../../../../Auth/pages/Login/components/LoginWidget/LoginWidget.css';
import HomeInput from './components/HomeInput';
import style from './Home.css';
import snap1000 from './images/snap-1000.png';
import snap2018 from './images/snap-2018.png';
import snap500 from './images/snap-500.png';
import placeholder from './images/snap-placeholder.jpg';

const cookies = new Cookies(/* Your cookie header, on browsers defaults to document.cookie */);
const bs = classNames.bind(bsStyle);

const messages = defineMessages({
  screenShotAlt: { id: 'Home.ScreenshotAlt', defaultMessage: 'Promoter Ninja screenshot' },
  cookieBanner: {
    id: 'Home.CookieBanner',
    defaultMessage: 'Promoter Ninja uses cookies. By continuing to browse the site you\'re agreeing to our',
  },
  cookieBannerClose: {
    id: 'Home.CookieBannerClose',
    defaultMessage: 'Close',
  },
});


const Home = ({
  surveyType, signUp, errorMessage, onCloseError,
}) => {
  const el = React.useRef(null);
  const typed = useRef(null);

  useEffect(() => {
    if (!surveyType) {
      el.current.innerText = '';
      typed.current = new Typed(el.current, {
        strings: ['NPS', 'CSAT', '5-star', 'CES'],
        typeSpeed: 80,
        backSpeed: 40,
        backDelay: 2000,
        loop: true,
        startDelay: 0,
        smartBackspace: false,
      });
    }
    return () => {
      // Make sure to destroy Typed instance during cleanup to prevent memory leaks
      if (typed.current) {
        typed.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (global.window && global.window.location.hash) {
      const id = global.window.location.hash.replace('#', '');
      const elem = global.document.getElementById(id);
      if (elem) {
        elem.scrollIntoView();
      }
    }
  }, []);

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const longType = surveyType ? formatMessage(homeMessages[surveyType]) : '';
  const shortType = surveyType ? formatMessage(homeMessages[`${surveyType}Short`]) : '';

  useGoogleOneTapLogin({
    onError: (error) => {
      console.log(error);
    },
    onSuccess: (response) => {
      console.log(response);
    },
    promptMomentNotification: (notification) => {
      console.log(notification);
    },
    useOneTap: true,
    auto_select: true,
    cancel_on_tap_outside: true,
    itp_support: true,
    shape: 'pill',
    size: 'large',
    prompt_parent_id: 'intro',
  });

  return (
    <div style={{ position: 'relative' }}>
      <div className={style.intro} id="intro">
        <div className={bs('container')}>
          <div className={`${bs('row', 'center-content')} ${style.img}`}>
            <div className={`${bs('col-md-6')} ${style.left}`}>
              <h3 className={bs('mbr-title-font')}>
                <strong>
                  <FormattedMessage
                    id="Home.ClaimGeneric"
                    defaultMessage="The easiest {type} survey in the market"
                    values={{
                      type: (
                        surveyType
                          ? shortType
                          : <span className={style.surveyType}><span ref={el}>NPS</span></span>
                      ),
                    }}
                  />
                </strong>
              </h3>
              <p>
                <FormattedMessage
                  id="Home.SubClaimGeneric"
                  defaultMessage="Send {longType} surveys to your customers and gather actionable feedback."
                  values={{
                    longType,
                  }}
                />
              </p>
            </div>
            <div className={`${bs('col-md-5', 'col-md-push-1')} ${style.right}`}>
              <div className={bs('panel', 'panel-default')}>
                <div className={bs('panel-body')} style={{ textAlign: 'center' }}>
                  <div className={style.formTitle}>
                    <FormattedMessage
                      id="Home.SubClaim2"
                      defaultMessage="Send now your first 500 surveys for free."
                    />
                  </div>
                  <Message
                    type="error"
                    msg={errorMessage}
                    onClose={onCloseError}
                    className={`${styles.msg} ${errorMessage ? styles.visible : styles.hidden}`}
                  />
                  <Formsy.Form onValidSubmit={creds => signUp(creds, navigate)}>
                    <HomeInput
                      id="email"
                      name="email"
                      validations="isEmail"
                      validationError={formatMessage(loginMessages.invalidEmail)}
                      placeholder={formatMessage(loginMessages.email)}
                      required
                      autoComplete="email"
                      autoFocus
                    />
                    <HomeInput
                      id="password"
                      name="password"
                      type="password"
                      placeholder={formatMessage(loginMessages.password)}
                      required
                      autoComplete="password"
                    />
                    <button
                      type="submit"
                      className={bs('btn', 'mbr-title-font', 'btn-lg', 'btn-primary', style.cta)}
                    >
                      <FormattedMessage id="Home.CallToAction" defaultMessage="Get Started" />
                    </button>
                  </Formsy.Form>
                  <span className={style.acceptTerms}>
                    <FormattedMessage
                      id="Home.AgreeTerms"
                      defaultMessage="By creating an account you agree to our {terms} & {privacy}."
                      values={{
                        terms: (
                          <Link to={{ pathname: '/legal/terms' }}>
                            <FormattedMessage
                              id="Home.Terms"
                              defaultMessage="Terms"
                            />
                          </Link>
                        ),
                        privacy: (
                          <Link to={{ pathname: '/legal/privacy' }}>
                            <FormattedMessage
                              id="Home.Privacy"
                              defaultMessage="Privacy Policy"
                            />
                          </Link>
                        ),
                      }}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`${bs('row', 'center-content')} ${style.img} ${style.reverse}`}>
            <div className={`${bs('col-md-4')} ${style.right}`}>
              <h3>
                <FormattedMessage
                  id="Home.SegmentWithPropertiesTitle"
                  defaultMessage="Segmented analysis with just one click"
                />
              </h3>
              <p>
                <FormattedMessage
                  id="Home.SegmentWithPropertiesDescription"
                  defaultMessage="Just add some columns to the CSV you upload and we will automatically create filters for you. See NPS differences by product, geography or any other property you define."
                />
              </p>
            </div>
            <div className={`${bs('col-md-7')} ${style.left}`}>
              <BrowserWindow>
                <ResponsiveImage
                  sizes="(max-width: 767px) 100vw, 55vw"
                  placeholder={placeholder}
                  srcSet={{
                    500: snap500,
                    1000: snap1000,
                    2018: snap2018,
                  }}
                  alt={formatMessage(messages.screenShotAlt)}
                />
              </BrowserWindow>
            </div>
          </div>
        </div>
      </div>
      <div style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 10000,
      }}
      >
        <CookiesProvider cookies={cookies}>
          <CookieBannerUniversal
            message={formatMessage(messages.cookieBanner)}
            dismissOnScroll
            link={(
              <Link to="/legal/cookies" style={{ marginLeft: '0.5em' }}>
                <FormattedMessage
                  id="Home.CookieBannerLink"
                  defaultMessage="Use of Cookies"
                />
              </Link>
            )}
            buttonMessage={formatMessage(messages.cookieBannerClose)}
          />
        </CookiesProvider>
      </div>
    </div>
  );
};


const types = ['nps', 'ces', 'five-star', 'csat'];

const mapStateToProps = state => ({
  errorMessage: getErrorMessage(state),
});

const mapDispatchToProps = dispatch => ({
  signUp(creds, navigate) {
    dispatch(loginUser(creds, true, navigate));
  },
  onCloseError() {
    dispatch(loginError(null));
  },
});

Home.defaultProps = {
  surveyType: null,
  errorMessage: null,
};

Home.propTypes = {
  signUp: PropTypes.func.isRequired,
  surveyType: PropTypes.oneOf(types),
  errorMessage: PropTypes.string,
  onCloseError: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
