import { Button, ClickAwayListener, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Pagination from '@mui/material/Pagination';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { useFindBySurveyQuery, useRemoveMutation } from 'client/modules/Surveys/savedSearchApiSlice';
import SaveSearchModal from 'client/modules/Surveys/pages/DashboardPage/SaveSearchModal';
import SearchKitContext from 'client/modules/Surveys/pages/DashboardPage/SearchKitContext';
import useActions from 'client/util/useActions';
import useOpenClose from 'client/util/useOpenClose';
import { openConfirm } from 'components/Confirm/ConfirmActions';
import Spinner from 'components/Spinner/Spinner';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useContext, useEffect } from 'react';
import { IoCheckmark, IoTrashOutline } from 'react-icons/io5';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import {
  Link, useLocation, useNavigate, useNavigationType, useParams,
} from 'react-router-dom';

const messages = defineMessages({
  title: {
    id: 'SavedSearches.title',
    defaultMessage: 'Saved searches ({count})',
  },
  subtitle: {
    id: 'SavedSearches.subtitle',
    defaultMessage: 'Saved searches allow you to categorize and track responses. Save them from a search or filters on the responses tab.',
  },
  noSearches: {
    id: 'SavedSearches.noSearches',
    defaultMessage: 'You have no saved searches.',
  },
  save: {
    id: 'SavedSearches.create',
    defaultMessage: 'Save current search',
  },
  saveChanges: {
    id: 'SavedSearches.saveChanges',
    defaultMessage: 'Save changes',
  },
  saveAsNew: {
    id: 'SavedSearches.saveAsNew',
    defaultMessage: 'Save as new',
  },
  delete: {
    id: 'SavedSearches.delete',
    defaultMessage: 'Delete',
  },
  deleteConfirm: {
    id: 'SavedSearches.deleteConfirm',
    defaultMessage: 'Are you sure you want to delete this saved search?',
  },
});


const PAGE_SIZE = 5;

const SavedSearchesPopper = ({
  anchorEl, open, handleClose,
}) => {
  const { searchkit } = useContext(SearchKitContext);
  const [isModalOpen, openModal, closeModal, { createNew = false }] = useOpenClose();
  const { id: surveyId, savedSearchId } = useParams();
  const { data, isFetching } = useFindBySurveyQuery(surveyId);
  const [remove] = useRemoveMutation();
  const location = useLocation();
  const savedSearchIdx = data ? data.findIndex(s => s._id === savedSearchId) : -1;
  const [page, setPage] = React.useState(savedSearchIdx > 0 ? Math.ceil((savedSearchIdx + 1) / PAGE_SIZE) : 1);
  const count = data ? Math.ceil(data.length / PAGE_SIZE) : 0;
  const savedSearch = data?.[savedSearchIdx];
  const savedSearchTouched = location.search && savedSearch && savedSearch.query !== location.search;
  const handleOpenConfirm = useActions(openConfirm);
  const navigate = useNavigate();
  const action = useNavigationType();

  useEffect(() => {
    if (!location.search && savedSearch?.query && action) {
      searchkit.searchFromUrlQuery(savedSearch.query);
    }
  }, [location.search, savedSearch?.query, action]);

  useEffect(() => {
    if (savedSearchIdx > 0) {
      setPage(Math.ceil((savedSearchIdx + 1) / PAGE_SIZE));
    } else {
      setPage(1);
    }
  }, [savedSearchIdx]);

  const handleChangePage = (event, value) => {
    setPage(value);
  };
  const savedSearches = data?.slice((page - 1) * PAGE_SIZE, page * PAGE_SIZE);

  useEffect(() => {
    handleClose();
  }, [location.pathname, location.search]);

  const { formatMessage } = useIntl();
  const handleOpenModal = shouldCreateNew => () => openModal({ createNew: shouldCreateNew });

  const handleClick = search => () => {
    searchkit.searchFromUrlQuery(search.query);
    handleClose();
  };

  const handleDelete = search => (e) => {
    e.preventDefault();
    e.stopPropagation();
    handleOpenConfirm({
      title: (<FormattedMessage
        id="SavedSearches.ConfirmDelete"
        defaultMessage="Are you sure you want to delete this saved search?"
      />),
      text: search.name,
      onOk: async (event) => {
        event.stopPropagation();
        remove(search._id);
        if (search._id === savedSearchId) {
          navigate(`/surveys/${surveyId}`);
        }
      },
      textOk: (<FormattedMessage
        id="SavedSearches.Delete search"
        defaultMessage="Delete search"
      />),
      danger: true,
    });
  };

  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      sx={{
        zIndex: 101,
        "&[data-popper-placement^='top'] [data-popper-arrow]": {
          bottom: -2,
          '&::before': {
            boxShadow: '3px 3px 2px 0px rgba(0,0,0,0.1)',
          },
        },
        "&[data-popper-placement^='bottom'] [data-popper-arrow]": {
          top: -2,
          '&::before': {
            boxShadow: '-1px -1px 2px 0px rgba(0,0,0,0.1)',
          },
        },
      }}
      modifiers={[{
        name: 'arrow',
        enabled: true,
        options: {
          element: '[data-popper-arrow]',
        },
      }, {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      }, {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      }]}
    >
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={handleClose}>
          <Fade {...TransitionProps} timeout={350}>
            <Paper elevation={4} sx={{ minWidth: 400, display: 'flex', flexDirection: 'column' }}>
              <Box
                data-popper-arrow
                sx={{
                  bottom: 0,
                  visibility: 'hidden',
                  '&, &::before': {
                    position: 'absolute',
                    width: '8px',
                    height: '8px',
                    background: 'inherit',
                  },
                  '&::before': {
                    visibility: 'visible',
                    content: '""',
                    transform: 'rotate(45deg)',
                  },
                }}
              />
              {isFetching ? (<Spinner />)
                : (
                  <>
                    <Typography variant="h6" sx={{ px: 2, py: 1, fontWeight: 700 }}>
                      {formatMessage(messages.title, { count: data?.length })}
                    </Typography>
                    <Divider />
                    <Box sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 1,
                      '& *:hover': {
                        background: '#fbfbfe',
                      },
                    }}
                    >
                      {savedSearches?.map(search => (
                        <Box
                          component={savedSearchId === search._id ? 'div' : Link}
                          sx={{
                            p: 0.5,
                            background: savedSearchId === search._id ? '#fbfbfe' : 'inherit',
                            display: 'flex',
                            alignItems: 'center',
                            '& > button': {
                              visibility: 'hidden',
                            },
                            '&:hover > button': {
                              visibility: 'visible',
                            },
                          }}
                          {...(savedSearchId !== search._id && { onClick: handleClick(search), to: `/surveys/${search.survey}/saved-searches/${search._id}` })}
                        >
                          <Box sx={{
                            display: 'flex', alignItems: 'center', flexGrow: 1, gap: 1,
                          }}
                          >
                            <Typography>{search.name}</Typography>
                            { savedSearchId === search._id && (
                              <IoCheckmark />
                            )}
                          </Box>
                          <IconButton onClick={handleDelete(search)} sx={{ fontSize: 16, color: 'error.main' }}>
                            <IoTrashOutline />
                          </IconButton>
                        </Box>
                      ))}
                      {!savedSearches?.length && (
                        <Typography variant="body2" sx={{ textAlign: 'center' }}>
                          <FormattedMessage
                            id="SavedSearches.No saved searches"
                            defaultMessage="You have not saved any searches yet."
                          />
                        </Typography>
                      )}
                    </Box>
                    {count > 1 && (
                      <Pagination sx={{ mb: 1, alignSelf: 'center' }} size="small" page={page} count={count} onChange={handleChangePage} />
                    )}
                  </>
                )}
              <Divider />
              <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
                {savedSearchTouched && !!location.search && (
                  <Button
                    onClick={handleOpenModal(true)}
                    variant="outlined"
                    sx={{ mr: 1 }}
                  >
                    {formatMessage(messages.saveAsNew)}
                  </Button>
                )}
                <Button
                  onClick={handleOpenModal(false)}
                  disabled={!savedSearch && !location.search}
                  variant="contained"
                  color="primary"
                  sx={{ mr: 1 }}
                >
                  {formatMessage(savedSearch ? messages.saveChanges : messages.save)}
                </Button>
              </Box>
              <SaveSearchModal open={isModalOpen} onClose={closeModal} savedSearch={!isModalOpen || createNew ? null : savedSearch} />
            </Paper>
          </Fade>
        </ClickAwayListener>
      )}
    </Popper>
  );
};

SavedSearchesPopper.propTypes = {
  anchorEl: PropTypes.object,
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  savedSearches: PropTypes.array,
};

SavedSearchesPopper.defaultProps = {
  anchorEl: null,
  open: false,
  savedSearches: [],
};

export default SavedSearchesPopper;
