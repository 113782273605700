import { handleActions } from 'redux-actions';

const initialState = {
  unsubscribes: {
    system: undefined,
    features: undefined,
  },
  email: null,
  group: null,
};

export const EmailPreferencesReducer = handleActions({
  SET_EMAIL_PREFERENCES_CB: (state, action) => ({
    ...state,
    unsubscribes: action.payload.unsubscribes,
    email: action.payload.email,
    group: action.payload.group,
  }),
  FETCH_EMAIL_PREFERENCES_CB: (state, action) => ({
    ...state,
    unsubscribes: action.payload.unsubscribes,
    email: action.payload.email,
  }),
}, initialState);

export const getUnsubscribes = state => state.emailPreferences.unsubscribes;
export const getEmail = state => state.emailPreferences.email;
export const getGroup = state => state.emailPreferences.group;

export default EmailPreferencesReducer;
