/* eslint-disable-next-line react/require-default-props */
import { ExpandMore, Save, Search } from '@mui/icons-material';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import OutlinedInput from '@mui/material/OutlinedInput';
import SavedSearch from 'client/modules/Surveys/pages/DashboardPage/SavedSearch';
import SavedSearchesPopper from 'client/modules/Surveys/pages/DashboardPage/SavedSearchesPopper';
import SearchKitContext from 'client/modules/Surveys/pages/DashboardPage/SearchKitContext';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { QueryAccessor, SearchkitComponent } from 'searchkit';

const throttle = require('lodash/throttle');
const assign = require('lodash/assign');
const isUndefined = require('lodash/isUndefined');

const SearchBox = (props) => {
  const { searchkit } = useContext(SearchKitContext);
  const lastSearchMs = useRef(0);
  const [accessor, setAccessor] = useState(undefined);
  const [focused, setFocused] = React.useState(false);
  const [input, setInput] = React.useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    const {
      id,
      prefixQueryFields,
      queryFields,
      queryBuilder,
      queryOptions,
      prefixQueryOptions,
    } = props;
    const queryAccessor = new QueryAccessor(id, {
      prefixQueryFields,
      prefixQueryOptions: assign({}, prefixQueryOptions),
      queryFields: queryFields || ['_all'],
      queryOptions: assign({}, queryOptions),
      queryBuilder,
      onQueryStateChange: () => {
        if (input) {
          setInput(undefined);
        }
      },
    });
    setAccessor(queryAccessor);
    searchkit.addAccessor(queryAccessor);
    return () => {
      searchkit.removeAccessor(queryAccessor);
    };
  }, []);

  const getAccessorValue = () => {
    return `${accessor.state.getValue() || '' }`;
  };

  const getValue = () => {
    if (isUndefined(input)) {
      return getAccessorValue();
    }
    return input;
  };

  const searchQuery = (query) => {
    const shouldResetOtherState = false;
    accessor.setQueryString(query, shouldResetOtherState);
    const now = +new Date();
    const newSearch = now - lastSearchMs.current > 2000;
    lastSearchMs.current = now;
    searchkit.performSearch(newSearch);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    searchQuery(getValue());
  };

  const throttledSearch = throttle(() => {
    searchQuery(accessor.getQueryString());
  }, props.searchThrottleTime);
  const onChange = (e) => {
    const query = e.target.value;
    if (props.searchOnChange) {
      accessor.setQueryString(query);
      throttledSearch();
    } else {
      setInput(query);
    }
  };

  const setFocusState = (newFocused) => {
    if (!newFocused) {
      if (
        props.blurAction === 'search'
        && !isUndefined(input)
        && input !== getAccessorValue()
      ) {
        searchQuery(input);
      }
      setFocused(newFocused);
      setInput(undefined); // Flush (should use accessor's state now)
    } else {
      setFocused(newFocused);
    }
  };

  if (!accessor) return null;

  const translate = key => searchkit.translate(key);

  const handleOpenSavedClick = (e) => {
    e.preventDefault();
    setAnchorEl(prev => (prev ? null : e.currentTarget));
  };

  return (
    <Box
      component="form"
      onSubmit={onSubmit}
      sx={{
        position: 'relative', flexGrow: 1, display: 'flex', justifyContent: 'flex-end',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', flexGrow: 1 }}>
        <OutlinedInput
          sx={{
            width: focused ? '100%' : 280,
            backgroundColor: 'white',
            transition: 'width 200ms ease',
            pr: 0,
            '& input': { p: 1.5 },
          }}
          type="text"
          data-qa="query"
          placeholder={props.placeholder || translate('searchbox.placeholder')}
          value={getValue()}
          onFocus={setFocusState.bind(null, true)}
          onBlur={setFocusState.bind(null, false)}
          autoFocus={props.autofocus}
          onChange={onChange}
          startAdornment={<Search />}
          endAdornment={(
            <>
              <Divider sx={{ height: 'calc(100% - 2px)' }} orientation="vertical" />
              <ButtonBase
                sx={{
                  color: '#757575', height: '100%', pl: 1, pr: 0.5,
                }}
                onClick={handleOpenSavedClick}
              >
                <Save />
                <ExpandMore />
              </ButtonBase>
            </>
          )}
        />
        <SavedSearch sx={{ fontSize: 12, color: 'muted.main', mr: 1 }} />
      </Box>
      <input
        style={{ display: 'none' }}
        type="submit"
        value={translate('searchbox.button')}
        data-qa="submit"
      />
      <div
        data-qa="loader"
      />
      <SavedSearchesPopper anchorEl={anchorEl} open={!!anchorEl} handleClose={() => setAnchorEl(null)} />
    </Box>
  );
};

SearchBox.defaultProps = {
  id: 'q',
  mod: 'sk-search-box',
  searchThrottleTime: 200,
  blurAction: 'search',
};

SearchBox.propTypes = {
  ...SearchkitComponent.propTypes,
  id: PropTypes.string,
  searchOnChange: PropTypes.bool,
  searchThrottleTime: PropTypes.number,
  queryBuilder: PropTypes.func,
  queryFields: PropTypes.arrayOf(PropTypes.string),
  autofocus: PropTypes.bool,
  queryOptions: PropTypes.object,
  prefixQueryFields: PropTypes.arrayOf(PropTypes.string),
  prefixQueryOptions: PropTypes.object,
  translations: SearchkitComponent.translationsPropType(SearchBox.translations),
  mod: PropTypes.string,
  placeholder: PropTypes.string,
  blurAction: PropTypes.string,
};

export default SearchBox;
