import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import SearchKitContext from 'client/modules/Surveys/pages/DashboardPage/SearchKitContext';
import { useCreateMutation, useUpdateMutation } from 'client/modules/Surveys/savedSearchApiSlice';
import HttpStatusCodes from 'http-status-codes';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SelectedFilters } from 'searchkit';

const messages = defineMessages({
  errorNameRequired: {
    id: 'SaveSearchModal.errorNameRequired',
    defaultMessage: 'Name is required',
  },
  errorNameAlreadyExists: {
    id: 'SaveSearchModal.errorNameAlreadyExists',
    defaultMessage: 'A saved search with this name already exists',
  },
  errorSaving: {
    id: 'SaveSearchModal.errorSaving',
    defaultMessage: 'Error saving search',
  },
  save: {
    id: 'SaveSearchModal.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'SaveSearchModal.cancel',
    defaultMessage: 'Cancel',
  },
  saving: {
    id: 'SaveSearchModal.saving',
    defaultMessage: 'Saving...',
  },
  create: {
    id: 'SaveSearchModal.create',
    defaultMessage: 'Create new saved search',
  },
  update: {
    id: 'SaveSearchModal.update',
    defaultMessage: 'Update saved search',
  },
  name: {
    id: 'SaveSearchModal.name',
    defaultMessage: 'Search name',
  },
});

const SaveSearchModal = ({ open, onClose, savedSearch }) => {
  const { searchkit } = useContext(SearchKitContext);
  const { id: survey } = useParams();
  const [searchName, setSearchName] = useState(savedSearch?.name || '');
  const [error, setError] = useState(null);
  const { search: query } = useLocation();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const [create, { error: createError, isLoading: isCreating }] = useCreateMutation();
  const [update, { error: updateError, isLoading: isUpdating }] = useUpdateMutation();

  const isSaving = isCreating || isUpdating;

  useEffect(() => {
    if (createError || updateError) {
      setError(createError || updateError);
    }
  }, [createError, updateError]);

  useEffect(() => {
    setSearchName(savedSearch?.name || '');
  }, [savedSearch?.name]);

  const handleChange = (e) => {
    setSearchName(e.target.value);
  };
  const handleSave = async (e) => {
    e.preventDefault();
    const parsedQuery = pick(searchkit.buildQuery().query, ['query', 'post_filter']);
    if (!searchName) {
      setError(formatMessage(messages.errorNameRequired));
    } else {
      try {
        if (savedSearch) {
          await update({
            _id: savedSearch._id,
            name: searchName,
            query: query || savedSearch.query,
            parsedQuery,
          }).unwrap();
        } else {
          const { _id } = await create({
            survey,
            name: searchName,
            query: query,
            parsedQuery,
          }).unwrap();
          navigate(`/surveys/${survey}/saved-searches/${_id}`);
        }
        onClose();
      } catch (err) {
        if (err.status === HttpStatusCodes.CONFLICT) {
          setError(formatMessage(messages.errorNameAlreadyExists));
        } else {
          setError(formatMessage(messages.errorSaving));
        }
      }
    }
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <form onSubmit={handleSave}>
        <DialogTitle>
          {savedSearch ? formatMessage(messages.update) : formatMessage(messages.create)}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={formatMessage(messages.name)}
            fullWidth
            value={searchName}
            onChange={handleChange}
            error={!!error}
            helperText={error}
          />
          <Box sx={{ my: 2 }}>
            <SelectedFilters
              itemComponent={({ labelKey, labelValue }) => (
                <Typography
                  component="span"
                  sx={{
                    background: '#f2f2f2', marginRight: 1, px: 0.5, mb: 1, borderRadius: 1,
                  }}
                >
                  <Box component="span" sx={{ color: 'muted.main' }}>
                    {labelKey.trim()}
                    {labelKey.trim() ? ': ' : ''}
                  </Box>
                  {labelValue}
                </Typography>
              )}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>{formatMessage(messages.cancel)}</Button>
          <Button disabled={isSaving} type="submit">{formatMessage(messages[isSaving ? 'saving' : 'save'])}</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

SaveSearchModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  savedSearch: PropTypes.object,
};

SaveSearchModal.defaultProps = {
  savedSearch: null,
};

export default SaveSearchModal;
