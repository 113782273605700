import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useActions from 'client/util/useActions';
import { closeConfirm } from 'components/Confirm/ConfirmActions';
import { confirmSelector } from 'components/Confirm/ConfirmReducer';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

const Confirm = () => {
  const {
    open, title, text, onOk, textOk, textCancel, danger,
  } = useSelector(confirmSelector);
  const close = useActions(closeConfirm);

  const handleClose = (e) => {
    e.stopPropagation();
    close();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && (
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
      )}
      {text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button color="inherit" onClick={handleClose}>{textCancel || <FormattedMessage id="Confirm.Cancel" defaultMessage="Cancel" />}</Button>
        <Button color={danger ? 'error' : 'primary'} onClick={(e) => { onOk(e); handleClose(e); }} autoFocus>{textOk || <FormattedMessage id="Confirm.Ok" defaultMessage="Ok" /> }</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirm;
