import React from 'react';
import Icon from 'react-icon-base';

const FileUpload = props => (
  <Icon viewBox="0 0 40 40" {...props}>
    <g>
      <path
        d="M36.8,10.2c-0.3-0.7-0.6-1.3-1-1.7l-7-7c-0.4-0.5-1-0.8-1.7-1.1c-0.7-0.3-1.4-0.4-2-0.4h-20C4.5-0.1,4,0.1,3.6,0.6C3.2,1.1,3,1.6,3,2.1v35.8c0,0.6,0.2,1.1,0.6,1.5C4,39.8,4.5,40,5.1,40h30c0.7,0.1,1.2-0.1,1.6-0.6c0.4-0.5,0.6-1,0.6-1.5V12.1C37.2,11.6,37.1,10.9,36.8,10.2z M25.9,3c0.4,0.2,0.7,0.4,0.9,0.5l7,7c0.1,0.2,0.3,0.5,0.5,0.9h-8.4V3z M34.4,37.1H5.9V2.9H23v9.2c0,0.7,0.2,1.2,0.6,1.6c0.4,0.4,0.9,0.6,1.5,0.6h9.3V37.1z M20.9,15.6l7.2,7.2c0.3,0.3,0.4,0.7,0.2,1.1c-0.2,0.4-0.5,0.7-0.9,0.7h-4.1v7.2c0,0.3-0.1,0.5-0.4,0.7c-0.2,0.2-0.5,0.3-0.7,0.3h-4.1c-0.3,0-0.5-0.1-0.7-0.3c-0.2-0.2-0.3-0.4-0.3-0.7v-7.2h-4.1c-0.5,0-0.8-0.2-0.9-0.7c-0.2-0.4-0.1-0.8,0.2-1.1l7.2-7.2c0.2-0.2,0.4-0.4,0.7-0.4S20.7,15.4,20.9,15.6z"
      />
    </g>
  </Icon>
);

export default FileUpload;
