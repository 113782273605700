import React from 'react';
import { OutlinedInput } from '@mui/material';
import PropTypes from 'prop-types';

const ListOption = ({ setOptions, option }) => {
  const handleChange = event => {
    setOptions(o =>
      o.map(opt => (opt._id === option._id ? { ...opt, text: event.target.value } : opt)),
    );
  };
  return (
    <OutlinedInput
      size="small"
      onChange={handleChange}
      value={option.text}
      sx={{ flex: 1, backgroundColor: '#fff', width: '80%' }}
    />
  );
};

ListOption.propTypes = {
  option: PropTypes.object.isRequired,
  setOptions: PropTypes.func.isRequired,
};

export default ListOption;
