import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setError, setInfo, setOk } from '../../AppActions';
import { getError, getInfo, getOk } from '../../AppReducer';

import Layout from './Layout';

const LayoutContainer = (props) => {
  const location = useLocation();

  useEffect(() => {
    props.setError();
    props.setInfo();
    props.setOk();
  }, [location]);

  return (
    <Layout
      key={props.key}
      setError={props.setError}
      setInfo={props.setInfo}
      setOk={props.setOk}
      error={props.error}
      info={props.info}
      ok={props.ok}
      leftNav={props.leftNav}
      rightNav={props.rightNav}
      returnLinkTo={props.returnLinkTo}
      returnLinkText={props.returnLinkText}
      className={props.className}
      showHeader={props.showHeader}
      intl={props.intl}
      secondaryNav={props.secondaryNav}
      disableGutters={props.disableGutters}
      backContent={props.backContent}
    >
      <Helmet>
        <title>{props.title}</title>
        {props.description ? <meta name="description" content={props.description} /> : null}
      </Helmet>
      {props.children}
    </Layout>
  );
};

LayoutContainer.defaultProps = {
  leftNav: null,
  rightNav: null,
  children: null,
  returnLinkTo: null,
  returnLinkText: null,
  className: null,
  showHeader: true,
  error: null,
  info: null,
  ok: null,
  intl: null,
  title: 'Promoter Ninja',
  description: null,
  key: 'layout',
  secondaryNav: null,
  disableGutters: false,
  backContent: null,
};


LayoutContainer.propTypes = {
  children: PropTypes.any,
  setError: PropTypes.func.isRequired,
  setInfo: PropTypes.func.isRequired,
  setOk: PropTypes.func.isRequired,
  leftNav: PropTypes.object,
  rightNav: PropTypes.object,
  returnLinkTo: PropTypes.string,
  returnLinkText: PropTypes.string,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
  error: PropTypes.object,
  info: PropTypes.object,
  ok: PropTypes.object,
  key: PropTypes.string,
  intl: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  secondaryNav: PropTypes.node,
  disableGutters: PropTypes.bool,
  backContent: PropTypes.node,
};

const mapStateToProps = (state) => {
  return {
    error: getError(state),
    info: getInfo(state),
    ok: getOk(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setError: msg => dispatch(setError(msg)),
    setInfo: msg => dispatch(setInfo(msg)),
    setOk: msg => dispatch(setOk(msg)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LayoutContainer));
