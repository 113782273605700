import React, { forwardRef, useImperativeHandle, useState } from 'react';

import styles from './Trasher.css';

const initialState = { explode: false, left: -9999, top: -9999 };

const Trasher = (props, ref) => {
  const [state, setState] = useState(initialState);

  useImperativeHandle(ref, () => ({
    explode: (left, top) => {
      setTimeout(() => {
        setState({ explode: true, left, top });
      }, 250);
      setTimeout(() => {
        setState(initialState);
      }, 750);
    },
  }));


  const className = state.explode ? `${styles.trasher} ${styles.explode}` : styles.trasher;
  return <div className={className} style={{ left: `${state.left}px`, top: `${state.top}px` }} />;
};

export default forwardRef(Trasher);
