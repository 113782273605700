import PropTypes from 'prop-types';
import React from 'react';
import { isFragment } from 'react-is';

const TabPanels = ({
  id, labelledby, children: childrenProp, value,
}) => {
  let childIndex = 0;
  const valueToIndex = new Map();
  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    if (process.env.NODE_ENV !== 'production') {
      if (isFragment(child)) {
        console.error(
          [
            "The TabPanels component doesn't accept a Fragment as a child.",
            'Consider providing an array instead.',
          ].join('\n'),
        );
      }
    }

    const childValue = child.props.value === undefined ? childIndex : child.props.value;
    valueToIndex.set(childValue, childIndex);
    const selected = childValue === value;

    childIndex += 1;
    return React.cloneElement(child, {
      id: `${id}-${child.props.value || childIndex}`,
      labelledby: `${labelledby}-${child.props.value || childIndex}`,
      index: childIndex,
      selected,
      ...child.props,
    });
  });
  return children;
};

TabPanels.propTypes = {
  id: PropTypes.string.isRequired,
  labelledby: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
};

export default TabPanels;
