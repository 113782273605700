import {
  CheckBox, FormatQuote, Notes, RadioButtonChecked, StarHalf,
} from '@mui/icons-material';
import {
  freeResponse, scale, selectMany, selectOne, statement,
} from 'client/modules/Surveys/pages/CustomizeSurvey/QuestionTypes';
import PropTypes from 'prop-types';
import React from 'react';

const iconMap = {
  [freeResponse]: Notes,
  [selectOne]: RadioButtonChecked,
  [selectMany]: CheckBox,
  [scale]: StarHalf,
  [statement]: FormatQuote,
};
const colorMap = {
  [freeResponse]: '#efb1dc',
  [selectOne]: '#9ebade',
  [selectMany]: '#a29acc',
  [scale]: '#e2c789',
  [statement]: '#6f6f6f',
};

const QuestionIcon = ({ type, sx }) => {
  const Icon = iconMap[type];
  const color = colorMap[type];
  return Icon ? <Icon sx={{ color, ...sx }} /> : null;
};
QuestionIcon.propTypes = {
  type: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
QuestionIcon.defaultProps = {
  sx: {},
};
export default QuestionIcon;
