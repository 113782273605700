import { ArrowDownward } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ListItemText, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import classNames from 'classnames/bind';
import styles from 'client/modules/Surveys/components/SearchDateRange/SearchDateRange.css';
import FormatDateCalendar, { formatDateCalendar } from 'client/util/FormatDateCalendar';
import isEqual from 'lodash/isEqual';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { FaCalendar } from 'react-icons/fa';
import { defineMessages, useIntl } from 'react-intl';
import useDidUpdateEffect from '../../../../../server/util/useDidUpdateEffect';

const messages = defineMessages({
  all: {
    id: 'SearchDateRange.AllTime',
    defaultMessage: 'All time',
  },
  'This week': {
    id: 'SearchDateRange.ThisWeek',
    defaultMessage: 'This week',
  },
  'Last week': {
    id: 'SearchDateRange.LastWeek',
    defaultMessage: 'Last week',
  },
  'This month': {
    id: 'SearchDateRange.ThisMonth',
    defaultMessage: 'This month',
  },
  'Last month': {
    id: 'SearchDateRange.LastMonth',
    defaultMessage: 'Last month',
  },
  'This quarter': {
    id: 'SearchDateRange.ThisQuarter',
    defaultMessage: 'This quarter',
  },
  'Last quarter': {
    id: 'SearchDateRange.LastQuarter',
    defaultMessage: 'Last quarter',
  },
  'This year': {
    id: 'SearchDateRange.ThisYear',
    defaultMessage: 'This year',
  },
  'Last year': {
    id: 'SearchDateRange.LastYear',
    defaultMessage: 'Last year',
  },
  'Custom range': {
    id: 'SearchDateRange.CustomRange',
    defaultMessage: 'Custom range...',
  },
  ok: {
    id: 'SearchDateRange.OK',
    defaultMessage: 'OK',
  },
  cancel: {
    id: 'SearchDateRange.Cancel',
    defaultMessage: 'Cancel',
  },
});

export const presets = [
  ['This week', 'now/w', 'now'],
  ['Last week', 'now/w-1w/w', 'now/w-1w'],
  ['This month', 'now/M', 'now'],
  ['Last month', 'now/M-1M/M', 'now/M-1M'],
  ['This quarter', 'now/1q', 'now'],
  ['Last quarter', 'now/q-1q/q', 'now/q-1q'],
  ['This year', 'now/y', 'now'],
  ['Last year', 'now/y-1y/y', 'now/y-1y'],
];

export const datePresets = () => ({
  now: moment(),
  'now/w': moment().startOf('isoWeek'),
  'now/w-1w/w': moment().subtract(1, 'week').startOf('isoWeek'),
  'now/w-1w': moment().subtract(1, 'week').endOf('isoWeek'),
  'now/M': moment().startOf('month'),
  'now/M-1M/M': moment().subtract(1, 'month').startOf('month'),
  'now/M-1M': moment().subtract(1, 'month').endOf('month'),
  'now/1q': moment().startOf('quarter'),
  'now/q-1q/q': moment().subtract(1, 'quarter').startOf('quarter'),
  'now/q-1q': moment().subtract(1, 'quarter').endOf('quarter'),
  'now/y': moment().startOf('year'),
  'now/y-1y/y': moment().subtract(1, 'year').startOf('year'),
  'now/y-1y': moment().subtract(1, 'year').endOf('year'),
});

const PresetFilterComponent = (props) => {
  const { value } = props;
  const { formatMessage } = useIntl();
  const renderValue = useCallback((selected) => {
    if (selected.length === 0) {
      return props.emptyValue;
    }
    const [start, end] = selected.split(':');
    const text = presets.find(p => p[1] === start && p[2] === end)[0];
    return formatMessage(messages[text]);
  }, []);

  return (
    <Select
      renderValue={renderValue}
      variant="standard"
      disableUnderline
      value={value ? value.join(':') : null}
      onChange={event => props.onSetRange(...event.target.value.split(':'))}
      className={`${styles.formInput} ${styles.time}`}
      IconComponent={({ className }) => (value?.length > 0 ? (
        <IconButton
          className={className}
          style={{ pointerEvents: 'all', top: 'calc(50% - 20px)' }}
          onClick={() => {
            if (value?.length > 0) props.onSetRange();
          }}
        >
          {value?.length > 0 ? <ClearIcon /> : <ExpandMoreIcon />}
        </IconButton>
      ) : <ExpandMoreIcon className={className} />)}
      displayEmpty
    >
      {presets.map(([text, start, end]) => (
        (
          <MenuItem
            key={text}
            value={`${start}:${end}`}
            label="hioewjr"
          >
            <ListItemText>{formatMessage(messages[text])}</ListItemText>
            {!start && end === 'now' ? null : (
              <Typography variant="body2" color="text.secondary">
                <FormatDateCalendar date={datePresets()[start]} />
                {' - '}
                <FormatDateCalendar date={datePresets()[end]} />
              </Typography>
            )}
          </MenuItem>
        )
      ))}
      <MenuItem value=":">{formatMessage(messages['Custom range'])}</MenuItem>
    </Select>
  );
};

PresetFilterComponent.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSetRange: PropTypes.func.isRequired,
  emptyValue: PropTypes.string.isRequired,
};

const getStatus = (value, alwaysOpen) => {
  if (value?.length === 2) {
    const [start, end] = value;
    return presets.find(p => p[1] === start && p[2] === end) ? 'presets' : 'custom';
  }
  return alwaysOpen ? 'presets' : 'closed';
};

const SearchDateRangeControl = ({
  className, value: valueProp, onChange, emptyValue, alwaysOpen,
}) => {
  const [value, setValue] = useState(valueProp || []);
  const [status, setStatus] = useState(getStatus(valueProp, alwaysOpen));
  const intl = useIntl();

  useDidUpdateEffect(() => {
    onChange(value, status);
  }, [status, value]);

  const showPresets = useCallback(() => {
    setStatus('presets');
  }, []);

  const onSetRange = useCallback((min, max) => {
    if ((min === '' && max !== 'now') || max === '') {
      setStatus('custom');
    } else if (typeof min === 'undefined' && typeof max === 'undefined') {
      if (!alwaysOpen) {
        setStatus('closed');
      }
      setValue([]);
    } else {
      setValue([min, max]);
    }
  }, []);

  useEffect(() => {
    if (!isEqual(value, valueProp)) {
      setValue(valueProp);
      setStatus(getStatus(valueProp, alwaysOpen));
    }
  }, [valueProp]);

  const [startDate, endDate] = value ? value.map(v => (v ? new Date(datePresets()[v] || v) : v)) : [null, null];

  const searchTimeControls = {
    closed: (
      <button type="button" className={styles.formInput} onClick={showPresets}>
        <FaCalendar />
      </button>
    ),
    presets: (
      <PresetFilterComponent value={value} onSetRange={onSetRange} emptyValue={emptyValue} />
    ),
    custom: (
      <div className={styles.dates}>
        <DatePicker
          slotProps={{ textField: { sx: { mt: 0 } } }}
          rifmFormatter={d => (d ? formatDateCalendar(intl, d) : null)}
          className={styles.dateInput}
          value={moment(startDate)}
          onChange={date => onSetRange(moment(date).format('YYYY-MM-DD'), endDate)}
        />
        <DatePicker
          slotProps={{ textField: { sx: { mt: 0, pr: 2 } } }}
          rifmFormatter={d => (d ? formatDateCalendar(intl, d) : null)}
          className={styles.dateInput}
          value={moment(endDate)}
          onChange={date => onSetRange(startDate, moment(date).format('YYYY-MM-DD'))}
        />
        <IconButton
          className={styles.clear}
          onClick={() => {
            setValue([]);
            setStatus(getStatus([], alwaysOpen));
          }}
        >
          <ClearIcon />
          <fieldset />
        </IconButton>
      </div>
    ),
  };

  return (
    <div className={classNames(className, styles.main, styles[status])}>
      {searchTimeControls[status]}
    </div>
  );
};

SearchDateRangeControl.propTypes = {
  className: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  emptyValue: PropTypes.string,
  alwaysOpen: PropTypes.bool,
};

SearchDateRangeControl.defaultProps = {
  className: null,
  value: [],
  onChange: () => {},
  emptyValue: '',
  alwaysOpen: false,
};

export default SearchDateRangeControl;
