import { createAction } from 'redux-actions';

import callApi from '../../util/apiCaller';
import { setError } from '../App/AppActions';

export const setEmailPreferencesCb = createAction('SET_EMAIL_PREFERENCES_CB');
export const fetchEmailPreferencesCb = createAction('FETCH_EMAIL_PREFERENCES_CB');

export function fetchEmailPreferences(token, payload, expiry, signature) {
  return (dispatch) => {
    if (token) {
      return callApi(dispatch, 'users/current/email-preferences', token).then((res) => {
        dispatch(fetchEmailPreferencesCb(res));
        return res;
      });
    }
    return callApi(dispatch, `email-preferences/${payload}/${expiry}/${signature}`, null).then((res) => {
      dispatch(fetchEmailPreferencesCb(res));
      return res;
    });
  };
}

export function setEmailPreferences(token, payload, expiry, signature, group, unsubscribed, getErrorMessage) {
  return (dispatch) => {
    if (token) {
      return callApi(dispatch, 'users/current/email-preferences', token, 'PUT', {
        group,
        unsubscribed,
      }).then((res) => {
        dispatch(setEmailPreferencesCb(res));
      }).catch((err) => {
        const msg = getErrorMessage(err);
        dispatch(setError(msg));
      });
    }
    return callApi(dispatch, `email-preferences/${payload}/${expiry}/${signature}`, null, 'PUT', {
      group,
      unsubscribed,
    }).then((res) => {
      dispatch(setEmailPreferencesCb(res));
    }).catch((err) => {
      const msg = getErrorMessage(err);
      dispatch(setError(msg));
    });
  };
}
