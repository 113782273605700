import useActions from 'client/util/useActions';
import React, { useEffect } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import SVGInline from 'react-svg-inline';
import LayoutFocused from '../../../App/components/LayoutFocused/LayoutFocused';
import { confirmEmail as confirmEmailAction } from '../../AuthActions';

import email from './images/email.svg';

import styles from './Login.css';

const messages = defineMessages({
  title: {
    id: 'VerifyEmail.Title',
    defaultMessage: 'Verify your email address',
  },
});

const VerifyEmailPage = () => {
  const navigate = useNavigate();
  const confirmEmail = useActions(confirmEmailAction);
  const { token } = useParams();

  useEffect(() => {
    if (token) {
      confirmEmail(token, navigate);
    }
  }, [token]);


  const { formatMessage } = useIntl();
  if (token) {
    return null;
  }
  return (
    <LayoutFocused title={formatMessage(messages.title)}>
      <div className={styles.email}>
        <SVGInline svg={email} />
      </div>
      <h1>
        <FormattedMessage
          id="VerifyEmail.Heading"
          defaultMessage="Check your email"
        />
      </h1>
      <p>
        <FormattedMessage
          id="VerifyEmail.WelcomeTo"
          defaultMessage="Welcome to Promoter Ninja! For security reasons we need to confirm your email address to grant you access."
        />
      </p>
      <p>
        <FormattedMessage
          id="VerifyEmail.PleaseCheck"
          defaultMessage="Please, check that you have received our email and click the link you will find in it to confirm your email address."
        />
      </p>
      <p>
        <FormattedMessage
          id="VerifyEmail.GoBackTo"
          defaultMessage="Go back to {homeLink}"
          values={{
            homeLink: (
              <Link to="/">
                <FormattedMessage id="ForgotPasswordSent.HomeLink" defaultMessage="promoter.ninja" />
              </Link>
            ),
          }}
        />
      </p>
    </LayoutFocused>
  );
};

export default VerifyEmailPage;
