import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHeader from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FlatButton from 'components/FlatButton/FlatButton';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import FormatDateCalendar from '../../../../util/FormatDateCalendar';
import withParams from '../../../../util/withParams';
import Layout from '../../../App/components/Layout/LayoutContainer';
import Spinner from '../../../App/components/Spinner/Spinner';
import connector from './connector';
import styles from './ExportResultsPage.css';

const ExportResultsPage = ({
  actions, survey, exportResults: {
    loading, loaded, error, data,
  },
  currentExport,
}) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  useEffect(() => {
    actions.exportResultsList(survey.id);
    actions.listen(survey.id);
    return () => actions.stopListening(survey.id);
  }, []);
  const checkExpiration = (event) => {
    const a = event.target.closest('a');
    const url = new URL(a.href);
    const expires = url.searchParams.get('Expires') * 1000;
    const now = Date.now();
    if (now > expires) {
      event.preventDefault();
      event.stopPropagation();
      actions.exportResultsList(survey.id);
      setOpen(true);
    }
  };
  return (
    <Layout returnLinkTo="back" returnLinkText={<FormattedMessage id="ExportResultsPage.returnToSurvey" defaultMessage="Return to the survey" />}>
      <div className={styles.main}>
        <h1 className={styles.mainTitle}>
          <FormattedMessage id="ExportResultsPage.title" defaultMessage="Export" />
        </h1>
        <FormattedMessage id="ExportResultsPage.subtitle" defaultMessage="Files with exported responses from your account." />
        <div className={styles.content}>
          {loading && <Spinner />}
          {loaded && (
            <Table selectable={false} className={styles.table} style={{ width: 'auto' }} bodyStyle={{ overflow: 'auto' }} fixedHeader={false}>
              <TableHeader displaySelectAll={false} adjustForCheckbox={false}>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="ExportResultsPage.Date" defaultMessage="Date" />
                  </TableCell>
                  <TableCell sx={{textAlign: 'right'}}>
                    <FormattedMessage id="ExportResultsPage.Answers" defaultMessage="Answers" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="ExportResultsPage.Status" defaultMessage="Status" />
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHeader>
              <TableBody displayRowCheckbox={false}>
                {data.map((dbExp) => {
                  const isCurrent = currentExport && dbExp._id === currentExport.id;
                  const exp = isCurrent ? currentExport : dbExp;
                  const stalled = exp.updatedAt && moment(exp.updatedAt).isBefore(moment().subtract(2, 'minutes'));
                  return (
                    <TableRow key={exp._id}>
                      <TableCell><FormatDateCalendar date={exp.createdAt} addTime addYear /></TableCell>
                      <TableCell sx={{ textAlign: 'right'}}>
                        <FormattedNumber value={exp.progress?.total || 0} />
                      </TableCell>
                      <TableCell>
                        {(() => {
                          switch (exp.status || dbExp.status) {
                            case 'pending':
                              return <FormattedMessage id="ExportResultsPage.status.pending" defaultMessage="Pending" />;
                            case 'process':
                              return (
                                <div>
                                  {stalled ? (<FormattedMessage id="ExportResultsPage.status.error" defaultMessage="Error" />) : (<FormattedMessage id="ExportResultsPage.status.process" defaultMessage="In process" />)}
                                  {!stalled && exp.progress && exp.progress.processed && (<LinearProgress value={exp.progress.processed} max={exp.progress.total} mode="determinate" />)}
                                </div>
                              );
                            case 'done':
                              return <FormattedMessage id="ExportResultsPage.status.done" defaultMessage="Done" />;
                            default:
                              return <FormattedMessage id="ExportResultsPage.status.error" defaultMessage="Error" />;
                          }
                        })()}
                      </TableCell>
                      <TableCell>
                        {exp.status === 'done' && (
                          <a href={exp.url} onClick={checkExpiration} download>
                            <FormattedMessage id="Download" defaultMessage="Download" />
                          </a>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
          {error && (
            <FormattedMessage id="ExportResultsPage.error" defaultMessage="Error loading export results. :(" />
          )}
        </div>
      </div>
      <Dialog
        title={<h1><FormattedMessage id="ExportResultsPage.expiredLink" defaultMessage="Regenerating expired link... Please click again." /></h1>}
        open={open}
        actions={[
          <FlatButton
            label={<FormattedMessage id="Close" defaultMessage="Close" />}
            primary
            onClick={handleClose}
          />,
        ]}
        onRequestClose={handleClose}
      />
    </Layout>
  );
};

ExportResultsPage.propTypes = {
  currentExport: PropTypes.object,
  exportResults: PropTypes.object.isRequired,
  survey: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
};

ExportResultsPage.defaultProps = {
  currentExport: null,
};

export default withParams(connector(ExportResultsPage));
