import { RadioGroup } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { HOC } from 'formsy-react';

const FormsyRadioGroup = (props) => {
  const muiComponent = useRef();
  const [value] = useState(props.value);


  const handleValueChange = (event, v) => {
    props.setValue(v);
    if (props.onChange) props.onChange(event, v);
  };

  let {
    validations, // eslint-disable-line no-unused-vars, prefer-const
    validationError, // eslint-disable-line no-unused-vars, prefer-const
    validationErrors, // eslint-disable-line no-unused-vars, prefer-const
    defaultSelected, // eslint-disable-line prefer-const
    value: propValue,
    ...rest // eslint-disable-line prefer-const
  } = props;

  // For backward compatibility or for
  // users used to MaterialUI, use the "defaultSelected"
  // attribute for the "value" if the value was not
  // explicitly set.
  if (typeof value === 'undefined') {
    propValue = defaultSelected;
  }
  return (
    <RadioGroup
      disabled={props.isFormDisabled()}
      {...rest}
      ref={muiComponent}
      onChange={handleValueChange}
      value={props.getValue()}
      defaultSelected={propValue}
    >
      {props.children}
    </RadioGroup>
  );
};

FormsyRadioGroup.propTypes = {
  children: PropTypes.node.isRequired,
  defaultSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  validationError: PropTypes.string,
  validationErrors: PropTypes.object,
  validations: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  isFormDisabled: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

FormsyRadioGroup.defaultProps = {
  defaultSelected: null,
  onChange: null,
  validationError: null,
  validationErrors: null,
  validations: null,
  value: null,
};

export default HOC(FormsyRadioGroup);
