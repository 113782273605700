import { createApi } from '@reduxjs/toolkit/dist/query/react';
import baseQueryWithReauth from 'client/util/baseQueryWithReauth';

export const apiKeysSlice = createApi({
  reducerPath: 'apiKeyReducer',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['apiKey'],
  endpoints: builder => ({
    createApiKey: builder.mutation({
      query: ({ name, fullAccess, permissions }) => ({
        url: '/api-keys',
        method: 'POST',
        body: { name, fullAccess, permissions },
      }),
      invalidatesTags: [{ type: 'apiKey', id: 'LIST' }],
    }),
    readApiKeys: builder.query({
      query: () => '/api-keys',
      providesTags: (apiKeys) => {
        return [
          ...(apiKeys || []).map(({ _id: id }) => ({
            type: 'apiKey',
            id,
          })),
          {
            type: 'apiKey',
            id: 'LIST',
          },
        ];
      },
    }),
    resetApiKey: builder.mutation({
      query: ({ _id }) => ({
        url: `/api-keys/${_id}/reset`,
        method: 'PUT',
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'apiKey',
          id: _id,
        }];
      },
    }),
    updateApiKey: builder.mutation({
      query: ({
        _id, name, fullAccess, permissions,
      }) => ({
        url: `/api-keys/${_id}`,
        method: 'PATCH',
        body: { name, fullAccess, permissions },
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'apiKey',
          id: _id,
        }];
      },
    }),
    deleteApiKey: builder.mutation({
      query: ({ _id }) => ({
        url: `/api-keys/${_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { _id }) => {
        return [{
          type: 'apiKey',
          id: _id,
        }];
      },
    }),
  }),
});

export const {
  useCreateApiKeyMutation,
  useReadApiKeysQuery,
  useResetApiKeyMutation,
  useUpdateApiKeyMutation,
  useDeleteApiKeyMutation,
  reducer: apiKeyReducer,
} = apiKeysSlice;
